export enum EScreenMode {
    undefined = 0,
    view = 1,
    edit = 2,
    loading = 4,
    delete = 8,
    new = 16,
    details = 32,
    manageEmployees = 64,
    newEmployee = 128,
    editEmployee = 256
};

export enum ERoleRight
{
    undefined = 0,
    visible = 1,
    editable = 2,

    reserved1 = 4,
    reserved2 = 8,

    appUi = 16,
    appBo = 32,

    reserved3 = 64,
    reserved4 = 128,

    posVb = 256,
    posVl = 512,
    roleSm = 1024,
    roleUm = 2048
}