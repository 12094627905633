import React from "react";

import { IAppContainerState } from "../../components/page/AppContainer";

export interface ITableComponentState
{

};

export interface ITableComponentProps
{
    id: string;
    config: ITableConfig;
};

export enum ETableSource
{
    // data is fetched page by page
    dynamic = 0,
    // data fetched at once
    static = 1,
     // data is already fetched just visualise it
    fetched = 2
};

export interface ITableConfig
{
    url: string;
    sourceType: ETableSource;
    fields: ITableField[];
};

export interface ITableField
{
    label: string;
    name: string;
    sortable?: boolean;
    renderer?: any;
};

export class TableComponent extends React.Component<ITableComponentProps, ITableComponentState>  {
    constructor(props: any) {
        super(props);

        this.state = {
            collapsed: false
        };
    };

    render = () => {
        return <div>
            {this.props.id}
        </div>
    };
};

export default TableComponent;