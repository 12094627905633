import { INavLink, INavLinkGroup, Nav } from "office-ui-fabric-react/lib/Nav";
import * as React from "react";
import "../../assets/css/nav.css";
import AppRouter from "../../utils/AppRouter";
import { Roles } from "../../utils/Defaults";
import Global from "../../utils/Global";
import StorageHelper from "../../utils/StorageHelper";

export interface IMainNavProps {
	navOpen: boolean;
}

export interface IMainNavState {
	navOpen: boolean;
}

export class MainNav extends React.Component<IMainNavProps, IMainNavState> {
	constructor(props: IMainNavProps) {
		super(props);

		//this.onLinkClick = this.onLinkClick.bind(this);
		this.state = {
			navOpen: this.props.navOpen,
		};
	}

	public componentWillReceiveProps(next: IMainNavProps) {
		this.setState({ navOpen: next.navOpen });
	}

	public render() {
		let ctrl: JSX.Element = this.create();

		return ctrl;
	}

	private onLinkClick? = (
		ev?: React.MouseEvent<HTMLElement>,
		item?: INavLink
	) => {
		let comp = AppRouter.route(item);

		if (comp) {
			Global.setContainer(comp as JSX.Element, "");
		}

		if (ev) {
			ev.preventDefault();
		}
	};

	// private createOld(): JSX.Element {
	//     let menu: INavLinkGroup[] = [];

	//     let g: INavLinkGroup = {
	//         links: []
	//     };

	//     g.links.push({ name: 'Dashboard', url: '/', key: 'Dashboard', icon: 'ViewAll2' });

	//     let ladmin: INavLink = { name: 'Tarifmanager', url: '', key: 'admin', icon: '' };
	//     let lVBtool: INavLink = { name: 'VB Tool', url: '', key: 'vbtool', icon: '' };
	//     let lFraudMgmt: INavLink = { name: 'Fraud Managment', url: '', key: 'lFraudMgmt', icon: '' };
	//     let lusermanOld: INavLink = { name: 'Nutzerverwaltung', url: 'https://nutzerverwaltung.otelo-easy.de/', key: 'adminNutzerOld', icon: 'Website', onClick: (event) => { event?.preventDefault(); window.open('https://nutzerverwaltung.otelo-easy.de/') } };

	//     ladmin.links = [];
	//     lVBtool.links = [];
	//     lFraudMgmt.links = [];
	//     let lbrands: INavLink = { name: 'Brands', url: '/brands', key: 'adminBrands', icon: 'Brunch', onClick: this.onLinkClick };

	//     let lhandler: INavLink = { name: 'Händler', url: '/handler', key: 'handler', icon: 'Brunch', onClick: this.onLinkClick };
	//     let lrepresentatives: INavLink = { name: 'Vertretung', url: '/representatives', key: 'representatives', icon: 'Vacation', onClick: this.onLinkClick };

	//     let lfamilies: INavLink = { name: 'Product Family', url: '/structFamily', key: 'adminFamilies', icon: 'Family', onClick: this.onLinkClick };
	//     let lserviceFamilies: INavLink = { name: 'Service Family', url: '/serviceFamily', key: 'adminServiceFamilies', icon: 'Family', onClick: this.onLinkClick };
	//     let lTreeProduct: INavLink = { name: 'Product Properties', url: '/getTreeProperties', key: 'adminTree', icon: 'Org', onClick: this.onLinkClick };
	//     let ldefCon: INavLink = { name: 'DefCon', url: '/defcon', key: 'defcon', icon: 'Org', onClick: this.onLinkClick };

	//     let mTanValidation:  INavLink = { name: 'mTan Validierung', url: '/mTanValidation', key: 'mTanValidation', icon: 'BlockContact', onClick: this.onLinkClick }

	//     ladmin.links.push(lbrands);
	//     lVBtool.links.push(lhandler);
	//     lVBtool.links.push(lrepresentatives);

	//     ladmin.links.push(lfamilies);
	//     ladmin.links.push(lTreeProduct);
	//     ladmin.links.push(lserviceFamilies);

	//     if (Global.inRole(Roles.PortalAdministrator)) {
	//         ladmin.links.push(ldefCon);
	//     }

	//     if (Global.inAnyRole([Roles.PortalAdministrator, Roles.VB, Roles.VL])) {
	//         g.links.push( { name: "Account manager" , url: "am", key: "am", onClick: this.onLinkClick,
	//             links: [
	//                 {name: "Benutzer", url: "/am/user", key: "am_user", icon: "ContactLink", onClick: this.onLinkClick},
	//                 {name: "Händler", url: "/am/account", key: "am_account", icon: "AccountManagement", onClick: this.onLinkClick},
	//                 {name: "Vertretung", url: "/am/user/substitution", key: "am_user_sub", icon: "AccountManagement", onClick: this.onLinkClick},
	//             ]});
	//     };

	//     if (Global.inRole(Roles.PortalAdministrator)) {
	//         g.links.push( { name: "Product manager" , url: "epm", key: "epm", onClick: this.onLinkClick,
	//             links: [
	//                 {name: "Tarife", url: "/epm/product", key: "epm_product", icon: "ProductVariant", onClick: this.onLinkClick},
	//                 {name: "Hardware", url: "/epm/hardware", key: "epm_hardware", icon: "MobileSelected", onClick: this.onLinkClick},
	//                 {name: "Optionen", url: "/epm/option", key: "epm_option", icon: "ClipboardList", onClick: this.onLinkClick}
	//             ]});
	//     };

	//     if (Global.inAnyRole([Roles.PortalAdministrator, Roles.HL])) {
	//         g.links.push( { name: "Hotline" , url: "hotline", key: "hotline", onClick: this.onLinkClick,
	//             links: [
	//                 {name: "Bestellungen", url: "/hotline/shop", key: "hotline_shop", icon: "ReservationOrders", onClick: this.onLinkClick}
	//             ]});
	//     };

	//     if (Global.inRole(Roles.E3_dealer_administration)) {
	//         g.links.push(lVBtool);
	//     };

	//     if (Global.inRole(Roles.E3_EPM)) {
	//         g.links.push(ladmin);
	//     };

	//     if (Global.inRole(Roles.E3_user_administration)) {
	//         g.links.push(lusermanOld);
	//     };

	//     if (Global.inRole(Roles.FraudMgmt)) {
	//         lFraudMgmt.links.push(mTanValidation)
	//         g.links.push(lFraudMgmt);
	//     };

	//     menu.push(g);

	//     let css = this.state.navOpen ? { width: '200px' } : { width: '50px' };

	//     return (
	//         <div id="nav" className={this.state.navOpen ? '' : 'dn'}>
	//             <div className="wrapper" style={css}>
	//                 <Nav
	//                     groups={menu} />
	//             </div>
	//         </div>
	//     );
	// };

	private create(): JSX.Element {
		let menu: INavLinkGroup[] = [];

		let usr = StorageHelper.getUser();

		/*main links*/
		var all: INavLink[] = [
			{
				name: "Verwaltung",
				url: "am",
				key: "am",
				onClick: this.onLinkClick,
				links: [
					{
						name: "Benutzer",
						url: "/am/user",
						key: "am.user",
						icon: "ContactLink",
						onClick: this.onLinkClick,
						disabled: true,
					},
					{
						name: "Portalzugang",
						url: "/am/portal",
						key: "am.portal",
						icon: "ClipboardList",
						onClick: this.onLinkClick,
						disabled: true,
					},
					{
						name: "Händler",
						url: "/am/account",
						key: "am.account",
						icon: "AccountManagement",
						onClick: this.onLinkClick,
						disabled: true,
					},
          {
						name: "Gruppen",
						url: "/am/groups",
						key: "am.groups",
						icon: "ClipboardList",
						onClick: this.onLinkClick,
						disabled: true,
					},
					{
						name: "Vertretung",
						url: "/vb/substitution",
						key: "vb.substitution",
						icon: "Vacation",
						onClick: this.onLinkClick,
						disabled: true,
					},
				],
			},
			{
				name: "Logger",
				url: "log",
				key: "log",
				onClick: this.onLinkClick,
				links: [
					{
						name: "Zeitschrift",
						url: "/log/journal",
						key: "log.journal",
						icon: "ClipboardList",
						disabled: true,
						onClick: this.onLinkClick,
					},
				],
			},
			{
				name: "Tarif manager",
				url: "epm",
				key: "epm",
				icon: "",
				links: [
					{
						name: "Tarife",
						url: "/epm/product",
						key: "epm.product",
						icon: "ProductVariant",
						onClick: this.onLinkClick,
						disabled: true,
					},
					{
						name: "Hardware",
						url: "/epm/hardware",
						key: "epm.hardware",
						icon: "MobileSelected",
						onClick: this.onLinkClick,
						disabled: true,
					},
					{
						name: "Optionen",
						url: "/epm/option",
						key: "epm.option",
						icon: "ClipboardList",
						onClick: this.onLinkClick,
						disabled: true,
					},
					{
						name: "Blacklist",
						url: "/epm/blacklist",
						key: "epm.blacklist",
						icon: "ClipboardList",
						onClick: this.onLinkClick,
						disabled: true,
					},
				],
			},
			{
				name: "Fraud Managment",
				url: "admin",
				key: "adm",
				onClick: this.onLinkClick,
				links: [
					{
						name: "mTan Validierung",
						url: "/admin/tanauth",
						key: "adm.tanauth",
						icon: "BlockContact",
						disabled: true,
						onClick: this.onLinkClick,
					},
					{
						name: "Monitoring",
						url: "/admin/monitoring",
						key: "adm.monitoring",
						icon: "BeerMug",
						disabled: true,
						onClick: this.onLinkClick,
					},
					{
						name: "Whitelist für Bestandskunden Aufrufe",
						url: "/admin/whitelist-bestandskunden",
						key: "adm.whitelist",
						icon: "BlockContact",
						disabled: true,
						onClick: this.onLinkClick,
					},
				],
			},
			{
				name: "Niederlassungssupport",
				url: "hotline",
				key: "hot",
				onClick: this.onLinkClick,
				links: [
					{
						name: "Bestellungen",
						url: "/hotline/shop",
						key: "hot.shop",
						icon: "ReservationOrders",
						disabled: true,
						onClick: this.onLinkClick,
					},
				],
			},
			{
				name: "Query",
				url: "query",
				key: "qur",
				onClick: this.onLinkClick,
				links: [
					{
						name: "Booking Status",
						url: "/query/bookingStatus",
						key: "qur.status",
						icon: "ReservationOrders",
						disabled: true,
						onClick: this.onLinkClick,
					},
				],
			},
			{
				name: "Orders",
				url: "orders",
				key: "order",
				onClick: this.onLinkClick,
				links: [
					{
						name: "Order History",
						url: "/order/orderHistory",
						key: "order.history",
						icon: "ReservationOrders",
						disabled: true,
						onClick: this.onLinkClick,
					},
				],
			},
			{
				name: "Booking",
				url: "booking",
				key: "bok",
				onClick: this.onLinkClick,
				links: [
					{
						name: "Process Viewer",
						url: "/booking/process",
						key: "bok.process",
						icon: "ReservationOrders",
						disabled: true,
						onClick: this.onLinkClick,
					},
					{
						name: "Archivierung",
						url: "/booking/archive",
						key: "bok.archive",
						icon: "ReservationOrders",
						disabled: true,
						onClick: this.onLinkClick,
					},
				],
			},
			{
				name: "Messages",
				url: "messages",
				key: "msg",
				onClick: this.onLinkClick,
				links: [
					{
						name: "Messages",
						url: "/messages",
						key: "msg.message",
						icon: "Message",
						disabled: true,
						onClick: this.onLinkClick,
					},
				],
			},
		];

		if (
			Global.inAnyRoleUser(usr, [
				Roles.PortalAdministrator,
				Roles.NS,
				Roles.HL,
				Roles.TemoDealer,
				Roles.TemoBackOffice,
			])
		) {
			all
				.filter((x) => x.key == "am")[0]
				.links!.filter((x) => x.key == "am.user")
				.map((x) => (x.disabled = false));
		}
		if (
			Global.inAnyRoleUser(usr, [Roles.PortalAdministrator, Roles.NS, Roles.HL])
		) {
			all
				.filter((x) => x.key == "am")[0]
				.links!.filter((x) => x.key == "am.portal")
				.map((x) => (x.disabled = false));
		}

		if (
			Global.inAnyRoleUser(usr, [
				Roles.PortalAdministrator,
				Roles.VB,
				Roles.VL,
				Roles.NS,
				Roles.NL,
				Roles.BO,
				Roles.HL,
				Roles.VBBA,
			])
		) {
			all
				.filter((x) => x.key == "am")[0]
				.links!.filter((x) => x.key == "am.account")
				.map((x) => (x.disabled = false));
		}
    if (
      Global.inAnyRoleUser(usr, [Roles.PortalAdministrator, Roles.NS, Roles.HL, Roles.TemoDealer, Roles.TemoBackOffice])
    ) {
      all
        .filter((x) => x.key == "am")[0]
        .links!.filter((x) => x.key == "am.groups")
        .map((x) => (x.disabled = false));
    }

		if (
			Global.inAnyRoleUser(usr, [
				Roles.PortalAdministrator,
				Roles.VB,
				Roles.VL,
				Roles.NS,
				Roles.NL,
				Roles.BO,
				Roles.HL,
				Roles.VBBA,
			])
		) {
			all
				.filter((x) => x.key == "bok")[0]
				.links!.filter((x) => x.key == "bok.process")
				.map((x) => (x.disabled = false));
		}

		if (
			Global.inAnyRoleUser(usr, [Roles.PortalAdministrator, Roles.DsArchive])
		) {
			all
				.filter((x) => x.key == "bok")[0]
				.links!.filter((x) => x.key == "bok.archive")
				.map((x) => (x.disabled = false));
		}

		if (
			Global.inAnyRoleUser(usr, [Roles.PortalAdministrator])
		) {
			all
				.filter((x) => x.key == "msg")[0]
				.links!.filter((x) => x.key == "msg.message")
				.map((x) => (x.disabled = false));
		}

		if (Global.inAnyRoleUser(usr, [Roles.PortalAdministrator])) {
			all
				.filter((x) => x.key == "epm")[0]
				.links!.map((x) => (x.disabled = false));
		}

		if (Global.inAnyRoleUser(usr, [Roles.PortalAdministrator])) {
			all
				.filter((x) => x.key == "log")[0]
				.links!.map((x) => (x.disabled = false));
		}

		if (Global.inAnyRoleUser(usr, [Roles.PortalAdministrator, Roles.BO])) {
			all
				.filter((x) => x.key == "hot")[0]
				.links!.map((x) => (x.disabled = false));
		}

		if (Global.inAnyRoleUser(usr, [Roles.PortalAdministrator])) {
			all
				.filter((x) => x.key == "msg")[0]
				.links!.map((x) => (x.disabled = false));
		}

		if (Global.inAnyRoleUser(usr, [Roles.PortalAdministrator])) {
			all
				.filter((x) => x.key == "qur")[0]
				.links!.map((x) => (x.disabled = false));
		}

		if (Global.inAnyRoleUser(usr, [Roles.PortalAdministrator])) {
			all
				.filter((x) => x.key == "order")[0]
				.links!.map((x) => (x.disabled = false));
		}

		if (
			Global.inAnyRoleUser(usr, [
				Roles.E3_dealer_administration,
				Roles.VB,
				Roles.VL,
			])
		) {
			all
				.filter((x) => x.key == "am")[0]
				.links!.filter((x) => x.key == "vb.substitution")
				.map((x) => (x.disabled = false));
		}

		if (
			Global.inAnyRoleUser(usr, [Roles.PortalAdministrator, Roles.FraudMgmt])
		) {
			all
				.filter((x) => x.key == "adm")[0]
				.links!.filter(
					(x) =>
						x.key == "adm.tanauth" ||
						x.key == "adm.monitoring" ||
						x.key == "adm.whitelist"
				)
				.map((x) => (x.disabled = false));
		}

		menu.push({ links: this.prepareMenu(all) });

		let css = this.state.navOpen ? { width: "200px" } : { width: "50px" };

		return (
			<div id="nav" className={this.state.navOpen ? "" : "dn"}>
				<div className="wrapper" style={css}>
					<Nav groups={menu} />
				</div>
			</div>
		);
	}

	private prepareMenu(all: INavLink[]): INavLink[] {
		var a = all.filter(
			(x) =>
				x.key == "dash" ||
				(x.links && x.links.filter((x) => !x.disabled).length > 0)
		);

		a.map((x) => {
			if (x.links) {
				x.links = x.links!.filter((y) => !y.disabled);
			}
		});

		return a;
	}
}

export default MainNav;
