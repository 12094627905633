import { Panel, PanelType } from "office-ui-fabric-react";
import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import GatewayManager from "../../utils/GatewayManager";
import { EScreenMode } from "../../model/app/Enums";

const OrderHistoryPanel = ({ onDismiss, kibanaId }) => {
    const [mode, setMode] = useState(EScreenMode.loading);
    const [kibanaData, setKibanaData] = useState(null);

    const gateManager = new GatewayManager();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await gateManager.getKibanaId(kibanaId);
                setMode(EScreenMode.view);
                setKibanaData(data);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData();
    }, [kibanaId]);

    return (
        <Panel
            isOpen={true}
            onDismiss={onDismiss}
            type={PanelType.large}
            headerText="Kibana data"
            isFooterAtBottom={true}
        >
            <Container>
                <Row>
                    <Col>
                        <pre>
                            {kibanaData ? JSON.stringify(kibanaData, null, 2) : "Loading..."}
                        </pre>
                    </Col>
                </Row>
            </Container>
        </Panel>
    );
};

export default OrderHistoryPanel;
