import React, { Component } from "react";
import { HttpMethods, MenuItems, Roles } from "../../utils/Defaults";
import { EScreenMode } from "../../model/app/Enums";
import { IBookingStatusState } from "./interfaces/IBookingStatusState";
import { IBookingStatus } from "./interfaces/IBookingStatus.interface";
import { ETableSource } from "../../components/table/enums/table-source.enum";
import TableWrapper, {
  ITableWrapperProps,
  TableConfig
} from "../../components/table/TableWrapper";
import { EControlType } from "../../components/table/enums/control-type.enum";
import AccessManager from "../../utils/AccessManager";
import Global from "../../utils/Global";
import linq from "linq";

class BookingStatus extends Component<{}, IBookingStatusState> {
  private _current?: IBookingStatus;
  private _pivotMain = "main";
  private _pivotBookingStatus = "booking";

  constructor(props: {}) {
    super(props);

    this.state = {
      mode: EScreenMode.view,
      pivot: this._pivotMain
    };
  }

  render() {
    return (
      <AccessManager roles={[Roles.PortalAdministrator, Roles.BO]}>
        {this.renderTable()}
      </AccessManager>
    );
  }

  private processContextMenu = (item, e) => {
    if (e.key == MenuItems.edit) {
      this.setState({
        mode: EScreenMode.edit,
        id: item.id,
        pivot: this._pivotMain
      });
    }
  };

  private renderTable = () => {
    let tableConfiguration = TableConfig.createInstance({
      url: `${Global.getConfiguration().envUrl}query/bookingStatus`,
      method: HttpMethods.get,
      sourceType: ETableSource.static,
      arrayName: "booking",
      sort: [],
      staticFilter: (data: IBookingStatus[], needle: string) => {
        return linq
          .from<IBookingStatus>(data)
          .where(
            (obj: IBookingStatus) =>
              obj.id.toString().indexOf(needle) >= 0 ||
              obj.brand.indexOf(needle) >= 0 ||
              obj.productFamily.indexOf(needle) >= 0 ||
              obj.businessCase.indexOf(needle) >= 0
          )
          .toArray();
      },
      columns: [
        { name: "Id", label: "Id", sortable: true },
        { name: "brand", label: "Brand", sortable: true },
        { name: "productFamily", label: "Product Family", sortable: true },
        { name: "businessCase", label: "Business Case", sortable: true },
        { name: "countBooking", label: "Count Booking", sortable: true },
        { name: "successBooking", label: "Success Booking", sortable: true },
        { name: "errorBooking", label: "Error Booking", sortable: true },
        { name: "errorQuote", label: "Error Quote", sortable: true },
        { name: "Error", label: "Error", sortable: true }
      ]
    });

    let cfg: ITableWrapperProps = {
      id: "rety",
      action: this.processContextMenu,
      onItemChanged: (u) => {
        this._current = u;
      },
      model: {
        needle: "",
        statusId: "0",
        paging: {
          start: 0,
          pageSize: null
        }
      },
      filter: [{ type: EControlType.text, key: "needle", label: "Suche" }],
      config: tableConfiguration
    };

    return (
      <>
        <h1>Buchungsstatus</h1>
        <div className="data">
          <TableWrapper {...cfg} />
        </div>
      </>
    );
  };
}

export default BookingStatus;
