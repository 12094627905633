import { IStructures } from "../model/app/IIdName";
import { IAmAccount, IAmUser, IShopOrder, IUserFilterModel } from "../screens/am/Models";
import Api from "./Api";
import ApiAsync from "./ApiAsync";
import { HttpMethods, WebSites } from "./Defaults";
import StorageHelper from "./StorageHelper";

export class GatewayManager {
    private base: string;

    constructor() {
        this.base = StorageHelper.getWebsites().filter(x=>x.key == WebSites.gate)[0].text;

        if(this.base.endsWith('/')) {
            this.base = this.base.substring(0, this.base.length - 1);
        };

        if(!ApiAsync.token) {
            ApiAsync.token = StorageHelper.getUser()!.easyToken;
        };
    };

    async getShopOrder(id) : Promise<IShopOrder> {
        const api = `${this.base}/shop/order/${id}`;

        return await ApiAsync.run<IShopOrder>(api, HttpMethods.get);
    }; 

    async patchShopOrder(id, statusId, text) : Promise<IShopOrder> {
        const api = `${this.base}/shop/order/${id}/status`;
 
        return await ApiAsync.run<IShopOrder>(api, HttpMethods.patch, {statusId: statusId, text: text});
    };     

    async getKibanaId(kibanaId: string) : Promise<any>{
        const api = `${this.base}/elastic/get/kibana/${kibanaId}`

        return await ApiAsync.run<any>(api, HttpMethods.get);
    }

    async getAllKibana() : Promise<any>{
        const api = `${this.base}/elastic/get/all/kibana`

        return await ApiAsync.run<any>(api, HttpMethods.post);
    }
};

export default GatewayManager;