import { ActionButton, Checkbox, DefaultButton, Dropdown, Panel, PanelType, Pivot, PivotItem, PrimaryButton, TextField } from "office-ui-fabric-react";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import TableFilter from "../../../components/table/TableFilter";
import { EScreenMode } from "../../../model/app/Enums";
import LoaderVf, { ELoaderVfType } from "../../../Support/LoaderVf";
import AccountManager from "../../../utils/AccountManager";
import { AmHelper, EUserType, IAmUser } from "../Models";
import * as linq from 'linq';
import IKeyText from "../../../model/app/IKeyText";
import IIdName, { IIdNameFlag, IStructures } from "../../../model/app/IIdName";
import StorageHelper from "../../../utils/StorageHelper";
import { DEFAULT_ROW_HEIGHTS } from "office-ui-fabric-react/lib/components/DetailsList/DetailsRow.styles";
import DialogLoader from "../../../Support/DialogLoader";
import Global from "../../../utils/Global";
import { EValidationOption, IValidationOption, Validation } from "../../../utils/Validation";
import { ValidationMessages } from "../../../utils/messages";
import { ITableScreen } from "../../../interfaces/ITableScreen";
import { Roles } from "../../../utils/Defaults";

export interface IUserEditProps
{
    id: string;
    void?: string;
    onDismiss: any;
};

export interface IUserEditState extends ITableScreen
{
    pivot: string;
    model?: IAmUser;
    configured: boolean;
};

export class AmUserEditPanel extends React.Component<IUserEditProps,IUserEditState> 
{
    private static prefix:string = 'ef';
    private am:AccountManager = new AccountManager();
    private val: Validation = new Validation();
    private struct:IStructures = StorageHelper.getStructures();
    private usr = StorageHelper.getUser();

    public static pivotNew = "new";
    public static pivotMain = "main";
    public static pivotRolles = "roles";
    public static pivotDealer = "dealer";

    private canedit: boolean = false;
    private isnew: boolean=false;
    private original?: IAmUser;

    constructor(props: any) {
        super(props);

        this.state = {
            mode: EScreenMode.loading,
            pivot: this.props.id == Global.getEmptyGuid() ? AmUserEditPanel.pivotNew : AmUserEditPanel.pivotMain,
            configured: false
        };
        // this.loadData();
    };

    static getPivots = () : string[] => {
        return [AmUserEditPanel.pivotMain, AmUserEditPanel.pivotDealer, AmUserEditPanel.pivotRolles, AmUserEditPanel.pivotNew];
    };

    componentDidMount(): void {
         this.loadData();
    };

    render = () => {
        let ctrl = <></>;

        this.isnew = this.props.id == Global.getEmptyGuid();
        this.canedit = this.state.mode == EScreenMode.edit || this.state.mode == EScreenMode.new;
             
        ctrl = <>
                {
                    this.state.mode == EScreenMode.loading || (this.state.model && this.state.model.error) ? 
                        <LoaderVf type={ELoaderVfType.full} onClose={(x) => {
                            var u = this.state.model as IAmUser;

                            if(u.error) {
                                u.error = undefined;
                                this.setState({model: u});
                            };
                        }} message={LoaderVf.parseApiError(this.state.model ? this.state.model.error : undefined)}/> 
                        : null
                }

            <Panel isOpen={true} onDismiss={this.onDismiss} type={PanelType.medium} headerText="Benutzer" isFooterAtBottom={true} onRenderFooterContent={this.onRenderFooterContent}>
                <Pivot onLinkClick={(e) => { this.switchView(e!.props.itemKey) }}  defaultSelectedKey={this.state.pivot}>
                    {
                        this.isnew ?
                            <PivotItem itemKey={AmUserEditPanel.pivotNew} headerText="Neu" onClick={()=>{this.switchView(AmUserEditPanel.pivotNew)}}>
                                <Container>
                                    <Row>
                                        <Col>
                                            {this.renderNew()}

                                            {
                                                this.state.configured ? 
                                                <>
                                                    {this.renderMain()}
                                                    {this.renderRoles()}
                                                </>
                                                : null
                                            }
                                        </Col>
                                    </Row>
                                </Container>
                            </PivotItem>
                            : <></>
                    }

                    {
                        this.isnew  ? null :
                        <PivotItem itemKey={AmUserEditPanel.pivotMain} headerText="Stammdaten" onClick={()=>{this.switchView(AmUserEditPanel.pivotMain)}}>
                            <Container>
                                <Row>
                                    <Col>
                                        {this.renderMain()}
                                    </Col>
                                </Row>
                            </Container>
                        </PivotItem>
                    }

                    {
                        this.isnew ? null :                 
                        <PivotItem itemKey={AmUserEditPanel.pivotRolles} headerText="Rollen" onClick={()=>{this.switchView(AmUserEditPanel.pivotRolles)}}>
                            <Container>
                                    <Row>
                                        <Col>
                                            {this.renderRoles()}
                                        </Col>
                                    </Row>
                            </Container>
                        </PivotItem>
                    }

                    {
                        !this.isnew && this.state.mode == EScreenMode.view && this.state.model && this.state.model.typeId == EUserType.dealer ?
                            <PivotItem itemKey={AmUserEditPanel.pivotDealer} headerText="Händlerdaten" onSelect={()=>{this.switchView(AmUserEditPanel.pivotDealer)}}>
                                <Container>
                                        <Row>
                                            <Col>
                                                {this.renderDealer()}
                                            </Col>
                                        </Row>
                                </Container>
                            </PivotItem>
                            : null
                    }
                </Pivot>
            </Panel>
            </>

        return ctrl;
    };

    private switchView = (key) => {
        if(this.state.pivot != key) {
            this.setState({mode: this.isnew ? this.state.mode : EScreenMode.view, pivot: key});
        };
    };

    private renderNew = () => {
        if(this.state.model)
        {
            var l = linq.from<IKeyText>(StorageHelper.getStructure('userTypes'))
            .select(x => {return {key: x.key, text: x.text}})
            .toArray()
            .filter(x => x.key);

            var u = StorageHelper.getUser();

            if(!Global.inRole(Roles.PortalAdministrator)) {
                l = linq.from<IKeyText>(l).where(x=>x.key == EUserType.dealer).toArray();
            };

            var m = this.state.model as IAmUser;

            // 2023-04-13 sm - if voidf set, then the panel is being opened from account-panel and the void should be preselected
            if(this.props.void && !m.void) {
                m.void = this.props.void;
            };

            var canedit:boolean = !this.state.configured;

            let ctrl = <Container className="ee">
                <Row>
                    <Col md={2} xs={2}>Typ</Col>
                    <Col md={4} xs={4}>
                        <Dropdown id={TableFilter.prefix + '-typeId' } disabled={!canedit} options={l}
                                selectedKey={m.typeId}
                                onChange={(e,v) => {this.onChange(e, v, "typeId")}}/>                          
                    </Col>
                </Row>

                {
                    this.state.model && this.state.model.typeId != EUserType.dealer ?
                    <Row>
                        <Col md={2} xs={2}>Benutzername</Col>
                        <Col md={4} xs={4}><TextField id={AmUserEditPanel.prefix + '-username'} errorMessage={Validation.getMessage(m, 'username')} required={true} disabled={!canedit} value={m.username} onChange={(e,v) => this.onChange(e,v, undefined)}/></Col>
                    </Row>
                    : null
                }

                {
                    this.state.model && this.state.model.typeId == EUserType.vodafone ?
                    <>
                        <Row>
                            <Col md={2} xs={2}>Controller</Col>
                            <Col md={4} xs={4}>
                                <TextField id={AmUserEditPanel.prefix + '-controllerId'} errorMessage={Validation.getMessage(m, 'controllerId')} required={false} disabled={!canedit} value={m.controllerId} onChange={(e,v) => this.onChange(e,v, undefined)}/>
                            </Col>                            
                            <Col md={2} xs={2}>Position</Col>
                            <Col md={4} xs={4}>
                                <Dropdown id={TableFilter.prefix + '-positionId' } errorMessage={Validation.getMessage(m, 'positionId')} required={true} disabled={!canedit} options={linq.from<IKeyText>(StorageHelper.getStructure('positions')).select(x => {return {key: x.key, text: x.text}}).toArray()}
                                        selectedKey={m.positionId}
                                        onChange={(e,v) => {this.onChange(e, v, "positionId")}}/>                          
                            </Col>
                        </Row>
                        <Row>
                            <Col md={2} xs={2}>Manager</Col>
                            <Col md={4} xs={4}>
                                <Dropdown id={TableFilter.prefix + '-managerId' } errorMessage={Validation.getMessage(m, 'managerId')} required={true} disabled={!canedit} options={linq.from<IKeyText>(StorageHelper.getStructure('users')).select(x => {return {key: x.key, text: x.text}}).toArray()}
                                        selectedKey={m.managerId}
                                        onChange={(e,v) => {this.onChange(e, v, "managerId")}}/>                          
                            </Col>                            
                        </Row>
                    </>
                    : <></>
                }

                {
                    this.state.model && this.state.model.typeId == EUserType.dealer ?
                    <>
                        <Row>
                            <Col md={2} xs={2}>VOID</Col>
                            <Col md={4} xs={4}>
                                <TextField id={AmUserEditPanel.prefix + '-void'} errorMessage={Validation.getMessage(m, 'void')} required={true} disabled={!canedit || !!this.props.void} value={m.void} onChange={(e,v) => this.onChange(e,v, undefined)}/>
                            </Col>                            
                        </Row>
                    </>
                    : <></>
                }
            </Container>

            return ctrl;
        }
    };

    private renderRoles = () => {
        let ctrl:JSX.Element = <></>;

        if(this.state.model) 
        {
            var all = Global.filterRoles();

            var m = this.state.model as IAmUser;

            ctrl = <Container className="ee">
                    <Row>
                        {
                            m.roles && all.map((x,i)=>{
                                return (<><Col key={'col1-' + x.id} md={2} xs={2}><Checkbox key={'cb-' + x.id} disabled={!this.canedit} onChange={(e, v)=>{this.onChangeRole(v, x.id)}} checked={m.roles.indexOf(x.id)>=0}/></Col><Col key={'col2-' + x.id} md={4} xs={4}>{x.name}</Col></>)
                            })
                        }
                    </Row>

                    {
                        Global.inAnyRole([Roles.VB]) ?
                        <Row>
                            Mit Erteilung dieses Rechts erhält der Mitarbeiter Shopmanager Rechte. Mit diesen Rechten ist der Mitarbeiter in der Lage, alle Mitarbeiter in seinem Shop selbst zu verwalten.
                        </Row>
                        : null
                    }
                </Container>
        }

        return ctrl;
    };

    private renderMain = () => {
        if(this.state.model)
        {
            var m = this.state.model as IAmUser;

            if(m.salutationId === 0)
                m.salutationId = 1;

            console.log(m)

            const ctrl = <Container className="ee">
                <Row>
                    <Col md={2} xs={2}>Benutzername</Col>
                    <Col md={4} xs={4}>{m.username}</Col>
                </Row>
                <Row>
                    <Col md={2} xs={2}>Status</Col>
                    <Col md={10} xs={10}>
                        <Dropdown id={TableFilter.prefix + '-statusId' } disabled={!this.canedit} options={linq.from<IKeyText>(StorageHelper.getStructure('statuses')).select(x => {return {key: x.key, text: x.text}}).toArray()}
                                selectedKey={m.statusId}
                                onChange={(e,v) => {this.onChange(e, v, "statusId")}}/>                          
                    </Col>
                </Row>       
                <Row>
                    <Col md={2} xs={2}>Anrede</Col>
                    <Col md={4} xs={4}>
                        <Dropdown id={TableFilter.prefix + '-salutationId' } disabled={!this.canedit} options={linq.from<IKeyText>(StorageHelper.getStructure('salutations')).select(x => {return {key: x.key, text: x.text}}).toArray()}
                                selectedKey={m.salutationId}
                                onChange={(e,v) => {this.onChange(e, v, "salutationId")}}
                                defaultSelectedKey={m.salutationId}/>                        
                    </Col>
                </Row>                          
                <Row>
                    <Col md={2} xs={2}>Vorname</Col>
                    <Col md={4} xs={4}>
                        <TextField id={AmUserEditPanel.prefix + '-firstname'} errorMessage={Validation.getMessage(m, 'firstname')} required={true} disabled={!this.canedit} value={m.firstname} onChange={(e,v) => this.onChange(e,v, undefined)}/>
                    </Col>
                    <Col md={2} xs={2}>Nachname</Col>
                    <Col md={4} xs={4}>
                        <TextField id={AmUserEditPanel.prefix + '-surname'} errorMessage={Validation.getMessage(m, 'surname')} required={true}  disabled={!this.canedit} value={m.surname} onChange={(e,v) => this.onChange(e,v, undefined)}/>
                    </Col>                    
                </Row>            
                <Row>
                    <Col md={3} xs={3}>E-Mail</Col>
                    <Col md={9} xs={9}>
                        <TextField id={AmUserEditPanel.prefix + '-email'} errorMessage={Validation.getMessage(m, 'email')} required={true} disabled={!this.canedit} value={m.email} onChange={(e,v) => this.onChange(e,v, undefined)}/>
                    </Col>
                </Row>
                <Row>
                    <Col md={3} xs={3}>Mobilfunknummer</Col>
                    <Col md={9} xs={9}>
                        <TextField id={AmUserEditPanel.prefix + '-mobile'} errorMessage={Validation.getMessage(m, 'mobile')} required={true}  disabled={!this.canedit} value={m.mobile} onChange={(e,v) => this.onChange(e,v, undefined)}/>
                    </Col>                    
                </Row>                       
            </Container>

            return ctrl;
        }
    };

    private renderDealer = () => {
        var canedit: boolean = this.state.mode == EScreenMode.edit || this.state.mode == EScreenMode.new;

        if(this.state.model)
        {
            var m = this.state.model as IAmUser;

            console.log("info--->",m.dealer.booking)

            let ctrl = <Container className="ee">
                <Row>
                    <Col md={2} xs={2}>Name</Col>
                    <Col md={10} xs={10}>{m.dealer.name}</Col>
                </Row>
                <Row>
                    <Col md={2} xs={2}>Status</Col>
                    <Col md={4} xs={4}>
                        {m.dealer.statusCode}                        
                    </Col>
                </Row>

                <Row>
                    <Col md={2} xs={2}>VOID</Col>
                    <Col md={4} xs={4}>
                        {m.dealer.id}                        
                    </Col>
                    <Col md={2} xs={2}>VPKN</Col>
                    <Col md={4} xs={4}>
                        {m.dealer.vpkn}                        
                    </Col>            
                    <Col md={2} xs={2}>FNVOID</Col>
                    <Col md={4} xs={4}>
                        {m.dealer.fnvoid}                        
                    </Col>              
                    <Col md={2} xs={2}>Sales id</Col>
                    <Col md={4} xs={4}>
                        {m.dealer.unitySalesId}                        
                    </Col>                                  
                </Row>                  

                <Row>
                    <Col md={12} xs={12}><h2>Adresse</h2></Col>
                </Row>
                
                <Row>
                    <Col md={2} xs={2}>Strasse</Col>
                    <Col md={6} xs={6}>
                        <TextField id={AmUserEditPanel.prefix + '-street'} disabled={!canedit} value={m.dealer.address.street} onChange={(e,v) => this.onChange(e,v, undefined)}/>
                    </Col>
                    <Col md={2} xs={2}>Nr.</Col>
                    <Col md={2} xs={2}>
                        <TextField id={AmUserEditPanel.prefix + '-nr'} disabled={!canedit} value={m.dealer.address.nr} onChange={(e,v) => this.onChange(e,v, undefined)}/>
                    </Col>                    
                </Row>
                <Row>
                    <Col md={2} xs={2}>Ort</Col>
                    <Col md={6} xs={6}>
                        <TextField id={AmUserEditPanel.prefix + '-city'} disabled={!canedit} value={m.dealer.address.city} onChange={(e,v) => this.onChange(e,v, undefined)}/>
                    </Col>
                    <Col md={2} xs={2}>PLZ</Col>
                    <Col md={2} xs={2}>
                        <TextField id={AmUserEditPanel.prefix + '-zip'} disabled={!canedit} value={m.dealer.address.zip} onChange={(e,v) => this.onChange(e,v, undefined)}/>
                    </Col>                    
                </Row>

                <Row>
                    <Col md={12} xs={12}><h2>Rechte</h2></Col>
                </Row>     

                <Row>
                    <Col md={2} xs={2}>Vodafone</Col>
                    <Col md={4} xs={4}>
                        <Checkbox checked={m.dealer.booking.bookVodafone} disabled={true}/>
                    </Col>
                    <Col md={2} xs={2}>Otelo</Col>
                    <Col md={4} xs={4}>
                        <Checkbox checked={m.dealer.booking.bookOtelo} disabled={true}/>
                    </Col>            
                    <Col md={2} xs={2}>Kabel</Col>
                    <Col md={4} xs={4}>
                        <Checkbox checked={m.dealer.booking.bookCable} disabled={true}/>                     
                    </Col>              
                    <Col md={2} xs={2}>DSL</Col>
                    <Col md={4} xs={4}>
                        <Checkbox checked={m.dealer.booking.bookDsl} disabled={true}/>   
                    </Col>
                    {Global.inAnyRole([Roles.PortalAdministrator])? <><Col md={2} xs={2}>Tivano</Col>
                    <Col md={4} xs={4}>
                        <Checkbox checked={m.dealer.booking.bookTivano} disabled={true}/>   
                    </Col></> : <></>}
                    <Col md={2} xs={2}>Callya</Col>
                    <Col md={4} xs={4}>
                        <Checkbox checked={m.dealer.booking.bookCallya} disabled={true}/>   
                    </Col>   
                    <Col md={2} xs={2}>Business</Col>
                    <Col md={4} xs={4}>
                        <Checkbox checked={m.dealer.booking.bookBusiness} disabled={true}/>   
                    </Col>    
                    <Col md={2} xs={2}>NBA</Col>
                    <Col md={4} xs={4}>
                        <Checkbox checked={m.dealer.booking.nbaAllowed} disabled={true}/>   
                    </Col>    
                    <Col md={2} xs={2}>VF Hardware VVL</Col>
                    <Col md={4} xs={4}>
                        <Checkbox checked={m.dealer.booking.vfVvlHardware} disabled={true}/>   
                    </Col>            
                </Row>                                       
            </Container>

            return ctrl;
        }
    };

    private onRenderFooterContent = () : JSX.Element => {
        if (this.state.pivot == AmUserEditPanel.pivotDealer) {
            return <></>
        };

        if(this.state.pivot === AmUserEditPanel.pivotMain && Global.inAnyRoleUser(this.usr, [Roles.TemoDealer, Roles.TemoBackOffice])){
            return <></>
        }

        if (this.state.pivot === AmUserEditPanel.pivotRolles && Global.inAnyRoleUser(this.usr, [Roles.HL])) {
            return <></>;
        }

        let ctrl = <></>;

        if(this.isnew && this.state.mode == EScreenMode.new) {
            ctrl = <>
                {
                    this.state.configured ? 
                    <>
                        <ActionButton iconProps={{ iconName: 'Cancel' }} text="Abbrechen" onClick={()=>{
                            var u = this.state.model as IAmUser;
                            u.error = undefined;

                            this.setState({model:u}, ()=>{
                                this.onDismiss();
                            });
                        }} />
                        <PrimaryButton text="Speichern"  onClick={()=>{
                            var u = this.state.model as IAmUser;

                            if(!this.val.validateModel(u, this.getValidationOptions())) {
                                this.setState({model: u});  
                                return;  
                            } else {                            
                                this.onSave();
                            };
                        }}/>
                    </>
                    : 
                    <PrimaryButton text="Weiter" onClick={()=>{
                        var u = this.state.model as IAmUser;

                        if(!this.val.validateModel(u, this.getValidationOptions())) {
                            this.setState({model: u, configured: false, mode: EScreenMode.new, pivot: AmUserEditPanel.pivotNew});  
                            return;  
                        } else {
                            this.setState({configured: true});
                        };
                    }}/>
                }
            </>
        } else {
            ctrl = <>
                {
                    this.state.mode == EScreenMode.edit ? 
                        <>
                            <ActionButton iconProps={{ iconName: 'Cancel' }} text="Abbrechen" onClick={()=>{
                                this.setState({model: this.original, mode:EScreenMode.view})}
                            } />
                            <PrimaryButton text="Speichern"  onClick={()=>{this.onSave()}}/>
                        </>
                        : 
                        <PrimaryButton text="Edit" onClick={()=>this.setState({mode: EScreenMode.edit})} />
                }
                </>
        };

        return <div className="ea">{ctrl}</div>;
    };

    onChangeRole = (c, r) => {
        if(this.state.model) {
            var u = this.state.model as IAmUser;

            var i = u.roles.indexOf(r);

            if(i >= 0) {
                u.roles.splice(i, 1);
            } else {
                u.roles.push(r);
            };

            this.setState({model: u});
        };
    };

    onChange = (e, v, k) => {
        var key = k;
        
        if(this.state.model)
        {
            let u = this.state.model as IAmUser;

            if(key) {
                u[key] = v.key;
            } else { //text
                key = e.target.id.replace(AmUserEditPanel.prefix + '-', '');
                u[key] = e.target.value;
            };

            this.setState({model: u});
        };
    };

    onDismiss = () => {
        var u = this.state.model as IAmUser;

        if(!u.error) {
            this.props.onDismiss();
        };
    };

    onSave = () => {
        if(this.state.pivot == AmUserEditPanel.pivotNew || this.state.pivot == AmUserEditPanel.pivotRolles || this.state.pivot == AmUserEditPanel.pivotMain) {
            this.patch();
        };
    };

    async patch() {
        var u = this.state.model as IAmUser;
        var x : any = '';

        u.error = undefined;
        this.setState({mode: EScreenMode.loading, model: u});

        if(this.state.pivot == AmUserEditPanel.pivotNew) {
            // 2023-11-03 sm - temp fix for required validation.
            if(!u.void) { u.void = '0';   };
            if(!u.password) { u.password = 'x';  };

            u.autoActivate = true;
            x = await this.am.addUser(u);
            this.setState({model: x, mode: x.error ? EScreenMode.new : EScreenMode.view, pivot: AmUserEditPanel.pivotMain});
        } else if(this.state.pivot == AmUserEditPanel.pivotRolles) {
            x = await this.am.patchRoles(u.username, u.roles);
        } else if (this.state.pivot == AmUserEditPanel.pivotMain) {
            x = await this.am.patchUser(u);
        };

        if(x.error) {
            u.error = x.error;
            this.setState({model: u});
        } else {
            this.original = JSON.parse(JSON.stringify(x as IAmUser));
            this.setState({model: x, mode: EScreenMode.view, configured: true});
        };
    };

    async loadData () {
        if(this.props.id == Global.getEmptyGuid()) {
            var u = AmHelper.getNewUser();
            this.setState({model: u, mode: EScreenMode.new});
        } else {
            var u = await this.am.getUser(this.props.id);
            this.original = JSON.parse(JSON.stringify(u as IAmUser));
            this.setState({model: u, mode: EScreenMode.view});
        };
    };

    private getValidationOptions = () : IValidationOption[] => {
        var all:IValidationOption[] = [];

        var u = this.state.model as IAmUser;

        // configured so validate the main data
        if(this.state.configured) {
            all.push({key: 'firstname', type: EValidationOption.required, message: ValidationMessages.required});
            all.push({key: 'surname', type: EValidationOption.required, message: ValidationMessages.required});
            all.push({key: 'email', type: EValidationOption.required, message: ValidationMessages.required});
            all.push({key: 'mobile', type: EValidationOption.required, message: ValidationMessages.required});
        };

        if(u.typeId == EUserType.dealer)
        {
            all.push({key: 'void', type: EValidationOption.required, message: ValidationMessages.required});
            all.push({key: 'void', type: EValidationOption.numeric, message: ValidationMessages.numeric});
            all.push({key: 'void', type: EValidationOption.exactLength, message: ValidationMessages.exactLength, target: 8});
        };

        if(u.typeId == EUserType.admin || u.typeId == EUserType.vodafone)
        {
            all.push({key: 'username', type: EValidationOption.required, message: ValidationMessages.required});
            all.push({key: 'username', type: EValidationOption.minLength, message: ValidationMessages.minLength, target: 3});
        };

        if(u.typeId == EUserType.vodafone)
        {
            all.push({key: 'positionId', type: EValidationOption.notEqual, target: '7', message: ValidationMessages.required});
            all.push({key: 'managerId', type: EValidationOption.required, message: ValidationMessages.required});

            // all.push({key: 'controllerId', type: EValidationOption.required, message: ValidationMessages.required});
            // all.push({key: 'controllerId', type: EValidationOption.minLength, message: ValidationMessages.minLength, target: 3});
        };

        return all;
    };
};

export default AmUserEditPanel;