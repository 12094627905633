import React, { useEffect, useState } from "react"
import { EScreenMode } from "../../../../model/app/Enums";
import { IAmPortalAccess } from "../../../../model/data/IAmPortalAccess";
import { Panel, PanelType, Pivot, PivotItem, TextField } from "office-ui-fabric-react";
import { Col, Container, Row } from "react-bootstrap";
import { RoleTable } from "../../role/RoleTable";
import AccountManager from "../../../../utils/AccountManager";

interface PortalAccessPanelProps extends IAmPortalAccess {
    onDismiss: () => void;
}

export const PortalAccessPanel = ({
    onDismiss,
    id,
    hourFrom,
    hourTo,
    dateFrom,
    dateTo,
    type,
    voidName
}) => {    
    const renderMain = () => {
        return (
          <Container className="ee">
            <Row>
              <Col md={2} xs={2}>
                Working Days:
              </Col>
              <Col md={10} xs={10}>
                <div id={id + "-days"}>
                  {`From ${dateFrom} to ${dateTo}`}
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={2} xs={2}>
                Working Hours:
              </Col>
              <Col md={10} xs={10}>
                <div id={id + "-hours"}>
                  {`From ${hourFrom} to ${hourTo}`}
                </div>
              </Col>
            </Row>
          </Container>
        );
      };

    return (
        <Panel
            isOpen={true}
            onDismiss={onDismiss}
            type={PanelType.medium}
            headerText="Portal Access Rules"
            isFooterAtBottom={true}
        >
            <Pivot
            >
                <PivotItem
                    headerText="Data"
                >
                    <Container>
                        <Row>
                            <Col>{renderMain()}</Col>
                        </Row>
                        <Row>
                            <RoleTable currentRuleId={id} />
                        </Row>
                    </Container>
                </PivotItem>
            </Pivot>
        </Panel>
    )
}