export enum EApiResponseStatus {
    undefined = 0,
    ok = 1,
    failed = 2
};

export enum ERAPIAction {
    undefined = 0,
    follow = 1,
    login = 2,
    show = 3,
    wait = 4
};

export interface IApiAction {
    type: ERAPIAction;
    url: string;
};

export interface IApiError {
    code: string;
    message: string;
    action: IApiAction;
};

export interface IApiResponse {
    error: IApiError,
    data: any,
    status: EApiResponseStatus,
    isAuthenticated?: boolean,
    success: boolean
};

export enum EUserStateAccount {
    New = 1,
    Active = 2,
    Inactive = 3,
    Blocked = 4,
    Firstlogon = 5,
    ResetPassword = 6,
    FraudBlock = 7,
    Unknown3 = 8
}

export default {};  