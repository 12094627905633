import { Button, IconButton, Link } from "office-ui-fabric-react";
import React from "react";
import { IPaging } from "./Models";

export interface IPaginationProps {
  model: IPaging;
  onPageChanged: any;
}

export class Pagination extends React.Component<IPaginationProps, {}> {
  private comp: JSX.Element | null = null;

  private pages: number = 0;
  private current: number = 0;

  constructor(props: any) {
    super(props);
  }

  render = () => {
    this.pages = 0;
    this.current = 0;

    if (this.props.model.all) {
      this.pages = parseInt(
        (this.props.model.all / this.props.model.pageSize).toString()
      );

      if (this.props.model.all % this.props.model.pageSize != 0) {
        this.pages++;
      }

      this.current = this.props.model.start / this.props.model.pageSize;
    }

    return (
      <div className="ep">
        <IconButton
          iconProps={{ iconName: "DoubleChevronLeft" }}
          title="first"
          ariaLabel="DoubleChevronLeftMedIcon"
          disabled={this.props.model.start == 0}
          onClick={(e) => {
            this.gotoPage(0);
          }}
        />
        <IconButton
          iconProps={{ iconName: "ChevronLeft" }}
          title="prev"
          ariaLabel="DoubleChevronLeftMedIcon"
          disabled={this.props.model.start == 0}
          onClick={(e) => {
            this.gotoPage(this.current - 1);
          }}
        />
        {this.renderLinks()}
        <IconButton
          iconProps={{ iconName: "ChevronRight" }}
          title="next"
          ariaLabel="DoubleChevronLeftMedIcon"
          disabled={this.pages == 0 || this.current == this.pages - 1}
          onClick={(e) => {
            this.gotoPage(this.current + 1);
          }}
        />
        <IconButton
          iconProps={{ iconName: "DoubleChevronRight" }}
          title="last"
          ariaLabel="DoubleChevronLeftMedIcon"
          disabled={this.pages == 0 || this.current == this.pages - 1}
          onClick={(e) => {
            this.gotoPage(this.pages);
          }}
        />
        <Link className="ld">all: {this.props.model.all}</Link>
      </div>
    );
  };

  private renderLinks() {
    var res: JSX.Element[] = [];

    var start = this.current - 2;

    if (start < 0) {
      start = 0;
    }

    for (let i = start; i < start + 5; i++) {
      if (i >= this.pages) {
        break;
      }

      var c = (
        <Link
          className="ld"
          iconProps={{ iconName: "none" }}
          key={"page" + i}
          disabled={i == this.current}
          onClick={(e) => {
            this.gotoPage(i);
          }}
        >
          [{i + 1}]
        </Link>
      );

      res.push(c);
    }

    return <span className="digits">{res}</span>;
  }

  private gotoPage(target: any) {
    this.props.onPageChanged(target);
  }

  private firstEnabled() {
    if (this.props.model.all) {
    }
  }
}

export default Pagination;
