import * as React from 'react';
import linq from 'linq';
import { ErrorMessages, ValidationMessages } from '../utils/messages';
import { Container, Row, Col } from 'react-bootstrap';
import { TextField, Link, Spinner, SpinnerSize, Dialog, DialogType, DialogFooter } from 'office-ui-fabric-react';
import Button from 'react-bootstrap/Button';
import AppRouter from '../utils/AppRouter';
import StorageHelper from '../utils/StorageHelper';
import IUser from '../model/app/IUser';
import { IApiResponse } from '../model/app/IApiResponse';
import Api from '../utils/Api';
import Global from '../utils/Global';
import { EApiResultStatus } from './editPassword';
import { EValidationOption, IValidationOption, Validation } from '../utils/Validation';
import IKeyText from '../model/app/IKeyText';
import { WebSites } from '../utils/Defaults';
import AccountManager from '../utils/AccountManager';
import { IAmProfilePatchDTORequest } from './am/Models';
import { EScreenMode } from '../model/app/Enums';
import LoaderVf, { ELoaderVfType, EMessageType } from '../Support/LoaderVf';

export interface IEditUserScreenProps {

};

export interface IEditUserScreenState {
    changed: boolean;
    spinner: boolean;
    hidden: boolean;
    disableSaveName: boolean;
    disableSaveSurname: boolean;
    mode: EScreenMode;
    model: SaveProfile;
    okMessage: boolean;
};

export class SaveProfile {
    public clientId: number = 0;
    public username: string = '';
    public firstName: string = '';
    public secondName: string = '';
    public email: string = '';
    public mobile: string = ''
    public error: any = undefined
}

export class EditUser extends React.Component<IEditUserScreenProps, IEditUserScreenState> {
    private model: SaveProfile = new SaveProfile();
    private url: string = "activationdata/";
    private successfulSaving: string = 'Erfolgreich gespeichert'
    private val: Validation = new Validation();
    private websites: IKeyText[] = StorageHelper.getWebsites();
    private am: AccountManager = new AccountManager();

    constructor(props: IEditUserScreenProps) {
        super(props);

        let u: IUser | undefined = StorageHelper.getUser();

        if (u !== undefined) {
            this.model.username = u.username;
            this.model.clientId = u.ClientId;
        }

        this.state = {
            changed: false,
            spinner: false,
            hidden: true,
            disableSaveName: false,
            disableSaveSurname: false,
            mode: EScreenMode.loading,
            model: new SaveProfile(),
            okMessage: false
        };

        this._refreshModel = this._refreshModel.bind(this);
        this.onDataArrival = this.onDataArrival.bind(this);
        this._onNo = this._onNo.bind(this);

        this.LoadData();
    };

    public render() {
        let ctrl = (
            <>
                {
                    this.state.mode == EScreenMode.loading || (this.state.model && this.state.model.error) || this.state.okMessage
                        ?
                        <LoaderVf type={ELoaderVfType.full} message={this.state.okMessage ? { title: '', body: 'Erfolgreich gespeichert', type: EMessageType.content } :
                            (LoaderVf.parseApiError(this.state.model.error ? this.state.model.error : undefined))
                        } />
                        :
                        null
                }
                <Dialog
                    hidden={this.state.hidden}
                    onDismiss={this._onNo}
                    minWidth={600}
                    dialogContentProps={{
                        type: DialogType.normal,
                        title: this.successfulSaving
                    }}
                    modalProps={{
                        titleAriaId: this.successfulSaving,
                        // subtitleAriaId: t2,
                        isBlocking: false,
                        containerClassName: 'ms-dialogMainOverride'
                    }}
                >
                    <DialogFooter>
                    </DialogFooter>
                </Dialog>

                <Container style={{ backgroundColor: '#F8F8F8', width: '1000px', borderColor: '#C0C0C0', borderRadius: '15px', boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.19)' }}>
                    <Row className="justify-content-md-center">
                        <Col xs={6} md={6}>
                            <TextField label="Vorname" errorMessage={Validation.getMessage(this.state.model, 'firstName')} validateOnLoad={false} maxLength={30} name="firstName" required value={this.state.model.firstName} onChange={this._refreshModel} />
                        </Col>
                    </Row>
                    <Row className="justify-content-md-center">
                        <Col xs={6} md={6}>
                            <TextField label="Nahname" errorMessage={Validation.getMessage(this.state.model, 'secondName')} validateOnLoad={false} maxLength={30} name="secondName" required value={this.state.model.secondName} onChange={this._refreshModel} />
                        </Col>
                    </Row>
                    <Row className="justify-content-md-center">
                        <Col xs={6} md={6}>
                            <TextField label="Mobilfunknummer" errorMessage={Validation.getMessage(this.state.model, 'mobile')} id="mobileProfile" name="mobile" required value={this.state.model.mobile} onChange={this._refreshModel} />
                        </Col>
                    </Row>
                    <Row className="justify-content-md-center">
                        <Col xs={6} md={6}>
                            <TextField label="Email" name="email" required errorMessage={Validation.getMessage(this.state.model, 'email')} value={this.state.model.email} onChange={this._refreshModel} />
                        </Col>
                    </Row>
                    <br />
                    <Row className="justify-content-md-center">
                        <Col xs={12} md={12}>
                            <p style={{ color: '#909090', fontSize: '12px' }}>* Aus Sicherheitsgründen werden Änderungen erst nach einer erfolgreichen mTAN-Validierung wirksam. Bitte beachten Sie insbesondere, dass auch eine Änderung Ihrer Mobilnummer erst nach erfolgreicher mTAN-Validierung gegen Ihre alte Mobilnummer greift. Sollte es Ihnen nicht möglich sein, die mTAN auf Ihrer alten Mobilnummer zu empfangen, wenden Sie sich bitte an unseren Support.</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={8} md={8}>
                        </Col>
                        <Col xs={2} md={2}>
                            <Link onClick={() => { AppRouter.routeTo(window.location.href, '/') }}>Abbrechen</Link>
                        </Col>
                        <Col xs={2} md={2}>
                            <Button disabled={this.state.disableSaveName || this.state.disableSaveSurname} size="sm" variant="primary" onClick={() => { this.onSave() }}>Speichern</Button>
                        </Col>
                    </Row>
                    <br />
                </Container>
            </>
        );

        return ctrl;
    };

    private async onSave() {
        if (!this.val.validateModel(this.model, this.getValidationOptions())) {
            this.setState({ changed: true });
            return;
        } else {
            let mode = this.websites.find(x => x.key == WebSites.am);
            if (mode) {
                let req: IAmProfilePatchDTORequest = {
                    firstname: this.model.firstName,
                    surname: this.model.secondName,
                    email: this.model.email,
                    mobile: this.model.mobile,
                    username: this.model.username
                };
                this.setState({ mode: EScreenMode.loading })
                let res = await this.am.updateProfile(req);

                if (res.error) {
                    this.model.error = res.error!;
                    if (res.error.detail) {

                    }
                    this.setState({ mode: EScreenMode.view, model: this.state.model })
                } else {

                    this.setState({
                        hidden: true,
                        mode: EScreenMode.view,
                        model: this.state.model,
                        okMessage: true
                    });
                }
            } else {
                Api.fetch(this.url + 'SaveProfile', this.model, "POST",
                    (api: IApiResponse) => {
                        if (api.data.status === EApiResultStatus.ok) {
                            this.setState({ hidden: false })
                        }
                    });
            }
        }
    }

    private async LoadData() {
        let mode = this.websites.find(x => x.key == WebSites.am);
        if (mode) {
            let res = await this.am.getProfile(this.model.username);
            if (!res.error) {
                 this.model.username = res.username;
                 this.model.firstName = res.firstname;
                 this.model.secondName = res.surname;
                 this.model.email = res.email;
                 this.model.mobile = res.mobile;
                 this.setState({model:this.model,mode:EScreenMode.view })
            }else{
                this.model.error = res.error;
                this.setState({model:this.model})
            }
        } else {
            Api.fetch(this.url + 'getProfile?' + 'username=' + this.model.username, undefined, "GET", this.onDataArrival);
        }
    }

    private _onNo() {
        this.setState({ hidden: true });
    };

    private onDataArrival(api: IApiResponse) {
        if (api.data) {
            this.model.firstName = api.data.firstName;
            this.model.secondName = api.data.secondName;
            this.model.email = api.data.email;
            this.model.mobile = api.data.mobile;
        }
      
        this.setState({
            changed: true,
            spinner: false,
            model: this.model,
            mode: EScreenMode.view
        });
    }

    private getValidationOptions = (): IValidationOption[] => {
        var all: IValidationOption[] = [];
        all.push({ key: 'firstName', type: EValidationOption.required, message: ValidationMessages.required });
        all.push({ key: 'firstName', type: EValidationOption.validateName, message: ValidationMessages.nameNotValidError });
        all.push({ key: 'secondName', type: EValidationOption.required, message: ValidationMessages.required });
        all.push({ key: 'secondName', type: EValidationOption.validateName, message: ValidationMessages.nameNotValidError });
        all.push({ key: 'mobile', type: EValidationOption.required, message: ValidationMessages.required });
        all.push({ key: 'mobile', type: EValidationOption.mobile, message: ValidationMessages.errorMobile });
        all.push({ key: 'email', type: EValidationOption.required, message: ValidationMessages.required });
        all.push({ key: 'email', type: EValidationOption.email, message: ValidationMessages.errorEmailnotValid });

        return all;
    };

    private _refreshModel(ctrl: any) {
        console.log(ctrl)
        Global.setValueByPath(this.model, ctrl.target.name, ctrl.target.value);
        this.setState({ changed: true });
    };
};

export default EditUser;