import { ActionButton, Checkbox, DefaultButton, DetailsList, DetailsListLayoutMode, Dialog, DialogFooter, DialogType, Dropdown, FontIcon, IColumn, IContextualMenuItem, IDropdownOption, ITooltipHostStyles, Label, Link, mergeStyles, Panel, PanelType, Pivot, PivotItem, PrimaryButton, SelectionMode, TextField, TooltipHost } from "office-ui-fabric-react";
import React from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import TableFilter from "../../../components/table/TableFilter";
import { EScreenMode } from "../../../model/app/Enums";
import LoaderVf, { ELoaderVfType } from "../../../Support/LoaderVf";
import AccountManager from "../../../utils/AccountManager";
import { AmHelper, EUserType, IAmAccount, IAmUser, IBooking, ILogHistoryAccountProperties } from "../Models";
import * as linq from 'linq';
import IKeyText from "../../../model/app/IKeyText";
import IIdName, { IStructures } from "../../../model/app/IIdName";
import StorageHelper from "../../../utils/StorageHelper";
import { DEFAULT_ROW_HEIGHTS } from "office-ui-fabric-react/lib/components/DetailsList/DetailsRow.styles";
import DialogLoader from "../../../Support/DialogLoader";
import Global from "../../../utils/Global";
import { EValidationOption, IValidationOption, Validation } from "../../../utils/Validation";
import { MainTexts, ValidationMessages } from "../../../utils/messages";
import { ITableScreen } from "../../../interfaces/ITableScreen";
import { IModelPanel } from "../../../interfaces/IModel";
import IUser from "../../../model/app/IUser";
import { Defaults, HttpMethods, MenuItems, Roles, WebSites } from "../../../utils/Defaults";
import { JSXElement } from "@babel/types";
import AmUserEditPanel from "./AmUserEditPanel";
import ApiAsync from "../../../utils/ApiAsync";
import TableWrapper, { ITableWrapperProps, TableConfig } from "../../../components/table/TableWrapper";
import { ETableSource } from "../../../components/controls/Table";
import { EControlType } from "../../../components/table/enums/control-type.enum";

export interface IAccountEditProps extends IModelPanel {
  pivot: string;
};

export interface IAccountEditState extends ITableScreen {
  pivot: string;
  model?: IAmAccount;
  configured: boolean;
  allUsers: IAmUser[];
  hintInactive: boolean;
  selectedEmployeeForEdit: IAmUser | undefined;
  canEdit?: boolean;
  accountProprtiesHistory?: any;
};

export class AmAccountEditPanel extends React.Component<IAccountEditProps, IAccountEditState>
{
  private static prefix: string = 'ef';
  private am: AccountManager = new AccountManager();
  private val: Validation = new Validation();
  private struct: IStructures = StorageHelper.getStructures();
  private support: IKeyText[] = StorageHelper.getWebsites();
  private tableWrapper = React.createRef<TableWrapper>();

  public static pivotMain = "main";
  public static pivotStatus = "status";
  public static pivotEmployees = "epmloyees";
  public static pivotStruct = "structure";
  public static pivotBooking = "booking";
  public static pivotHistory = "history";

  public canedit: boolean = false;
  private isnew: boolean = false;
  private original?: IAmAccount;
  private usr: IUser;

  constructor(props: any) {
    super(props);

    this.usr = StorageHelper.getUser() as IUser;

    this.state = {
      mode: EScreenMode.loading,
      pivot: this.props.pivot || AmAccountEditPanel.pivotMain,
      configured: false,
      allUsers: [],
      hintInactive: false,
      selectedEmployeeForEdit: undefined
    };
  };

  static getPivots = (): string[] => {
    return [AmAccountEditPanel.pivotMain, AmAccountEditPanel.pivotStatus, AmAccountEditPanel.pivotStruct, AmAccountEditPanel.pivotEmployees, AmAccountEditPanel.pivotBooking, AmAccountEditPanel.pivotHistory];
  };

  componentDidMount(): void {
    this.loadData();
  };

  render = () => {
    let ctrl = <></>;

    this.isnew = this.props.id == Global.getEmptyGuid();
    this.canedit = this.state.mode != EScreenMode.view;

    ctrl = <>
      {
        this.state.mode == EScreenMode.loading || (this.state.model && this.state.model.error) ?
          <LoaderVf type={ELoaderVfType.full} message={LoaderVf.parseApiError(this.state.model ? this.state.model.error : undefined)} onClose={() => {
            var u = this.state.model as IAmAccount;

            if (u.error) {
              u.error = undefined;
              this.setState({ model: u });
            };
          }} />
          : null
      }

      {this.renderAccountPannel()}

      {this.state.mode === EScreenMode.editEmployee ? <AmUserEditPanel void={this.props.id} id={this.state.selectedEmployeeForEdit ? this.state.selectedEmployeeForEdit.id : Global.getEmptyGuid()} onDismiss={() => { this.loadData(); }} /> : <></>}
    </>

    return ctrl;
  };

  private switchView = (key) => {
    if (this.state.pivot != key) {
      this.setState({ mode: this.isnew ? this.state.mode : EScreenMode.view, pivot: key });
    };
  };

  private renderStatus = () => {
    let ctrl: JSX.Element = <></>;

    if (this.state.model) {
      var m = this.state.model as IAmAccount;

      ctrl = <Container className="ee">
        <Row>
          <Col md={2} xs={2}>Händlerstatus</Col>
          <Col md={4} xs={4}>
            <Dropdown id={TableFilter.prefix + '-statusCode'} disabled={!this.canedit} options={linq.from<IKeyText>(StorageHelper.getStructure('statusesAccount', false)).select(x => { return { key: x.key, text: x.text } }).toArray()}
              selectedKey={m.statusCode}
              onChange={(e, v) => { this.onChange(e, v, "statusCode") }} />
          </Col>
        </Row>
      </Container>
    }

    return ctrl;
  };

  private renderEmployees = () => {
    let ctrl: JSX.Element = <></>;

    if (this.state.model && this.state.allUsers) {
      var m = this.state.model as IAmAccount;

      var allsm = this.state.allUsers.filter(x => x.roles && x.roles.indexOf(Roles.XTND_ShopManager) > -1);
      var allother = this.state.allUsers.filter(x => x.roles && x.roles.indexOf(Roles.XTND_ShopManager) == -1);
      var disptlayUserCount = 4;

      ctrl = <Container className="ee">
        <Row >
          <Col className="np"><h2>{"Shopmanagers (" + allsm.length + ")"}</h2></Col>
        </Row>

        <Row>{allsm.slice(0, disptlayUserCount).map((x, i) => (this.displayUser(x)))}</Row>

        <Row>
          <Col className="np"><h2>{"Mitarbeiter (" + (this.state.allUsers.length - allsm.length) + ")"}</h2></Col>
        </Row>

        <Row>{allother.slice(0, disptlayUserCount).map((x, i) => (this.displayUser(x)))}</Row>
      </Container>
    }

    return ctrl;
  };

  private renderStructure = () => {
    let ctrl: JSX.Element = <></>;

    const renderParent = (a: IAmAccount | undefined) => {
      if (!a) { return <></> };

      return <Row>
        <Col md={4} xs={4} className="level">Level {a!.level}</Col>
        <Col md={8} xs={8}>
          <div className="detail">{a.id}</div>
          <div className="text">{a.name}</div>
          <div className="text">{Global.toGermanAddress(a.address)}</div>
        </Col>
      </Row>
    };

    if (this.state.model) {
      var m = this.state.model as IAmAccount;

      var p1 = m.parents && linq.from<IAmAccount>(m.parents).where(x => x.level == 1).firstOrDefault();
      var p2 = m.parents && linq.from<IAmAccount>(m.parents).where(x => x.level == 2).firstOrDefault();

      ctrl = <Container className="ee">
        <Row>
          <Col md={12} xs={12}><h2>Händlerstruktur</h2></Col>
        </Row>

        {renderParent(p1)}

        {renderParent(p2)}
      </Container>
    };

    return ctrl;
  };

  private renderMain = () => {
    if (this.state.model) {
      var m = this.state.model as IAmAccount;

      let ctrl = <Container className="ee">
        <Row>
          <Col md={2} xs={2}>Status</Col>
          <Col md={4} xs={4}>
            <Dropdown id={TableFilter.prefix + '-statusId'} disabled={!this.canedit} options={linq.from<IKeyText>(StorageHelper.getStructure('statusesAccount')).select(x => { return { key: x.key, text: x.text } }).toArray()}
              selectedKey={m.statusCode}
              onChange={(e, v) => { this.onChange(e, v, "statusId") }} />
          </Col>
          <Col md={2} xs={2}>Anrede</Col>
          <Col md={4} xs={4}>
            <Dropdown id={TableFilter.prefix + '-regionId'} disabled={!this.canedit} options={linq.from<IKeyText>(StorageHelper.getStructure('regions')).select(x => { return { key: x.key, text: x.text } }).toArray()}
              selectedKey={m.regionId}
              onChange={(e, v) => { this.onChange(e, v, "regionId") }} />
          </Col>
        </Row>
        <Row>
          <Col md={2} xs={2}>Name</Col>
          <Col md={10} xs={10}>
            <TextField id={AmAccountEditPanel.prefix + '-name'} errorMessage={Validation.getMessage(m, 'name')} required={true} disabled={!this.canedit} value={m.name} onChange={(e, v) => this.onChange(e, v, undefined)} />
          </Col>
        </Row>
      </Container>

      return ctrl;
    }
  };

  onChange = (e, v, k) => {
    var key = k;
    if (this.state.model) {
      let u = this.state.model as IAmAccount;
      if (key) {
        u[key] = v.key;
      } else { //text
        key = e.target.id.replace(AmAccountEditPanel.prefix + '-', '');
        u[key] = e.target.value;
      };

      this.setState({ model: u });
    };
  };

  onDismiss = () => {
    if (this.state.mode === EScreenMode.editEmployee) {
      return;
    };

    this.props.onDismiss();
  };

  onSave = () => {
    
    if (this.state.pivot == AmAccountEditPanel.pivotStatus || this.state.pivot == AmAccountEditPanel.pivotBooking) {

      if (!this.val.validateModel(this.state.model, this.getValidationOptions())) {
        this.setState({ model: this.state.model });
        return;
      } else {
        this.patch();
      };
    };
  };

  private onRenderFooterContent = (): JSX.Element => {
    if (this.state.pivot == AmAccountEditPanel.pivotMain) {
      return <></>
    };

    let ctrl = <></>;

    ctrl = <>
      {
        this.state.pivot == AmAccountEditPanel.pivotStatus || this.state.pivot == AmAccountEditPanel.pivotBooking  && Global.inAnyRole([Roles.VBBA])?
          <>
            {
              this.state.mode == EScreenMode.edit ?
                <>
                  <ActionButton iconProps={{ iconName: 'Cancel' }} text={MainTexts.cancel} onClick={() => this.setState({ model: this.original, mode: EScreenMode.view })} />
                  <PrimaryButton text={MainTexts.save} onClick={() => { this.onSave() }} />
                </>
                : <PrimaryButton text={MainTexts.edit} onClick={() => this.setState({ mode: EScreenMode.edit })} />
            }
          </>
          : null
      }
    </>

    return <div className="ea">{ctrl}</div>;
  };

  async patch() {
    this.setState({ mode: EScreenMode.loading });
    var u = this.state.model as IAmAccount;
    var b: IBooking = {};
    var x: any = '';

    if (this.state.pivot == AmAccountEditPanel.pivotStatus) {
      x = await this.am.patchAccountStatus(u.id, u.statusCode);
    } else if (this.state.pivot == AmAccountEditPanel.pivotBooking) {
      
      b = await this.am.patchProperties(u);
    };

    if (x.error) {
      u.error = x.error;
      this.setState({ model: u });
    } else {
      this.original = JSON.parse(JSON.stringify(x as IAmUser));

      // replace booking props only
      if (this.state.pivot == AmAccountEditPanel.pivotBooking) {
        u.properties = b;
        x = u;
        this.original = x;
      };

      let history = await this.getHistoryAccountPropertiesFromMongo();

      this.setState({ model: x, mode: EScreenMode.view, configured: true, accountProprtiesHistory: history });
    };
  };

  async loadData() {
    var u = await this.am.getAccount(this.props.id);
    this.original = JSON.parse(JSON.stringify(u as IAmAccount));
    u.properties.comment = undefined;
    var users = await this.am.getUsersByVoid(this.props.id);
    var history = await this.getHistoryAccountPropertiesFromMongo()
    this.setState({ model: u, mode: EScreenMode.view, allUsers: users, selectedEmployeeForEdit: undefined, accountProprtiesHistory: history });
  };

  private displayUser = (u: IAmUser): JSX.Element => {
    return <Col md={4} xs={4} className="user-item np">
      <div className="name">{u.firstname + " " + u.surname}</div>
      <div className="id">{u.username}</div>
    </Col>
  };

  private getValidationOptions = (): IValidationOption[] => {
    var all: IValidationOption[] = [];
    all.push({ key: 'properties.comment', type: EValidationOption.required, message: ValidationMessages.required });
    return all;
  };

  private renderAccountPannel() {
    let ctrl = <></>;

    var m = this.state.model as IAmAccount;

    ctrl = <Panel isOpen={true} onDismiss={this.onDismiss} type={PanelType.medium} headerText={m ? `${m.name} (${m.id})` : 'Händler'} isFooterAtBottom={true} onRenderFooterContent={this.onRenderFooterContent}>
      <Pivot onLinkClick={(e) => { this.switchView(e!.props.itemKey) }} defaultSelectedKey={this.state.pivot}>
        <PivotItem itemKey={AmAccountEditPanel.pivotMain} headerText="Stammdaten" onClick={(e) => { this.switchView(AmAccountEditPanel.pivotMain) }}>
          <Container>
            <Row>
              <Col>
                {this.renderMain()}
              </Col>
            </Row>
          </Container>
        </PivotItem>

        {
          Global.inAnyRoleUser(this.usr, [Roles.PortalAdministrator, Roles.HL]) ?
            <PivotItem itemKey={AmAccountEditPanel.pivotStatus} headerText="Status" onClick={(e) => { this.switchView(AmAccountEditPanel.pivotStatus) }}>
              <Container>
                <Row>
                  <Col>
                    {this.renderStatus()}
                  </Col>
                </Row>
              </Container>
            </PivotItem>
            : null
        }

        <PivotItem itemKey={AmAccountEditPanel.pivotEmployees} headerText="Mitarbeiter" onClick={(e) => { this.switchView(AmAccountEditPanel.pivotEmployees) }}>
          <Container>
            <Row>
              <Col>
                {this.renderEmployees()}
              </Col>
            </Row>
            {this.renderAllUsers()}
          </Container>
        </PivotItem>

        <PivotItem itemKey={AmAccountEditPanel.pivotStruct} headerText="Struktur" onClick={(e) => { this.switchView(AmAccountEditPanel.pivotEmployees) }}>
          <Container className="np">
            <Row>
              <Col>
                {this.renderStructure()}
              </Col>
            </Row>
          </Container>
        </PivotItem>

        {
          Global.inAnyRole([Roles.PortalAdministrator, Roles.VBBA]) ?
            <PivotItem itemKey={AmAccountEditPanel.pivotBooking} headerText="Buchungsrechte" >
              <Container className="np">
                <Row>
                  <Col>
                    {this.renderBooking()}
                  </Col>
                </Row>
              </Container>
            </PivotItem>
            : null
        }

        {
          Global.inAnyRole([Roles.PortalAdministrator, Roles.VBBA]) ?
            <PivotItem itemKey={AmAccountEditPanel.pivotHistory} headerText="Historie">
              <Container className="np" >
                {this.state.accountProprtiesHistory ? this.renderHistory() : <></>}
              </Container>
            </PivotItem>
            : null
        }
      </Pivot>
    </Panel>
    return ctrl;
  };

  private renderHistory = () => {
    let ctrl: JSX.Element[] = [];
    const calloutProps = { gapSpace: 0 };
    const hostStyles: Partial<ITooltipHostStyles> = { root: { display: 'inline-block' } };
    let info = this.state.accountProprtiesHistory as ILogHistoryAccountProperties[];


    let tc = TableConfig.createInstance({
      url: "",
      method: "post",
      sourceType: ETableSource.fetched,
      arrayName: "logs",
      sort: [],
      columns: [
        {
          name: "created",
          label: "Datum",
          sortable: false,
          renderer: (r, c) => {
            return Global.toGermanDate(r["created"]);
          },
        },
        {
          name: "changedByUsername",
          label: "Änderung durch",
          sortable: false,
        },
        {
          name: "comment",
          label: "Kommentar",
          sortable: false,
        },
        {
          label:"Änderungen",
          name:"changes",
          sortable: false,
          renderer: (r, c) => {
            return <div>
                     <TooltipHost
                      content={<ul>
                        {this.determineTextFromPropertyChange(r)}
                      </ul>}
                      id={"tooltip-" + c.name}
                    // calloutProps={calloutProps}
                    // styles={hostStyles}
                    >
                      <Label aria-describedby={"tooltip-" + c.name}>Änderungen</Label>
                    </TooltipHost>
                  </div>
          },
        }
      ],
    });

    let cfg: ITableWrapperProps = {
      id: "history",
      onItemChanged: (u) => {
      },
      fetchedItems: info,
      model: { needle: "", paging: TableConfig.initPaging() },
      filter: [],
      config: tc,
      updateWrapper: undefined
    };

    return (
      <>
        <div className="data">
          <TableWrapper {...cfg} ref={this.tableWrapper} />
        </div>
      </>
    );
  };

  private determineTextFromPropertyChange(currentRecord: ILogHistoryAccountProperties) {
    let ctrl: JSX.Element[] = [];

    if (currentRecord["BookBusiness"] !== undefined || currentRecord["bookBusiness"] !== undefined) {
      let row = <li>{'Vodafone Business wurde' + (currentRecord["BookBusiness"] ? ' freigeschaltet' : ' deaktiviert')}</li>;
      ctrl.push(row);
    }
    if (currentRecord['BookCable'] !== undefined && currentRecord['bookCable'] !== undefined) {
      let row = <li>{'Kabel wurde' + (currentRecord['BookCable']  ? ' freigeschaltet' : ' deaktiviert')}</li>;
      ctrl.push(row);
    }
    if (currentRecord['BookCallya'] !== undefined || currentRecord['bookCallya'] !== undefined) {
      let row = <li>{'CallYa wurde' + (currentRecord['BookCallya'] ? ' freigeschaltet' : ' deaktiviert')}</li>;
      ctrl.push(row);
    }
    if (currentRecord['BookDsl'] !== undefined || currentRecord['bookDsl'] !== undefined) {
      let row = <li>{'DSL wurde' + (currentRecord['BookDsl'] ? ' freigeschaltet' : ' deaktiviert')}</li>;
      ctrl.push(row);
    }
    if (currentRecord['BookVodafone'] !== undefined || currentRecord['bookVodafone'] !== undefined) {
      let row = <li>{'Vodafone Mobilfunk wurde' + (currentRecord['BookVodafone'] ? ' freigeschaltet' : ' deaktiviert')}</li>;
      ctrl.push(row);
    }
    if (currentRecord['BookOtelo'] !== undefined || currentRecord['bookOtelo'] !== undefined) {
      let row = <li>{'Otelo wurde' + (currentRecord['BookOtelo'] ? ' freigeschaltet' : ' deaktiviert')}</li>;
      ctrl.push(row);
    }
    if (currentRecord['BookUnity'] !== undefined || currentRecord['bookUnity'] !== undefined) {
      let row = <li>{'UnityMedia wurde' + (currentRecord['BookUnity'] ? ' freigeschaltet' : ' deaktiviert')}</li>;
      ctrl.push(row);
    }
    if (currentRecord['VfVvlHardware'] !== undefined || currentRecord['vfVvlHardware'] !== undefined) {
      let row = <li>{'VVL with Vodafone Hardware wurde' + (currentRecord['VfVvlHardware']  ? ' freigeschaltet' : ' deaktiviert')}</li>;
      ctrl.push(row);
    }
    if (currentRecord['NbaAllowed'] !== undefined || currentRecord['nbaAllowed'] !== undefined) {
      let row = <li>{'NBA-Tipps wurden' + (currentRecord['NbaAllowed'] ? ' freigeschaltet' : ' deaktiviert')}</li>;
      ctrl.push(row);
    }
    if (currentRecord['DigitalSignAllowed'] !== undefined || currentRecord['digitalSignAllowed'] !== undefined) {
      let row = <li>{'Digitale Vertragsunterschrift wurde' + (currentRecord['DigitalSignAllowed'] ? ' freigeschaltet' : ' deaktiviert')}</li>;
      ctrl.push(row);
    }

    return ctrl;
  };

  private renderBooking = () => {
    let ctrl: JSX.Element = <></>;

    if (this.state.model) {
      var m = this.state.model as IAmAccount;

      if (!m.properties) {
        return <></>
      };

      ctrl = <Container className="ee">
        <Row>
          <Col md={12} xs={12}><h2>Vertriebswege</h2></Col>
        </Row>
        <Row>
          <Col md={4} xs={4} className="level">Vertriebsweg</Col>
          <Col md={8} xs={8}>
            <div className="detail">{m.sfType}</div>
          </Col>
        </Row>
        <Row>
          <Col md={4} xs={4} className="level">Untervertriebsweg</Col>
          <Col md={8} xs={8}>
            <div className="detail">{m.sfForm}</div>
          </Col>
        </Row>

        <Row>
          <Col md={12} xs={12}><h2>Brands</h2></Col>
        </Row>

        <Row>
          {this.renderBookingOption('bookVodafone', 'Vodafone Mobilfunk', m.properties.bookVodafone, this.state.mode == EScreenMode.edit, (m.vAccountBooking? m.vAccountBooking.bookVodafone : false))}
          {this.renderBookingOption('bookOtelo', 'Otelo Mobilfunk', m.properties.bookOtelo, this.state.mode == EScreenMode.edit, (m.vAccountBooking? m.vAccountBooking.bookOtelo : false))}
          {this.renderBookingOption('bookDsl', 'DSL', m.properties.bookDsl, this.state.mode == EScreenMode.edit, (m.vAccountBooking? m.vAccountBooking.bookDsl : false))}
          {this.renderBookingOption('bookCable', 'Vodafone Kabel KD + TV', m.properties.bookCable, this.state.mode == EScreenMode.edit, (m.vAccountBooking? m.vAccountBooking.bookCable : false))}
          {this.renderBookingOption('bookUnity', 'Vodafone Kabel UM', m.properties.bookUnity, this.state.mode == EScreenMode.edit, (m.vAccountBooking? m.vAccountBooking.bookUnity : false))}
          {this.renderBookingOption('bookTivano', 'Glasfaser', m.properties.bookTivano, this.state.mode == EScreenMode.edit, (m.vAccountBooking? m.vAccountBooking.bookTivano : false))}

        </Row>

        <Row>
          <Col md={12} xs={12}><h2>Weitere</h2></Col>
        </Row>

        <Row>
          {this.renderBookingOption('bookBusiness', 'Business', m.properties.bookBusiness, this.state.mode == EScreenMode.edit, (m.vAccountBooking? m.vAccountBooking.bookBusiness : false))}
          {this.renderBookingOption('bookCallya', 'Callya', m.properties.bookCallya, this.state.mode == EScreenMode.edit, (m.vAccountBooking? m.vAccountBooking.bookCallya : false))}
          {this.renderBookingOption('vfVvlHardware', 'VVL mit Hardware', m.properties.vfVvlHardware, this.state.mode == EScreenMode.edit, (m.vAccountBooking? m.vAccountBooking.bookVodafoneHardware : false))}
          {this.renderBookingOption('nbaAllowed', 'NBA', m.properties.nbaAllowed, this.state.mode == EScreenMode.edit, (m.vAccountBooking? m.vAccountBooking.nbaAllowed : false))}
          {
            Global.inAnyRole([Roles.PortalAdministrator]) ?
              this.renderBookingOption('digitalSignAllowed', 'Digital sign', m.properties.nbaAllowed, this.state.mode == EScreenMode.edit)
              : null
          }
        </Row>

        <Row>
          {this.state.mode == EScreenMode.edit ? <TextField id={AmAccountEditPanel.prefix + '-properties.comment'}
            errorMessage={Validation.getMessage(m, 'properties.comment')}
            required={true}
            disabled={!this.canedit}
            label="Warum die Änderung vorgenommen wurde"
            value={m.properties.comment}
            onChange={(e, v) => {
              m.properties.comment = v;
              this.setState({ model: m, mode: EScreenMode.edit })
            }
            }
          /> : <></>}
        </Row>

        <Row>
          <Col md={12} xs={12}><h2>Aktualisiert</h2></Col>
        </Row>

        {
          m.properties.bookManualDate ?
            <Row>
              <Col>{Global.toGermanDate(m.properties.bookManualDate, true)}</Col>
            </Row>
            : null
        }
      </Container>
    };

    return ctrl;
  };

  private renderBookingOption = (id: string, name: string, selected: boolean | undefined, canedit: boolean, selectedByStructureImport?:boolean) => {
    const checkboxStyles = {
      root: {
        border: '1px dashed green',
        borderRadius: '50px',
        padding: '4px'
      },
    };


    return <>
      <Col md={6} xs={6} style={{paddingBottom:'10px'}}> 
        <Checkbox
          key={'cbo-' + id}
          disabled={!canedit}
          styles={selectedByStructureImport? checkboxStyles : undefined}
          onChange={(e, v) => {
            if (this.state.model) {
              var m = this.state.model;

              m.properties[id] = v;
              e!.preventDefault();
              e!.stopPropagation();
              this.setState({ model: m, mode: EScreenMode.edit });
            };
            //this.onChangeBooking(e);
          }}
          checked={selected || false}
          label={name} />
          
      </Col>
      {/* <Col key={'col2-' + id} md={11} xs={11}>{name}</Col> */}
    </>
  };

  private renderAllUsers() {
    let columns: IColumn[] = [
      {
        key: 'username',
        name: 'Username',
        fieldName: 'username',
        minWidth: 70,
        maxWidth: 90,
        isResizable: true,
        isCollapsible: true,
        data: 'string',
        isPadded: true
      },
      {
        key: 'userStatus',
        name: 'Status',
        fieldName: 'userStatus',
        minWidth: 70,
        maxWidth: 90,
        isResizable: true,
        isCollapsible: true,
        data: 'string',
        isPadded: true,
        onRender: (item: IAmUser) => {
          let stat = this.struct.statuses.find(x => x.id === item.statusId);
          return <span>{stat!.name}</span>;
        },
      },
      {
        key: 'firstname',
        name: 'Name',
        fieldName: 'firstname',
        minWidth: 70,
        maxWidth: 90,
        isResizable: true,
        isCollapsible: true,
        data: 'string',
        isPadded: true,
        onRender: (item: IAmUser) => {
          return <span>{item.firstname + " " + item.surname}</span>;
        },
      }
    ];

    if (Global.inAnyRoleUser(this.usr, [Roles.PortalAdministrator, Roles.VB])) {
      columns.push({
        key: 'edit', name: '', fieldName: 'edit', minWidth: 63, maxWidth: 63, isResizable: false,
        onRender: (item: IAmUser) => (
          item.statusId == 7 ? <></> : <Link style={{ color: item.statusId == 7 ? 'white' : '' }} onClick={() => {
            this.setState({ mode: EScreenMode.editEmployee, selectedEmployeeForEdit: item })
          }}>Bearbeiten</Link>
        )
      });
    };

    let ctrl = <></>;
    ctrl =
      <>
        {
          Global.inAnyRoleUser(this.usr, [Roles.PortalAdministrator, Roles.VB]) ?
            <Link onClick={() => { this.setState({ mode: EScreenMode.editEmployee, selectedEmployeeForEdit: undefined }) }}>+ Mitarbeiter hinzufügen</Link>
            : null
        }

        <DetailsList
          items={this.state.allUsers}
          compact={true}
          columns={columns}
          selectionMode={SelectionMode.none}
          getKey={this._getKey}
          setKey="none"
          layoutMode={DetailsListLayoutMode.justified}
          isHeaderVisible={true}
        />
      </>
    return ctrl;
  };

  private _getKey(item: any, index?: number): string {
    return item.key;
  };

  private async getHistoryAccountPropertiesFromMongo() {
    let base = this.support.find(x => x.key === 'easy4-gateway');
    const result = await ApiAsync.run<any>(base!.text + 'history/changesBOHistory/AccountProperties/' + this.props.id, HttpMethods.get, undefined);
    return result;
  }
};

export default AmAccountEditPanel;