import * as React from 'react';
import { Pagination } from 'react-bootstrap';



export interface IPagingModel {
    page: number;
    count: number;
}

export interface IPagingProps {
    model: IPagingModel;
    pagesToRender: number;
    parent: any;
    totalItemsCount: number;
}
export interface IPagingState {
    selectedPage: number,
    changed: boolean
}

export class TablePaging extends React.Component<IPagingProps, IPagingState> {
    // private options: IKeyTextNumber[] = Global.getPaginationItemsPerPage();
    private itemsPerPage: number = 500;
    constructor(props: IPagingProps) {
        super(props);

        this.onFirstPageClick = this.onFirstPageClick.bind(this);
        this.onLastPageClick = this.onLastPageClick.bind(this);

        this.onPrevPageClick = this.onPrevPageClick.bind(this);
        this.onNextPageClick = this.onNextPageClick.bind(this);

        this.onPageClick = this.onPageClick.bind(this);

        this.state = { selectedPage: this.props.model.page,changed:false };
    }

    render() {

        const { pagesToRender } = this.props;
        const { selectedPage } = this.state;

        let lastPage: number = Math.ceil(this.props.totalItemsCount / 500);
       
        let items: JSX.Element[] = [];
        items.push(
            <Pagination.Item key={selectedPage} active={true}>
                {selectedPage}
            </Pagination.Item>
        );

        for (let number = selectedPage + 1; number < pagesToRender + selectedPage; number++) {
            items.push(
                <Pagination.Item id={number} key={number} active={false} disabled={number > lastPage} onClick={() => this.onPageClick(number)}>
                    {number}
                </Pagination.Item>
            );
        }

        return <Pagination size="sm">
            <Pagination.First disabled={selectedPage === 1} onClick={this.onFirstPageClick} />
            <Pagination.Prev disabled={selectedPage === 1} onClick={this.onPrevPageClick} />
            {items}
            <Pagination.Next disabled={selectedPage >= lastPage} onClick={this.onNextPageClick} />
            <Pagination.Last disabled={true} onClick={this.onLastPageClick} />
        </Pagination>
    }

    private onFirstPageClick() {
        this.setState({ selectedPage: 1 });
        this.props.parent.onPaginationPageChanged(1);
    }

    private onLastPageClick() {
        const lastPage: number = Math.ceil(this.props.totalItemsCount / this.props.model.count);
        this.setState({ selectedPage: lastPage });
        this.props.parent.onPaginationPageChanged(lastPage);
    }

    private onPrevPageClick() {
        const page = this.state.selectedPage - 1;
        this.setState({ selectedPage: page });
        this.props.parent.onPaginationPageChanged(page);
    }

    private onNextPageClick() {
        const page = this.state.selectedPage + 1;
        this.setState({ selectedPage: page });
        console.log("inner i was  here ")
        this.props.parent.onPaginationPageChanged(page);
    }

    private onPageClick(page: number) {
        console.log("i was here")
        this.setState({ selectedPage: page });
        this.props.parent.onPaginationPageChanged(page);
    }
}