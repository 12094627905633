import React, { useState } from "react";
import EpmManager from "../../../utils/EpmManager";
import { ButtonToolbar, Button } from "react-bootstrap";
import { EScreenMode } from "../../../model/app/Enums";
import { ITableScreen } from "../../../interfaces/ITableScreen";
import Global from "../../../utils/Global";
import TableWrapper, { ITableWrapperProps, TableConfig } from "../../../components/table/TableWrapper";
import { from } from "linq";
import IKeyText from "../../../model/app/IKeyText";
import StorageHelper from "../../../utils/StorageHelper";
import { MenuItems, WebSites } from "../../../utils/Defaults";
import { ETableSource } from "../../../components/controls/Table";
import { EControlType } from "../../../components/table/enums/control-type.enum";
import { ContextualMenuItemType, IContextualMenuItem } from "office-ui-fabric-react";
import { IBlacklistItem } from "../../../model/data/IBlacklistItem";
import NewBlacklistItemForm from "../panels/NewBlacklistItemPanel";
import { BlacklistItemEditPanel } from "../panels/BlacklistItemEditPanel";

export interface IBlacklistScreenState extends ITableScreen {
    item?: IBlacklistItem | undefined;
    brands?: any;
    showNewItemForm?: boolean;
    items?: IBlacklistItem[];
}

export const EpmBlacklistScreen: React.FC<IBlacklistScreenState> = () => {
    const cfg1 = Global.getConfiguration();
    const [blacklistState, setBlacklistState] = useState<IBlacklistScreenState>({
        item: undefined,
        id: undefined,
        mode: EScreenMode.view,
        brands: undefined,
        showNewItemForm: false,
        items: []
    });
    const epmManager = new EpmManager();

    const [current, setCurrent] = useState<IBlacklistItem | undefined>(undefined);

    const processContextMenu = (item: IBlacklistItem, e) => {
        if (e.key == MenuItems.edit) {
            setBlacklistState({
                mode: EScreenMode.edit,
                item: item,
                id: item.id,
                items: blacklistState.items,
            });
        } else if (e.key == MenuItems.delete && item.id) {
            epmManager.deleteBlacklistItem(item.id);
            setBlacklistState({
                mode: EScreenMode.view,
                item: undefined,
                id: undefined,
                items: blacklistState.items?.filter((blacklistItem) => blacklistItem.id != item.id),
            });
        }
    };

    let cm: IContextualMenuItem[] = [
        {
            key: MenuItems.edit,
            iconProps: { iconName: "Edit" },
            text: "Bearbeiten",
        },
        { key: "divider_1", itemType: ContextualMenuItemType.Divider },
        {
            key: MenuItems.delete,
            iconProps: { iconName: "Delete"},
            text: "Löschen"
        }
    ];

    const tc = TableConfig.createInstance({
        url:
            from<IKeyText>(StorageHelper.getWebsites())
                .toArray()
                .filter(x => x.key == WebSites.epm)[0].text + 'blacklist/filter',
        sourceType: ETableSource.dynamic,
        arrayName: "data",
        sort: [],
        columns: [
            { name: "id", label: "Id", sortable: true },
            { name: "brandId", label: "Brand", sortable: true },
            { name: "typeId", label: "TypeId", sortable: true },
            { name: "key", label: "Key", sortable: true }
        ]
    });

    let cfg: ITableWrapperProps = {
        id: "rety",
        action: processContextMenu,
        onItemChanged: (u) => {
            setCurrent(u)
        },
        model: { needle: "", paging: TableConfig.initPaging() },
        contextMenu: cm,
        filter: [
            { type: EControlType.text, key: "needle", label: "Suche" },
            {
                type: EControlType.dropdown,
                key: "brandId",
                label: "Brand",
                list: StorageHelper.getBrandKeys(true)
            }
        ],
        config: tc,
    };

    const handleNewItem = () => {
        setBlacklistState({ ...blacklistState, mode: EScreenMode.new, showNewItemForm: true });
    };

    const handleCloseForm = () => {
        setBlacklistState({ ...blacklistState, mode: EScreenMode.view, showNewItemForm: false });
    };

    return (
        <>
            {blacklistState.mode !== EScreenMode.loading && (
                <>
                    <ButtonToolbar style={{
                        marginBottom: '12px',
                    }}>
                        <Button style={{ backgroundColor: "red" }} onClick={handleNewItem}>New Item</Button>
                    </ButtonToolbar>
                    <TableWrapper {...cfg} />
                    <NewBlacklistItemForm
                        show={blacklistState.showNewItemForm}
                        onHide={handleCloseForm}
                        setBlacklistState={setBlacklistState}
                    />
                </>
            )}
            {blacklistState.mode === EScreenMode.edit ? (
                <BlacklistItemEditPanel
                    id={blacklistState.id as string}
                    onDismiss={() => {
                        setBlacklistState({ mode: EScreenMode.view });
                    }}
                    selectedItem={blacklistState.item}
                />
                )
                : null
            }
        </>
    );
};

export default EpmBlacklistScreen;
