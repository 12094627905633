import { ActionButton, Dropdown, Panel, PanelType, Pivot, PivotItem, PrimaryButton, TextField, DatePicker, IDropdownOption } from "office-ui-fabric-react";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { IModelPanel } from "../../../interfaces/IModel";
import { ITableScreen } from "../../../interfaces/ITableScreen";
import { EScreenMode } from "../../../model/app/Enums";
import LoaderVf, { ELoaderVfType } from "../../../Support/LoaderVf";
import AccountManager from "../../../utils/AccountManager";
import Global from "../../../utils/Global";
import { ValidationMessages } from "../../../utils/messages";
import StorageHelper from "../../../utils/StorageHelper";
import { EValidationOption, IValidationOption, Validation } from "../../../utils/Validation";
import { IAmUserSub } from "../Models";
import { VfPositions, WebSites } from "../../../utils/Defaults";
import IUser from "../../../model/app/IUser";
import linq from "linq";
import IKeyText from "../../../model/app/IKeyText";

export interface IUserSubEditProps extends IModelPanel {
    id: string;
    onDismiss: any;
    model?: IAmUserSub;
    onModelChanged?: Function
};

export interface IUserSubEditState extends ITableScreen {
    model?: IAmUserSub;
    userId?: string;
};


export class AmUserSubstitutionEditPanel extends React.Component<IUserSubEditProps, IUserSubEditState> {
    private val: Validation = new Validation();
    private am: AccountManager = new AccountManager();
    private u?: IUser = StorageHelper.getUser();

    constructor(props: any) {
        super(props);

        this.onRenderFooterContent = this.onRenderFooterContent.bind(this);

        var m = this.props.model as IAmUserSub;

        if (m.id == Global.getEmptyGuid()) {
            m.from = new Date();
            m.to = new Date();
            m.id = Global.getUniqueId();
            m.isNew = true;
        };
        let userIdUrl = linq
            .from<IKeyText>(StorageHelper.getWebsites())
            .toArray()
            .filter((x) => x.key == WebSites.am)[0].text +
            `user/session/${this.u?.SessionId}`;
        fetch(userIdUrl, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': this.u?.easyToken ? this.u.easyToken : ''
            }
        })
            .then((res => res.json()))
            .then(result => {
                this.setState({ userId: result.id })
            })

        this.state = {
            mode: EScreenMode.view,
            model: m
        };
    };

    render() {
        return <>
            {
                this.state.mode == EScreenMode.loading || (this.state.model && this.state.model.error) ?
                    <LoaderVf type={ELoaderVfType.full} message={LoaderVf.parseApiError(this.state.model ? this.state.model.error : undefined)} /> : null
            }
            <Panel isOpen={true} onDismiss={this.props.onDismiss} type={PanelType.medium} headerText="Vertretung" isFooterAtBottom={true} onRenderFooterContent={this.onRenderFooterContent}>
                <Pivot>
                    <PivotItem>
                        <Container>
                            {this.state.userId ? this.renderMain() : ''}
                        </Container>
                    </PivotItem>

                </Pivot>
            </Panel>
        </>
    };

    private renderMain() {
        let options: IDropdownOption[] = [
            {
                key: this.state.userId ? this.state.userId : '',
                text: this.u?.Fullname ? this.u.Fullname : ''
            }
        ]
        if (this.state.model) {
            let model = this.state.model as IAmUserSub;
            let ctrl = <Container className="ee">
                <Row className="justify-content-between">
                    <Col md={1} xs={1}>Von</Col>
                    <Col md={4} xs={4} >
                        <DatePicker
                            isRequired={true} disabled={this.state.mode !== EScreenMode.edit}
                            value={new Date(model.from)}
                            formatDate={(date) => Global.toGermanDate(date)}
                            onSelectDate={(v) => {
                                if (v) {
                                    model.from = v;
                                    this.updateModel(model);
                                }
                            }} />
                    </Col>
                    <Col md={1} xs={1}>Bis</Col>
                    <Col md={4} xs={4}>
                        <DatePicker
                            isRequired={true} disabled={this.state.mode !== EScreenMode.edit}
                            value={new Date(model.to)}
                            formatDate={(date) => Global.toGermanDate(date)}
                            onSelectDate={(v) => {
                                if (v) {
                                    model.to = v;
                                    this.updateModel(model);
                                }
                            }} />
                    </Col>
                </Row >

                <Row className="justify-content-between">
                    <Col md={1} xs={1}>Source</Col>
                    <Col md={4} xs={4}>
                        <Dropdown options={options}
                            selectedKey={model.sourceId} disabled={this.state.mode !== EScreenMode.edit}
                            onChange={(e, v) => {
                                if (v && v.key) {
                                    model.sourceId = v?.key.toString();
                                    this.updateModel(model);
                                }
                            }} />
                    </Col>
                    <Col md={1} xs={1}>Vertreter</Col>
                    <Col md={4} xs={4}>
                        <Dropdown options={StorageHelper.getStructureUsers(VfPositions.Vertriebsbeauftragter).sort((a, b) => a.text.localeCompare(b.text))}
                            disabled={this.state.mode !== EScreenMode.edit}
                            selectedKey={model.targetId}
                            onChange={(e, v) => {
                                if (v && v.key) {
                                    model.targetId = v?.key.toString();
                                    this.updateModel(model);
                                }
                            }} />
                    </Col>
                </Row>
            </Container >
            return ctrl;
        }

    };

    private onRenderFooterContent() {
        let ctrl = this.state.mode === EScreenMode.edit ? <>
            <ActionButton iconProps={{ iconName: 'Cancel' }} text="Abbrechen" onClick={() => this.setState({ mode: EScreenMode.view })} />
            <PrimaryButton text="Speichern" onClick={() => {
                if (!this.val.validateModel(this.state.model, this.getValidationOptions())) {
                    return;
                } else {
                    this.onSave();
                };
            }} />
        </>
            :
            <>
                <PrimaryButton text="Edit" onClick={() => this.setState({ mode: EScreenMode.edit })} />
            </>

        return ctrl;
    };

    private getValidationOptions() {
        let options: IValidationOption[] = [
            { key: 'from', type: EValidationOption.required, message: ValidationMessages.required },
            { key: 'to', type: EValidationOption.required, message: ValidationMessages.required },
        ];

        return options;
    };

    private updateModel(model: IAmUserSub) {
        this.setState({ model: { ...model } });
    };

    private async onSave() {
        this.setState({ mode: EScreenMode.loading });

        if (this.state.model) {
            await this.am.updateUserSubstitution(this.state.model);
        }

        if (this.props.onModelChanged) {
            this.props.onModelChanged();
        }

        this.setState({ mode: EScreenMode.view });
    };
};

