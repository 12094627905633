import {
  ActionButton,
  Dropdown,
  Panel,
  PanelType,
  Pivot,
  PivotItem,
  PrimaryButton,
  TextField,
} from "office-ui-fabric-react";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import TableFilter from "../../../components/table/TableFilter";
import { EScreenMode } from "../../../model/app/Enums";
import LoaderVf, { ELoaderVfType } from "../../../Support/LoaderVf";
import * as linq from "linq";
import IKeyText from "../../../model/app/IKeyText";
import { IStructures } from "../../../model/app/IIdName";
import StorageHelper from "../../../utils/StorageHelper";
import Global from "../../../utils/Global";
import { IValidationOption, Validation } from "../../../utils/Validation";
import { MainTexts } from "../../../utils/messages";
import { ITableScreen } from "../../../interfaces/ITableScreen";
import { IModelPanel } from "../../../interfaces/IModel";
import { IShopOrder } from "../../am/Models";
import GatewayManager from "../../../utils/GatewayManager";

export interface IAccountEditProps extends IModelPanel {}

export interface IUserEditState extends ITableScreen {
  pivot: string;
  model?: IShopOrder;
  configured: boolean;
}

export class HotlineShopEditPanel extends React.Component<
  IAccountEditProps,
  IUserEditState
> {
  private static prefix: string = "ef";
  private gm: GatewayManager = new GatewayManager();
  private val: Validation = new Validation();
  private struct: IStructures = StorageHelper.getStructures();

  public static pivotMain = "main";
  public static pivotProducts = "products";

  private canedit: boolean = false;
  private isnew: boolean = false;
  private original?: IShopOrder;

  constructor(props: any) {
    super(props);

    this.state = {
      mode: EScreenMode.loading,
      pivot: HotlineShopEditPanel.pivotMain,
      configured: false,
    };
  }

  static getPivots = (): string[] => {
    return [HotlineShopEditPanel.pivotMain, HotlineShopEditPanel.pivotProducts];
  };

  componentDidMount(): void {
    this.loadData();
  }

  render = () => {
    let ctrl = <></>;

    this.isnew = this.props.id == Global.getEmptyGuid();
    this.canedit =
      this.state.mode == EScreenMode.edit || this.state.mode == EScreenMode.new;

    ctrl = (
      <>
        {this.state.mode == EScreenMode.loading ||
        (this.state.model && this.state.model.error) ? (
          <LoaderVf
            type={ELoaderVfType.full}
            message={LoaderVf.parseApiError(
              this.state.model ? this.state.model.error : undefined
            )}
          />
        ) : null}

        <Panel
          isOpen={true}
          onDismiss={this.onDismiss}
          type={PanelType.medium}
          headerText="Produktbestellung"
          isFooterAtBottom={true}
          onRenderFooterContent={this.onRenderFooterContent}
        >
          <Pivot
            onLinkClick={(e) => {
              this.switchView(e!.props.itemKey);
            }}
            defaultSelectedKey={this.state.pivot}
          >
            <PivotItem
              itemKey={HotlineShopEditPanel.pivotMain}
              headerText="Status"
              onClick={() => {
                this.switchView(HotlineShopEditPanel.pivotMain);
              }}
            >
              <Container>
                <Row>
                  <Col>{this.renderMain()}</Col>
                </Row>
              </Container>
            </PivotItem>
          </Pivot>
        </Panel>
      </>
    );

    return ctrl;
  };

  private switchView = (key) => {
    if (this.state.pivot != key) {
      this.setState({
        mode: this.isnew ? this.state.mode : EScreenMode.view,
        pivot: key,
      });
    }
  };

  private renderMain = () => {
    if (this.state.model) {
      var m = this.state.model as IShopOrder;

      let ctrl = (
        <Container className="ee">
          <Row>
            <Col md={2} xs={2}>
              {MainTexts.id}
            </Col>
            <Col md={10} xs={10}>
              <TextField
                id={HotlineShopEditPanel.prefix + "-id"}
                disabled={true}
                value={m.id.toString()}
              />
            </Col>
          </Row>

          <Row>
            <Col md={2} xs={2}>
              {MainTexts.date}
            </Col>
            <Col md={10} xs={10}>
              <TextField
                id={HotlineShopEditPanel.prefix + "-date"}
                disabled={true}
                value={Global.toGermanDate(m.orderDate)}
              />
            </Col>
          </Row>

          <Row>
            <Col md={2} xs={2}>
              {MainTexts.status}
            </Col>
            <Col md={10} xs={10}>
              <Dropdown
                id={TableFilter.prefix + "-typeId"}
                disabled={!this.canedit}
                options={linq
                  .from<IKeyText>(StorageHelper.getStructure("statusesOrder"))
                  .select((x) => {
                    return { key: x.key, text: x.text };
                  })
                  .toArray()}
                selectedKey={m.statusId.toString()}
                onChange={(e, v) => {
                  this.onChange(e, v, "statusId");
                }}
              />
            </Col>
          </Row>

          <Row>
            <Col md={2} xs={2}>
              {MainTexts.freetext}
            </Col>
            <Col md={10} xs={10}>
              <TextField
                multiline
                rows={3}
                id={HotlineShopEditPanel.prefix + "-text"}
                disabled={!this.canedit}
                value={m.text}
                onChange={(e, v) => this.onChange(e, v, undefined)}
              />
            </Col>
          </Row>

          <Row>
            <Col className="np">
              <h2>Produkte</h2>
            </Col>
          </Row>

          <Row>
            <Col md={2} xs={2}>
              &nbsp;
            </Col>
            <Col md={10} xs={10}>
              {m.products
                ? m.products.map((x, i) => {
                    return (
                      <div className="product">
                        {i + 1}. {x.name} ({x.amount})
                      </div>
                    );
                  })
                : null}
            </Col>
          </Row>
        </Container>
      );

      return ctrl;
    }
  };

  private onRenderFooterContent = (): JSX.Element => {
    let ctrl = <></>;

    ctrl = (
      <>
        {this.state.pivot == HotlineShopEditPanel.pivotMain ? (
          <>
            {this.state.mode == EScreenMode.edit ? (
              <>
                <ActionButton
                  iconProps={{ iconName: "Cancel" }}
                  text={MainTexts.cancel}
                  onClick={() =>
                    this.setState({
                      model: this.original,
                      mode: EScreenMode.view,
                    })
                  }
                />
                <PrimaryButton
                  text={MainTexts.save}
                  onClick={() => {
                    this.onSave();
                  }}
                />
              </>
            ) : (
              <PrimaryButton
                text={MainTexts.edit}
                onClick={() => this.setState({ mode: EScreenMode.edit })}
              />
            )}
          </>
        ) : null}
      </>
    );

    return <div className="ea">{ctrl}</div>;
  };

  onChange = (e, v, k) => {
    var key = k;

    if (this.state.model) {
      let u = this.state.model as IShopOrder;

      if (key) {
        u[key] = v.key;
      } else {
        //text
        key = e.target.id.replace(HotlineShopEditPanel.prefix + "-", "");
        u[key] = e.target.value;
      }

      this.setState({ model: u });
    }
  };

  onDismiss = () => {
    this.props.onDismiss();
  };

  onSave = () => {
    if (this.state.pivot == HotlineShopEditPanel.pivotMain) {
      this.patch();
    }
  };

  async patch() {
    this.setState({ mode: EScreenMode.loading });
    var u = this.state.model as IShopOrder;
    var x: any = "";

    if (this.state.pivot == HotlineShopEditPanel.pivotMain) {
      x = await this.gm.patchShopOrder(u.id, u.statusId, u.text);
    }

    if (x.error) {
      u.error = x.error;
      this.setState({ model: u });
    } else {
      this.original = JSON.parse(JSON.stringify(x as IShopOrder));
      this.setState({ model: x, mode: EScreenMode.view, configured: true });
    }
  }

  async loadData() {
    var u = await this.gm.getShopOrder(this.props.id);
    this.original = JSON.parse(JSON.stringify(u as IShopOrder));
    this.setState({ model: u, mode: EScreenMode.view });
  }

  private getValidationOptions = (): IValidationOption[] => {
    var all: IValidationOption[] = [];

    var u = this.state.model as IShopOrder;

    // configured so validate the main data
    if (this.state.configured) {
      //all.push({key: 'firstname', type: EValidationOption.required, message: ValidationMessages.required});
    }

    return all;
  };
}

export default HotlineShopEditPanel;
