import { Button, PrimaryButton } from "office-ui-fabric-react";
import React from "react";
import StorageHelper from "../../utils/StorageHelper";

export class TestScreen extends React.Component <any, any>
{
    constructor(props: any) {
        super(props);

        this.state = {
        };
    };

    render = () => {
        let ctrl = <div>
            <PrimaryButton 
                text="reset"
                onClick={(e)=>{
                    StorageHelper.reset();
                }}/>
        </div>

        return ctrl;
    };
};

export default TestScreen;