import React, { useEffect, useState } from "react";
import TableWrapper, {
  ITableWrapperProps,
  TableConfig,
} from "../../../components/table/TableWrapper";
import { EControlType } from "../../../components/table/enums/control-type.enum";
import { ETableSource } from "../../../components/controls/Table";
import StorageHelper from "../../../utils/StorageHelper";
import IKeyText from "../../../model/app/IKeyText";
import { from } from "linq";
import { HttpMethods, MenuItems, WebSites } from "../../../utils/Defaults";
import Global from "../../../utils/Global";
import {
  Dialog,
  DialogFooter,
  DialogType,
  IContextualMenuItem,
  PrimaryButton,
  TextField,
} from "office-ui-fabric-react";
import { ExportCSV } from "../../../components/controls/ExportCSV";
import { Button } from "react-bootstrap";
import ApiAsync from "../../../utils/ApiAsync";
import LoaderVf, {
  ELoaderVfType,
  EMessageType,
  ILoaderMessage,
} from "../../../Support/LoaderVf";
import { MainTexts } from "../../../utils/messages";
import * as XLSX from "xlsx";
import FileSaver from "file-saver";

interface UpdatedAccountProps {
  bookVodafone: boolean;
  bookOtelo: boolean;
  bookCable: boolean;
  bookDsl: boolean;
  bookTivano: boolean;
  bookUnity: boolean;
  tanAuthExCustomer: boolean;
  bookCallya: boolean;
  bookBusiness: boolean;
  nbaAllowed: boolean;
  digitalSignAllowed: boolean;
  bookVfBusinessBrand: boolean;
  vfVvlHardware: boolean;
  parkingEnabled: boolean;
  kabelOrderState: boolean;
  bookHardwarelistBNTmnpDCS: boolean;
  fraudCounter: number;
  buvLimit: boolean;
  freeTextCMallowed: boolean;
  customerLoginNoPass: boolean;
  id: string;
}

const WhitelistBestandskunden = () => {
  const [showDialog, setShowDialog] = useState(true);
  const [inputValue, setInputValue] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isVOIDValueWrong, setVOIDIsValueWrong] = useState(true);
  const [showLoader, setShowLoader] = useState(false);
  const [showInvalidVoidDialog, setShowInvalidVoidDialog] = useState(true);
  const [showInvalidUpdateDialog, setShowInvalidUpdateDialog] = useState(true);
  const [updateWrapper, setUpdateWrapper] = useState(false);
  const [currentAccount, setCurrentAccount] = useState<UpdatedAccountProps>();
  const [showSuccDialog, setShowSuccDialog] = useState(true);
  const [loaderMessage, _] = useState<ILoaderMessage>({
    title: MainTexts.appName,
    body: "Anwendung wird initialisiert",
    type: EMessageType.loader,
  });

  const amURL = from<IKeyText>(StorageHelper.getWebsites())
    .toArray()
    .filter((x) => x.key == WebSites.am)[0].text;

  const ValidateVOID = (event, newValue) => {
    // Remove non-numeric characters and spaces from the input
    const numericValue = newValue.replace(/[^0-9]/g, "");

    if (numericValue.length !== 8) {
      setInputValue(numericValue);
      setErrorMessage("Die Eingabe muss genau 8 Zahlen enthalten");
      setVOIDIsValueWrong(true);
    } else {
      setInputValue(numericValue);
      setErrorMessage("");
      setVOIDIsValueWrong(false);
    }
  };

  const SearchAccountByVoid = async () => {
    setShowLoader(true);
    setShowDialog(true);

    const url = `${amURL}account/properties/${inputValue}`;
    const isUserFoundAndUpdated: boolean = await ApiAsync.run<boolean>(
      url,
      HttpMethods.get
    );

    if (isUserFoundAndUpdated) {
      setUpdateWrapper(true);
    } else setShowInvalidVoidDialog(false);

    setUpdateWrapper(false);
    setShowLoader(false);
  };

  const UpdateAccountByVoid = async (VOID: string): Promise<void> => {
    setShowLoader(true);

    const url = `${amURL}account/${VOID}`;
    const updatedUser: UpdatedAccountProps =
      await ApiAsync.run<UpdatedAccountProps>(url, HttpMethods.put);

    if (updatedUser.fraudCounter === 100 && updatedUser != null) {
      setUpdateWrapper(true);
      setShowSuccDialog(false);
    } else setShowInvalidUpdateDialog(false);

    setUpdateWrapper(false);
    setShowLoader(false);
  };

  const cm: IContextualMenuItem[] = [
    {
      key: MenuItems.delete,
      iconProps: { iconName: "Delete" },
      text: "Benutzer von Whitelist entfernen",
    },
  ];

  const tc = TableConfig.createInstance({
    url: `${amURL}account`,
    sourceType: ETableSource.dynamic,
    arrayName: "data",
    sort: [],
    columns: [
      {
        name: "id",
        label: "VOID",
        sortable: true,
      },
      {
        name: "name",
        label: "Firmenname",
        sortable: false,
      },
      {
        name: "street",
        label: "Adresse",
        sortable: false,
      },
      {
        name: "level",
        label: "Level",
        sortable: false,
      },
      {
        name: "voidL1",
        label: "L1",
        sortable: true,
      },
      {
        name: "voidL2",
        label: "L2",
        sortable: true,
      },
      {
        name: "vbName",
        label: "VB",
        sortable: false,
      },
      {
        name: "vlName",
        label: "VL",
        sortable: false,
      },
      {
        name: "fraudCounter",
        label: "Fraud Sperre",
        sortable: true,
      },
    ],
  });

  //this is case where we need the whole table in excel not only the page that we are currently on
  const exportAllDataToCSV = async () => {
    setShowLoader(true);
    if (!ApiAsync.token) ApiAsync.token = StorageHelper.getUser()!.easyToken;

    const MAX_INT_VALUE = 2147483647;
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";

    const data = await fetch(`${amURL}account`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: ApiAsync.token,
      },
      body: JSON.stringify({
        needle: "",
        paging: {
          start: 0,
          pageSize: MAX_INT_VALUE,
        },
      }),
    });
    const response = await data.json();

    const ws = XLSX.utils.json_to_sheet(response.data);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const dataExcel = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(dataExcel, "export.xlsx");

    setShowLoader(false);
  };

  const cfg: ITableWrapperProps = {
    id: "rety",
    onItemChanged: (u) => {
      setCurrentAccount(u);
    },
    model: { needle: "", paging: TableConfig.initPaging() },
    filter: [{ type: EControlType.text, key: "needle", label: "Suche" }],
    filterAction: (a) => {
      return (
        <>
          <PrimaryButton
            text="Händler hinzufügen"
            onClick={() => setShowDialog(false)}
          />

          <PrimaryButton
            text="Download CSV"
            onClick={() => exportAllDataToCSV()}
          />
        </>
      );
    },
    action: async () => await UpdateAccountByVoid(currentAccount!.id),
    contextMenu: cm,
    updateWrapper: updateWrapper,
    config: tc,
  };

  return (
    <>
      {showLoader && (
        <LoaderVf type={ELoaderVfType.full} message={loaderMessage} />
      )}
      <TableWrapper {...cfg} />
      <Dialog
        hidden={showDialog}
        onDismiss={() => setShowDialog(true)}
        dialogContentProps={{
          type: DialogType.normal,
          title: "Soll der VOID: zur Whitelist\n hinzugefügt werden?",
        }}
        modalProps={{
          isBlocking: true,
          containerClassName: "ms-dialogMainOverride",
        }}
      >
        <TextField
          value={inputValue}
          errorMessage={errorMessage}
          placeholder={"VOID dem mTan Verfahren hinzufügen."}
          onChange={ValidateVOID}
          type="number"
          maxLength={8}
          minLength={8}
          min={8}
          max={8}
        />
        <DialogFooter>
          <Button
            variant="danger"
            onClick={SearchAccountByVoid}
            disabled={isVOIDValueWrong}
          >
            Ja
          </Button>
          <Button variant="secondary" onClick={() => setShowDialog(true)}>
            Abbrechen
          </Button>
        </DialogFooter>
      </Dialog>

      <Dialog
        hidden={showSuccDialog}
        onDismiss={() => setShowSuccDialog(true)}
        dialogContentProps={{
          type: DialogType.largeHeader,
          title: `Benutzer erfolgreich mit VOID ${currentAccount?.id} aktualisiert`,
        }}
        modalProps={{
          isBlocking: true,
          containerClassName: "ms-dialogMainOverride",
        }}
      >
        <DialogFooter>
          <Button variant="secondary" onClick={() => setShowSuccDialog(true)}>
            Abbrechen
          </Button>
        </DialogFooter>
      </Dialog>

      <Dialog
        hidden={showInvalidVoidDialog}
        onDismiss={() => setShowInvalidVoidDialog(true)}
        dialogContentProps={{
          type: DialogType.largeHeader,
          title: `Benutzer mit VOID ${inputValue} nicht gefunden`,
        }}
        modalProps={{
          isBlocking: true,
          containerClassName: "ms-dialogMainOverride",
        }}
      >
        <DialogFooter>
          <Button
            variant="secondary"
            onClick={() => setShowInvalidVoidDialog(true)}
          >
            Abbrechen
          </Button>
        </DialogFooter>
      </Dialog>

      <Dialog
        hidden={showInvalidUpdateDialog}
        onDismiss={() => setShowInvalidUpdateDialog(true)}
        dialogContentProps={{
          type: DialogType.largeHeader,
          title: `Ungültiges Update für Benutzer mit VOID ${inputValue}`,
        }}
        modalProps={{
          isBlocking: true,
          containerClassName: "ms-dialogMainOverride",
        }}
      >
        <DialogFooter>
          <Button
            variant="secondary"
            onClick={() => setShowInvalidUpdateDialog(true)}
          >
            Abbrechen
          </Button>
        </DialogFooter>
      </Dialog>
    </>
  );
};

export default WhitelistBestandskunden;
