import * as linq from 'linq';
import _ from 'lodash';
import { ErrorMessages, ValidationMessages } from './messages';

export interface IValidationOption {
    key: string;
    message: string;
    type: EValidationOption;

    target?: any;
};

export enum EValidationOption {
    undefined = 0,
    required = 1,
    minLength = 2,
    exactLength = 3,
    maxLength = 4,
    email = 8,
    numeric = 17,
    passwordOutsideCustomerData = 33,
    notEqual = 41,
    validateName = 42,
    mobile = 43
};

export interface IValidationError {
    key: string;
    message: string;
};

export class Validation {
    validateModel = (model: any, options: IValidationOption[]) => {
        model.validation = [];
        for (var prop in model) {
            if (Object.prototype.hasOwnProperty.call(model, prop)) {
                var all = linq.from<IValidationOption>(options).where(x => x.key == prop).toArray();

                if (all) {
                    for (var a of all) {
                        if (a.type == EValidationOption.required) {
                            this.validateRequired(model, a);
                        } else if (a.type == EValidationOption.numeric) {
                            this.validateNumeric(model, a);
                        } else if (a.type == EValidationOption.exactLength) {
                            this.validateExactLength(model, a);
                        } else if (a.type == EValidationOption.minLength) {
                            this.validateMinLength(model, a);
                        } else if (a.type == EValidationOption.notEqual) {
                            this.validateNotEqual(model, a);
                        } else if (a.type === EValidationOption.passwordOutsideCustomerData) {
                            this.passwordOutsideCustomerData(model, a)
                        } else if (a.type === EValidationOption.validateName) {
                            this.validateName(model, a);
                        } else if (a.type === EValidationOption.email) {
                            this.validateEMail(model, a);
                        } else if (a.type === EValidationOption.mobile) {
                            this.validateMobile(model, a);
                        }
                    };
                };
            };
        };

        //reverse
        var req = linq.from<IValidationOption>(options).where(x => x.type == EValidationOption.required).toArray();
        for (var r of req) {
            if (!Object.prototype.hasOwnProperty.call(model, r.key)) {
                if (this.validateRequired(model, r)) {
                    continue;
                };
                this.appendError(model, { key: r.key, message: ValidationMessages.required });
            };
        };

        if (!model.validation || model.validation.length == 0) {
            return true;
        };

        return model.validation.length == 0;
    };

    appendError(target: any, error: IValidationError) {
        if (!target.validation) {
            target.validation = [];
        };

        target.validation.push(error);
    };

    private passwordOutsideCustomerData(model: any, option: IValidationOption) {
        var re = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?!.*?[;,'"])(?!.*?[äöüÖÄÜß]).{8,30}$/gm;
        var s: string = model[option.key].toString();
        if (s !== '' && !s.match(re)) {
            this.appendError(model, { key: option.key, message: ValidationMessages.errorPasswordOutsideCustomerData });
        }
    };

    validateRequired(model: any, option: IValidationOption): boolean {
      
        if (model[option.key]) {
            return true;
        };

        if (option.key.includes('.')) {
            let splitted = option.key.split('.');
            let root = model[splitted[0]];
            if (root[splitted[1]]) {
                return true;
            }
        }

        this.appendError(model, { key: option.key, message: ValidationMessages.required });

        return false;
    };

    validateMinLength(model: any, option: IValidationOption) {
        if (model[option.key]) {
            var s: string = model[option.key].toString();

            if (option.target && s.length >= option.target) {
                return;
            }
        };

        this.appendError(model, { key: option.key, message: option.message.replace('{0}', (option.target!).toString()) });
    };

    validateExactLength(model: any, option: IValidationOption) {
        if (model[option.key]) {
            var s: string = model[option.key].toString();

            if (option.target && s.length == option.target) {
                return;
            }
        };

        this.appendError(model, { key: option.key, message: option.message.replace('{0}', (option.target!).toString()) });
    };

    validateNotEqual(model: any, option: IValidationOption) {
        if (model[option.key]) {
            var s: string = model[option.key].toString();

            if (option.target && s != option.target) {
                return;
            }
        };

        this.appendError(model, { key: option.key, message: option.message.replace('{0}', (option.target!).toString()) });
    };

    validateNumeric(model: any, option: IValidationOption) {
        if (model[option.key]) {
            var s = model[option.key].toString();

            if (s.match(/^-?\d+$/)) {
                //valid integer (positive or negative)
                return;
            } else if (s.match(/^\d+\.\d+$/)) {
                //valid float
                return;
            } else {
                //not valid number
            }
        };

        this.appendError(model, { key: option.key, message: ValidationMessages.numeric });
    };

    validateName(model: any, option: IValidationOption) {
        const regex = /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u;
        var s: string = model[option.key].toString();
        if (s !== '' && !s.match(regex)) {
            this.appendError(model, { key: option.key, message: ValidationMessages.nameNotValidError });
        }
    }

    validateEMail(model: any, option: IValidationOption) {
        var regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        var s: string = model[option.key].toString();
        if (s !== '' && !s.match(regex)) {
            this.appendError(model, { key: option.key, message: ValidationMessages.errorEmailnotValid });
        }
    }

    validateMobile(model: any, option: IValidationOption) {
        var regex = /^(?:\+|0)\d+/;
        var s: string = model[option.key].toString();
        if (s !== '' && !s.match(regex)) {
            this.appendError(model, { key: option.key, message: ValidationMessages.errorMobile });
        }
    }

    static getMessage = (model: any, key: string) => {
      
        if (model && model.validation) {
            var i = linq.from<IValidationError>(model.validation).where(x => x.key == key).toArray();
            if (i && i.length > 0) {
                return i[0].message;
            };
        };

        return undefined;
    };
};