import React from 'react';
import { Product, IProductEditModel } from '../model/data/Product';
import { EScreenMode } from '../model/app/Enums';
import Api from '../utils/Api';
import linq from 'linq';
import IKeyText from '../model/app/IKeyText';
import { IApiResponse } from '../model/app/IApiResponse';
import {
    DocumentCard,
    DocumentCardActivity,
    DocumentCardTitle,
    DocumentCardDetails,
    DocumentCardImage,
    IDocumentCardStyles,
    IDocumentCardActivityPerson
} from 'office-ui-fabric-react/lib/DocumentCard';
import Global from '../utils/Global';

import { ImageFit } from 'office-ui-fabric-react/lib/Image';
import { Dialog, DialogFooter, DialogType, Icon, Pivot, PivotItem, Panel, PanelType, PrimaryButton, DefaultButton, TextField, BaseButton, Dropdown, IDropdownOption } from 'office-ui-fabric-react';
import Loader from '../Support/Loader';
import { Brand } from '../model/data/Brand';
import { ListValueOption } from "../model/data/ListValueOption";
import EditPanel from '../screens/panels/EditPanel';
import { DatePicker, DayOfWeek, IDatePickerStrings } from 'office-ui-fabric-react/lib/DatePicker';
import StorageHelper from '../utils/StorageHelper';
import SUBSforProduct from '../screens/panels/subsForProductScreen';
import BusinessCases from '../screens/panels/businessCasesForProduct';
import { ServiceFamily } from '../model/data/ServiceFamily';
import AppRouter from '../utils/AppRouter';
import Button from 'react-bootstrap/Button';
import { ProgressIndicator } from 'office-ui-fabric-react/lib/ProgressIndicator';

export interface IFamilyScreenState {
    families?: ServiceFamily[],
    mode: EScreenMode,
    hidden: boolean,
    changed: boolean,
    disableButtonNameField: boolean
};

export class ServiceFamilyScreen extends React.Component<any, IFamilyScreenState> {
    private url: string = "activationdata/";
    private currentFamily: ServiceFamily;

    private cardStyles: IDocumentCardStyles = {
        root: { display: 'inline-block', marginRight: 20, marginBottom: 20, width: 320,backgroundColor:  '#F5F5F5',borderRadius: '15px',
        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.19)' }
    };
    private people: IDocumentCardActivityPerson[] = [
        { name: 'Jens Seidel', profileImageSrc: "/assets/img/jens.jpg" },
    ];


    constructor(props: any) {
        super(props);

        this.onDataArrival = this.onDataArrival.bind(this);

        this._openEditor = this._openEditor.bind(this);
        this._saveFamily = this._saveFamily.bind(this);
        this._refreshModel = this._refreshModel.bind(this);
        this._hidePanel = this._hidePanel.bind(this);
        this._onYes = this._onYes.bind(this);
        this._onNo = this._onNo.bind(this);
        this._getErrorMessageNameField = this._getErrorMessageNameField.bind(this);

        this.prepareForDeletion = this.prepareForDeletion.bind(this);


        this.currentFamily = new ServiceFamily();

        this.state = {
            families: undefined,
            mode: EScreenMode.loading,
            hidden: true,
            changed: false,
            disableButtonNameField: false
        };


        Api.fetch(this.url + 'getServiceFamilies/', undefined, "GET", this.onDataArrival);

    };

    public render() {
        if (this.state.mode === EScreenMode.loading) {
            return <ProgressIndicator label="Daten werden geladen" description="Bitte warten Sie" />;
        }
        else {
            return this.renderData();
        }
    };


    private renderData(): JSX.Element {
        let ctrl = (<></>);

        let cfg = Global.getConfiguration();

        let t1 = this.props.title == null ? 'Bitte bestätigen' : this.props.title;
        let t2 = this.props.text == null ? 'Sind Sie sicher?' : this.props.text;

        if (this.state.families) {
            ctrl = (
                <>
                    {/* <PrimaryButton text='+ Neu Familie' onClick={() => { this._openEditorForNewFamily(this.currentFamily)}} />  */}
                    <Button variant="danger" onClick={() => { this._openEditorForNewFamily(this.currentFamily) }}>+ Neue Dienstfamilie</Button>
                    {
                        this.state.hidden ? <></> :
                            <>
                                <Dialog
                                    hidden={this.state.hidden}
                                    onDismiss={this._onNo}
                                    dialogContentProps={{
                                        type: DialogType.normal,
                                        title: t1,
                                        subText: t2
                                    }}
                                    modalProps={{
                                        titleAriaId: t1,
                                        subtitleAriaId: t2,
                                        isBlocking: false,
                                        containerClassName: 'ms-dialogMainOverride'
                                    }}
                                >
                                    <DialogFooter>
                                       
                                        <Button variant="danger" onClick={this._onYes}>Ja</Button>
                                 
                                        <Button variant="secondary" onClick={this._onNo}>Abbrechen</Button>
                                    </DialogFooter>
                                </Dialog>
                            </>
                    }
                    <div className="spacer-default" />
                    <div className="spacer-default" />
                    {this.state.families.map((i, idx) => (
                        this.renderFamily(i)
                    ))}

                    {this.renderEditor()}
                </>
            );
        }

        return ctrl;
    };

    private _onNo() {
        this.setState({ hidden: true, mode: EScreenMode.view });
    };

    private _onYes() {
        Api.fetch(this.url + 'deleteServiceFamily/', this.currentFamily, "POST",
            (api: IApiResponse) => {
                this.setState({
                    mode: EScreenMode.view,
                    families: api.data,
                    hidden: true,
                });
            });
        return this.renderFamily;
    };

    private _openEditorForNewFamily(dc: any) {
        this.setState({ mode: EScreenMode.edit });
        this._openEditor(undefined);
    };

    private renderEditor(): JSX.Element {
        let ctrl = <></>

        ctrl =
            <Panel isBlocking={false} isOpen={this.state.mode === EScreenMode.edit} onDismiss={this._hidePanel} type={PanelType.large} headerText="Bearbeiten">
                {
                    this.state.mode === EScreenMode.loading ? <Loader /> :
                        (
                            this.renderControl()
                        )
                }
            </Panel>

        return ctrl;
    };


    public renderControl() {
        if (this.state.mode != EScreenMode.edit) {
            return;
        }

        let ctrl = <div>

            <Pivot>
                <PivotItem headerText="Generell">
                    <div className="ms-Grid">
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-md6">
                                <TextField label="Name" name="Name" onGetErrorMessage={this._getErrorMessageNameField}
                                    validateOnLoad={false} required value={this.currentFamily.Name} onChange={this._refreshModel} />
                            </div>
                            <div className="ms-Grid-col ms-md6">
                                <TextField label="Sort" name="Sort" required value={this.currentFamily.Sort.toString()} onChange={this._refreshModel} />
                            </div>
                        </div>
                        <br/>
                        {/* <PrimaryButton text="Speichern" onClick={this._saveFamily} />
                        <DefaultButton className="default-button-edit-screen" text="Abbrechen" onClick={this._hidePanel} /> */}
                        <Button variant="primary" disabled={this.state.disableButtonNameField} onClick={this._saveFamily} >Speichern</Button>
                        <Button className="default-button-edit-screen" variant="secondary" onClick={this._hidePanel}>Abbrechen</Button>
                    </div>

                </PivotItem>
            </Pivot>
        </div>
        return ctrl;
    }

    private _getErrorMessageNameField = (value: string): string=>{
        const regex = /^[A-Za-z0-9_-\s&]{1,63}$/gm;
      
        const str = value;
        let m;
        let err: string = '';

    
        if ((m = regex.exec(str)) !== null) {
            err = '';
            this.setState({ disableButtonNameField: false });
        } else {
            err = `Das Feld sollte nur aus Buchstaben, Zahlen, &, Unterstrichen und Bindestrichen bestehen und nicht mehr als 63 Symbole.`;
            this.setState({ disableButtonNameField: true });
        }


        return err;
    }

    private _saveFamily() {


        Api.fetch(this.url + 'saveServiceFamily/', this.currentFamily, "POST",
            (api: IApiResponse) => {
                this.setState({
                    mode: EScreenMode.view,
                    families: api.data
                });
            });
    };



    private _hidePanel() {
        this.setState({ mode: EScreenMode.view });
    };

    private _refreshModel(ctrl: any) {
        Global.setValueByPath(this.currentFamily, ctrl.target.name, ctrl.target.value);
        this.setState({ changed:true });
    }

    private _openEditor(dc: any) {
        
        let id = dc == undefined ? "0" : dc.currentTarget.id.replace("dc-", "");

        Api.fetch(this.url + 'getServiceFamily/' + id, undefined, "GET", (api: IApiResponse) => {
            this.currentFamily = api.data;
            this.setState({ mode: EScreenMode.edit });
        });
    };

    private renderFamily(family: ServiceFamily): JSX.Element {
        let id: string = 'dc-' + family.Id;
        let idButton: string = 'dc-' + family.Id;


        let ctrl =
            (

                <DocumentCard  id={id} key={family.Id} styles={this.cardStyles} >
                    {/* <DocumentCardImage height={150} imageFit={ImageFit.cover} /> */}
                    <DocumentCardDetails>
                        <DocumentCardTitle title={family.Name} shouldTruncate />
                    </DocumentCardDetails>
                  
                   <div style={{float: 'right'}}>{'Sort: '+family.Sort}</div>
                    <Icon className="dcicon" id={idButton} iconName="Edit" title="Produkt bearbeiten" onClick={this._openEditor} />
                    <Icon className="dcicon" id={idButton} iconName="Delete" title="Produkt löschen" onClick={this.prepareForDeletion} />
                </DocumentCard>
            );

        return ctrl;
    };

    private prepareForDeletion(dc: any) {
        let id = dc.currentTarget.id.replace("dc-", "");
        if (this.state.families) {
            this.currentFamily = linq.from<ServiceFamily>(this.state.families)
                .where(x => x.Id === Number(id))
                .firstOrDefault();
            if (!this.currentFamily) {


            }
            this.setState({ hidden: false });
        }
    };







    private onDataArrival(api: IApiResponse) {
        this.setState({
            mode: EScreenMode.view,
            families: api.data
        });
    };
};

export default ServiceFamilyScreen;