import React from "react";
import { IContextualMenuItem } from "office-ui-fabric-react";
import TableWrapper, {
  ITableWrapperProps,
  TableConfig
} from "../../components/table/TableWrapper";
import { ITableScreen } from "../../interfaces/ITableScreen";
import { EScreenMode } from "../../model/app/Enums";
import { MenuItems, Roles, WebSites } from "../../utils/Defaults";
import { IShopOrder } from "../am/Models";
import { ETableSource } from "../../components/table/enums/table-source.enum";
import { EControlType } from "../../components/table/enums/control-type.enum";
import AccessManager from "../../utils/AccessManager";
import AccountManager from "../../utils/AccountManager";
import linq from "linq";
import IKeyText from "../../model/app/IKeyText";
import StorageHelper from "../../utils/StorageHelper";
import HotlineShopEditPanel from "./panels/HotlineShopEditPanel";
import Global from "../../utils/Global";

export interface IAmAccountScreenState extends ITableScreen {
  pivot: string;
}

export class HotlineShopScreen extends React.Component<
  {},
  IAmAccountScreenState
> {
  private comp: JSX.Element | null = null;
  private am: AccountManager = new AccountManager();
  private current?: IShopOrder;

  constructor(props: any) {
    super(props);

    this.state = {
      mode: EScreenMode.view,
      pivot: HotlineShopEditPanel.pivotMain
    };
  }

  render = () => {
    return (
      <AccessManager roles={[Roles.PortalAdministrator, Roles.BO]}>
        {this.renderInner()}
      </AccessManager>
    );
  };

  private processContextMenu = (item, e) => {
    if (e.key == MenuItems.edit) {
      this.setState({
        mode: EScreenMode.edit,
        id: item.id,
        pivot: HotlineShopEditPanel.pivotMain
      });
    }
  };

  private renderInner = () => {
    let cm: IContextualMenuItem[] = [
      {
        key: MenuItems.edit,
        iconProps: { iconName: "Edit" },
        text: "Bearbeiten"
      }
    ];

    const stat = StorageHelper.getStructure("statusesOrder");

    let tc = TableConfig.createInstance({
      url:
        linq
          .from<IKeyText>(StorageHelper.getWebsites())
          .toArray()
          .filter((x) => x.key == WebSites.gate)[0].text + "shop/order/filter",
      sourceType: ETableSource.dynamic,
      arrayName: "orders",
      sort: [],
      columns: [
        { name: "void", label: "VOID", sortable: true },
        {
          name: "orderDate",
          label: "Datum",
          sortable: true,
          renderer: (r, c) => {
            return Global.toGermanDate(r.orderDate);
          }
        },
        {
          name: "a",
          label: "Status",
          renderer: (r, c) => {
            var a = linq
              .from<IKeyText>(stat)
              .where((x) => x.key == r.statusId)
              .firstOrDefault();
            if (a) {
              return a.text;
            }
          }
        },
        { name: "productsCount", label: "Produkte", sortable: false },
        {
          name: "x",
          label: "Adresse",
          renderer: (r, c) => {
            var a = Global.formatAddress(r);
            return <p title={a}>{Global.formatAddress(r)}</p>;
          }
        },
        { name: "regionId", label: "Region", sortable: true }
      ]
    });

    let cfg: ITableWrapperProps = {
      id: "rety",
      action: this.processContextMenu,
      onItemChanged: (u) => {
        this.current = u;
      },
      model: { needle: "", statusId: "-1", paging: TableConfig.initPaging() },
      contextMenu: cm,
      filter: [
        { type: EControlType.text, key: "needle", label: "Suche" },
        {
          type: EControlType.dropdown,
          key: "statusId",
          label: "Status",
          list: StorageHelper.getStructure("statusesOrder", true)
        },
        {
          type: EControlType.dropdown,
          key: "regionId",
          label: "Region",
          list: StorageHelper.getStructure("regions", true)
        }
      ],
      config: tc
    };

    return (
      <>
        <h1>Bestellungen</h1>
        <div className="data">
          <TableWrapper {...cfg} />

          {this.state.mode == EScreenMode.edit &&
          HotlineShopEditPanel.getPivots().indexOf(this.state.pivot) >= 0 ? (
            <HotlineShopEditPanel
              id={this.state.id!}
              onDismiss={() => {
                this.setState({ mode: EScreenMode.view });
              }}
            />
          ) : null}
        </div>
      </>
    );
  };
}

export default HotlineShopScreen;
