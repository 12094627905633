import { initializeIcons } from '@uifabric/icons';
import { createBrowserHistory } from 'history';
import { FontWeights, loadTheme, Spinner, SpinnerSize } from 'office-ui-fabric-react';
import { ResponsiveMode } from 'office-ui-fabric-react/lib/utilities/decorators/withResponsiveMode';
import React from 'react';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import "regenerator-runtime/runtime";
import '../src/assets/css/app.css';
import '../src/assets/css/icons.css';
import '../src/assets/css/office.css';
import '../src/assets/css/dev.css';
import '../src/assets/css/table.css';
import '../src/assets/css/loader.css';

import '../src/assets/css/site.css';
import '../src/assets/css/e4.css';
import * as c from './components/index';
import Api from './utils/Api';
import AppRouter from './utils/AppRouter';
import LoaderVf, { ELoaderVfType, EMessageType, ILoaderMessage } from './Support/LoaderVf';
import { MainTexts } from './utils/messages';
import Global from './utils/Global';
import StorageHelper from './utils/StorageHelper';

export const hist = createBrowserHistory();

loadTheme({
  palette: {
    themePrimary: '#e60000',
    themeLighterAlt: '#fef4f4',
    themeLighter: '#fbd3d3',
    themeLight: '#f7adad',
    themeTertiary: '#f06060',
    themeSecondary: '#e91c1c',
    themeDarkAlt: '#cf0000',
    themeDark: '#ae0000',
    themeDarker: '#810000',
    neutralLighterAlt: '#f8f8f8',
    neutralLighter: '#f4f4f4',
    neutralLight: '#eaeaea',
    neutralQuaternaryAlt: '#dadada',
    neutralQuaternary: '#d0d0d0',
    neutralTertiaryAlt: '#c8c8c8',
    neutralTertiary: '#c2c2c2',
    neutralSecondary: '#858585',
    neutralPrimaryAlt: '#4b4b4b',
    neutralPrimary: '#333333',
    neutralDark: '#272727',
    black: '#1d1d1d',
    white: '#ffffff',
  }
});

initializeIcons();

export interface IAppState {
  ready: boolean;
  mounted?: boolean;
  lm: ILoaderMessage;
}

export class App extends React.Component<any, IAppState> {
  constructor(props: any) {
    super(props);

    this.state = {
      ready: false,
      mounted: false,
      lm: { title: MainTexts.appName, body: 'Anwendung wird initialisiert', type: EMessageType.loader }
    }

    // Api.getUser(() => {
    //   this.setState({ ready: true });
    // });

    window.onpopstate = (e: PopStateEvent) => {
      AppRouter.routeTo(e.state, window.location.href);
    };
  };

  componentDidMount(): void {
    this.setState({ mounted: true });

    this.start();
  };

  public render() {
    console.log('m', this.state);
    if (!this.state.mounted) { return <></> };

    const user = StorageHelper.getUser();

    let ctrl = (
      !this.state.ready ? this.renderStart() : user ?
        <>
          <c.Head {... { navOpen: false, responsiveMode: ResponsiveMode.large, title: 'Backoffice' }} />
          <c.AppContainer />
        </> : <>...</>
    );

    return ctrl;
  };

  private renderStart(): JSX.Element {
    let ctrl = <div className="container-bg">
      <LoaderVf type={ELoaderVfType.full} message={this.state.lm} />
    </div>

    return ctrl;
  };

  private async start() {
    // if(window.location.pathname != '/') {
    //   this.setState({ready: true});
    //   return;
    // };

    var step = 1000;

    await Global.wait(step);

    await Api.getUserOnly(async () => {
      this.setState(p => ({ lm: { ...p.lm, body: 'Benutzerdaten werden geladen' } }));

      await Global.wait(step);


      Api.getStructures(() => {
        this.setState(p => ({ lm: { ...p.lm, body: 'Defintionen werden geladen' } }));
      });

      Api.getContainers(() => {
        this.setState(p => ({ lm: { ...p.lm, body: 'Defintionen werden geladen' } }));
      })

      Api.getBrands(() => {
        this.setState(p => ({ lm: { ...p.lm, body: 'Defintionen werden geladen' } }));
      })
    });

    await Global.wait(step);

    this.setState(p => ({ lm: { ...p.lm, body: 'Anwendung gestartet' } }));

    await Global.wait(step);

    this.setState({ ready: true });
  };
};

export default App;
