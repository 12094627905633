import { EScreenMode } from "../../../../src/model/app/Enums";
import { IEpmProduct } from "../../../../src/model/data/IEpmProduct";
import React, { useEffect, useState } from "react";
import EpmManager from "../../../utils/EpmManager";
import { IModelPanel } from "../../../interfaces/IModel";
import { ITableScreen } from "../../../interfaces/ITableScreen";
import Global from "../../../utils/Global";
import LoaderVf, { ELoaderVfType } from "../../../Support/LoaderVf";
import { ActionButton, Dropdown, Panel, PanelType, Pivot, PivotItem, PrimaryButton, TextField } from "office-ui-fabric-react";
import { Col, Container, Row } from "react-bootstrap";
import { MainTexts } from "../../../utils/messages";
import { PropertiesEditor } from "./PropertiesEditor";

import linq from "linq";
import StorageHelper from "../../../utils/StorageHelper";
import IKeyText from "../../../model/app/IKeyText";
import TableFilter from "../../../components/table/TableFilter";
import IEpmProperties from "../../../model/data/Properties";
import { IBlacklistItem } from "../../../model/data/IBlacklistItem";
import { MerchandiseTypes } from "../blacklist/merchandise-type.enum";


export const pivotMain = "main";
export const pivotProps = "props"

export const getPivots = (): string[] => {
    return [pivotMain];
}

export const BlacklistItemEditPanel = ({
    selectedItem,
    id,
    onDismiss,
}) => {
    const prefix: string = "ef";
    const epmManager: EpmManager = new EpmManager;

    const [mode, setMode] = useState(EScreenMode.loading);
    const [pivot, setPivot] = useState(pivotMain);
    const [item, setItem] = useState<IBlacklistItem>(selectedItem)
    const [isChanged, setIsChanged] = useState(false);
    const brands = StorageHelper.getBrandKeys(true)
    const types = MerchandiseTypes;

    useEffect(() => {
        setMode(EScreenMode.view)
    }, []);

    const handleInputChange = (e) => {
        setIsChanged(true);
        const { name, value } = e.target;

        setItem((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const onSave = async () => {
        setMode(EScreenMode.view)
        epmManager.updateBlacklistItem(item);
    };

    const onRenderFooterContent = (): JSX.Element => {

        return (<div>
            <ActionButton
                iconProps={{ iconName: "Cancel" }}
                text={MainTexts.cancel}
                onClick={onDismiss}
                disabled={!isChanged}
            />
            <PrimaryButton
                text={MainTexts.save}
                onClick={onSave}
                disabled={!isChanged}
            />
        </div>);
    };

    const renderMain = () => {
        if (item) {
            const m = item as IBlacklistItem;

            return (
                <Container className="ee">
                    <Row>
                        <Col md={2} xs={2}>
                            {MainTexts.id}
                        </Col>
                        <Col md={10} xs={10}>
                            <TextField
                                id={prefix + "-id"}
                                name="id"
                                disabled={true}
                                value={m.id?.toString()}
                                onChange={handleInputChange}
                                readOnly
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={2} xs={2}>
                            Brand
                        </Col>
                        <Col md={10} xs={10}>
                        <select
                            className="form-control"
                            name="brandId"
                            value={item.brandId}
                            onChange={handleInputChange}
                        >
                            <option value="">Select a Brand</option>
                            {brands.map((brand) => (
                                <option key={brand.key} value={brand.key}>
                                    {brand.text}
                                </option>
                            ))}
                        </select>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={2} xs={2}>
                            Type
                        </Col>
                        <Col md={10} xs={10}>
                        <select
                            className="form-control"
                            name="typeId"
                            value={m.typeId}
                            onChange={handleInputChange}
                        >
                            <option value="">Select a Type</option>
                            {types.map((type) => (
                                <option key={type.key} value={type.key}>
                                    {type.text}
                                </option>
                            ))}
                        </select>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={2} xs={2}>
                            Key
                        </Col>
                        <Col md={10} xs={10}>
                            <TextField
                                id={prefix + "-key"}
                                name="key"
                                value={m.key?.toString()}
                                onChange={handleInputChange}
                            />
                        </Col>
                    </Row>
                </Container>
            );
        }
    };

    let ctrl = <></>;
    if (mode === EScreenMode.loading || (item && item.error)) {
        ctrl = <LoaderVf type={ELoaderVfType.full}/>;
    } else {
        ctrl = (
            <Panel
                isOpen={true}
                onDismiss={onDismiss}
                type={PanelType.medium}
                headerText="Product"
                isFooterAtBottom={true}
                onRenderFooterContent={onRenderFooterContent}
            >
                <Container>
                    <Row>
                        <Col>{renderMain()}</Col>
                    </Row>
                </Container>
            </Panel>
        );
    }

    return ctrl;
}




