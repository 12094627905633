export class ListValueOption {
  public text: string = "";
  public value: string = "";
  public selected: boolean = true;

  public static createInstance(
    text: string,
    value: string,
    selected: boolean
  ): ListValueOption {
    let i: ListValueOption = new ListValueOption();

    i.selected = selected;
    i.text = text;
    i.value = value;

    return i;
  }
}
