import linq from "linq";
import {
  Checkbox,
  ContextualMenu,
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  DirectionalHint,
  IContextualMenuItem,
  Panel,
  PanelType,
  Pivot,
  PivotItem,
  PrimaryButton,
  SearchBox,
  TextField
} from "office-ui-fabric-react";
import {
  DetailsList,
  IColumn,
  IGroup,
  Selection,
  SelectionMode
} from "office-ui-fabric-react/lib/DetailsList";
import { Dropdown } from "office-ui-fabric-react/lib/Dropdown";
import { ProgressIndicator } from "office-ui-fabric-react/lib/ProgressIndicator";
import { getTheme } from "office-ui-fabric-react/lib/Styling";
import React from "react";
import Button from "react-bootstrap/Button";
import "../../src/App.css";
import { EScreenMode } from "../model/app/Enums";
import { IApiResponse } from "../model/app/IApiResponse";
import { Brand } from "../model/data/Brand";
import { ListValueOption } from "../model/data/ListValueOption";
import IKeyText from "../model/app/IKeyText";
import Service from "../model/data/Service";
import Loader from "../Support/Loader";
import Api from "../utils/Api";
import Global from "../utils/Global";
import StorageHelper from "../utils/StorageHelper";

const theme = getTheme();

export interface IServiceScreenState {
  //services: Service[],
  mode: EScreenMode;
  hidden: boolean;
  changed: boolean;
  disableButtonAgeValField: boolean;
  disableButtonNameField: boolean;
  disableButtonKeyField: boolean;
  filter: string;
  contextMenu?: {
    target: HTMLElement;
    items: IContextualMenuItem[];
  };
  currentItem?: any;
}

export interface IServicesViewModel {
  services: Service[];
  types: ListValueOption[];
  families: ListValueOption[];
}

export interface IServiceItem {
  Id: number;
  Name: string;
  Key: string;
  BrandId: number;
  Type: string;
  FamilyId: number;
  TypeXmlId: number;
  Mandatory: boolean;
  ActivationFee: string;
}

export class ServiceScreenGroupedColumns extends React.Component<
  any,
  IServiceScreenState
> {
  private url: string = "activationdata/";
  private currentService: Service;
  private currentBrandId: number;
  private model: IServicesViewModel;

  private _items: IServiceItem[] = [];
  private _itemsSorted: IServiceItem[] = [];
  private _columns: IColumn[] = [];
  private _groups: IGroup[] = [];
  private _selection: Selection = new Selection();

  constructor(props: any) {
    super(props);

    this.currentService = new Service();
    this.model = { services: [], types: [], families: [] };

    this.state = {
      //services: [],
      mode: EScreenMode.loading,
      disableButtonKeyField: false,
      hidden: true,
      disableButtonNameField: false,
      changed: false,
      disableButtonAgeValField: false,
      filter: ""
    };

    this.onDataArrival = this.onDataArrival.bind(this);
    this._openEditor = this._openEditor.bind(this);
    this._refreshModel = this._refreshModel.bind(this);
    this._hidePanel = this._hidePanel.bind(this);
    this._saveService = this._saveService.bind(this);
    this.prepareForDeletion = this.prepareForDeletion.bind(this);
    this.onChangeService = this.onChangeService.bind(this);
    this._onNo = this._onNo.bind(this);
    this._onYes = this._onYes.bind(this);
    this.onItemContextMenu = this.onItemContextMenu.bind(this);
    this._onContextMenuItemClicked = this._onContextMenuItemClicked.bind(this);
    this._onContextMenuDismiss = this._onContextMenuDismiss.bind(this);
    this._openEditor = this._openEditor.bind(this);
    this._getErrorMessageKeyField = this._getErrorMessageKeyField.bind(this);
    this._getErrorMessageNameField = this._getErrorMessageNameField.bind(this);
    this._getErrorMessageAgeValidation =
      this._getErrorMessageAgeValidation.bind(this);

    this.currentBrandId = props.brandKey;

    this._columns = [
      {
        key: "Id",
        name: "Id",
        fieldName: "Id",
        minWidth: 100,
        maxWidth: 200,
        isResizable: true
      },
      {
        key: "Key",
        name: "Key",
        fieldName: "Key",
        minWidth: 100,
        maxWidth: 200
      },
      {
        key: "Name",
        name: "Name",
        fieldName: "Name",
        minWidth: 100,
        maxWidth: 200
      },
      {
        key: "BrandId",
        name: "BrandId",
        fieldName: "BrandId",
        minWidth: 100,
        maxWidth: 200
      },
      {
        key: "Type",
        name: "Type",
        fieldName: "Type",
        minWidth: 100,
        maxWidth: 200
      },
      {
        key: "FamilyId",
        name: "FamilyId",
        fieldName: "FamilyId",
        minWidth: 100,
        maxWidth: 200
      },
      {
        key: "TypeXmlId",
        name: "TypeXmlId",
        fieldName: "TypeXmlId",
        minWidth: 100,
        maxWidth: 200
      },
      {
        key: "Mandatory",
        name: "Mandatory",
        fieldName: "MAndatory",
        minWidth: 100,
        maxWidth: 200
      },
      {
        key: "ActivationFee",
        name: "ActivationFee",
        fieldName: "ActivationFee",
        minWidth: 100,
        maxWidth: 200
      },
      {
        key: "MonthlyFee",
        name: "MonthlyFee",
        fieldName: "MonthlyFee",
        minWidth: 100,
        maxWidth: 200
      },
      {
        key: "Sort",
        name: "Sort",
        fieldName: "Sort",
        minWidth: 100,
        maxWidth: 200
      },
      {
        key: "Model",
        name: "Model",
        fieldName: "Model",
        minWidth: 100,
        maxWidth: 200
      },
      {
        key: "TypeId",
        name: "TypeId",
        fieldName: "TypeId",
        minWidth: 100,
        maxWidth: 200
      }
    ];

    Api.fetch(
      this.url + "getServices/" + props.brandKey,
      undefined,
      "GET",
      this.onDataArrival
    );
  }

  public render() {
    if (this.state.mode === EScreenMode.loading) {
      return (
        <ProgressIndicator
          label="Daten werden geladen"
          description="Bitte warten Sie"
        />
      );
    } else {
      return (
        <>
          {this.renderGroups()}
          {this.state.mode === EScreenMode.edit ? this.renderEditor() : null}
        </>
      );
    }
  }

  private renderGroups(): JSX.Element {
    let ctrl = <></>;
    let t1 = this.props.title == null ? "Bitte bestätigen" : this.props.title;
    let t2 = this.props.text == null ? "Sind Sie sicher?" : this.props.text;

    this.prepare();

    ctrl = (
      <>
        {this.state.hidden ? (
          <></>
        ) : (
          <>
            <Dialog
              hidden={this.state.hidden}
              onDismiss={this._onNo}
              dialogContentProps={{
                type: DialogType.normal,
                title: t1,
                subText: t2
              }}
              modalProps={{
                titleAriaId: t1,
                subtitleAriaId: t2,
                isBlocking: false,
                containerClassName: "ms-dialogMainOverride"
              }}
            >
              <DialogFooter>
                <Button variant="danger" onClick={this._onYes}>
                  Ja
                </Button>
                <Button variant="secondary" onClick={this._onNo}>
                  Abbrechen
                </Button>
              </DialogFooter>
            </Dialog>
          </>
        )}
        {
          <SearchBox
            placeholder="Suche"
            // onSearch={this.onNewSearchRequest}
            // onChange={(e) => { this.setState({ filter: e }); }}
            onBlur={() => console.log("onBlur called")}
            underlined={true}
          />
        }

        <div>&nbsp;</div>

        {this.state.contextMenu ? (
          <ContextualMenu
            onItemClick={this._onContextMenuItemClicked}
            items={this.state.contextMenu.items}
            isBeakVisible={true}
            target={this.state.contextMenu.target}
            directionalHint={DirectionalHint.bottomLeftEdge}
            onDismiss={this._onContextMenuDismiss}
          />
        ) : null}
        <DetailsList
          items={this._itemsSorted}
          //onRenderCell={this._onRenderCell}
          onRenderItemColumn={this._onRenderColumn}
          //onRenderRow={this._onRenderRow}
          onItemContextMenu={this.onItemContextMenu}
          columns={this._columns}
          selection={this._selection}
          selectionMode={SelectionMode.multiple}
          groups={this._groups}
          compact={true}
        />
      </>
    );

    return ctrl;
  }

  private onItemContextMenu(item?: any, index?: number, ev?: Event) {
    if (item !== undefined) {
      this.currentService = item;

      let actionItems: any[] = [];

      let target = ev ? ev.target : undefined;

      if (target !== undefined) {
        actionItems.push({
          key: "new",
          iconProps: { iconName: "Add" },
          text: "Neu"
        });
        actionItems.push({
          key: "edit",
          iconProps: { iconName: "Edit" },
          text: "Bearbeiten"
        });
        actionItems.push({
          key: "delete",
          iconProps: { iconName: "Delete" },
          text: "Löschen"
        });

        this.setState({
          contextMenu: {
            target: target as HTMLElement,
            items: actionItems
          },
          currentItem: item
        });

        if (ev) {
          ev.preventDefault();
        }
      }
    }
  }

  private _onContextMenuDismiss(): void {
    this.setState({
      contextMenu: undefined
    });
  }

  private _onContextMenuItemClicked(
    ev?: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>,
    item?: IContextualMenuItem
  ) {
    if (item && item.key == "new") {
      //   let itm = this.state.currentItem;
      //   if (itm !== undefined) {
      //       itm.ParentId = itm.Key;
      //   }

      this.setState({
        currentItem: undefined,
        mode: EScreenMode.edit
      });
      this.currentService = new Service();
    } else if (item && item.key == "edit") {
      this._openEditor(this.currentService);
    } else {
      this.setState({
        mode: EScreenMode.delete,
        hidden: false
      });
    }
  }

  private prepare() {
    var gr: IGroup[] = [];

    let gdef = linq.from<ListValueOption>(this.model.families).toArray();

    this._itemsSorted = linq
      .from<Service>(this.model.services)
      .cast<IServiceItem>()
      .orderBy((x) => x.FamilyId)
      .toArray();

    if (this.state.filter.length > 0) {
      this._itemsSorted = linq
        .from(this._itemsSorted)
        .where((x) => x.Key.toLocaleLowerCase().indexOf(this.state.filter) >= 0)
        .orderBy((x) => x.Name)
        .toArray();
    }

    let si: number = 0;

    gdef.map((i, idx) => {
      let my = linq
        .from<IServiceItem>(this._itemsSorted)
        .where((x) => x.FamilyId.toString() === i.value)
        .toArray();

      gr.push({
        key: i.value,
        name: i.text,
        startIndex: si,
        count: my.length
      });

      si += my.length;
    });

    this._groups = gr;
  }

  // private _onRenderRow = (props: any): JSX.Element => {
  //     console.log(props);
  //     const customStyles: Partial<IDetailsRowStyles> = {};
  //     if (props.itemIndex % 2 === 0) {
  //       customStyles.root = { backgroundColor: theme.palette.redDark };
  //     }

  //     return <DetailsRow {...props} styles={customStyles} />;
  // };

  private _onRenderColumn(
    item?: IServiceItem,
    index?: number,
    column?: IColumn
  ) {
    const value =
      item && column && column.fieldName
        ? item[column.fieldName as keyof IServiceItem] || ""
        : "";

    return <div data-is-focusable={true}>{value}</div>;
  }

  private _onNo() {
    this.setState({ hidden: true, mode: EScreenMode.view });
  }

  private _onYes() {
    Api.fetch(
      this.url + "deleteService/",
      this.currentService,
      "POST",
      (api: IApiResponse) => {
        this.model = api.data;
        this.setState({
          mode: EScreenMode.view,
          hidden: true
        });
      }
    );
  }

  private prepareForDeletion(dc: any) {
    let id = dc.currentTarget.id.replace("dc-", "");
    this.currentService = linq
      .from<Service>(this.model.services)
      .where((x) => x.Id === Number(id))
      .firstOrDefault();

    if (!this.currentService) {
      this.currentService = new Service();
      this.currentService.BrandId = this.props.brandId;
    }

    this.setState({ hidden: false });
  }

  private _openEditor(dc: any) {
    let id = this.currentService.Id;
    if (this.model.services) {
      this.currentService = linq
        .from<Service>(this.model.services)
        .where((x) => x.Id === Number(id))
        .firstOrDefault();

      if (!this.currentService) {
        this.currentService = new Service();
        this.currentService.BrandId = Number(this.props.brandKey);
      }

      var arr_from_json: any = undefined;

      if (this.currentService.Model === "") {
      } else {
        arr_from_json = JSON.parse(this.currentService.Model);
      }

      if (arr_from_json === undefined) {
        if (this.props.brandKey === "dsl") {
          arr_from_json = JSON.parse(
            '{"product":{},"product_ui":{"ui_tooltip":"","ui_name":"","ui_extra":"","minDuration":""}}'
          );
        } else {
          arr_from_json = JSON.parse(
            '{"product":{},"product_ui":{"ui_tooltip":"","ui_name":"","ui_extra":""}}'
          );
        }
      }

      this.currentService.ui_name = arr_from_json.product_ui.ui_name;
      this.currentService.ui_tooltip = arr_from_json.product_ui.ui_tooltip;
      this.currentService.ui_extra = arr_from_json.product_ui.ui_extra;
      this.currentService.minDuration = arr_from_json.product_ui.minDuration;

      this.setState({ mode: EScreenMode.edit });
    }
  }

  private renderEditor(): JSX.Element {
    let ctrl = <></>;

    ctrl = (
      <Panel
        isOpen={this.state.mode === EScreenMode.edit}
        onDismiss={this._hidePanel}
        type={PanelType.medium}
        headerText="Bearbeiten"
      >
        {this.state.mode === EScreenMode.loading ? (
          <Loader />
        ) : (
          this.renderControl()
        )}
      </Panel>
    );

    return ctrl;
  }

  public renderControl() {
    let isMinDurationVisible: boolean = true;

    let optionsTypeForFamily = linq
      .from<ListValueOption>(this.model.families)
      .select<IKeyText>((x) => ({ key: x.value, text: x.text }))
      .toArray();

    if (this.props.brandKey === "dsl") {
      isMinDurationVisible = false;
    }

    let ctrl = (
      <div>
        <Pivot>
          <PivotItem headerText="Generell">
            <div className="ms-Grid">
              <div className="ms-Grid-row">
                <TextField
                  label="Name"
                  name="Name"
                  required
                  onGetErrorMessage={this._getErrorMessageNameField}
                  validateOnLoad={false}
                  value={this.currentService.Name}
                  onChange={this._refreshModel}
                />
                <TextField
                  label="Key"
                  name="Key"
                  required
                  onGetErrorMessage={this._getErrorMessageKeyField}
                  validateOnLoad={false}
                  value={this.currentService.Key}
                  onChange={this._refreshModel}
                />
                <TextField
                  label="ActivationFee"
                  name="ActivationFee"
                  required
                  value={this.currentService.ActivationFee.toString()}
                  onChange={this._refreshModel}
                />
                <TextField
                  label="Altersvalidierung"
                  name="AgeValidation"
                  onGetErrorMessage={this._getErrorMessageAgeValidation}
                  validateOnLoad={false}
                  required
                  value={this.currentService.AgeValidation}
                  onChange={this._refreshModel}
                />
                {/* <Dropdown
                                placeholder="Select an option"
                                label="Wählen sie die art der dienstleistung:"
                                options={[
                                    { key: '1', text: 'Option' },
                                    { key: '2', text: 'Promotion' },
                                    { key: '3', text: 'Additional' }
                                ]}
                                selectedKey={this.currentService.Type.toString()}
                                required={true}
                                onChanged={(option) => {
                                    this.currentService.Type = option.key.toString();
                                }}
                            /> */}

                <Checkbox
                  label="Versteckt"
                  checked={this.currentService.Hidden}
                  onChange={(ev, ischecked) => {
                    if (ischecked === true) {
                      this.currentService.Hidden = true;
                    } else {
                      this.currentService.Hidden = false;
                    }
                    console.log(ischecked);
                    console.log(this.currentService);
                    this.setState({ changed: true });
                  }}
                />

                {/* <Dropdown
                                placeholder="Family"
                                label="Family:"
                                options={[
                                    { key: '1', text: 'Family 1' },
                                    { key: '2', text: 'Family 2' },
                                    { key: '3', text: 'Family 3' }
                                ]}
                                required={true}
                                selectedKey={this.currentService.FamilyId.toString()}
                                onChanged={(option) => {
                                    this.currentService.FamilyId = Number(option.key);
                                    this.setState({ changed: true });
                                }}
                            /> */}

                <Dropdown
                  placeholder="Family name"
                  required={true}
                  id="FamilyId"
                  label="Family"
                  selectedKey={this.currentService.FamilyId.toString()}
                  options={optionsTypeForFamily}
                  onChanged={(option) => {
                    this.currentService.FamilyId = Number(option.key);

                    this.setState({ changed: true });
                  }}
                />

                <Dropdown
                  placeholder="Select an option"
                  label="XML type:"
                  options={[
                    { key: "0", text: "k.A." },
                    { key: "1", text: "Zusatzdienst" },
                    { key: "2", text: "Datendienst" },
                    { key: "3", text: "Mailbox" },
                    { key: "4", text: "Anrufsperre" },
                    { key: "5", text: "Rufnummernanzeige" }
                  ]}
                  required={true}
                  selectedKey={this.currentService.TypeXmlId.toString()}
                  onChanged={(option) => {
                    this.currentService.TypeXmlId = Number(option.key);

                    this.setState({ changed: true });
                  }}
                />
              </div>
              <br />
              <div className="ms-Grid-col ms-md12">
                <Button
                  style={{ marginRight: "5px" }}
                  disabled={
                    this.state.disableButtonKeyField ||
                    this.state.disableButtonNameField ||
                    this.state.disableButtonAgeValField
                  }
                  variant="primary"
                  onClick={this._saveService}
                >
                  Speichern
                </Button>
                <Button variant="secondary" onClick={this._hidePanel}>
                  Abbrechen
                </Button>
              </div>
            </div>
          </PivotItem>

          <PivotItem headerText="Nicht buchbar mit">
            {this.renderServices()}
          </PivotItem>

          <PivotItem headerText="UI Text">
            <TextField
              label="ui_name"
              name="ui_name"
              required
              value={this.currentService.ui_name}
              onChange={this._refreshModel}
            />
            <TextField
              label="ui_tooltip"
              name="ui_tooltip"
              required
              value={this.currentService.ui_tooltip}
              onChange={this._refreshModel}
            />
            <TextField
              label="ui_extra"
              name="ui_extra"
              required
              value={this.currentService.ui_extra}
              onChange={this._refreshModel}
            />
            <TextField
              disabled={isMinDurationVisible}
              label="Min Duration"
              name="minDuration"
              required
              value={this.currentService.minDuration}
              onChange={this._refreshModel}
            />
          </PivotItem>
        </Pivot>
      </div>
    );

    return ctrl;
  }

  private _getErrorMessageKeyField = (value: string): string => {
    const regex = /^[A-Za-z0-9_-]{1,40}$/gm;
    const str = value;
    let m;
    let err: string = "";

    if ((m = regex.exec(str)) !== null) {
      err = "";
      this.setState({ disableButtonKeyField: false });
    } else {
      err = `Das Feld sollte nur aus Buchstaben, Zahlen, Unterstrichen und Bindestrichen bestehen und nicht mehr als 40 Symbole.`;
      this.setState({ disableButtonKeyField: true });
    }

    return err;
  };

  private _getErrorMessageAgeValidation = (value: string): string => {
    const regex = /^[0-9-]{1,40}$/gm;
    const str = value;
    let m;
    let err: string = "";

    if ((m = regex.exec(str)) !== null) {
      err = "";
      this.setState({ disableButtonAgeValField: false });
    } else {
      err = `Das Feld sollte nur Zahlen und einen Bindestrich enthalten und nicht länger als 40 Zeichen sein`;
      this.setState({ disableButtonAgeValField: true });
    }

    return err;
  };

  private _getErrorMessageNameField = (value: string): string => {
    const regex = /^[A-Za-z0-9_-\s&]{1,150}$/gm;

    const str = value;
    let m;
    let err: string = "";

    if ((m = regex.exec(str)) !== null) {
      err = "";
      this.setState({ disableButtonNameField: false });
    } else {
      err = `Das Feld sollte nur aus Buchstaben, Zahlen, &, Unterstrichen und Bindestrichen bestehen und nicht mehr als 150 Symbole.`;
      this.setState({ disableButtonNameField: true });
    }

    return err;
  };

  private renderServices(): JSX.Element {
    if (this.currentService == null) {
      return <></>;
    }

    let ctrl = (
      <>
        {this.model.services.map((i, idx) => (
          <Checkbox
            id={"svc-" + i.Id.toString()}
            key={idx}
            checked={
              this.currentService.Excludes &&
              this.currentService.Excludes.indexOf(i.Id) >= 0
                ? true
                : false
            }
            label={i.Name}
            onChange={this.onChangeService}
          />
        ))}
      </>
    );

    return ctrl;
  }

  private onChangeService(
    ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
    isChecked?: boolean
  ): void {
    if (ev == undefined) return;
    let s: string = (ev.target as HTMLElement).id;
    s = s.replace("svc-", "");

    var svc = linq
      .from<Service>(this.model.services)
      .where((x) => x.Id == Number(s))
      .firstOrDefault();

    if (!this.currentService.Excludes) {
      this.currentService.Excludes = [];
    }

    if (isChecked) {
      this.currentService.Excludes.push(svc.Id);
    } else {
      Global.arrayRemove(this.currentService.Excludes, svc.Id);
    }

    this.setState({ changed: true });

    this.saveService({
      brand_id: this.currentService.BrandId,
      source_id: this.currentService.Id,
      target_id: Number(svc.Id),
      action: isChecked ? 1 : 2
    });
  }

  private saveService(model: any) {
    Api.fetch(
      this.url + "addRemoveServiceForCheckbox",
      model,
      "POST",
      (api: IApiResponse) => {
        this.setState({
          mode: EScreenMode.edit
        });
      }
    );
  }

  public renderNewServiceEditor() {
    let ctrl = <></>;

    ctrl = (
      <Panel
        isBlocking={false}
        isOpen={this.state.mode === EScreenMode.edit}
        onDismiss={this._hidePanel}
        type={PanelType.medium}
        headerText="Neuen dienst hinzufügen"
      >
        {
          <Pivot>
            <PivotItem headerText="Generell">
              <div className="ms-Grid">
                <div className="ms-Grid-row">
                  <TextField
                    label="Name"
                    name="Name"
                    required
                    value={this.currentService.Name}
                    onChange={this._refreshModel}
                  />
                  <TextField
                    label="Key"
                    name="Key"
                    required
                    value={this.currentService.Key}
                    onChange={this._refreshModel}
                  />
                </div>
                <br />
                <PrimaryButton text="Speichern" onClick={this._saveService} />
                <DefaultButton
                  className="default-button-edit-screen"
                  text="Abbrechen"
                  onClick={this._hidePanel}
                />
              </div>
            </PivotItem>
          </Pivot>
        }
      </Panel>
    );

    return ctrl;
  }

  private _hidePanel() {
    this.setState({ mode: EScreenMode.view });
  }

  private _refreshModel(ctrl: any) {
    Global.setValueByPath(
      this.currentService,
      ctrl.target.name,
      ctrl.target.value
    );
    this.setState({ changed: true });
  }

  private _saveService() {
    // if (!this.currentService.BrandId) {
    //   let br = StorageHelper.getBrnads();
    //   this.currentService.BrandId = linq
    //     .from<Brand>(br)
    //     .where((x) => x.Key == this.props.brandKey)
    //     .first().Id;
    // }

    var arr_from_json: any = undefined;

    if (this.currentService.Model === "") {
    } else {
      arr_from_json = JSON.parse(this.currentService.Model);
    }

    if (arr_from_json === undefined) {
      if (this.props.brandKey === "dsl") {
        arr_from_json = JSON.parse(
          '{"product":{},"product_ui":{"ui_tooltip":"","ui_extra":"","ui_name":"","minDuration":""}}'
        );
      } else {
        arr_from_json = JSON.parse(
          '{"product":{},"product_ui":{"ui_tooltip":"","ui_extra":"","ui_name":""}}'
        );
      }
    }

    arr_from_json.product_ui.ui_name = this.currentService.ui_name;
    arr_from_json.product_ui.ui_tooltip = this.currentService.ui_tooltip;
    arr_from_json.product_ui.ui_extra = this.currentService.ui_extra;
    arr_from_json.product_ui.minDuration = this.currentService.minDuration;

    this.currentService.Model = JSON.stringify(arr_from_json);

    this.currentService.Key = this.currentService.Key.trim();
    console.log("serviuce--->", this.currentService.Key + "!");

    // Api.fetch(this.url + 'saveService', this.currentService, "POST",
    //     (api: IApiResponse) => {
    //         this.model = api.data;

    //         this.setState({
    //             mode: EScreenMode.view
    //         });
    //     });
  }

  private onDataArrival(api: IApiResponse) {
    this.model = api.data;

    this.setState({
      mode: EScreenMode.view
    });
  }
}

export default ServiceScreenGroupedColumns;
