import { ContextualMenu, DefaultButton, Dialog, DialogFooter, DialogType, DirectionalHint, IContextualMenuItem, Panel, PanelType, Pivot, PivotItem, PrimaryButton, TextField } from 'office-ui-fabric-react';
import { ProgressIndicator } from 'office-ui-fabric-react/lib/ProgressIndicator';
import React from 'react';
import Button from 'react-bootstrap/Button';
import TreeViewMenu from 'react-simple-tree-menu';
import { Item } from 'react-simple-tree-menu/dist/TreeMenu/walk';
import { EScreenMode } from '../model/app/Enums';
import { IApiResponse } from '../model/app/IApiResponse';
import Loader from '../Support/Loader';
import Api from '../utils/Api';
import Global from '../utils/Global';

const DEFAULT_PADDING = 0.75;
const LEVEL_SPACE = 1.75;
const ICON_SIZE = 2;

const ToggleIcon = ({ on }: { on: boolean }) => (
    <div
        role="img"
        aria-label="Toggle"
        style={{
            width: `${ICON_SIZE}rem`,
            height: `${ICON_SIZE}rem`,
            textAlign: 'center',
            lineHeight: `${ICON_SIZE}rem`,
        }}
    >
        {on ? '-' : '+'}
    </div>
);

export interface IServiceScreenState {
    mode: EScreenMode,
    treeData: any,
    changed: boolean,
    hidden: boolean,
    disableButtonNameField: boolean,
    disableButtonKeyField: boolean,
    menu: boolean,
    contextMenu?: {
        target: HTMLElement;
        items: IContextualMenuItem[];
    },
    currentItem?: Item;
};

export default class ProductTreeScreen extends React.Component<any, IServiceScreenState> {
    private url: string = "activationdata/";

    constructor(props: any) {
        super(props);

        this.state = {
            mode: EScreenMode.loading,
            treeData: undefined,
            changed: false,
            hidden: true,
            menu: false,
            contextMenu: undefined,
            currentItem: undefined,
            disableButtonKeyField: false,
            disableButtonNameField: false
        };

        this.onDataArrival = this.onDataArrival.bind(this);
        this._refreshModel = this._refreshModel.bind(this);
        this._saveStruct = this._saveStruct.bind(this);
        this._onYes = this._onYes.bind(this);
        this._onNo = this._onNo.bind(this);
        this._getErrorMessageNameField = this._getErrorMessageNameField.bind(this);

        this.onContextMenu = this.onContextMenu.bind(this);
        this._onContextMenuItemClicked = this._onContextMenuItemClicked.bind(this);
        this._onContextMenuDismiss = this._onContextMenuDismiss.bind(this);
        this._getErrorMessageKeyField = this._getErrorMessageKeyField.bind(this);

        this._hidePanel = this._hidePanel.bind(this);

        Api.fetch(this.url + 'getTreeProperties/', undefined, "GET", this.onDataArrival);
    }

    public render() {
        if (this.state.mode === EScreenMode.loading) {
            return <ProgressIndicator label="Daten werden geladen" description="Bitte warten Sie" />;
        } else {
            return this.renderData();
        }
    };

    private renderData(): JSX.Element {
        const { contextMenu } = this.state;
        let ctrl = <></>;
        let editor = <></>;

        let t1 = this.props.title == null ? 'Bitte bestätigen' : this.props.title;
        let t2 = this.props.text == null ? 'Sind Sie sicher?' : this.props.text;

        if(this.state.mode === EScreenMode.edit || this.state.mode === EScreenMode.new) {
            editor = this.renderEditor();
        }

        if (this.state.treeData !== undefined) {
            ctrl = (
                <>
                 {
                    this.state.hidden ? <></> :
                    <>
                        <Dialog
                            hidden={this.state.hidden}
                            onDismiss={this._onNo}
                            dialogContentProps={{
                            type: DialogType.normal,
                            title: t1,
                            subText: t2
                        }}
                            modalProps={{
                            titleAriaId: t1,
                            subtitleAriaId: t2,
                            isBlocking: false,
                            containerClassName: 'ms-dialogMainOverride'
                        }}
                        >
                        <DialogFooter>
                            <PrimaryButton onClick={this._onYes} text="Ja" />
                            <DefaultButton onClick={this._onNo} text="Abbrechen" />
                        </DialogFooter>
                        </Dialog> 
                    </>
                }
                    {contextMenu ? (
                        <ContextualMenu
                            onItemClick={this._onContextMenuItemClicked}
                            items={contextMenu.items}
                            isBeakVisible={true}
                            target={contextMenu.target}
                            directionalHint={DirectionalHint.bottomLeftEdge}
                            onDismiss={this._onContextMenuDismiss} />
                    ) : null
                    }
                    <TreeViewMenu
                        data={this.state.treeData}
                        debounceTime={125}>
                        {({ search, items }) => (
                            <ul style={{ listStyleType: 'none', paddingLeft: 0, borderTop: '1px solid #ccc' }}>
                                {items.map((node) => <li
                                    id={node.custom_key}
                                    onContextMenu={(e) => this.onContextMenu(e, node)}
                                    style={{
                                        padding: ` .75rem  1rem  .75rem ${DEFAULT_PADDING +
                                            ICON_SIZE * (node.hasNodes ? 0 : 1) +
                                            node.level * LEVEL_SPACE}rem`,
                                        cursor: 'pointer',
                                        color: node.active ? 'white' : '#333',
                                        background: node.active ? '#179ed3' : 'none',
                                        borderBottom: node.active ? 'none' : '1px solid #ccc',
                                        boxShadow: node.focused ? '0px 0px 5px 0px #222' : 'none',
                                        zIndex: node.focused ? 999 : 'unset',
                                        position: 'relative',
                                    }}
                                    role="button"
                                    aria-pressed={node.active}
                                    key={node.key}
                                    onClick={node.onClick}
                                >
                                    {node.hasNodes && (
                                        <div
                                            style={{ display: 'inline-block' }}
                                            onClick={e => {
                                                node.hasNodes && node.toggleNode && node.toggleNode();
                                                e.stopPropagation();
                                            }}
                                        >
                                            <ToggleIcon on={node.isOpen} />
                                        </div>
                                    )}
                                    {node.label}
                                </li>
                                )}
                            </ul>
                        )}
                    </TreeViewMenu>
               
                    {editor}
                </>
            );
        }

        return ctrl;
    };

    private onDataArrival(api: IApiResponse) {
        let data = [api.data];

        this.setState({
            mode: EScreenMode.view,
            treeData: data
        });
    }

    private onContextMenu(ev: React.MouseEvent<HTMLLIElement, MouseEvent>, item: Item): void {

        let actionItems: any[] = [];

        let target = ev ? ev.target : undefined;


        if (target !== undefined) {
            actionItems.push({ key: 'new', iconProps: { iconName: 'Add' }, text: 'Neu' });
            actionItems.push({ key: 'edit', iconProps: { iconName: 'Edit' }, text: 'Bearbeiten' });
            actionItems.push({ key: 'delete', iconProps: { iconName: 'Delete' }, text: 'Löschen' });

            let itm = item;
            
            itm.Name = item.label;
            itm.Key = item.custom_key;
            itm.ParentId = item.l_parent;
            itm.key = item.l_key;

            this.setState({
                contextMenu: {
                    target: target as HTMLElement,
                    items: actionItems
                },
                currentItem: itm
            });
        }

        if (ev) {
            ev.preventDefault();
        }
    };

    private _onContextMenuItemClicked(ev?: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>, item?: IContextualMenuItem) {
        if (item && item.key == 'new') {

            let itm = this.state.currentItem;
            if (itm !== undefined) {
                itm.ParentId = itm.Key;
            }

            this.setState({
                mode: EScreenMode.new,
                currentItem: itm
            });

        } else if (item && item.key == 'edit') {
            this.setState({
                mode: EScreenMode.edit
            });
            console.log(this.state.mode);
        } else {
            this.setState({
                mode: EScreenMode.delete,
                hidden: false 
            }); 
        }
    };

 

    private _onNo() {
        this.setState({ hidden: true, mode: EScreenMode.view });
    };

    private _onYes() {
        if (this.state.currentItem === undefined) return;

        Api.fetch(this.url + 'deleteElementFromStruct_Properties/' + this.state.currentItem.Key, undefined, "POST",
            (api: IApiResponse) => {
                this.setState({
                    mode: EScreenMode.view,
                    hidden: true,
                    treeData: [api.data]
                });
            });
    };


    private renderEditor(): JSX.Element {
        let ctrl = <></>

        ctrl =
            <Panel isBlocking={false} isOpen={(this.state.mode === EScreenMode.edit) || (this.state.mode === EScreenMode.new)} onDismiss={this._hidePanel} type={PanelType.large} headerText="Bearbeiten">
                {
                    this.state.mode === EScreenMode.loading ? <Loader /> :
                        (
                            this.renderControl()
                        )
                }
            </Panel>

        return ctrl;
    };

    public renderControl() {

        if (this.state.currentItem == undefined) {
            return;
        }
        if (this.state.mode === EScreenMode.edit || this.state.mode === EScreenMode.new) {
            let ctrlInner = <></>;
            if (this.state.mode === EScreenMode.edit) {
                ctrlInner = <div className="ms-Grid-col ms-md6">
                    <TextField label="Name" name="Name" required 
                                    value={this.state.currentItem.Name} onChange={this._refreshModel} />
                    <TextField label="Key" name="Key" 
                                     required value={this.state.currentItem.key} onChange={this._refreshModel} />
                </div>;
            } else {
                ctrlInner = <div className="ms-Grid-col ms-md6">
                    <TextField label="Name" name="Name" required value={this.state.currentItem.Name} onChange={this._refreshModel} />
                    <TextField label="Key" name="Key" required value={this.state.currentItem.Key} onChange={this._refreshModel} />
                    <TextField label="ParentId" name="Parent" required value={this.state.currentItem.ParentId} onChange={this._refreshModel} />
                </div>;
            }


            let ctrl = <div>
                <Pivot>
                    <PivotItem headerText="Generell">
                        <div className="ms-Grid">
                            <div className="ms-Grid-row">
                                {ctrlInner}
                            </div>
                            <div className="spacer-default" />
                            <div className="ms-Grid-row">
                                <div className="ms-Grid-col ms-md12">
                                   

                                    <Button style={{ marginRight: '5px' }} variant="primary" disabled={this.state.disableButtonKeyField||this.state.disableButtonNameField} onClick={this._saveStruct} >Speichern</Button>
                                <Button variant="secondary" onClick={this._hidePanel}>Abbrechen</Button>
                                </div>
                            </div>
                        </div>
                    </PivotItem>
                </Pivot>
            </div>
            return ctrl;
        } else {
            return;
        }
    }

    private _getErrorMessageNameField = (value: string): string=>{
        const regex = /^[A-Za-z0-9_-\s&]{1,63}$/gm;
      
        const str = value;
        let m;
        let err: string = '';

    
        if ((m = regex.exec(str)) !== null) {
            err = '';
            this.setState({ disableButtonNameField: false });
        } else {
            err = `Das Feld sollte nur aus Buchstaben, Zahlen, &, Unterstrichen und Bindestrichen bestehen und nicht mehr als 63 Symbole.`;
            this.setState({ disableButtonNameField: true });
        }


        return err;
    }

    private _getErrorMessageKeyField = (value: string): string => {

        const regex = /^[A-Za-z0-9_-]+$/gm;
        const str = value;
        let m;
        let err: string = '';

    
        if ((m = regex.exec(str)) !== null) {
            err = '';
            this.setState({ disableButtonKeyField: false });
        } else {
            err = `Das Feld sollte nur aus Buchstaben, Zahlen, Unterstrichen und Bindestrichen bestehen.`;
            this.setState({ disableButtonKeyField: true });
        }

        return err;

    }

    private _saveStruct() {
        if (this.state.currentItem == undefined) return;

        if (this.state.mode === EScreenMode.edit) {
            var model = { ParentId: Number(this.state.currentItem.ParentId), Name: this.state.currentItem.Name, Id: this.state.currentItem.custom_key, Key: this.state.currentItem.Key }
            Api.fetch(this.url + 'alterTableStruct_properties/', model, "POST",
                (api: IApiResponse) => {
                    this.setState({
                        mode: EScreenMode.view,
                        treeData: [api.data]
                    });
                });
        } else { // new
            var model1 = { ParentId: this.state.currentItem.ParentId, Name: this.state.currentItem.Name, Id: Number(this.state.currentItem.Key) }
            Api.fetch(this.url + 'alterTableStruct_properties/', model1, "POST",
                (api: IApiResponse) => {
                    this.setState({
                        mode: EScreenMode.view,
                        treeData: [api.data]
                    });
                });
        }
    };

    private _hidePanel() {
        this.setState({ mode: EScreenMode.view });
    };

    private _refreshModel(ctrl: any) {
        Global.setValueByPath(this.state.currentItem, ctrl.target.name, ctrl.target.value);
        console.log(this.state.changed);
        this.setState({ changed: true });
        console.log(this.state.changed);
    };

    private _onContextMenuDismiss(): void {
        //this.currentItem = undefined;
        this.setState({
            contextMenu: undefined
        });
    }

}


