import IUser from "../model/app/IUser";
import { HttpMethods } from "./Defaults";
import StorageHelper from "./StorageHelper";

export interface IApiErrorDetail {
	code: string;
	text: string;
}

export interface IApiError {
	message: string;
	detail: IApiErrorDetail;
}

export class ApiAsync {
	static token: string;

	static async post<T>(
		url: string,
		model: any = undefined
	): Promise<T | string> {
		if (!ApiAsync.token) {
			ApiAsync.token = StorageHelper.getUser()!.easyToken;
		}

		return await ApiAsync.run<T>(url, HttpMethods.post, model);
	}

	static async get(url: string, arrayName: string) {
		const data = await fetch(url);
		const response = await data.json();
		return { [arrayName]: response, paging: undefined };
	}

	static async run<T>(
		url: string,
		method: string,
		model: any = undefined
	): Promise<T> {
		let message: string = "";
		let err: IApiError | undefined;
		console.log("call " + url, model);

		try {
			let what: any = {
				method: method == null ? "GET" : method,
				//credentials: 'include',
				headers: {
					Accept: "application/json",
					"Content-Type": "application/json",
					Authorization: ApiAsync.token,
				},
				mode: "cors",
				body: method == HttpMethods.get ? undefined : JSON.stringify(model),
			};

			const r = await fetch(url, what)
				.then((response) => response)
				.catch((error) => {
					if (err) {
						throw new Error();
					} else {
						err = {
							message: "Server unavailable",
							detail: { code: "999", text: "Server unavailable" },
						};
					}

					throw new Error("server is down");
				});

			var data = await r.text();

			if (r.status >= 400 && r.status < 600) {
				err = {
					message: "Bad response from server",
					detail: {
						code: r.status.toString(),
						text: "Bad response from server",
					},
				};

				var e = data ? JSON.parse(data) : undefined;
				if (e) {
					err = {
						message: e.title,
						detail: { code: e.detail.code, text: e.detail.text },
					};
				}
			} else {
				if (r.status == 204) {
					return {} as T;
				} else {
					return JSON.parse(data) as T;
				}
			}
		} catch (error: any) {
			if (error && !err) {
				err = {
					message: error.message,
					detail: { code: "888", text: error.message },
				};
			}
		} finally {
		}

		return JSON.parse(JSON.stringify({ error: err }));
	}
}

export default ApiAsync;
