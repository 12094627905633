import React from "react";
import { ETableSource } from "../../components/controls/Table";
import TableWrapper, {
	ITableWrapperProps,
	TableConfig,
} from "../../components/table/TableWrapper";
import { Defaults, MenuItems, Roles, WebSites } from "../../utils/Defaults";
import { from } from "linq";
import {
	ContextualMenuItemType,
	IContextualMenuItem,
} from "office-ui-fabric-react";
import { EScreenMode } from "../../model/app/Enums";
import { IAmUser } from "./Models";
import { ITableScreen } from "../../interfaces/ITableScreen";
import AmUserEditPanel from "./panels/AmUserEditPanel";
import AmUserAdminPanel from "./panels/AmUserAdminPanel";
import Global from "../../utils/Global";
import ErrorScreen from "../error";
import StorageHelper from "../../utils/StorageHelper";
import IKeyText from "../../model/app/IKeyText";
import { EControlType } from "../../components/table/enums/control-type.enum";

export interface IAmUserScreenState extends ITableScreen {
	mode: EScreenMode;
	pivot: string;
}

export class AmUserScreen extends React.Component<{}, IAmUserScreenState> {
	private comp: JSX.Element | null = null;
	private current?: IAmUser;

	constructor(props: any) {
		super(props);

		this.state = {
			id: Global.getEmptyGuid(),
			mode: EScreenMode.view,
			pivot: "",
		};
	}

	processContextMenu = (item, e) => {
		if (e.key == MenuItems.new) {
			this.setState({
				mode: EScreenMode.new,
				id: Global.getEmptyGuid(),
				pivot: AmUserEditPanel.pivotNew,
			});
		} else if (e.key == MenuItems.edit) {
			this.setState({
				mode: EScreenMode.edit,
				id: item.id,
				pivot: AmUserEditPanel.pivotMain,
			});
		} else if (e.key == MenuItems.pwPatch || e.key == MenuItems.resetUser) {
			this.setState({
				mode: EScreenMode.edit,
				id: this.current!.id,
				pivot: e.key,
			});
		}
	};

	render = () => {
		if (
			!Global.inAnyRole([
				Roles.PortalAdministrator,
				Roles.VB,
				Roles.VL,
				Roles.HL,
				Roles.BO,
				Roles.TemoDealer,
				Roles.TemoBackOffice,
			])
		) {
			return <ErrorScreen data={{ code: 403 }} />;
		}

		let cm: IContextualMenuItem[] = [
			{
				key: MenuItems.edit,
				iconProps: { iconName: "Edit" },
				text: "Bearbeiten",
			},
		];

		if (!Global.inAnyRole([Roles.TemoDealer])) {
			cm.push({ key: "new", iconProps: { iconName: "Add" }, text: "Neu" });
		}

		if (Global.inAnyRole([Roles.PortalAdministrator, Roles.VL, Roles.HL])) {
			cm.push({ key: "divider_1", itemType: ContextualMenuItemType.Divider });

			let adm: IContextualMenuItem = {
				key: "admin",
				iconProps: { iconName: "AdminALogoFill32" },
				text: "Administration",
				subMenuProps: { items: [] },
			};

			if (Global.inAnyRole([Roles.PortalAdministrator])) {
				adm.subMenuProps!.items.push({
					key: MenuItems.pwPatch,
					iconProps: { iconName: "AzureKeyVault" },
					text: "Password ändern",
					onClick: this.processContextMenu,
				});
			}

			if (Global.inAnyRole([Roles.PortalAdministrator, Roles.VL, Roles.HL])) {
				adm.subMenuProps!.items.push({
					key: MenuItems.resetUser,
					iconProps: { iconName: "ChatBot" },
					text: "Zurücksetzen",
					onClick: this.processContextMenu,
				});
			}

			cm.push(adm);
		}

		let tc = TableConfig.createInstance({
			url:
				from<IKeyText>(StorageHelper.getWebsites())
					.toArray()
					.filter((x) => x.key == WebSites.am)[0].text + "user/filter",
			sourceType: ETableSource.dynamic,
			arrayName: "users",
			sort: [],
			columns: [
				{ name: "username", label: "Benutzername", sortable: true },
				{
					name: "userStatus",
					label: "Status",
					renderer: (r, c) => {
						return r["statusText"];
					},
				},
				{
					name: "statusCode",
					label: "AStatus",
					renderer: (r, c) => {
						return r["account"] ? r["account"].statusCode : Defaults.na;
					},
				},
				{
					name: "typeId",
					label: "Typ",
					renderer: (r, c) => {
						return r["typeText"];
					},
				},
				{ name: "u.firstname", label: "Vorname", sortable: true },
				{ name: "u.surname", label: "Nachname", sortable: true },
				{ name: "u.email", label: "E-Mail", sortable: false },
				{ name: "u.mobile", label: "Mobilfunknummer", sortable: false },
			],
		});

		let filter = Global.inAnyRole([Roles.TemoDealer])
			? [{ type: EControlType.text, key: "needle", label: "Suche" }]
			: [
					{ type: EControlType.text, key: "needle", label: "Suche" },
					{
						type: EControlType.dropdown,
						key: "statusId",
						label: "Status",
						list: StorageHelper.getStructure("statuses", true),
					},
					{
						type: EControlType.dropdown,
						key: "typeId",
						label: "Typ",
						list: StorageHelper.getStructure("userTypes", true),
					},
			  ];

		let cfg: ITableWrapperProps = {
			id: "rety",
			action: this.processContextMenu,
			onItemChanged: (u) => {
				this.current = u;
			},
			model: { needle: "", statusId: "0", paging: TableConfig.initPaging() },
			contextMenu: cm,
			filter: filter,
			config: tc,
		};

		return (
			<>
				<h1>Benutzer</h1>
				<div className="data">
					<TableWrapper {...cfg} />

					{(this.state.mode == EScreenMode.edit ||
						this.state.mode == EScreenMode.new) &&
					AmUserEditPanel.getPivots().indexOf(this.state.pivot) >= 0 ? (
						<AmUserEditPanel
							id={this.state.id!}
							onDismiss={() => {
								this.setState({ mode: EScreenMode.view });
							}}
						/>
					) : null}

					{this.state.mode == EScreenMode.edit &&
					AmUserAdminPanel.getPivots().indexOf(this.state.pivot) >= 0 ? (
						<AmUserAdminPanel
							pivot={this.state.pivot}
							id={this.state.id!}
							onDismiss={() => {
								this.setState({ mode: EScreenMode.view });
							}}
						/>
					) : null}
				</div>
			</>
		);
	};
}

export default AmUserScreen;
