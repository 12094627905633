import React, { useEffect, useState } from "react"
import Global from "../../../utils/Global";
import { ILogJournal, ILogMonitoring } from "../../../model/data/ILogJournal";
import { MenuItems, StorageKeys, WebSites } from "../../../utils/Defaults";
import { EScreenMode } from "../../../model/app/Enums";
import { ContextualMenuItemType, IContextualMenuItem } from "office-ui-fabric-react";
import TableWrapper, { ITableWrapperProps, TableConfig } from "../../../components/table/TableWrapper";
import IKeyText from "../../../model/app/IKeyText";
import StorageHelper from "../../../utils/StorageHelper";
import { from } from "linq";
import { ETableSource } from "../../../components/controls/Table";
import { EControlType } from "../../../components/table/enums/control-type.enum";
import LogManager from "../../../utils/LogManager";

export interface ILogMonitoringState extends ILogMonitoring {
    mode: EScreenMode;
    monitoring?: ILogMonitoring | undefined;
    types?: string | ILogMonitoring;
}

export const LogMonitoringScreen: React.FC<ILogMonitoringState> = () => {

    const [monitoringScreen, setMonitoringScreen] = useState<ILogMonitoringState | undefined>({
        status: undefined,
        type: undefined,
        mode: EScreenMode.view,
        monitoring: undefined,
    });
    const logManager = new LogManager();
    const [current, setCurrent] = useState<ILogMonitoring | undefined>(undefined);

    const processContextMenu = (item: ILogMonitoring, e) => {
        if (e.key == MenuItems.edit) {
            setMonitoringScreen({
                mode: EScreenMode.edit,
                monitoring: item,
                status: item.status,
                type: item.type,
            });
        }
    };

    useEffect(() => {
        onDataArrival()
    }, [])

    const tc = TableConfig.createInstance({
        url:
        from<IKeyText>(StorageHelper.getWebsites())
        .toArray()
        .filter(x => x.key == WebSites.log)[0].text + 'monitoring/filter',
        sourceType: ETableSource.dynamic,
        arrayName: "data",
        sort: [],
        columns: [
          { name: "id", label: "Id", sortable: true },
          { name: "container", label: "Container", sortable: true },
          { name: "username", label: "Username", sortable: true },
          {
            name: "text",
            label: "Text",
            sortable: true,
            renderer: (r) => {
              return <p title={r.text}>{r.text}</p>;
            },
          },
          { name: "status", label: "Status", sortable: true },
          { name: "type", label: "Type", sortable: true },
          { name: "code", label: "Code", sortable: true },
          {
            name: "created",
            label: "Datum",
            sortable: true,
            renderer: (r) => {
              return Global.toGermanDate(r.created);
            },
          },
        ],
      });
      
      
    const onDataArrival = async () => {
        const response :ILogMonitoring[] | string = await logManager.getContainers()
        StorageHelper.set(StorageKeys.monitoringContainers, response);
        setMonitoringScreen({
            mode: EScreenMode.view,
            container: response as string,
        })
    };

    let cfg: ITableWrapperProps = {
        id: "rety",
        action: processContextMenu,
        onItemChanged: (u) => {
            setCurrent(u)
        },
        model: { needle: "", paging: TableConfig.initPaging() },
        filter: [
            { type: EControlType.text, key: "needle", label: "Suche" },
            {
                type: EControlType.dropdown,
                key: "container",
                label: "Containers",
                list: StorageHelper.getContainers(true)
            },
            {
                type: EControlType.dropdown,
                key: "status",
                label: "Status",
                list: StorageHelper.getStructure("monitoringStatusType", true)
            },
            {
              type: EControlType.dropdown,
              key: "type",
              label: "Type",
              list: StorageHelper.getStructure("monitoringType", true)
          },
        ],
        config: tc,
    };

    return (
        <>
            <TableWrapper {...cfg} />
        </>
    );
}