import React, { useEffect, useState } from "react";
import { IBookingProcess } from "../../model/data/IBookingProcess";
import { MenuItems, WebSites } from "../../utils/Defaults";
import { EScreenMode } from "../../model/app/Enums";
import {
	ContextualMenuItemType,
	IContextualMenuItem,
	Panel,
	PanelType,
} from "office-ui-fabric-react";
import TableWrapper, {
	ITableWrapperProps,
	TableConfig,
} from "../../components/table/TableWrapper";
import IKeyText from "../../model/app/IKeyText";
import StorageHelper from "../../utils/StorageHelper";
import { from } from "linq";
import { ETableSource } from "../../components/controls/Table";
import Global from "../../utils/Global";
import { EControlType } from "../../components/table/enums/control-type.enum";
import { OrderScreen } from "./OrderScreen";
import { IBookingOrder } from "../../model/data/IBookingOrder";
import vfLogo from "../../assets/svg/thumb-vf.svg";
import vfbLogo from "../../assets/svg/thumb-vfb.svg";
import oteloLogo from "../../assets/svg/thumb-otelo.svg";
import fnLogo from "../../assets/svg/thumb-fn.svg";
import tvLogo from "../../assets/svg/thumb-tv.svg";

export interface ProcessScreenState extends IBookingProcess {
	mode?: EScreenMode;
}

export const BookingProcessScreen: React.FC<IBookingProcess> = () => {
	const [processState, setProcessState] = useState<
		ProcessScreenState | undefined
	>({
		date: "",
		void: "",
		orders: undefined,
		mode: EScreenMode.undefined,
	});

	const [clickedOrder, setClickedOrder] = useState<IBookingOrder | null>(null);

	const handleOrder = (order: IBookingOrder) => {
		setClickedOrder(order);
		setProcessState({ mode: EScreenMode.edit });
	};

	const [current, setCurrent] = useState<IBookingProcess | undefined>(
		undefined
	);

	const processContextMenu = (item: IBookingProcess, e) => {
		if (e.key == MenuItems.edit) {
			setProcessState({
				date: item.date,
				void: item.void,
				mode: EScreenMode.edit,
			});
		}
	};

	const getBrandIcon = (brandId: string) => {
		switch (brandId) {
			case "vf":
				return <img src={vfLogo} alt="vf logo" width={"30px"} />;
			case "tivano":
				return <img src={fnLogo} alt="fn logo" width={"50px"} />;
			case "vfb":
				return <img src={vfbLogo} alt="vfb logo" width={"30px"} />;
			case "dsl":
				return <img src={fnLogo} alt="fn logo" width={"50px"} />;
			case "otelo":
				return <img src={oteloLogo} alt="otelo logo" width={"30px"} />;
			case "kabel":
				return <img src={fnLogo} alt="fn logo" width={"50px"} />;
			case "cable":
				return <img src={fnLogo} alt="fn logo" width={"50px"} />;
			case "unity":
				return <img src={tvLogo} alt="tv logo" width={"50px"} />;
			default:
				return brandId; // Return brandId if no specific icon matches
		}
	};

	const tc = TableConfig.createInstance({
		url:
			from<IKeyText>(StorageHelper.getWebsites())
				.toArray()
				.filter((x) => x.key == WebSites.gate)[0].text +
			"booking/process/filter",
		sourceType: ETableSource.dynamic,
		arrayName: "data",
		sort: [],
		columns: [
			{
				name: "date",
				label: "Date",
				sortable: true,
				renderer: (r) => {
					return Global.toGermanDate(r.date);
				},
			},
			{
				name: "void",
				label: "Username",
				sortable: false,
			},
			{
				name: "orders",
				label: "Orders",
				sortable: false,
				renderer: (r) => {
					return r.orders.map((order) => (
						<span
							key={order.id}
							onClick={() => handleOrder(order)}
							style={{ cursor: "pointer" }}
						>
							{getBrandIcon(order.brandId)}
						</span>
					));
				},
			},
		],
	});

	let cfg: ITableWrapperProps = {
		id: "rety",
		action: processContextMenu,
		onItemChanged: (u) => {
			setCurrent(u);
		},
		model: { needle: "", paging: TableConfig.initPaging() },
		filter: [{ type: EControlType.text, key: "needle", label: "Suche" }],
		config: tc,
	};

	return (
		<>
			<TableWrapper {...cfg} />
			<div>
				{processState?.mode === EScreenMode.edit && clickedOrder ? (
					<OrderScreen
						{...clickedOrder}
						onDismiss={() => {
							setProcessState({ mode: EScreenMode.view });
						}}
					/>
				) : null}
			</div>
		</>
	);
};
