import React from "react";
import { Container } from "react-bootstrap";
import { ErrorMessages } from "../utils/messages";
import ErrorScreen from "./error";

class NoMatch404Page extends React.Component<{}, {}> {

    public render() {
        return <Container>
            <h2>{ErrorMessages.title404}</h2>
            <ErrorScreen data={{ code: 404 }} />
        </Container>
    }
}

export default NoMatch404Page;