import { IContextualMenuItem } from 'office-ui-fabric-react/lib/ContextualMenu';
import { getRTL } from 'office-ui-fabric-react/lib/Utilities';
import { ResponsiveMode } from 'office-ui-fabric-react/lib/utilities/decorators/withResponsiveMode';
import * as React from 'react';
import '../../assets/css/head.css';
import Global from '../../utils/Global';
import StorageHelper from '../../utils/StorageHelper';
import * as c from '../index';
import MainNav from '../navigation/MainNav';
import AppContainer from '../page/AppContainer';

export interface IHeaderProps {
  title: string;
  responsiveMode?: ResponsiveMode;

};

export interface IHeaderState {
  navOpen: boolean,
  contextMenu?: {
    target: HTMLElement;
    items: IContextualMenuItem[];
  };
  changed: boolean;
}

let tgl: HTMLDivElement;

export class Head extends React.Component<IHeaderProps, IHeaderState> {
  private isRTLEnabled: boolean;

  constructor(props: IHeaderProps) {
    super(props);
    this.isRTLEnabled = getRTL();

    this.handleToggle = this.handleToggle.bind(this);
    this._logoClick = this._logoClick.bind(this);


    this.state = {
      navOpen: true,
      changed: false
    };


  }

  public render(): JSX.Element {
    let { responsiveMode } = this.props;

    if (responsiveMode === undefined) {
      responsiveMode = ResponsiveMode.large;
    }

    return (
      <>
        <div className="header">
          <div className="bg">
            <div className="toggle">
              <div id="nav-icon2" className={this.state.navOpen ? 'open' : ''} ref={(c) => (tgl = c as HTMLDivElement)} onClick={this.handleToggle}>
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
              </div>
            </div>
            <div className="logo" onClick={this._logoClick}>
              <img src="/assets/img/logo.png" height="40" />
            </div>
          </div>
          <c.UserThumb />
        </div>
        <MainNav {... { navOpen: this.state.navOpen }} />

      </>
    );
  };

  public handleToggle = () => {
    this.toggleNav();
    this.setState({ navOpen: !this.state.navOpen });

    let app = document.getElementById('container');
    let ctrl = Global.FindReact(app) as AppContainer;
    if (ctrl) {
      ctrl.collapse(this.state.navOpen);
    }
  };

  public toggleNav() {
    if (tgl != null) {
      tgl.classList.toggle('open');
    }
  };

  private _logoClick() {
    window.location.href = '/';
  };
}

export default Head;