export enum BuildEnv {
	Dev,
	Int,
	Prod,
	PreProd,
}

// SET THE BUILD ENV HERE:
export const build: BuildEnv =
	window.location.href.indexOf("//localhost") > 0
		? BuildEnv.Dev
		: window.location.href.indexOf("bo33.tradelight") > 0
		? BuildEnv.Int // Int
		: window.location.href.indexOf("vf-easy") > 0
		? BuildEnv.Prod
		: window.location.href.indexOf("easy4-bo") > 0
		? BuildEnv.PreProd
		: BuildEnv.Int;
