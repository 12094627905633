export enum EMessageType {
	None = 0,
	Error = 1,
	Hint = 2,
	Info = 3,
}

export enum EMessageContentType {
	None = 0,
	Plain = 1,
	Html = 2,
}

export type Message = {
	createdBy: string;
	groupId: number;
	validFrom: Date;
	validTo: Date;
	text: string;
	typeId: EMessageType;
	contentTypeId: EMessageContentType;
};
