export class Representative {
  public Id: string = "";
  public LoggedInUser: string = "";
  public RepresenterId: string = "";
  public StartDate?: Date | string = undefined;
  public EndDate?: Date | string = undefined;
  public Name: string = "";
}

export class RepresenterUser {
  public OriginUser: string = "";
  public OriginName: string = "";
}
