import React from 'react';
import { EScreenMode } from '../model/app/Enums';
import Api from '../utils/Api';
import { IApiResponse } from '../model/app/IApiResponse';
import ProductSub from '../model/data/ProductSub';
import linq from 'linq';
import { Card, Row, Col, Container } from 'react-bootstrap';
import '../../src/App.css';
import { SUB } from '../model/data/SUB';
import ProductSubServiceModel from '../model/data/ProductSubServiceModel';
import { Panel, PanelType, DefaultButton, PrimaryButton,Dialog, DialogFooter, DialogType } from 'office-ui-fabric-react';
import { ProductInfo, SubServices } from '../model/data/Product';
import StorageHelper from '../utils/StorageHelper';
import Service from '../model/data/Service';
import Button from 'react-bootstrap/Button';

// import { withStyles } from '@material-ui/core/styles';
// import { green } from '@material-ui/core/colors';
// import FormGroup from '@material-ui/core/FormGroup';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import color from '@material-ui/core/colors/amber';

import '../../src/App.css';
import { StorageKeys } from '../utils/Defaults';
import { Breadcrumb, IBreadcrumbItem, IDividerAsProps } from 'office-ui-fabric-react/lib/Breadcrumb';
import AppRouter from '../utils/AppRouter';


export interface IProductScreenState {
    productSubs?: ProductSub[],
    mode: EScreenMode,
    hidden: boolean,
    allServices?: ProductSubServiceModel[]
};






export class ProductSubandServiceScreen extends React.Component<any, IProductScreenState> {
    private url: string = "activationdata/";
    private allServicesByCurrentSub: Service[] = [];
    private currentProduct: ProductInfo = new ProductInfo();
    private currentSub: any = {};
    private currentSUB: any = {};
    private serviceTemp: Service[] = [];
    private groupName: string = '';
    private selectedItem: boolean = false;
    private subidforborder: number = 0;
    private currentSUBForConnectionBuild: SUB = new SUB();







    constructor(props: any) {
        super(props);

        this.state = {
            productSubs: undefined,
            mode: EScreenMode.loading,
            allServices: undefined,
            hidden: true,
        };
        this.onDataArrival = this.onDataArrival.bind(this);
        this.renderSub = this.renderSub.bind(this);
        this.mandatoryChange = this.mandatoryChange.bind(this);
        this.subServiceVisualisation = this.subServiceVisualisation.bind(this);
        this.renderEditor = this.renderEditor.bind(this);
        this._hidePanel = this._hidePanel.bind(this);
        this.ultracardChange = this.ultracardChange.bind(this);
        this._onNo = this._onNo.bind(this);
        this._onYes = this._onYes.bind(this);
     
        this.serviceBuildConnection = this.serviceBuildConnection.bind(this);

        // Api.fetch(this.url + 'getServicesByProduct/' + props.productKey, undefined, "GET", this.onDataArrival);



    }

    render() {
        let ctrl = (<></>);


        this.currentProduct = StorageHelper.getProduct();


        let products: ProductSub[] = [];
        if (this.state.productSubs !== undefined) {
            products = linq.from<ProductSub>(this.state.productSubs)
                .toArray()
        }

        let thirdParam: string = '';
        let firstParam: string = '';

        console.log("allprops--->",this.props)

        if (this.props.activationTypeId === '1,3') {
            thirdParam = 'ACT&ACTPORT';
        } else if (this.props.activationTypeId === '2,3') {
            thirdParam = 'PORT&ACTPORT';
        } else { // ===3
            thirdParam = 'ACTPORT';
        }

        if (this.currentProduct.product.BrandId === 1) {
            firstParam = 'Vodafone';
        } else if (this.currentProduct.product.BrandId === 2) {
            firstParam = 'Otelo';
        } else if (this.currentProduct.product.BrandId === 3) {
            firstParam = 'Kabel';
        } else if (this.currentProduct.product.BrandId === 4) {
            firstParam = 'DSL';
        }



        ctrl = (

            <>

                <Breadcrumb
                    items={[
                        { text: 'Start', key: 'Start', onClick: (ev: any, item: any) => { this._onBreadcrumbItemClicked(ev, item) } },
                        { text: firstParam + "-"+this.props.businessCaseId+"-" + thirdParam, key: this.props.brandKey },
                        { text: 'Products', key: thirdParam, onClick: (ev: any, item: any) => { this._onBreadcrumbItemClicked(ev, item) } },
                        { text: this.currentProduct.product.Name, key: 'f5', isCurrentItem: true }
                    ]}
                    ariaLabel="Breadcrumb with no maxDisplayedItems"
                    overflowAriaLabel="More links"
                />
                <br />

                <Container fluid className={this.state.mode === EScreenMode.edit ? ' withPanel' : ''}>

                    <Row >

                    {
                        this.state.hidden ? <></> :
                            <>
                                <Dialog
                                    hidden={this.state.hidden}
                                    onDismiss={this._onNo}
                                    dialogContentProps={{
                                        type: DialogType.normal,
                                        title: "Alle zugeornderte Subs werden entfernt. Sind Sie sicher?",
                                      
                                    }}
                                    modalProps={{
                                        titleAriaId: "Alle zugeornderte Subs werden entfernt. Sind Sie sicher?",
                                       
                                        isBlocking: false,
                                        containerClassName: 'ms-dialogMainOverride'
                                    }}
                                >
                                    <DialogFooter>
                                        <Button variant="danger" onClick={this._onYes}>Ja</Button>
                                        <Button variant="secondary" onClick={this._onNo}>Abbrechen</Button>
                                    </DialogFooter>
                                </Dialog>
                            </>
                    }

                        {this.currentProduct.allSubsServ.map((i, idx) => (
                            <div style={{ float: 'left' }}>
                                {this.renderSub(i)}

                            </div>

                        ))}

                        {this.state.mode !== EScreenMode.edit ? <></> : this.renderEditor()}

                    </Row>
                </Container>
            </>
        )
        return ctrl;
    }


    private _onNo() {
        this.setState({ hidden: true, mode: EScreenMode.view });
    };

    private _onYes() {
        let activationSubfromFetch: SUB = new SUB();
        let selected: boolean = false;



        Api.fetch(this.url + 'createSubConnection/', this.currentSUBForConnectionBuild, "POST",
            (api: IApiResponse) => {

                activationSubfromFetch = api.data;


                if (this.currentSUBForConnectionBuild.Selected === true) {
                    selected = false;
                } else {
                    selected = true;
                }



                for (let index = 0; index < this.currentProduct.allSubsServ.length; index++) {
                    var element = this.currentProduct.allSubsServ[index];
                    if (element.sub.Id === this.currentSUBForConnectionBuild.Id) {
                        this.currentProduct.allSubsServ[index].sub.Selected = selected;
                        this.currentProduct.allSubsServ[index].sub.ProductSubId = activationSubfromFetch.ProductSubId;
                        for (let index2 = 0; index2 < element.allServices.length; index2++) {
                            this.currentProduct.allSubsServ[index].allServices[index2].ProductSubId = activationSubfromFetch.ProductSubId;
                            this.currentProduct.allSubsServ[index].allServices[index2].Selected = false;
                            this.currentProduct.allSubsServ[index].allServices[index2].ProductSubServiceId = 0;
                        }
                        break;
                    }
                }

                StorageHelper.set(StorageKeys.keyProduct, this.currentProduct);

                this.setState({
                    mode: EScreenMode.view,
                    hidden: true,
        
                });

            });
    };


    private _onBreadcrumbItemClicked(ev: React.MouseEvent<HTMLElement>, item: IBreadcrumbItem): void {
        let brandKEY: string = '';
        let activationType: string = '';

        if (this.currentProduct.product.BrandId === 1) {
            brandKEY = 'vf';
        } else if (this.currentProduct.product.BrandId === 2) {
            brandKEY = 'otelo';
        } else if (this.currentProduct.product.BrandId === 3) {
            brandKEY = 'kabel';
        } else if (this.currentProduct.product.BrandId === 4) {
            brandKEY = 'dsl';
        }

        if (item.key === 'ACT&ACTPORT') {
            activationType = '1,3'
        } else if (item.key === 'PORT&ACTPORT') {
            activationType = '2,3'
        } else if (item.key === 'ACTPORT') {
            activationType = '3'
        }


        if (item.key === 'Start') {
            AppRouter.routeTo("Produkte", "/brands");
        } else {

            AppRouter.routeTo("Produkte", "/brands/" + brandKEY + '/' + this.props.businessCaseId + '/' + activationType + '/' + 'products');
        }

    }


    private renderSub(sub: SubServices): JSX.Element {
        let ctr = <></>;
        let isChecked: boolean = false;

        if (sub.sub.Model !== '') {
            var arr_from_json = JSON.parse(sub.sub.Model);

        }
        if (arr_from_json === undefined) {
            arr_from_json = {
                product_ui: {}
            };
        }

        if (sub.sub.Ultracard === 1) {
            isChecked = true;
        }
        let grayCard: string = '#F0F0F0';
        let blackCard: string = '#282828';
        let redBorder: string = '';

        let whiteTitle: string = '';

        let result: string = '';

        if (sub.sub.Selected === false) {
            result = grayCard;
            whiteTitle = '';

        } else {
            result = blackCard;
            whiteTitle = '#FFFFFF';
        }

        this.currentSub = sub;

        let borderwidth: string = '';
        let bordercolor: string = '';

        if (this.selectedItem === true && sub.sub.Id === this.subidforborder) {
            borderwidth = '6px';
            bordercolor = '#800000';
        } else {
            borderwidth = '';
            bordercolor = '';
        }

        let connectedServeces: number = 0;
        for (let index = 0; index < sub.allServices.length; index++) {
            const element = sub.allServices[index];
            if (element.Selected === true) {
                connectedServeces++;
            }
        }

        ctr = (

            <Card key={sub.sub.Key} style={{ borderColor: bordercolor, borderWidth: borderwidth, width: '10rem', height: '10rem', borderRadius: '15px', backgroundColor: result, cursor: 'pointer' }} onClick={() => { this.prepareForDeletion(sub.sub) }}>
                <Card.Body>
                    <Card.Title style={{ textAlign: 'center', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', fontSize: '11px', fontWeight: 'bold', color: whiteTitle }}>{arr_from_json.product_ui.ui_name === undefined ? '' : arr_from_json.product_ui.ui_name}</Card.Title>
                    <Card.Subtitle style={{ textAlign: 'center', fontSize: '12px', color: whiteTitle }}>{sub.sub.Name}</Card.Subtitle>

                    <DefaultButton disabled={!sub.sub.Selected} text={connectedServeces + " Services"} onClick={(event: any) => this.subServiceVisualisation(event, sub.allServices, sub.sub)} />
                    <PrimaryButton
                        disabled={!sub.sub.Selected}

                        text="Ultracard"
                        checked={sub.sub.Ultracard === 1 ? true : false}

                        onClick={(event: any) => this.ultracardChange(event, sub.sub)}

                        allowDisabledFocus={true}
                    />
                </Card.Body>

            </Card>
        )

        return ctr;
    }

    private prepareForDeletion(sub: any) {
      this.currentSUBForConnectionBuild = sub;
        this.setState({ hidden: false });
    };

    // private onSubClickForConnectionBuild(sub1: SUB) {
    //     let activationSubfromFetch: SUB = new SUB();
    //     let selected: boolean = false;



    //     Api.fetch(this.url + 'createSubConnection/', sub1, "POST",
    //         (api: IApiResponse) => {

    //             activationSubfromFetch = api.data;


    //             if (sub1.Selected === true) {
    //                 selected = false;
    //             } else {
    //                 selected = true;
    //             }



    //             for (let index = 0; index < this.currentProduct.allSubsServ.length; index++) {
    //                 var element = this.currentProduct.allSubsServ[index];
    //                 if (element.sub.Id === sub1.Id) {
    //                     this.currentProduct.allSubsServ[index].sub.Selected = selected;
    //                     this.currentProduct.allSubsServ[index].sub.ProductSubId = activationSubfromFetch.ProductSubId;
    //                     for (let index2 = 0; index2 < element.allServices.length; index2++) {
    //                         this.currentProduct.allSubsServ[index].allServices[index2].ProductSubId = activationSubfromFetch.ProductSubId;
    //                         this.currentProduct.allSubsServ[index].allServices[index2].Selected = false;
    //                         this.currentProduct.allSubsServ[index].allServices[index2].ProductSubServiceId = 0;
    //                     }
    //                     break;
    //                 }
    //             }

    //             StorageHelper.set(StorageKeys.keyProduct, this.currentProduct);

    //             this.setState({
    //                 mode: EScreenMode.view
    //             });

    //         });

    // }

    private ultracardChange(event: any, sub1: SUB) {
        event.stopPropagation();

        var ultracard: number = 0;

        if (sub1.Ultracard === 1) {
            ultracard = 2
        } else {
            ultracard = 1;
        }

        for (let index = 0; index < this.currentProduct.allSubsServ.length; index++) {
            var element = this.currentProduct.allSubsServ[index];
            if (element.sub.Id === sub1.Id) {
                this.currentProduct.allSubsServ[index].sub.Ultracard = ultracard;
                break;
            }

        }


        StorageHelper.set(StorageKeys.keyProduct, this.currentProduct);
        sub1.Ultracard = ultracard;

        Api.fetch(this.url + 'subUltracardChange/', sub1, "POST",
            (api: IApiResponse) => {

                this.setState({
                    mode: EScreenMode.view
                });
            });
        event.preventDefault();

    }

    private subServiceVisualisation(event: any, subServs: Service[], sub: SUB) {
        this.subidforborder = sub.Id;
        this.currentSUB = sub;
        this.selectedItem = true;

        event.stopPropagation();
        this.allServicesByCurrentSub = subServs;
        this.setState({ mode: EScreenMode.edit });
        event.preventDefault();

    }

    private renderEditor(): JSX.Element {

        let ctr = <Panel isBlocking={false} isOpen={this.state.mode === EScreenMode.edit} onDismiss={this._hidePanel} type={PanelType.medium} headerText={this.currentSUB.Name + '>Services'}>

            {

                this.prepareGroups()
            }
        </Panel>

        return ctr;

    }

    private prepareGroups() {
        let groups: string[] = [];

        if (this.allServicesByCurrentSub !== undefined) {


            for (let index = 0; index < this.allServicesByCurrentSub.length; index++) {
                var element = this.allServicesByCurrentSub[index];

                if (!groups.includes(element.FamilyName)) {
                    groups.push(element.FamilyName);
                }

            }
        }

        let arr: JSX.Element[] = [];

        for (let index = 0; index < groups.length; index++) {
            this.groupName = groups[index];

            if (this.allServicesByCurrentSub !== undefined) {
                this.serviceTemp = linq.from<Service>(this.allServicesByCurrentSub)
                    .where(x => x.FamilyName == this.groupName)
                    .toArray();
                arr.push(this.cardElement());
            }
        }

        return arr;
    }

    private cardElement(): JSX.Element {
        let ctr = <></>;


        ctr = (

            <Container fluid>
                <Row >
                    {this.groupName}
                </Row>

                <Row>
                    {this.serviceTemp.map((i, idx) => (
                        <Col xs={'auto'} md={'auto'}>
                            <Card key={i.Key} style={{ width: '8rem', height: '8rem', borderRadius: '15px', backgroundColor: i.Selected === true ? '#282828' : '#F0F0F0', cursor: 'pointer' }} onClick={() => this.serviceBuildConnection(i)} >
                                <Card.Body>
                                    <Card.Title style={{ textAlign: 'center', fontSize: '10px', fontWeight: 'bold', color: i.Selected === true ? '#FFFFFF' : '' }}>{i.Name}</Card.Title>
                                    <Card.Subtitle style={{ textAlign: 'center', fontSize: '12px', color: i.Selected === true ? '#FFFFFF' : '' }}>{i.Key}</Card.Subtitle>
                                    <PrimaryButton className='mandatory-not-checked'
                                        disabled={!i.Selected}

                                        text="Mandatory"
                                        checked={i.Mandatory === 1 ? true : false}

                                        onClick={(event: any) => this.mandatoryChange(event, i)}

                                        allowDisabledFocus={true}
                                    />
                                </Card.Body>

                            </Card>
                        </Col>
                    ))}

                </Row>
            </Container>
        )
        return ctr;
    }

    private _hidePanel() {
        this.selectedItem = false;
        this.subidforborder = 0;
        this.setState({ mode: EScreenMode.view });
    };

    private serviceBuildConnection(serv: Service) {
        let activationServicefromFetch: Service = new Service();
        let selected: boolean = false;

        let changeWasMade: boolean = false;



        Api.fetch(this.url + 'createServiceConnection/', serv, "POST",
            (api: IApiResponse) => {

                activationServicefromFetch = api.data;


                if (serv.Selected === true) {
                    selected = false;
                } else {
                    selected = true;
                }


                for (let index = 0; index < this.currentProduct.allSubsServ.length; index++) {
                    var element = this.currentProduct.allSubsServ[index];
                    for (let index2 = 0; index2 < element.allServices.length; index2++) {
                        const el = element.allServices[index2];
                        if (el.Id === serv.Id && el.ProductSubId === serv.ProductSubId) {
                            this.currentProduct.allSubsServ[index].allServices[index2].ProductSubServiceId = activationServicefromFetch.ProductSubServiceId;
                            this.currentProduct.allSubsServ[index].allServices[index2].Selected = selected;

                            changeWasMade = true;


                            break;
                        }


                    }
                    if (changeWasMade === true) {
                        break;
                    }
                }


                StorageHelper.set(StorageKeys.keyProduct, this.currentProduct);
                var index: any = this.allServicesByCurrentSub.findIndex(x => x.Id === serv.Id);

                this.allServicesByCurrentSub[index].Selected = selected;
                this.allServicesByCurrentSub[index].ProductSubServiceId = activationServicefromFetch.ProductSubServiceId;

                this.setState({
                    mode: EScreenMode.edit,

                });

            });




    }




    private mandatoryChange(ev: React.FormEvent<HTMLElement | HTMLInputElement>, serv: Service) {
        ev.stopPropagation();
        let mandatory: number = 0;

        if (serv.Mandatory === 1) {
            mandatory = 0;
        } else {
            mandatory = 1;
        }

        serv.Mandatory = mandatory;

        Api.fetch(this.url + 'mandatoryChange/', serv, "POST",
            (api: IApiResponse) => {

                for (let index = 0; index < this.currentProduct.allSubsServ.length; index++) {
                    var element = this.currentProduct.allSubsServ[index];
                    for (let index2 = 0; index2 < element.allServices.length; index2++) {
                        const el = element.allServices[index2];
                        if (el.Id === serv.Id) {
                            el.Mandatory = mandatory;
                        }

                    }
                }

                StorageHelper.set(StorageKeys.keyProduct, this.currentProduct);


                this.setState({
                    mode: EScreenMode.edit
                });

            });
        ev.preventDefault();
    }


    private onDataArrival(api: IApiResponse) {
        this.setState({
            mode: EScreenMode.view,
            productSubs: api.data

        });
    }
};
