import React from 'react';
import {ListValueOption} from '../../model/data/ListValueOption';
import { EScreenMode } from '../../model/app/Enums';
import Api from '../../utils/Api';
import {IApiResponse} from '../../model/app/IApiResponse';
import { Product, IProductEditModel } from '../../model/data/Product';
import { JSXElement } from '@babel/types';
import { Checkbox, CheckboxBase } from 'office-ui-fabric-react';
import linq from 'linq';
import {BusinessCase} from '../../model/data/BusinessCase';
import { ProductDetail } from '../../model/data/ProductDetail';


export interface IServiceScreenState {
    tariff: ProductDetail,
    mode: EScreenMode;
};

enum BusinessCassesOptions{
      bnt = 1,
      vvl = 2,
      tw = 4,
      vvltw = 8
}

export class BusinessCases extends React.Component<any, IServiceScreenState,BusinessCassesOptions> {
    private url: string = "activationdata/";
    
    constructor(props: any){
        super(props);

        this._onChange = this._onChange.bind(this);

        this.state = {
            mode: EScreenMode.view,
            tariff: props.tariff
        };

        
    }

    public render() {
        if(this.state.mode === EScreenMode.loading) {
            return <div>loading...</div>
        } else {
            return this.renderSUBS();
        }
    };

    private renderSUBS() : JSX.Element{
        let tariff = this.state.tariff;

        if(tariff==null) {
           return <></>;
        }
 
       let ctrl = (
        <>
            <Checkbox id={"svc-" + BusinessCassesOptions.bnt.toString()} checked={(tariff.BusinessCasesId & BusinessCassesOptions.bnt) !== 0} label="BNT" onChange={this._onChange}/>
            <Checkbox id={"svc-" + BusinessCassesOptions.vvl.toString()}  checked={(tariff.BusinessCasesId & BusinessCassesOptions.vvl) !== 0} label="VVL" onChange={this._onChange}/>
            <Checkbox id={"svc-" + BusinessCassesOptions.tw.toString()}  checked={(tariff.BusinessCasesId & BusinessCassesOptions.tw) !== 0} label="TW" onChange={this._onChange}/>
            <Checkbox id={"svc-" +  BusinessCassesOptions.vvltw.toString()}  checked={(tariff.BusinessCasesId & BusinessCassesOptions.vvltw) !== 0} label="VVLTW" onChange={this._onChange}/>
        </>
       )

        return ctrl;
    }

   
    private _onChange(event: any) {       
        var id = parseInt(event.target.id.replace("svc-", ""));
        var target = event.target as CheckboxBase;
        
        let tariff = this.state.tariff;

        if(target.checked){
            tariff.BusinessCasesId += id;
        } else { // unchecked
            tariff.BusinessCasesId -= id;
        }

        tariff.BusinesCase = tariff.BusinessCasesId;

        this.setState({tariff: tariff});
    }
}

export default BusinessCases;
