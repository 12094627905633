import React from "react";

export class DashboardScreen extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
  }

  public render() {
    let ctrl: JSX.Element = <></>;

    ctrl = <div>Dashboard</div>;

    return ctrl;
  }
}

export default DashboardScreen;
