import React from "react";
import {
  ContextualMenuItemType,
  IContextualMenuItem,
} from "office-ui-fabric-react";
import TableWrapper, {
  ITableWrapperProps,
  TableConfig,
} from "../../components/table/TableWrapper";
import { ITableScreen } from "../../interfaces/ITableScreen";
import { EScreenMode } from "../../model/app/Enums";
import { MenuItems, Roles, WebSites } from "../../utils/Defaults";
import { ETableSource } from "../../components/table/enums/table-source.enum";
import { IAmAccount } from "./Models";
import { EControlType } from "../../components/table/enums/control-type.enum";
import AccessManager from "../../utils/AccessManager";
import AccountManager from "../../utils/AccountManager";
import linq from "linq";
import AmAccountEditPanel from "./panels/AmAccountEditPanel";
import IKeyText from "../../model/app/IKeyText";
import StorageHelper from "../../utils/StorageHelper";
import Global from "../../utils/Global";

export interface IAmAccountScreenState extends ITableScreen {
  pivot: string;
}

export class AmAccountScreen extends React.Component<
  {},
  IAmAccountScreenState
> {
  private comp: JSX.Element | null = null;
  private am: AccountManager = new AccountManager();
  private current?: IAmAccount;

  constructor(props: any) {
    super(props);

    this.state = {
      mode: EScreenMode.view,
      pivot: AmAccountEditPanel.pivotMain,
    };
  }

  render = () => {
    return (
      <AccessManager
        roles={[
          Roles.PortalAdministrator,
          Roles.VB,
          Roles.VL,
          Roles.NL,
          Roles.NS,
          Roles.HL,
          Roles.BO,
        ]}
      >
        {this.renderInner()}
      </AccessManager>
    );
  };

  private processContextMenu = (item, e) => {
    if (e.key == MenuItems.edit) {
      this.setState({
        mode: EScreenMode.edit,
        id: item.id,
        pivot: AmAccountEditPanel.pivotMain,
      });
    } else if (e.key == MenuItems.statusPatch) {
      this.setState({
        mode: EScreenMode.edit,
        id: this.current!.id,
        pivot: AmAccountEditPanel.pivotStatus,
      });
    }
  };

  private renderInner = () => {
    let cm: IContextualMenuItem[] = [
      {
        key: MenuItems.edit,
        iconProps: { iconName: "Edit" },
        text: "Bearbeiten",
      },
      { key: "divider_1", itemType: ContextualMenuItemType.Divider },
    ];

    var usr = StorageHelper.getUser();

    if (Global.inAnyRoleUser(usr, [Roles.PortalAdministrator, Roles.HL])) {
      cm.push({
        key: MenuItems.admin,
        iconProps: { iconName: "AdminALogoFill32" },
        text: "Administration",
        subMenuProps: {
          items: [
            {
              key: MenuItems.statusPatch,
              iconProps: { iconName: "SyncStatus" },
              text: "Status ändern",
              onClick: this.processContextMenu,
            },
          ],
        },
      });
    }

    let tc = TableConfig.createInstance({
      url:
        linq
          .from<IKeyText>(StorageHelper.getWebsites())
          .toArray()
          .filter((x) => x.key == WebSites.am)[0].text + "account/filter",
      sourceType: ETableSource.dynamic,
      arrayName: "accounts",
      sort: [],
      columns: [
        { name: "id", label: "VOID", sortable: true },
        { name: "statusCode", label: "Status" },
        { name: "name", label: "Name" },
        { name: "level", label: "Level", sortable: true },
        { name: "voidL1", label: "L1", sortable: true },
        { name: "voidL2", label: "L2", sortable: true },
        { name: "regionText", label: "Region", sortable: true },
        { name: "kamName", label: "KAM", sortable: true },
        { name: "vbName", label: "VB", sortable: true },
        { name: "vlName", label: "VL", sortable: true },
        {
          name: "x",
          label: "Adresse",
          renderer: (r, c) => {
            var a = Global.formatAddress(r);
            return <p title={a}>{a}</p>;
          },
        },
      ],
    });

    let cfg: ITableWrapperProps = {
      id: "rety",
      action: this.processContextMenu,
      onItemChanged: (u) => {
        this.current = u;
      },
      model: { needle: "", statusId: "0", paging: TableConfig.initPaging() },
      contextMenu: cm,
      filter: [
        { type: EControlType.text, key: "needle", label: "Suche" },
        {
          type: EControlType.dropdown,
          key: "statusId",
          label: "Status",
          list: StorageHelper.getStructure("statusesAccount", true),
        },
        {
          type: EControlType.dropdown,
          key: "regionId",
          label: "Region",
          list: StorageHelper.getStructure("regions", true, ""),
        },
      ],
      config: tc,
    };

    return (
      <>
        <h1>Händler</h1>
        <div className="data">
          <TableWrapper {...cfg} />

          {this.state.mode == EScreenMode.edit &&
          AmAccountEditPanel.getPivots().indexOf(this.state.pivot) >= 0 ? (
            <AmAccountEditPanel
              id={this.state.id!}
              pivot={this.state.pivot}
              onDismiss={() => {
                this.setState({ mode: EScreenMode.view });
              }}
            />
          ) : null}
        </div>
      </>
    );
  };
}

export default AmAccountScreen;
