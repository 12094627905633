import React from 'react';
import { Checkbox, TextField, ProgressIndicator, DatePicker } from 'office-ui-fabric-react';
import Global from '../utils/Global';
import { Button, Container } from 'react-bootstrap';
import { ErrorScreen } from '.';
import { Roles } from '../utils/Defaults';
import Api from '../utils/Api';
import DateTimePicker from '../components/controls/DateTimePicker';
import { IApiResponse } from '../model/app/IApiResponse';
import { EScreenMode } from '../model/app/Enums';

enum DefconState {
    undefined = 0,
    ok = 1,
    okDb = 2,
    bookingAllowed = 4,
    warningVisible = 8
}

interface DefconConfig {

    notification: string;

    state: number;

    voidAllowed: string;

    activeVf: boolean;
    activeOtelo: boolean;
    activeKabel: boolean;
    activeDsl: boolean;

    // state
    ok: boolean;
    okDb: boolean;
    bookingAllowed: boolean;
    warningVisible: boolean;

    notAvailableFrom?: Date;
    notAvailableTo?: Date;
}

export interface IDefconScreenProps {

}

export interface IDefconScreenState {
    model: DefconConfig;
    mode: EScreenMode;
}

export class DefconScreen extends React.Component<IDefconScreenProps, IDefconScreenState> {
    constructor(props: any) {
        super(props);
        this.state = {
            mode: EScreenMode.loading, model: {

                notification: '',

                state: DefconState.undefined,

                voidAllowed: '',

                activeVf: false,
                activeOtelo: false,
                activeKabel: false,
                activeDsl: false,

                ok: false,
                okDb: false,
                bookingAllowed: false,
                warningVisible: false
            }
        };

        this._checkboxChange = this._checkboxChange.bind(this);
        this._refreshModel = this._refreshModel.bind(this);
        this.dateFromChanged = this.dateFromChanged.bind(this);
        this.dateToChanged = this.dateToChanged.bind(this);

        this._loadConfig = this._loadConfig.bind(this);
        this._onSave = this._onSave.bind(this);

        this._loadConfig(false);
    }

    render() {
        const inRole = Global.inRole(Roles.PortalAdministrator);
        
        if (!inRole) {
            return <ErrorScreen data={{ code: 403 }} />;
        }

        if (this.state.mode === EScreenMode.loading) {
            return <ProgressIndicator label="Daten werden geladen" description="Bitte warten Sie" />;
        }

        return <Container fluid>
            <h5>INFOBANNER</h5>

            <p>Brands</p>
            <Checkbox checked={this.state.model.activeVf} name="activeVf" label="Vf" onChange={this._checkboxChange} />
            <Checkbox checked={this.state.model.activeOtelo} name="activeOtelo" label="Otelo" onChange={this._checkboxChange} />
            <Checkbox checked={this.state.model.activeKabel} name="activeKabel" label="Kabel" onChange={this._checkboxChange} />
            <Checkbox checked={this.state.model.activeDsl} name="activeDsl" label="Dsl" onChange={this._checkboxChange} />

            <TextField label="Text" name="notification" value={this.state.model.notification || ''} onChange={this._refreshModel} />

            <br />

            <p>State</p>
            <Checkbox checked={this.state.model.ok} name="ok" label="Ok" onChange={this._checkboxChange} />
            <Checkbox checked={this.state.model.okDb} name="okDb" label="Ok Db" onChange={this._checkboxChange} />
            <Checkbox checked={this.state.model.bookingAllowed} name="bookingAllowed" label="BookingAllowed" onChange={this._checkboxChange} />
            <Checkbox checked={this.state.model.warningVisible} name="warningVisible" label="Warning Visible" onChange={this._checkboxChange} />

            <TextField label="Erlaubt für folgende Händler" name="voidAllowed" value={this.state.model.voidAllowed || ''} onChange={this._refreshModel} />

            <br />

            <DateTimePicker enableClearButton={true} label="From" date={this.state.model.notAvailableFrom} onChange={this.dateFromChanged} />
            <DateTimePicker enableClearButton={true} label="To" date={this.state.model.notAvailableTo} onChange={this.dateToChanged} />

            <Button variant="primary" onClick={this._onSave}>Speichern</Button>
        </Container>
    }

    dateFromChanged(date: Date | undefined) {
        let model = this.state.model;
        model.notAvailableFrom = date;
        this.setState({ model });
    }

    dateToChanged(date: Date | undefined) {
        let model = this.state.model;
        model.notAvailableTo = date;
        this.setState({ model });
    }

    _refreshModel = (ctrl: any) => {
        console.log(ctrl.target);
        let model = this.state.model;
        Global.setValueByPath(model, ctrl.target.name, ctrl.target.value);
        this.setState({ model: model });
    }

    _checkboxChange = (ev: any) => {
        let model = this.state.model;
        Global.setValueByPath(model, ev.target.name, ev.target.checked);

        this.setState({ model: model });
    }

    _onSave = () => {
        this.setState({ mode: EScreenMode.loading });

        let model = this.state.model;

        // calculate the state
        model.state = DefconState.undefined;
        if (model.ok) {
            model.state += DefconState.ok;
        }
        if (model.okDb) {
            model.state += DefconState.okDb;
        }
        if (model.bookingAllowed) {
            model.state += DefconState.bookingAllowed;
        }
        if (model.warningVisible) {
            model.state += DefconState.warningVisible;
        }


        Api.fetch('defcon/config', model, 'POST', (api: IApiResponse) => {
            console.log('defcon saved: ');
            console.log(api);
            this._loadConfig(false);
        });
    }

    _loadConfig = (initial: boolean) => {
        if (!initial) {
            this.setState({ mode: EScreenMode.loading });
        } else {
            //this.state.mode= = { mode: EScreenMode.loading };
        }

        Api.fetch('defcon/config', undefined, 'GET', (api: IApiResponse) => {
            const model = api.data as DefconConfig;

            console.log("defcon get: ", model);

            if (model) {
                if (model.notAvailableFrom) {
                    model.notAvailableFrom = new Date(model.notAvailableFrom);
                }

                if (model.notAvailableTo) {
                    model.notAvailableTo = new Date(model.notAvailableTo);
                }
            }

            if (!initial) {
                this.setState({ model: model, mode: EScreenMode.view });
            } else {
                this.state = { model: model, mode: EScreenMode.view };
            }
        });
    }
}

export default DefconScreen;