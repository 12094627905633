import React from "react";
import {
	ContextualMenu,
	DetailsList,
	DetailsListLayoutMode,
	DirectionalHint,
	IColumn,
	IContextualMenuItem,
	IDetailsRowProps,
	Selection,
	SelectionMode,
} from "office-ui-fabric-react";
import { EScreenMode } from "../../model/app/Enums";
import { Defaults, HttpMethods } from "../../utils/Defaults";
import LoaderVf, { ELoaderVfType } from "../../Support/LoaderVf";
import { isMobile } from "react-device-detect";
import { IAmAccount, IAmResponse } from "../../screens/am/Models";
import { IPaging } from "./Models";
import { ITableColumn } from "./interfaces/table-column.interface";
import { ISort } from "./interfaces/sort.interface";
import { ESort } from "./enums/sort.enum";
import { ETableSource } from "./enums/table-source.enum";
import { ITableFilterItem } from "./interfaces/table-filter-item.interface";
import TableFilter from "./TableFilter";
import ApiAsync from "../../utils/ApiAsync";
import linq from "linq";
import Pagination from "./Pagination";

export interface ITableWrapperProps {
	id: string;
	config: TableConfig;
	action?: any;
	onItemChanged: any;
	contextMenu?: any;
	filter?: ITableFilterItem[];
	filterAction?: any;
	model: any;
	updateWrapper?: boolean;
	fetchedItems?: any;
}

export interface ITableWrapperState {
	selection?: Selection;
	columns: IColumn[];
	items: any[];
	itemsAll: any[];
	itemsFiltered: any[];
	mode: EScreenMode;
	config: TableConfig;
	model: any;
	contextMenu?: any;
	filter?: ITableFilterItem[];
	changed?: boolean;
	testTouch?: boolean;
	currentContextTarget?: any;
}

type TableConfigPropertyMappers = { [key: string]: Function };

export class TableConfig {
	url: string = "";
	sourceType: ETableSource = ETableSource.static;
	columns: ITableColumn[] = [];
	arrayName: string = "items";
	sort: ISort[] = [];

	method?: string;
	paging?: IPaging = { start: 0, pageSize: Defaults.pageSize };
	propertyMappers?: TableConfigPropertyMappers;
	staticFilter?: any;
	dataTransform?: any;

	constructor() {}

	static createInstance(model: any): TableConfig {
		let t = new TableConfig();

		t.url = model.url;
		t.method = model.method;
		t.sourceType = model.sourceType;
		t.columns = model.columns;
		t.arrayName = model.arrayName;
		t.sort = model.sort;
		t.staticFilter = model.staticFilter || undefined;
		t.dataTransform = model.dataTransform || undefined;

		if (model.propertyMappers) t.propertyMappers = model.propertyMappers;

		return t;
	}

	static initPaging(): IPaging {
		return {
			start: 0,
			pageSize: Defaults.pageSize,
		};
	}

	prepareColumns = (onColumnClick: any): IColumn[] => {
		var res: IColumn[] = [];

		this.columns.map((x, i) => {
			let c = linq
				.from<ISort>(this.sort)
				.where((s) => s.column == x.name)
				.firstOrDefault();

			res.push({
				key: x.name,
				name: x.label,
				fieldName:
					x.name.indexOf(".") > 0
						? x.name.substring(x.name.indexOf(".") + 1)
						: x.name,
				minWidth: 100,
				maxWidth: 150,
				className: "aaa",
				isSorted: c ? true : false,
				isSortedDescending: c && c.direction == ESort.desc ? true : false,
				sortAscendingAriaLabel: "Sorted A to Z",
				sortDescendingAriaLabel: "Sorted Z to A",
				isResizable: true,
				isCollapsible: false,
				data: "string",
				onColumnClick: onColumnClick,
			});
		});

		return res;
	};

	applySort(column: string, callback: any) {
		let c = linq
			.from<ISort>(this.sort)
			.where((x) => x.column == column)
			.firstOrDefault();

		let cc = linq
			.from<ITableColumn>(this.columns)
			.where((x) => x.name == column)
			.firstOrDefault();

		if (!cc.sortable) callback(false);

		if (!c) {
			this.sort = [];
			this.sort.push({ column: column, direction: ESort.asc });
			callback(true);
			return;
		}

		c.direction = c.direction == ESort.asc ? ESort.desc : ESort.asc;
		callback(true);
	}

	static parse(json: string): TableConfig {
		var c = JSON.parse(json);
		return TableConfig.ensure(c);
	}

	static ensure(source: TableConfig): TableConfig {
		let aw = Object.assign(new TableConfig(), source);
		return aw;
	}
}

export class TableWrapper extends React.Component<
	ITableWrapperProps,
	ITableWrapperState
> {
	private currentContextTarget: any;
	private currentItem: any;
	private loaded: boolean = false;
	private timeoutId: NodeJS.Timeout | null = null;
	private dataLength: number = 0;

	constructor(props: any) {
		super(props);

		this.state = {
			columns: this.props.config.prepareColumns(this.onColumnClick),
			items: [],
			itemsAll: [],
			itemsFiltered: [],
			mode: EScreenMode.loading,
			config: this.props.config,
			contextMenu: undefined,
			model: this.props.model,
			filter: this.props.filter,
		};
	}

	componentDidMount = () => this.init();

	render = () => {
		let { selection, items } = this.state;

		if (this.props.updateWrapper) this.loadData();

		if (!items) items = [];

		const handleItemTouchStart = (
			props: IDetailsRowProps,
			event: React.TouchEvent<HTMLDivElement>
		) => {
			this.timeoutId = setTimeout(() => {
				this.currentItem = props.item;
				this.currentContextTarget = event;
				this.props.onItemChanged(this.currentItem);
				this.setState({
					contextMenu: this.props.contextMenu,
					currentContextTarget: props.itemIndex,
				});
			}, 500); // Adjust the duration as per your requirement
		};

		const handleItemTouchEnd = () => {
			if (this.timeoutId) {
				clearTimeout(this.timeoutId);
				this.timeoutId = null;
			}
		};

		const onRenderRow = (props, defaultRender) => {
			const { item } = props;
			let rowClassName: string = "";

			if (item.errorQuote === 0) rowClassName = "bgWhiteRow";
			else if (item.errorQuote > 5) rowClassName = "bgRedRow";
			else if (item.errorQuote > 0 && item.errorQuote < 5)
				rowClassName = "bgOrangeRow";

			return (
				<div
					className={rowClassName}
					id={"id-" + props!.itemIndex.toString()}
					onTouchStart={(e) => handleItemTouchStart(props!, e)}
					onTouchEnd={handleItemTouchEnd}
				>
					{defaultRender && defaultRender(props)}
				</div>
			);
		};

		return (
			<div className="et" id={`et-${this.props.id}`}>
				{
					<>
						{this.state.mode == EScreenMode.loading ||
						this.state.model.error ? (
							<LoaderVf
								type={ELoaderVfType.full}
								message={LoaderVf.parseApiError(this.state.model.error)}
							/>
						) : null}

						{this.props.filter ? (
							<TableFilter
								items={this.state.filter}
								action={
									this.props.filterAction
										? this.props.filterAction(this.state.itemsAll)
										: undefined
								}
								model={this.state.model}
								onChanged={this.onFilterChanged}
								onApply={this.onApplyFilter}
							/>
						) : null}

						<DetailsList
							items={items}
							compact={false}
							columns={this.state.config.prepareColumns(this.onColumnClick)}
							selectionMode={SelectionMode.none}
							getKey={this.getKey}
							setKey="single"
							layoutMode={DetailsListLayoutMode.justified}
							isHeaderVisible={true}
							selection={selection}
							selectionPreservedOnEmptyClick={true}
							onItemInvoked={this.onItemInvoked}
							onRenderItemColumn={this.onRenderColumn}
							onItemContextMenu={this.onItemContextMenu}
							enterModalSelectionOnTouch={true}
							ariaLabelForSelectionColumn="Toggle selection"
							ariaLabelForSelectAllCheckbox="Toggle selection for all items"
							onRenderRow={onRenderRow}
							checkButtonAriaLabel="Row checkbox"
						/>
						{this.state.model && this.state.model.paging ? (
							<Pagination
								model={this.state.model.paging}
								onPageChanged={(e) => {
									this.pageChanged(e);
								}}
							/>
						) : null}
						{this.state.contextMenu ? (
							<ContextualMenu
								onItemClick={this.onContextMenuItemClicked}
								items={this.state.contextMenu}
								isBeakVisible={true}
								target={
									isMobile
										? document.querySelector(
												`#id-` + this.state.currentContextTarget
										  )
										: this.currentContextTarget
								}
								directionalHint={DirectionalHint.bottomLeftEdge}
								onDismiss={this.onContextMenuDismiss}
							/>
						) : null}
					</>
				}
			</div>
		);
	};

	init = () => this.loadData();

	onFilterChanged = (m) => this.setState({ model: m });

	public itemAdded = (res: any) => this.loadData();

	reload = () => {
		this.loaded = false;
		this.loadData();
	};

	onApplyFilter = (model) => {
		// if (!model.paging.pageSize)
		//   model.paging = { start: 0, pageSize: this.dataLength };
		// else
		if (!model.paging) model.paging = TableConfig.initPaging();

		if (model.paging.pageSize === TableConfig.initPaging())
			model.paging = TableConfig.initPaging();

		if (model && model.needle) model.needle = model.needle.replace(/\s/g, "");

		this.setState(
			{
				model: model,
				mode:
					this.props.config.sourceType == ETableSource.dynamic
						? EScreenMode.loading
						: EScreenMode.view,
			},
			() => this.loadData()
		);
	};

	private onContextMenuItemClicked = (
		ev?: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>,
		item?: IContextualMenuItem
	) => {
		if (this.props.action) this.props.action(this.currentItem, item);
	};

	private onContextMenuDismiss = () => {
		this.setState({ contextMenu: undefined });
	};

	private onItemContextMenu = (item?: any, index?: number, ev?: Event) => {
		this.currentContextTarget = ev;
		this.currentItem = item;
		this.props.onItemChanged(this.currentItem);
		this.setState({ contextMenu: this.props.contextMenu });
	};

	private loadData = async () => {
		if (this.state.mode !== EScreenMode.loading)
			this.setState({ mode: EScreenMode.loading });

		if (this.loaded && this.props.config.sourceType == ETableSource.static) {
			this.prepareItems(this.state.model, undefined);

			return;
		}

		if (this.props.config.sourceType === ETableSource.fetched) {
			this.prepareItems(this.props.fetchedItems, undefined);

			return;
		}

		let res;

		if (this.state.config.method === HttpMethods.get) {
			res = await ApiAsync.get(
				this.state.config.url,
				this.state.config.arrayName
			);
		} else {
			res = await ApiAsync.post<IAmResponse>(
				this.state.config.url,
				this.state.model
			);
		}

		var next = this.state.model;

		this.loaded = true;

		if (this.state.config.propertyMappers) {
			this.executePropertyMapping(res[this.props.config.arrayName]);
		}

		if (this.state.config.dataTransform) {
			res[this.props.config.arrayName] = this.state.config.dataTransform(
				res[this.props.config.arrayName]
			);
		}

		if (res as IAmResponse) {
			this.setState(
				{ changed: true, itemsAll: [], mode: EScreenMode.undefined },
				() => {
					this.prepareItems(next, res as IAmResponse);
				}
			);
		}
	};

	pageChanged = (target) => {
		var model = this.state.model;

		model.paging!.start = target * model.paging!.pageSize;

		if (this.props.config.sourceType == ETableSource.static) {
			this.prepareItems({ paging: model.paging }, undefined);

			return;
		}

		this.setState({ model: model, mode: EScreenMode.loading }, () => {
			this.loadData();
		});
	};

	private prepareItems = (next: any, res: IAmResponse | undefined) => {
		const data = { visible: [], all: [], filtered: [], paging: {} };

		data.all = res ? res[this.props.config.arrayName] : [];

		if (this.props.config.sourceType == ETableSource.dynamic) {
			if (res) {
				next.paging = res["paging"];
				data.visible = res[this.props.config.arrayName];
			}

			data.paging = next.paging;
		} else {
			data.paging = next.paging;

			if (this.state.itemsAll && this.state.itemsAll.length > 0) {
				data.all = this.state.itemsAll as never[];
			}

			if (this.props.config.sourceType === ETableSource.fetched) {
				data.all = this.props.fetchedItems;
				data.visible = this.props.fetchedItems;
			}

			this.applyStaticPaging(data);

			next.paging = data.paging;
		}

		this.setState({
			items: data.visible,
			itemsAll: data.all,
			itemsFiltered: data.filtered,
			model: next,
			mode: EScreenMode.view,
		});
	};

	private applyStaticPaging = (data: any) => {
		const pages: IPaging = data.paging;

		if (this.state.model.needle) {
			if (this.props.config.staticFilter) {
				data.filtered = this.props.config.staticFilter(
					data.all,
					this.state.model.needle
				);
			}
		} else {
			data.filtered = [];
		}

		this.dataLength = data.all.length;

		if (this.props.config.sourceType === ETableSource.fetched) {
			return;
		}

		if (!data.paging.pageSize) {
			data.visible = data.all;
			return;
		}

		if (data.filtered.length > 0) {
			data.visible = linq
				.from<IAmAccount>(data.filtered)
				.skip(pages.start) //  * pages.pageSize)
				.take(pages.pageSize)
				.toArray();

			data.paging.all = data.filtered.length;
		} else {
			data.visible = linq
				.from<IAmAccount>(data.all)
				.skip(pages.start) // * pages.pageSize)
				.take(pages.pageSize)
				.toArray();

			data.paging.all = data.all.length;
		}
	};

	private onRenderColumn = (item?: any, index?: number, column?: IColumn) => {
		if (column) {
			var cfg = linq
				.from<ITableColumn>(this.props.config.columns)
				.where((x) => x.name == column.fieldName)
				.firstOrDefault();

			if (cfg && cfg.renderer) {
				return cfg.renderer(item, column);
			}
		}

		return item[column!.fieldName!];
	};

	private onColumnClick = (
		ev: React.MouseEvent<HTMLElement>,
		column: IColumn
	): void => {
		var c = this.props.config.columns.filter((x) => x.name == column.key)[0];

		if (!c.sortable) {
			return;
		}

		this.state.config.applySort(column.key, (b) => {
			if (b) {
				var m = this.state.model;

				m.sort = this.state.config.sort;

				this.setState({ config: this.state.config }, () => {
					this.onApplyFilter(m);
				});
			}
		});
	};

	private onItemInvoked = (item): any => {};

	private getKey(item: any, index?: number): string {
		return item.key;
	}

	private executePropertyMapping(responseItems: any[]) {
		let mappers = this.state.config
			.propertyMappers as TableConfigPropertyMappers;
		responseItems.forEach((item) => {
			Object.keys(item).forEach((key) => {
				if (mappers[key]) {
					item[key] = mappers[key](item[key]);
				}
			});
		});
	}
}

export default TableWrapper;
