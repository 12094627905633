import React, { useState, useEffect } from "react";
import { ITableScreen } from "../../interfaces/ITableScreen";
import Global from "../../utils/Global";
import { EScreenMode } from "../../model/app/Enums";
import { pivotMain } from "../epm/panels/EpmProductEditPanel";
import BookingManager from "./BookingManager";
import { MenuItems, Roles, WebSites } from "../../utils/Defaults";
import { IContextualMenuItem } from "office-ui-fabric-react";
import TableWrapper, { ITableWrapperProps, TableConfig } from "../../components/table/TableWrapper";
import { from } from "linq";
import IKeyText from "../../model/app/IKeyText";
import StorageHelper from "../../utils/StorageHelper";
import { ETableSource } from "../../components/controls/Table";
import { EControlType } from "../../components/table/enums/control-type.enum";
import LoaderVf, { ELoaderVfType } from "../../Support/LoaderVf";
import { IDsArchive } from "../../model/data/IDsArchive";
import { IApiError } from "../../utils/ApiAsync";

export interface IDsArchiveScreenState extends ITableScreen {
    order?: any | undefined;
    pivot?: string;
    brands?: any;
    showNewProductForm?: boolean;
    orders?: IDsArchive[];
    error?: IApiError; // Add error field
}

export const BookingDsArchive: React.FC<IDsArchiveScreenState> = () => {
    const cfg1 = Global.getConfiguration();
    const [archiveState, setArchiveState] = useState<IDsArchiveScreenState>({
        order: undefined,
        id: Global.getEmptyGuid(),
        mode: EScreenMode.view,
        pivot: pivotMain,
        brands: undefined,
        showNewProductForm: false,
        orders: [],
        error: undefined
    });
    const [isLoading, setIsLoading] = useState(false); // Loading state
    const [responseArchiveConfirm, setResponseArchiveConfirm] = useState<any | undefined>(undefined);
    const bookingManager = new BookingManager();

    const [current, setCurrent] = useState<IDsArchive | undefined>(undefined);

    const processContextMenu = (item: any, e) => {
        if (e.key === MenuItems.download && item.orderId === current?.orderId) {
            setArchiveState({
                ...archiveState,
                mode: EScreenMode.edit,
                order: item,
                id: item.id,
                error: undefined
            });
        } else if (e.key === MenuItems.archive && item.orderId === current?.orderId) {
            setArchiveState({
                ...archiveState,
                mode: EScreenMode.details,
                order: item,
                id: item.id,
                error: undefined
            });
        }
    };

    useEffect(() => {
        if (archiveState.mode === EScreenMode.details && archiveState.order.orderId === current?.orderId) {
            executeArchiveConfirmation();
        } else if (archiveState.mode === EScreenMode.edit && archiveState.order.orderId === current?.orderId) {
            executeDownload();
        }
    }, [archiveState.mode, archiveState.id, archiveState.order?.orderId]);

    let cm: IContextualMenuItem[] = [
        {
            key: MenuItems.download,
            iconProps: { iconName: "DoubleChevronLeft" },
            text: "Herunterladen",
        },
        {
            key: MenuItems.archive,
            iconProps: { iconName: "View" },
            text: "Archiv",
        }
    ];

    const tc = TableConfig.createInstance({
        url:
            from<IKeyText>(StorageHelper.getWebsites())
                .toArray()
                .filter(x => x.key === WebSites.ds)[0].text + 'booking/archive/filter',
        sourceType: ETableSource.dynamic,
        arrayName: "digitalSignArchives",
        sort: [],
        columns: [
            { name: "id", label: "Id", sortable: true },
            { name: "orderId", label: "Order Id", sortable: true },
            { name: "creator", label: "Creator", sortable: true },
            { name: "confirmBy", label: "Confirm By", sortable: true },
            { name: "status", label: "Status", sortable: true },
            { name: "remoteOrderId", label: "Remote Order Id", sortable: true },
            { name: "brand", label: "Brand", sortable: true },
            { name: "msisdn", label: "Msisdn", sortable: true },
            { name: "businessCase", label: "BusinessCase", sortable: false },
            { name: "activationType", label: "ActivationType", sortable: false },
            {
                name: "createDate",
                label: "Created",
                sortable: true,
                renderer: (r) => {
                    return Global.toGermanDate(r.createDate);
                },
            },
            { name: "sessionId", label: "Session Id", sortable: true },
            { name: "kId", label: "Kibana Id", sortable: true }
        ]
    });

    let cfg: ITableWrapperProps = {
        id: "rety",
        action: processContextMenu,
        onItemChanged: (u) => {
            setCurrent(u);
            setResponseArchiveConfirm(undefined);
            setIsLoading(false);
        },
        model: { needle: "", paging: TableConfig.initPaging() },
        contextMenu: cm,
        filter: [
            { type: EControlType.text, key: "needle", label: "Suche" },
            { type: EControlType.text, key: "status", label: "Status" },
            {
                type: EControlType.dropdown,
                key: "brand",
                label: "Brand",
                list: StorageHelper.getBrandNamesReturnKeyBrand(true)
            },
            { type: EControlType.text, key: "creator", label: "Creator" },
            { type: EControlType.dropdown, key: "activationType", label: "Activation Type", list: StorageHelper.getActivationTypes(true) },
            { type: EControlType.dropdown, key: "businessCase", label: "Business Case", list: StorageHelper.getBusinessCases(true) },
            { type: EControlType.text, key: "createDate", label: "Date Created" },
        ],
        config: tc,
    };

    const executeArchiveConfirmation = async () => {
        if (archiveState.mode === EScreenMode.details && archiveState.order?.orderId) {
            setIsLoading(true);
            try {
                const response = await bookingManager.archiveConfirmation(archiveState.order.orderId, current!.brand);
                if (response.error) {
                    setResponseArchiveConfirm({
                        error: response.error
                    });
                }
                else {
                    setResponseArchiveConfirm(response);
                    setIsLoading(false);
                }
            }
            finally {
                // setIsLoading(false);
            }
        }
    };

    const executeDownload = async () => {
        if (archiveState.mode === EScreenMode.edit && archiveState.order?.orderId) {
            setIsLoading(true);
            try {
                const response = await bookingManager.downloadZip(archiveState.order.orderId);

                if (response instanceof Response && !response!.ok) {
                    setResponseArchiveConfirm({
                        error: response.statusText
                    });
                }
                else {
                    const url = URL.createObjectURL(response as Blob);

                    const a = document.createElement('a');
                    a.href = url;
                    a.download = `contract-${current!.brand}-${archiveState.order.orderId}.zip`;

                    a.click();

                    URL.revokeObjectURL(url);

                    setResponseArchiveConfirm(response);
                    setIsLoading(false);

                }
            } finally {
            }
        }
    };

    return (
        <>
            <TableWrapper {...cfg} />
            {isLoading && (
                <LoaderVf
                    type={ELoaderVfType.full}
                    message={responseArchiveConfirm?.error ? LoaderVf.parseApiError(responseArchiveConfirm.error) : undefined}
                />
            )}
        </>
    );
};

export default BookingDsArchive;
