import StorageHelper from "./StorageHelper";
import { HttpMethods, WebSites } from "./Defaults";
import ApiAsync from "./ApiAsync";
import { IProductFilterModel } from "../screens/epm/Models";
import { IEpmProduct } from "../model/data/IEpmProduct";
import IEpmProperty from "../model/data/Property";
import IEpmProperties from "../model/data/Properties";
import { IBlacklistItem } from "../model/data/IBlacklistItem";


export class EpmManager {
    private base: string;

    constructor() {
        this.base = StorageHelper.getWebsites().filter(x => x.key == WebSites.epm)[0].text;

        if (this.base.endsWith('/')) {
            this.base = this.base.substring(0, this.base.length - 1)
        }

        if (!ApiAsync.token) {
            ApiAsync.token = StorageHelper.getUser()!.easyToken;
        };
    }

    async getParentProp(parentId: number): Promise<IEpmProperty> {
        const api = `${this.base}/property/${parentId}`;

        try {
            const result = await ApiAsync.run<IEpmProperty>(api, HttpMethods.get);

            return result;
        } catch (error: any) {
            return error.message
        }
    }

    async addProduct(model: IEpmProduct): Promise<IEpmProduct> {
        const api = `${this.base}/product`;

        try {
            const result = await ApiAsync.run<IEpmProduct>(api, HttpMethods.post, model)

            return result
        } catch (error: any) {
            return error.message
        }
    }

    async getAllCategories(): Promise<IEpmProduct[]> {
        const api = `${this.base}/product/getProductCategories`;

        try {
            const result = await ApiAsync.run<IEpmProduct[]>(api, HttpMethods.get);

            return result;
        } catch (error: any) {
            return error.message
        }
    }

    async getProductPropertyValue(productId: number, propertyId: number): Promise<IEpmProperties> {
        const api = `${this.base}/property/${productId}/${propertyId}`;

        const result = await ApiAsync.run<IEpmProperties>(api, HttpMethods.get)
        return result;
    }

    async getAllProperties(): Promise<IEpmProperty[]> {
        const api = `${this.base}/property/getAll`

        try {
            const result = await ApiAsync.run<IEpmProperty[]>(api, HttpMethods.get)

            return result;
        } catch (error: any) {
            return error.message
        }
    }

    async getAllProducts(): Promise<IEpmProduct[]> {
        const api = `${this.base}/product/list`;

        try {
            const result = await ApiAsync.run<IEpmProduct[]>(api, HttpMethods.get);

            return result;
        } catch (error: any) {
            return error.message
        }
    }

    async getProductFamilies(): Promise<IEpmProduct[]> {
        const api = `${this.base}/product/getProductFamilies`;

        try {
            const result = await ApiAsync.run<IEpmProduct[]>(api, HttpMethods.get);

            return result;
        } catch (error: any) {
            return error.message;
        }
    }

    async getAllBrands(): Promise<IEpmProduct[]> {
        const api = `${this.base}/brand/list`;

        try {
            const result = await ApiAsync.run<IEpmProduct[]>(api, HttpMethods.get);

            return result;
        } catch (error: any) {
            return error.message
        }
    }

    async createPropValueForProduct(productId : number, selectedPropertyId : number, changedValue: string): Promise<IEpmProperties>{
        const api = `${this.base}/property`; 
        const model = {
            productId,
            propertyId: selectedPropertyId,
            value: changedValue
        }
        try {
            const result = await ApiAsync.run<IEpmProperties>(api, HttpMethods.post, model);

            return result;
        } catch (error: any) {
            return error.message
        }
    }

    async updateProduct(model: IEpmProduct) {
        const api = `${this.base}/product`

        return await ApiAsync.run<IEpmProduct>(api, HttpMethods.put, model);
    }

    async getProduct(id: string): Promise<IEpmProduct | any> {
        const api = `${this.base}/product/${id}`;

        return await ApiAsync.run<IEpmProduct>(api, HttpMethods.get)
    }

    async editPropertyValue(model: IEpmProperties): Promise<IEpmProperties> {
        const api = `${this.base}/property`;

        return await ApiAsync.run<IEpmProperties>(api, HttpMethods.put, model);
    }

    async filterProducts(model?: IProductFilterModel): Promise<IEpmProduct | string | any> {
        const api = `${this.base}/product/v2/filter`;

        try {
            const result = await ApiAsync.run<IEpmProduct>(api, HttpMethods.post, model);

            return result;
        } catch (error: any) {
            if (error) {
                return error.message
            }
        };

        return "";
    }

    async addBlacklistItem(model: IBlacklistItem): Promise<IBlacklistItem> {
        const api = `${this.base}/blacklist`;

        try {
            const result = await ApiAsync.run<IBlacklistItem>(api, HttpMethods.post, model)

            return result
        } catch (error: any) {
            return error.message
        }
    }

    async updateBlacklistItem(model: IBlacklistItem) {
        const api = `${this.base}/blacklist`;

        return await ApiAsync.run<IBlacklistItem>(api, HttpMethods.put, model);
    }

    async deleteBlacklistItem(id: string) {
        const api = `${this.base}/blacklist/${id}`;

        return await ApiAsync.run<IBlacklistItem>(api, HttpMethods.delete, id);
    }
};



export default EpmManager;