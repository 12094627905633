import { IStructures } from "../model/app/IIdName";
import { IAmRole } from "../model/data/IAmRole";
import {
	IAmAccount,
	IAmUser,
	IAmUserSub,
	IUserFilterModel,
	IAmUserSubRequest,
	IAmUserChangePasswordRequest,
	IBooking,
	IAmProfilePatchDTORequest,
	IAmGroupMembership,
	IAmGroup
} from "../screens/am/Models";
import ApiAsync from "./ApiAsync";
import { HttpMethods, WebSites } from "./Defaults";
import Global from "./Global";
import StorageHelper from "./StorageHelper";

export class AccountManager {
	private base: string;
	private logBase: string;
	private gatewayBase: string;

	constructor() {
		this.base = StorageHelper.getWebsites().filter(
			(x) => x.key == WebSites.am
		)[0].text;
		this.logBase = StorageHelper.getWebsites().filter(
			(x) => x.key == WebSites.log
		)[0].text;
		this.gatewayBase = StorageHelper.getWebsites().filter(
			(x) => x.key == WebSites.gate
		)[0].text;

		if (this.base.endsWith("/")) {
			this.base = this.base.substring(0, this.base.length - 1);
		}

		if (this.logBase.endsWith("/")) {
			this.logBase = this.logBase.substring(0, this.logBase.length - 1);
		}

		if (!ApiAsync.token) {
			ApiAsync.token = StorageHelper.getUser()!.easyToken;
		}
	}

	async getStructures(): Promise<IStructures | string> {
		const api = `${this.gatewayBase}support/alltypes`;

		try {
			const result = await ApiAsync.run<IStructures>(api, HttpMethods.get);

			return result;
		} catch (error: any) {
			if (error) {
				return error.message;
			}
		}

		return "";
	}

	async getUser(id): Promise<IAmUser> {
		const api = `${this.base}/user/am/${id}`;

		return await ApiAsync.run<IAmUser>(api, HttpMethods.get);
	}

	async getUsersByVoid(voId): Promise<IAmUser[]> {
		const api = `${this.base}/account/${voId}/users`;

		return await ApiAsync.run<IAmUser[]>(api, HttpMethods.get);
	}

	async getAccount(id): Promise<IAmAccount> {
		const api = `${this.base}/account/${id}?options=all`;

		return await ApiAsync.run<IAmAccount>(api, HttpMethods.get);
	}

	async getRoles(): Promise<IAmRole[]> {
		const api = `${this.logBase}/portal/getroles`;

		return await ApiAsync.run<IAmRole[]>(api, HttpMethods.get);
	}

	async postRuleIdAndRoleId(model): Promise<IAmRole> {
		const api = `${this.logBase}/portal/createRelations`;

		return await ApiAsync.run<IAmRole>(api, HttpMethods.post, model);
	}

	async getPortalRelationTable(): Promise<IAmRole[]> {
		const api = `${this.logBase}/portal/getRelations`;

		return await ApiAsync.run<IAmRole[]>(api, HttpMethods.get);
	}

	async patchRoles(id, roles: string[]): Promise<IAmUser> {
		const api = `${this.base}/user/${id}/roles`;

		return await ApiAsync.run<IAmUser>(api, HttpMethods.patch, roles);
	}

	async patchPassword(username, password: string): Promise<IAmUser> {
		const api = `${this.base}/user/${username}/password`;

		return await ApiAsync.run<IAmUser>(api, HttpMethods.patch, {
			username: username,
			password: password,
		});
	}

	async changePassword(
		model: IAmUserChangePasswordRequest
	): Promise<IAmUserChangePasswordRequest> {
		const api = `${this.base}/user/changePassword`;

		return await ApiAsync.run<IAmUserChangePasswordRequest>(
			api,
			HttpMethods.put,
			model
		);
	}

	async updateProfile(
		model: IAmProfilePatchDTORequest
	): Promise<IAmProfilePatchDTORequest> {
		const api = `${this.base}/profile/${model.username}/update`;

		return await ApiAsync.run<IAmProfilePatchDTORequest>(
			api,
			HttpMethods.patch,
			model
		);
	}

	async getProfile(username: string): Promise<IAmUser> {
		const api = `${this.base}/profile/${username}`;

		return await ApiAsync.run<IAmUser>(api, HttpMethods.get, undefined);
	}

	async resetUser(username: string, model: IAmUser): Promise<IAmUser> {
		const api = `${this.base}/user/${username}/reset`;

		return ApiAsync.run<IAmUser>(api, HttpMethods.post, {
			username: username,
			email: model.email,
			mobile: model.mobile,
		});
	}

	async addUser(user: IAmUser): Promise<IAmUser> {
		const api = `${this.base}/user/add`;

		// 2023-03-23 sm => status should be NEW o the new user is forced to change password
		user.statusId = 1;

		return await ApiAsync.run<IAmUser>(api, HttpMethods.post, user);
	}

	async changeMtanAuth(id: string, status: number): Promise<IAmUser> {
		const api = `${this.base}/account/${id}/properties/tanauth/${status}`;

		return await ApiAsync.run<IAmUser>(api, HttpMethods.patch);
	}

	async patchUser(user: IAmUser): Promise<IAmUser> {
		const api = `${this.base}/user/${user.username}/update`;

		return await ApiAsync.run<IAmUser>(api, HttpMethods.patch, user);
	}

	async filterUsers(model: IUserFilterModel): Promise<IAmUser[] | string> {
		const api = `${this.base}user/filter`;

		try {
			const result = ApiAsync.run<IAmUser[]>(api, HttpMethods.post, model);

			return result;
		} catch (error: any) {
			if (error) {
				return error.message;
			}
		}

		return "";
	}

	async auto<T>(url: string, model: any): Promise<T | string> {
		try {
			const api = `${this.base}${url}`;

			const result = ApiAsync.run<T>(api, HttpMethods.post, model);

			return result;
		} catch (error: any) {
			if (error) {
				return error.message;
			}
		}

		return "";
	}

	async patchAccountStatus(
		id: string,
		statusCode: string
	): Promise<IAmAccount> {
		const api = `${this.base}/account/${id}/status/${statusCode}`;

		return await ApiAsync.run<IAmAccount>(api, HttpMethods.patch, undefined);
	}

	async patchProperties(model: IAmAccount): Promise<IBooking> {
		const api = `${this.base}/account/${model.id}/properties`;
		model.properties.bookManualDate = Global.toIsoStringCustom(new Date());
		return await ApiAsync.run<IBooking>(
			api,
			HttpMethods.patch,
			model.properties
		);
	}

	async removeUserSubstitution(id: string) {
		return await ApiAsync.run<IAmUserSubRequest>(
			`${this.base}/user/substitution/remove/${id}`,
			HttpMethods.delete,
			undefined
		);
	}

	async updateUserSubstitution(model: IAmUserSub) {
		let request: IAmUserSubRequest = {
			id: model.id,
			from: model.from.toISOString(),
			to: model.to.toISOString(),
			sourceId: model.sourceId,
			targetId: model.targetId,
		};

		if (model.isNew) {
			return await ApiAsync.run<IAmUserSubRequest>(
				`${this.base}/user/substitution/add`,
				HttpMethods.post,
				request
			);
		} else {
			return await ApiAsync.run<IAmUserSubRequest>(
				`${this.base}/user/substitution/update`,
				HttpMethods.put,
				request
			);
		}
	}


    async removeUserFromGroup(id: number) {
        return await ApiAsync.run(`${this.base}/groupmembership/${id}`, HttpMethods.post, undefined);

    }

    async addUserToGroup(model: any) : Promise<IAmGroupMembership> {
        return await ApiAsync.run(`${this.base}/groupmembership/add`, HttpMethods.post, model);
    }

	async addGroup(model: any) : Promise<IAmGroup> {
        return await ApiAsync.run(`${this.base}/group/add`, HttpMethods.post, model);
    }
};

export default AccountManager;
