import { initializeIcons } from '@uifabric/icons';
import { IRawStyle, mergeStyles, mergeStyleSets } from '@uifabric/merge-styles';
import { Check, Dialog, DialogFooter, DialogType, PrimaryButton } from 'office-ui-fabric-react';
import { Checkbox, Dropdown, Fabric, FontIcon, IDropdownOption, ITextFieldStyles, Label, Link, MessageBar, MessageBarType, Panel, PanelType, Pivot, PivotItem, ProgressIndicator, Spinner, SpinnerSize, TextField } from 'office-ui-fabric-react/lib';
import { DetailsList, DetailsListLayoutMode, IColumn, Selection, SelectionMode } from 'office-ui-fabric-react/lib/DetailsList';
import { Text } from 'office-ui-fabric-react/lib/Text';
import * as React from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import '../../src/App.css';
import { EScreenMode } from '../model/app/Enums';
import { EUserStateAccount, IApiResponse } from '../model/app/IApiResponse';
import { HandlerModel, MoreInfoShop } from '../model/data/HandlerModel';
import { ShopEmployeeModel } from '../model/data/ShopEmployeeModel';
import Loader from '../Support/Loader';
import Api from '../utils/Api';
import Global from '../utils/Global';
import { EmployeeList } from './employeeList';
import { TablePaging } from './paging';
import { Roles } from '../utils/Defaults';







// Initialize icons in case this example uses them
initializeIcons();

// loadTheme({
//     palette: {

//       'neutralLighter': 'red',       // Used for button background
//     }
//   });

{ }

const classNames = mergeStyleSets({
    fileIconHeaderIcon: {
        padding: 0,
        fontSize: '16px'
    },
    fileIconCell: {
        textAlign: 'center',
        selectors: {
            '&:before': {
                content: '.',
                display: 'inline-block',
                verticalAlign: 'middle',
                height: '100%',
                width: '0px',
                visibility: 'hidden'
            }
        }
    },
    fileIconImg: {
        verticalAlign: 'middle',
        maxHeight: '16px',
        maxWidth: '16px'
    },
    controlWrapper: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    exampleToggle: {
        display: 'inline-block',
        marginBottom: '10px',
        marginRight: '30px'
    },
    selectionDetails: {
        marginBottom: '20px'
    }
});
const controlStyles = {
    root: {
        margin: '0 30px 20px 0',
        maxWidth: '300px'
    }
};

export interface Positions {
    Id: number;
    Text: string;
}

export interface Positions {
    Id: number;
    Text: string;
}

export interface UserStatuses {
    Id: number;
    Name: string;
}

const commonStyles: IRawStyle = {
    display: 'inline-block',
    cursor: 'default',
    boxSizing: 'border-box',
    verticalAlign: 'top',
    background: 'none',
    backgroundColor: 'transparent',
    border: 'none',
};
const classNamesnew = mergeStyleSets({
    item: {
        selectors: {
            '&:hover': { background: '#eee' },
        },
    },
    // Overwrites the default style for Button
    check: [commonStyles, { padding: '11px 8px' }],
    cell: [
        commonStyles,
        {
            overflow: 'hidden',
            height: 36,
            padding: 8,
        },
    ],
});

const classNamesRadio = mergeStyleSets({
    item: {
        selectors: {
            '&:hover': { background: '#ffff' },
        },
    },
    // Overwrites the default style for Button
    check: [commonStyles, { padding: '11px 8px' }],
    cell: [
        commonStyles,
        {
            overflow: 'hidden',
            height: 36,
            padding: 8,
        },
    ],
});


const exampleChildClass = mergeStyles({
    display: 'block',
    marginBottom: '10px',

});

const iconClass = mergeStyles({
    fontSize: 25,
    height: 25,
    width: 25,
    color: '#C0C0C0',
    margin: '0 25px',
});

export interface IPagingModel {
    page: number;
    count: number;
}

export interface ITablePagingProps {

    page: number;
    count: number;
}



interface IDetailsListDocumentsExampleState {
    columns: IColumn[];
    // items: IDocument[];
    handlerItems: HandlerModel[];
    allEmployees: ShopEmployeeModel[];
    selectionDetails: string;
    isModalSelection: boolean;
    isCompactMode: boolean;
    announcedMessage?: string;
    mode: EScreenMode,
    changed: boolean,
    selectedEmployeeToEdit: ShopEmployeeModel,
    hidden: boolean,
    disableButtonNameField: boolean,
    lastSelectedPivot: string | undefined,
    spinner: boolean,
    isPanelOpen: boolean,
    isDialogVisible: boolean,
    hintInactive: boolean,
    error: string,
    isOKVisible: boolean,
    pagingModel: IPagingModel;
    errorResponse: boolean;
    representationExists: IRepresentation[] | undefined

}


const dialogContentProps = {
    type: DialogType.normal,
    title: 'Are you sure you want to close the panel?',
};
const dialogModalProps = {
    isBlocking: true,
    styles: { main: { maxWidth: 450 } },
};

export interface IRepresentation {
    StartDate: Date;
    EndDate: Date;
    fullName: string;
}

const narrowTextFieldStyles: Partial<ITextFieldStyles> = { fieldGroup: { height: 30, width: 150 } };

class Handler extends React.Component<{}, IDetailsListDocumentsExampleState> {
    private url: string = "activationdata/";
    private _selection: Selection;
    // private _allItems: IDocument[];
    private _allHandlerItems: HandlerModel[];
    private allEmployees: ShopEmployeeModel[];
    private shopManagers: ShopEmployeeModel[];
    private previewEmployees: ShopEmployeeModel[];
    private selectedEmployeeToEdit: ShopEmployeeModel = { id: '', employee_void: '', void: '', userType: '', status: 0, position: '', email: '', salutation: '', firstName: '', surname: '', mobile: '', email2: '', shopmanager: 0, userStatusId: 0 };
    private allPositions: IDropdownOption[];
    private allUserStatuses: IDropdownOption[];
    private shopVoidToSearch: string = '';
    private tempInfo: MoreInfoShop = new MoreInfoShop();
    private totalItems: number = 0;
    private howManypagToRender: number = 0;
    private currentPage: number = 1;
    private errorResponseMessage: string = '';



    constructor(props: {}) {
        super(props);

        // this._allItems = _generateDocuments();
        this._allHandlerItems = [];
        this._refreshModel = this._refreshModel.bind(this);
        this.allEmployees = [];
        this.shopManagers = [];
        this.previewEmployees = [];
        this.allPositions = [];
        this.allUserStatuses = [];
        this._hidePanel = this._hidePanel.bind(this);
        this.onDataArrival = this.onDataArrival.bind(this);
        this.onDataArrivalForEmployees = this.onDataArrivalForEmployees.bind(this);
        this.onNewSearchRequest = this.onNewSearchRequest.bind(this);
        this._onNo = this._onNo.bind(this);
        this._onYes = this._onYes.bind(this);
        this.showDialog = this.showDialog.bind(this);




        const columns: IColumn[] = [
            {
                key: 'void',
                name: 'VOID',
                ariaLabel: 'VOID',
                fieldName: 'void',
                minWidth: 70,
                isSorted: true,
                isSortedDescending: false,
                sortAscendingAriaLabel: 'Sorted A to Z',
                sortDescendingAriaLabel: 'Sorted Z to A',
                data: 'string',
                maxWidth: 90,
                onColumnClick: this._onColumnClick
            },
            {
                key: 'companyName',
                name: 'Firmenname',
                ariaLabel: 'Firmenname',
                fieldName: 'companyName',
                minWidth: 90,
                isSorted: true,
                isSortedDescending: false,
                sortAscendingAriaLabel: 'Sorted A to Z',
                sortDescendingAriaLabel: 'Sorted Z to A',
                data: 'string',
                maxWidth: 110,
                onColumnClick: this._onColumnClick
            },
            {
                key: 'level',
                name: 'Level',
                fieldName: 'level',
                minWidth: 20,
                maxWidth: 30,
                isRowHeader: true,
                isResizable: true,
                isSorted: true,
                isSortedDescending: false,
                sortAscendingAriaLabel: 'Sorted A to Z',
                sortDescendingAriaLabel: 'Sorted Z to A',
                onColumnClick: this._onColumnClick,
                data: 'string',
                isPadded: true
            },
            {
                key: 'level_1',
                name: 'Level 1 VOID',
                fieldName: 'level_1',
                minWidth: 70,
                maxWidth: 90,
                isResizable: true,
                onColumnClick: this._onColumnClick,
                isPadded: true
            },
            {
                key: 'level_2',
                name: 'Level 2 VOID',
                fieldName: 'level_2',
                minWidth: 70,
                maxWidth: 90,
                isResizable: true,
                isCollapsible: true,
                data: 'string',
                onColumnClick: this._onColumnClick,
                isPadded: true
            },
            {
                key: 'salesRepresentative',
                name: 'Vertriebsbeauftragter',
                fieldName: 'salesRepresentative',
                minWidth: 100,
                maxWidth: 150,
                isResizable: true,
                isCollapsible: true,
                data: 'string',
                onColumnClick: this._onColumnClick,
            },
            {
                key: 'salesManager',
                name: 'Vertriebsleiter',
                fieldName: 'salesManager',
                minWidth: 100,
                maxWidth: 150,
                isResizable: true,
                isCollapsible: true,
                data: 'string',
                onColumnClick: this._onColumnClick,
            },
            {
                key: 'regionIdText',
                name: 'Niederlassung',
                fieldName: 'regionIdText',
                minWidth: 100,
                isSorted: true,
                isSortedDescending: false,
                sortAscendingAriaLabel: 'Sorted A to Z',
                sortDescendingAriaLabel: 'Sorted Z to A',
                maxWidth: 150,
                isResizable: true,
                isCollapsible: true,
                data: 'string',
                onColumnClick: this._onColumnClick,
            },
            {
                key: 'city',
                name: 'Ort',
                fieldName: 'city',
                minWidth: 100,
                isSorted: true,
                isSortedDescending: false,
                sortAscendingAriaLabel: 'Sorted A to Z',
                sortDescendingAriaLabel: 'Sorted Z to A',
                maxWidth: 150,
                isResizable: true,
                isCollapsible: true,
                data: 'string',
                onColumnClick: this._onColumnClick,
            },
            {
                key: 'dealerType',
                name: 'Buchungskanal',
                fieldName: 'dealerType',
                minWidth: 100,
                isSorted: true,
                isSortedDescending: false,
                sortAscendingAriaLabel: 'Sorted A to Z',
                sortDescendingAriaLabel: 'Sorted Z to A',
                maxWidth: 150,
                isResizable: true,
                isCollapsible: true,
                data: 'string',
                onColumnClick: this._onColumnClick,
            }

        ];

        this._selection = new Selection({
            onSelectionChanged: () => {
                let id: string = this._selection.getSelection()[0] === undefined ? '' : (this._selection.getSelection()[0] as HandlerModel).void;

                if (this._selection.getSelectedCount() === 1) {
                    Api.fetch(this.url + 'getEmployeesByVoid/' + id, undefined, "GET", this.onDataArrivalForEmployees);

                    this.setState({
                        spinner: true
                    });
                } else if (this._selection.getSelectedCount() === 0) {
                    this.setState({ selectionDetails: this._getSelectionDetails(), mode: EScreenMode.view })
                }
            }
        });

        this.state = {
            // items: this._allItems,
            handlerItems: this._allHandlerItems,
            allEmployees: this.allEmployees,
            columns: columns,
            selectionDetails: this._getSelectionDetails(),
            isModalSelection: true,
            isCompactMode: false,
            announcedMessage: undefined,
            mode: EScreenMode.loading,
            hidden: true,
            changed: false,
            lastSelectedPivot: undefined,
            hintInactive: false,
            selectedEmployeeToEdit: {
                id: '',
                employee_void: '',
                void: '',
                userType: '',
                status: 0,
                position: '',
                email: '',
                salutation: '',
                firstName: '',
                surname: '',
                mobile: '',
                email2: '',
                shopmanager: 0,
                userStatusId: 0
            },
            disableButtonNameField: true,
            spinner: false,
            isPanelOpen: false,
            isDialogVisible: false,
            error: '',
            isOKVisible: false,
            pagingModel: { page: 1, count: 11000 },
            errorResponse: true,
            representationExists: []

        };

        Api.fetch(this.url + 'getHandlerInformation?' + 'shopVoidorNameModel=' + ' ' + '&PageNumberModel=' + 1 + '&RowsOfPageModel=' + 500 + '&countModel=' + 0, undefined, "GET", this.onDataArrival);
    }

    public render() {
        const { columns, isCompactMode, handlerItems, selectionDetails, isModalSelection, announcedMessage } = this.state;

        let t1 = 'Löschvorgang bestätigen';
        let t2 = 'Sind sie sicher, dass der Mitarbeiter gelöscht werden soll?';

        // let active = 2;
        // let items = [];
        // for (let number = 1; number <= 120; number++) {
        //     items.push(
        //         <Pagination.Item key={number} active={number === active}>
        //             {number}
        //         </Pagination.Item>,
        //     );
        // }

        narrowTextFieldStyles.fieldGroup = { height: 30, width: window.screen.width < 1500 ? 140 : 200 }


        return (

            this.state.mode === EScreenMode.loading ? <ProgressIndicator label="Daten werden geladen" description="Bitte warten Sie" /> :

                this.state.hidden === false ?
                    <>
                        <Dialog
                            hidden={this.state.hidden}
                            onDismiss={this._onNo}
                            dialogContentProps={{
                                type: DialogType.normal,
                                title: t1,
                                subText: t2
                            }}
                            modalProps={{
                                titleAriaId: t1,
                                subtitleAriaId: t2,
                                isBlocking: false,
                                containerClassName: 'ms-dialogMainOverride'
                            }}
                        >
                            <DialogFooter>
                                <Button variant="danger" onClick={this._onYes}>Ja</Button>
                                <Button variant="secondary" onClick={this._onNo}>Abbrechen</Button>
                            </DialogFooter>
                        </Dialog>
                    </> :
                    <>
                        <Fabric>
                            <div className={classNames.controlWrapper}>
                                {this.state.isOKVisible ? <Dialog
                                    hidden={!this.state.isOKVisible}
                                    dialogContentProps={{
                                        type: DialogType.normal,
                                        title: "Erfolgreich gespeichert!",

                                    }}
                                    modalProps={{

                                        isBlocking: true,
                                        containerClassName: 'ms-dialogMainOverride'
                                    }}
                                >
                                    <DialogFooter>
                                        <Button size="sm" block variant="primary" onClick={() => { this.setState({ isOKVisible: false }) }}>OK</Button>
                                    </DialogFooter>
                                </Dialog> : <></>}
                                {this.state.isDialogVisible ? <Dialog
                                    hidden={!this.state.isDialogVisible}
                                    onDismiss={(e) => { this.hideDialog(e) }}
                                    dialogContentProps={{
                                        type: DialogType.normal,
                                        title: "Möchten Sie dieses Fenster wirklich schließen?",

                                    }}
                                    modalProps={{
                                        titleAriaId: t1,

                                        isBlocking: true,
                                        containerClassName: 'ms-dialogMainOverride'
                                    }}
                                >
                                    <DialogFooter>
                                        <Button variant="danger" onClick={() => { this.setState({ isDialogVisible: false, isPanelOpen: false, mode: EScreenMode.view, lastSelectedPivot: "initial" }) }}>Ja</Button>
                                        <Button variant="secondary" onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => { this.hideDialog(e) }}>Abbrechen</Button>
                                    </DialogFooter>
                                </Dialog> : <></>}

                            </div>

                            <div style={{ width: '100%' }}>
                                <div style={{ float: 'left', width: '20%' }}> <TextField
                                    className={exampleChildClass}
                                    errorMessage={this.state.error}
                                    onKeyDown={(e) => { this.onNewSearchRequest(e) }}
                                    placeholder={"Eingabe von mind. 4 Ziffern."}
                                    onChange={this.gatherVoidtext}
                                //styles={narrowTextFieldStyles}
                                /></div>
                                <div style={{ float: 'left', width: '60%' }}><button type="button" style={{ marginLeft: '1%', paddingLeft: '2%', paddingRight: '2%', paddingBottom: '1%', borderRadius: '5px', backgroundColor: '#007bff', color: 'white' }} id="search" onClick={(e: any) => { this.onNewSearchRequest(e) }}>Suche</button></div>
                                <div style={{ float: 'left', width: '20%' }}> {this.state.representationExists == undefined
                                    ? <></> : <div className=""> <MessageBar
                                        messageBarType={MessageBarType.severeWarning}
                                    >
                                        <div>Achtung:</div>
                                        {this.renderRepresentations()}
                                    </MessageBar> </div>}</div>
                                <br style={{ clear: 'left' }} />
                            </div>

                            <div className={classNames.selectionDetails}>{selectionDetails}</div>

                            {isModalSelection ? (
                                this.state.spinner ? <Spinner size={SpinnerSize.large} /> :
                                    <>

                                        <TablePaging model={this.state.pagingModel} parent={this} pagesToRender={5} totalItemsCount={this.totalItems} />

                                        <DetailsList
                                            items={handlerItems}
                                            compact={isCompactMode}
                                            columns={columns}
                                            selectionMode={SelectionMode.single}
                                            getKey={this._getKey}
                                            setKey="single"
                                            layoutMode={DetailsListLayoutMode.justified}
                                            isHeaderVisible={true}
                                            selection={this._selection}
                                            selectionPreservedOnEmptyClick={true}
                                            onItemInvoked={this._onItemInvoked}
                                            enterModalSelectionOnTouch={true}
                                            ariaLabelForSelectionColumn="Toggle selection"
                                            ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                                            checkButtonAriaLabel="Row checkbox"
                                        />
                                    </>

                            ) : (
                                <DetailsList
                                    items={handlerItems}
                                    compact={isCompactMode}
                                    columns={columns}
                                    selectionMode={SelectionMode.single}
                                    getKey={this._getKey}
                                    setKey="none"
                                    layoutMode={DetailsListLayoutMode.justified}
                                    isHeaderVisible={true}
                                    onItemInvoked={this._onItemInvoked}
                                />
                            )}
                            {this.renderEditor()}
                        </Fabric>

                    </>
        );
    }

    renderRepresentations(): JSX.Element[] {
        let ctrl: JSX.Element[] = [];
        let repr = <></>;

        if (this.state.representationExists) {
            this.state.representationExists.forEach(el => {
                repr = <div>Vertetung von {el.fullName} im Zeitraum: {el.StartDate.toString().substring(0, 10)} bis {el.EndDate ? el.EndDate.toString().substring(0, 10) : 'Ende offen'}</div>
                ctrl.push(repr);
            });
        }

        return ctrl;
    }


    onPaginationPageChanged(page: number) {
        let { pagingModel } = this.state;
        this.setState({ spinner: true })
        pagingModel.page = page;
        this.currentPage = page;
        Api.fetch(this.url + 'getHandlerInformation?' + 'shopVoidorNameModel=' + ' ' + '&PageNumberModel=' + page + '&RowsOfPageModel=' + 500 + '&countModel=' + 0, undefined, "GET", this.onDataArrival);
        this.setState({ pagingModel });
    }

    callbackFunctionFromEmployeeList = (childData: ShopEmployeeModel, action: string) => {
        if (action === "edit") {
            this.selectedEmployeeToEdit = childData;
            this.setState({ selectedEmployeeToEdit: childData, mode: EScreenMode.editEmployee })
        } else if (action === "delete") {
            this.selectedEmployeeToEdit = childData;
            this.setState({ selectedEmployeeToEdit: childData, mode: EScreenMode.manageEmployees, hidden: false })
        }
    };

    private gatherVoidtext = (ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, text: any): any => {
        this.shopVoidToSearch = text;
        if (this.shopVoidToSearch === '') {
            this._selection.setAllSelected(false);
            this.setState({ spinner: true, error: '', selectionDetails: this._getSelectionDetails() })
            // Api.fetch(this.url + 'getHandlerInformation/', undefined, "GET", this.onDataArrival);
            Api.fetch(this.url + 'getHandlerInformation?' + 'shopVoidorNameModel=' + ' ' + '&PageNumberModel=' + 1 + '&RowsOfPageModel=' + 500 + '&countModel=' + 0, undefined, "GET", this.onDataArrival);
        }
    }

    private onNewSearchRequest(e?: any) {

        if (e) {
            if ((this.shopVoidToSearch.length < 4 && e.key === 'Enter') || (this.shopVoidToSearch.length < 4 && e.target.id === 'search')) {
                this.setState({ error: "Geben Sie bitte mind. 4 Ziffern ein." })
            }

            if ((this.shopVoidToSearch.length > 3 && e.key === 'Enter') || (this.shopVoidToSearch.length > 3 && e.target.id === 'search')) {
                this.setState({ spinner: true, error: '' })
                // Api.fetch(this.url + 'getHandlerInformation/' + this.shopVoidToSearch, undefined, "GET", this.onDataArrival);
                Api.fetch(this.url + 'getHandlerInformation?' + 'shopVoidorNameModel=' + this.shopVoidToSearch + '&PageNumberModel=' + 1 + '&RowsOfPageModel=' + 500 + '&countModel=' + 0, undefined, "GET", this.onDataArrival);

            }
            // if(this.shopVoidToSearch.length>5 && e.id==='search'){
            //     this.setState({ spinner: true,error:'' })
            //     Api.fetch(this.url + 'getHandlerInformation/' + this.shopVoidToSearch, undefined, "GET", this.onDataArrival);
            // }
        }

    }


    private _onNo() {
        this.setState({ hidden: true });
    };

    private _onYes() {
        let index = this.allEmployees.findIndex(x => x.employee_void === this.selectedEmployeeToEdit.employee_void);
        this.allEmployees.splice(index, 1);
        Api.fetch(this.url + 'deleteEmployee', this.selectedEmployeeToEdit, "POST",
            (api: IApiResponse) => {

                this.setState({ hidden: true, changed: true });
            });
    };



    public componentDidUpdate(previousProps: any, previousState: IDetailsListDocumentsExampleState) {
        if (previousState.isModalSelection !== this.state.isModalSelection && !this.state.isModalSelection) {
            this._selection.setAllSelected(false);
        }
    }

    private _getKey(item: any, index?: number): string {
        return item.key;
    }


    private renderEditor(): JSX.Element {
        let itemVoid: string = this._selection.getSelection()[0] === undefined ? '' : (this._selection.getSelection()[0] as HandlerModel).void;
        let itemStatus: string = this._selection.getSelection()[0] === undefined ? '' : (this._selection.getSelection()[0] as HandlerModel).status;

        // const onRenderNavigationContent: IRenderFunction<IPanelProps> =  (
        //     <>
        //       <Icon iconName={"BackIcon"} />

        //     </>
        // );


        let ctrl = <></>

        ctrl =
            <>

                <Panel isBlocking={false} isLightDismiss={false} onLightDismissClick={this.showDialog} isOpen={this.state.mode === EScreenMode.edit} onDismiss={this.showDialog} type={PanelType.medium} headerText={''}>


                    <Row>
                        <Col md={3} xs={3}>
                            <img src={'http://icon-library.com/images/user-profile-icon/user-profile-icon-4.jpg'}
                                style={{ width: 100, height: 100, borderRadius: 100 / 2 }} />
                        </Col>
                        <Col md={6} xs={6}>
                            <Row>
                                <Col md={6} xs={6}>
                                    <h3>{itemVoid === undefined ? '' : itemVoid}</h3>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6} xs={6}>
                                    {<h4>{itemStatus === undefined ? '' : itemStatus}</h4>}
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                    {
                        this.state.mode === EScreenMode.loading ? <Loader /> :
                            (
                                <>
                                    {this.renderPivotItems()}

                                </>
                            )
                    }
                </Panel>

                <Panel isBlocking={true} isLightDismiss={false} onLightDismissClick={this.showDialog} isOpen={this.state.mode === EScreenMode.manageEmployees} onDismiss={this.showDialog} type={PanelType.medium} >
                    <div onClick={() => { this.setState({ mode: EScreenMode.edit, lastSelectedPivot: "allEmployeesKey" }) }}> <FontIcon iconName="Back" className={iconClass} /> </div>
                    {

                        <>
                            {this.state.hintInactive ? <Dialog
                                hidden={false}
                                onDismiss={() => { this.setState({ hintInactive: false }) }}
                                dialogContentProps={{
                                    type: DialogType.normal,
                                    title: "Der Nutzer muss vom Shop Manager über Easy-Portal gespeichert und aktiviert werden"
                                }}
                                modalProps={{
                                    titleAriaId: '',
                                    subtitleAriaId: '',
                                    isBlocking: false,
                                    containerClassName: 'ms-dialogMainOverride'
                                }}
                            >
                                <DialogFooter>
                                    {/* <Button variant="secondary" onClick={() => { this.setState({ hintInactive: false }) }}>Abbrechen</Button> */}
                                </DialogFooter>
                            </Dialog> : <></>}
                            <Container>
                                <Row>
                                    <br />
                                    <br />
                                    <Col md={12} xs={12}>
                                        <Text variant={'xLarge'} nowrap block>Mitarbeiter verwalten</Text>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={6} xs={6}>
                                        <Button variant="danger" onClick={() => { this.newEmployee() }}>+ Mitarbeiter hinzufügen</Button>
                                    </Col>
                                    {/* <Col md={6} xs={6}>
                                        <Button variant="primary" onClick={() => { this.setState({ mode: EScreenMode.edit, lastSelectedPivot: "allEmployeesKey" }) }}>Zurück</Button>
                                    </Col> */}
                                </Row>
                                <br />
                                <br />
                                <Row>
                                    <Col md={12} xs={12}>
                                        <Text variant={'xLarge'} nowrap block>Mitarbeiter anzeigen</Text>
                                    </Col>
                                </Row>

                                {this.renderEmployeesList()}
                                <div className="spacer-default" />
                                <div className="spacer-default" />
                                {/* <Row>
                                    <Col md={6} xs={6}>
                                        <Button variant="danger" onClick={() => { }}>Schließen</Button>
                                    </Col>
                                </Row> */}

                            </Container>
                        </>
                    }
                </Panel>

                <Panel isBlocking={true} isLightDismiss={true} onLightDismissClick={this.showDialog} isOpen={this.state.mode === EScreenMode.newEmployee || this.state.mode === EScreenMode.editEmployee} onDismiss={this.showDialog} type={PanelType.medium} >

                    <div onClick={() => { this.setState({ mode: EScreenMode.manageEmployees }) }}> <FontIcon iconName="Back" className={iconClass} /> </div>


                    {

                        this.state.spinner ? <Spinner size={SpinnerSize.large} /> :
                            <>
                                <Container>

                                    <Row>
                                        <Col md={6} xs={6}>
                                            <Text variant={"xLarge"}>Mitarbeiterdaten verwalten</Text>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={12} xs={12}>
                                            <Dropdown
                                                id={"salutation"}
                                                placeholder="Select an option"
                                                label="Anrede"
                                                selectedKey={this.selectedEmployeeToEdit.salutation}
                                                options={[
                                                    { key: 'Herr', text: 'Herr' },
                                                    { key: 'Frau', text: 'Frau' },
                                                    { key: 'k.A.', text: 'k.A.' }

                                                ]}
                                                required={true}
                                                onChanged={(option) => {
                                                    this.selectedEmployeeToEdit.salutation = option.key.toString();
                                                    this.setState({ changed: true });
                                                }} />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={12} xs={12}>
                                            <TextField label="Vorname"
                                                required={true}
                                                name="firstName"
                                                maxLength={20}
                                                validateOnLoad={false}
                                                value={this.selectedEmployeeToEdit.firstName}
                                                onChange={this._refreshModel}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={12} xs={12}>
                                            <TextField label="Nachname"
                                                required={true}
                                                maxLength={20}
                                                name="surname"
                                                value={this.selectedEmployeeToEdit.surname}
                                                onChange={this._refreshModel}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={12} xs={12}>
                                            <TextField label="E-Mail"
                                                required={true}

                                                name="email"
                                                value={this.selectedEmployeeToEdit.email}
                                                onChange={this._refreshModel}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={12} xs={12}>
                                            <TextField label="Mobilfunknummer"
                                                required={true}
                                                maxLength={20}
                                                name="mobile"
                                                value={this.selectedEmployeeToEdit.mobile}
                                                onChange={this._refreshModel}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={12} xs={12}>
                                            <Dropdown
                                                placeholder="Select an option"
                                                label="Position"
                                                selectedKey={this.selectedEmployeeToEdit.position}
                                                options={this.allPositions}
                                                required={true}
                                                onChanged={(option) => {
                                                    this.selectedEmployeeToEdit.position = option.key.toString();
                                                    this.setState({ changed: true });
                                                }} />
                                        </Col>
                                    </Row>
                                    {/* <Row>
                                    <Col md={12} xs={12}>
                                        <Dropdown
                                            placeholder="Select an option"
                                            label="Status"
                                            selectedKey={this.selectedEmployeeToEdit.status}
                                            options={this.allUserStatuses}
                                            required={true}
                                            onChanged={(option) => {
                                                this.selectedEmployeeToEdit.status = option.key.toString();
                                                this.setState({ changed: true });
                                            }} />
                                    </Col>
                                </Row> */}

                                    <div className="spacer-default" />
                                    <div className="spacer-default" />

                                    <Row>
                                        <Col md={6} xs={6}>
                                            <Text variant={"xLarge"}>Berechtigung</Text>
                                        </Col>
                                    </Row>



                                    <Row>
                                        <Col md={12} xs={12}>
                                            <Checkbox checked={this.selectedEmployeeToEdit.shopmanager === 1 ? true : false} label='Shopmanager' onChange={() => {
                                                if (this.selectedEmployeeToEdit.shopmanager === 1) {
                                                    this.selectedEmployeeToEdit.shopmanager = 0
                                                } else {
                                                    this.selectedEmployeeToEdit.shopmanager = 1;
                                                }
                                                this.setState({ changed: true });
                                            }} />
                                            <Text variant={"xSmall"}>Mit Erteilung dieses Rechts erhält der Mitarbeiter Shopmanager Rechte. Mit diesen Rechten ist der Mitarbeiter in der Lage, alle Mitarbeiter in seinem Shop selbst zu verwalten.</Text>
                                        </Col>
                                    </Row>
                                    <div className="spacer-default" />
                                    <div className="spacer-default" />
                                    {/* how it used to be for now we dont want it */}
                                    {/* this.selectedEmployeeToEdit.userStatusId == 7 ? */}
                                    {false ? <Row>
                                        <Col md={12} xs={12}>
                                            <h4 style={{ color: 'red', fontSize: 'medium' }}>Der Mitarbeiter hat über 100 verschiedene Bestandskundendaten abgefragt und wurde deswegen gesperrt. Bitte Ja auswählen, um ihn zu entsperren.</h4>
                                            <Checkbox checked={this.selectedEmployeeToEdit.unblockEmployee!} label='Ja, Mitarbeiter entsperren.' onChange={(e) => {
                                                this.selectedEmployeeToEdit.unblockEmployee = e!.currentTarget['checked'];
                                            }} />

                                        </Col>
                                    </Row> : <></>}

                                    <div className="spacer-default" />
                                    <div className="spacer-default" />
                                    <div className="spacer-default" />
                                    <div className="spacer-default" />
                                    <Row>
                                        <Col md={6} xs={6}>
                                            <Button variant="primary" onClick={() => {
                                                this.setState({ spinner: true })
                                                this.selectedEmployeeToEdit.firstName = this.selectedEmployeeToEdit.firstName.trim();
                                                this.selectedEmployeeToEdit.surname = this.selectedEmployeeToEdit.surname.trim();

                                                if (this.selectedEmployeeToEdit.employee_void === '') {
                                                    if (this.selectedEmployeeToEdit.shopmanager === 1) {
                                                        this.selectedEmployeeToEdit.status = EUserStateAccount.Active; //Active
                                                    } else {
                                                        this.selectedEmployeeToEdit.status = EUserStateAccount.Inactive; //Inactive
                                                    }
                                                } else {
                                                    // we are in edit mode if shopmanager is selected just switch the status to active
                                                    if (this.selectedEmployeeToEdit.shopmanager === 1) {
                                                        this.selectedEmployeeToEdit.status = EUserStateAccount.Active;
                                                    }
                                                }


                                                this.selectedEmployeeToEdit.employee_void === '' ?
                                                    Api.fetch(this.url + 'addAnEmployee/', this.selectedEmployeeToEdit, "POST",
                                                        (api: IApiResponse) => {
                                                            // console.log("RESPONSE====>", api)
                                                            // if (api.data === undefined || api.error !== null) {
                                                            //     this.errorResponseMessage = api.error.message;
                                                            //     this.setState({ changed: true, errorResponse: false, spinner: false })
                                                            // } else {

                                                            this.allEmployees = api.data.allEmployees;
                                                            let userIsInactive = this.selectedEmployeeToEdit.status === EUserStateAccount.Inactive;

                                                            this.selectedEmployeeToEdit = { id: '', employee_void: '', void: '', userType: '', status: 0, position: '', email: '', salutation: '', firstName: '', surname: '', mobile: '', email2: '', shopmanager: 0, userStatusId: 0 };

                                                            this.setState({
                                                                changed: true,
                                                                mode: EScreenMode.manageEmployees,
                                                                allEmployees: api.data.allEmployees,
                                                                hintInactive: userIsInactive,
                                                                spinner: false,
                                                                selectionDetails: this._getSelectionDetails(),
                                                            });
                                                        })

                                                    : Api.fetch(this.url + 'updateAnEmployee/', this.selectedEmployeeToEdit, "POST",
                                                        (api: IApiResponse) => {
                                                            // console.log("RESPONSE====>", api)
                                                            // if (api.data === undefined || api.error !== null) {
                                                            //     this.errorResponseMessage = api.error.message;
                                                            //     this.setState({ changed: true, errorResponse: false, spinner: false })
                                                            // } else {

                                                            this.allEmployees = api.data.allEmployees;

                                                            this.selectedEmployeeToEdit = { id: '', employee_void: '', void: '', userType: '', status: 0, position: '', email: '', salutation: '', firstName: '', surname: '', mobile: '', email2: '', shopmanager: 0, userStatusId: 0 };

                                                            this.setState({
                                                                changed: true,
                                                                mode: EScreenMode.manageEmployees,
                                                                allEmployees: api.data.allEmployees,
                                                                spinner: false,
                                                                selectionDetails: this._getSelectionDetails(),
                                                            });
                                                        });
                                            }
                                            }>Änderungen speichern</Button>
                                        </Col>
                                    </Row>
                                </Container>
                            </>
                    }
                </Panel>

            </>

        return ctrl;
    };

    // private _getErrorField = (value: string): string => {
    //     const regex = /^[A-Za-z0-9_-\s&]{1,5}$/gm;

    //     const str = value;
    //     let m;
    //     let err: string = '';


    //     if ((m = regex.exec(str)) !== null) {
    //         err = '';
    //         this.setState({ disableButtonNameField: false });
    //     } else {
    //         err = `Das Feld sollte nur aus Buchstaben, Zahlen, &, Unterstrichen und Bindestrichen bestehen und nicht mehr als 63 Symbole.`;
    //         this.setState({ disableButtonNameField: true });
    //     }


    //     return err;
    // }

    private hideDialog(e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
            this.setState({ isDialogVisible: false, isPanelOpen: true })
        }
    }
    private showDialog() {
        this.setState({ isDialogVisible: true, isPanelOpen: true })
    }


    private _refreshModel(ctrl: any) {
        Global.setValueByPath(this.selectedEmployeeToEdit, ctrl.target.name, ctrl.target.value);
        this.setState({ changed: true });

    };

    private newEmployee() {
        let itemVoid: string = this._selection.getSelection()[0] === undefined ? '' : (this._selection.getSelection()[0] as HandlerModel).void;
        this.selectedEmployeeToEdit = { id: '', employee_void: '', void: itemVoid, userType: '', status: 0, position: '', email: '', salutation: '', firstName: '', surname: '', mobile: '', email2: '', shopmanager: 0, userStatusId: 0 };

        this.setState({ mode: EScreenMode.newEmployee });
    }


    private renderEmployeesList() {
        let ctrl = <></>;

        ctrl = <EmployeeList selectedEmployee={this.callbackFunctionFromEmployeeList} allEmployees={this.allEmployees} />;

        return ctrl;
    }

    private renderPivotItems() {
        let selectedElement: HandlerModel = this._selection.getSelection()[0] as HandlerModel;

        if (selectedElement !== undefined && selectedElement.moreInfo !== '-' && selectedElement.moreInfo !== undefined) {
            //  let one: string = JSON.stringify(selectedElement.moreInfo);
            this.tempInfo = JSON.parse(selectedElement.moreInfo);
        }

        let ctrl = <></>;

        ctrl = (

            <Pivot onLinkClick={(e) => { this.handlePivotItems(e) }} selectedKey={this.state.lastSelectedPivot}>
                {selectedElement === undefined ? <></> :
                    <PivotItem itemKey="initial" headerText="Stammdaten">
                        <Container>

                            <div className="spacer-default" />

                            <Row>
                                <Col xs={6} md={6}>
                                    {this.oneSection("Firmenname", selectedElement.companyName ? selectedElement.companyName : '')}
                                </Col>
                                <Col xs={6} md={6}>
                                    {this.oneSection("Zusatz", selectedElement.companyNameAdditional === '' ? "n/a" : selectedElement.companyNameAdditional)}
                                </Col>
                            </Row>

                            <div className="spacer-default" />
                            <div className="spacer-default" />
                            <div className="spacer-default" />

                            <Row>
                                <Col xs={6} md={6}>
                                    {this.oneSection("VOID", selectedElement.void ? selectedElement.void : '')}
                                </Col>
                                <Col xs={6} md={6}>
                                    {this.oneSection("FN VOID", selectedElement.fnvoid ? selectedElement.fnvoid : '')}
                                </Col>
                            </Row>

                            <div className="spacer-default" />
                            <div className="spacer-default" />
                            <div className="spacer-default" />

                            <Row>
                                <Col xs={6} md={6}>
                                    {this.oneSection("VPKN", selectedElement.vpkn ? selectedElement.vpkn : '')}
                                </Col>
                                <Col xs={6} md={6}>
                                    {this.oneSection("Sales ID", selectedElement.sales_id ? selectedElement.sales_id.toString() : "n/a")}
                                </Col>
                            </Row>

                            <div className="spacer-default" />
                            <div className="spacer-default" />
                            <div className="spacer-default" />

                            <Row>
                                <Col xs={6} md={6}>
                                    <Label>Adressdaten</Label>
                                </Col>
                            </Row>

                            <div className="spacer-default" />
                            <div className="spacer-default" />

                            <Row>
                                <Col xs={6} md={6}>
                                    {this.oneSection("Land", selectedElement.land ? selectedElement.land : '')}
                                </Col>
                                <Col xs={6} md={6}>
                                    {this.oneSection("Bundesland", selectedElement.federalState ? selectedElement.federalState : '')}
                                </Col>
                            </Row>

                            <div className="spacer-default" />
                            <div className="spacer-default" />
                            <div className="spacer-default" />

                            <Row>
                                <Col xs={6} md={6}>
                                    {this.oneSection("Stadt", selectedElement.city ? selectedElement.city : '')}
                                </Col>
                                <Col xs={6} md={6}>
                                    {this.oneSection("Postleizahl", selectedElement.zip ? selectedElement.zip : '')}
                                </Col>
                            </Row>

                            <div className="spacer-default" />
                            <div className="spacer-default" />
                            <div className="spacer-default" />

                            <Row>
                                <Col xs={6} md={6}>
                                    {this.oneSection("Adresse", selectedElement.address ? selectedElement.address : '')}
                                </Col>
                            </Row>

                            <div className="spacer-default" />
                            <div className="spacer-default" />
                            <div className="spacer-default" />

                            <Row>
                                <Col xs={6} md={6}>
                                    {this.oneSection("Längengrad", selectedElement.longitude ? selectedElement.longitude.toString() : '')}
                                </Col>
                                <Col xs={6} md={6}>
                                    {this.oneSection("Breitengrad", selectedElement.latitude ? selectedElement.latitude.toString() : '')}
                                </Col>
                            </Row>

                            <div className="spacer-default" />
                            <div className="spacer-default" />
                            <div className="spacer-default" />

                            <Row>
                                <Col xs={6} md={6}>
                                    <Label>Kontaktdaten</Label>
                                </Col>
                            </Row>

                            <div className="spacer-default" />
                            <div className="spacer-default" />

                            <Row>
                                <Col xs={6} md={6}>
                                    {this.oneSection("Telefon", selectedElement.phone ? selectedElement.phone : '')}
                                </Col>
                                <Col xs={6} md={6}>
                                    {this.oneSection("E-Mail", selectedElement.email ? selectedElement.email : '')}
                                </Col>
                            </Row>

                            <div className="spacer-default" />
                            <div className="spacer-default" />
                            <div className="spacer-default" />

                            <Row>
                                <Col xs={6} md={6}>
                                    {this.oneSection("Fax", selectedElement.fax ? selectedElement.fax : '')}
                                </Col>
                            </Row>

                            {selectedElement.moreInfo === undefined || selectedElement.moreInfo === "-" ? <></> : <>  <Row>
                                <Col xs={6} md={6}>
                                    <Label>{"Zusatzinformationen"}</Label>
                                </Col>
                            </Row>
                                <Row>
                                    <Col xs={2} md={2}>
                                        <Text variant={'small'} nowrap block>{"Name:"}</Text>
                                    </Col>
                                    <Col xs={4} md={4}>
                                        <Text variant={'small'} nowrap block>{this.tempInfo.address === undefined || this.tempInfo.address.name == null ? "n/a" : this.tempInfo.address.name}</Text>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={2} md={2}>
                                        <Text variant={'small'} nowrap block>{"Adresse:"}</Text>
                                    </Col>
                                    <Col xs={4} md={4}>
                                        <Text variant={'small'} nowrap block>{this.tempInfo.address ? this.tempInfo.address.street + " " + this.tempInfo.address.nr + " " + this.tempInfo.address.zip + " " + this.tempInfo.address.city : "n/a"}</Text>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={2} md={2}>
                                        <Text variant={'small'} nowrap block>{"Telefon:"}</Text>
                                    </Col>
                                    <Col xs={4} md={4}>
                                        <Text variant={'small'} nowrap block>{this.tempInfo.tel !== undefined && this.tempInfo.tel.cc ? this.tempInfo.tel.cc + this.tempInfo.tel.ndc + this.tempInfo.tel.sn : "n/a"}</Text>
                                    </Col>
                                </Row>
                            </>}


                            {/* <Text>{this.tempInfo.tel.cc}</Text> */}
                        </Container>
                    </PivotItem>
                }

                <PivotItem itemKey="allEmployeesKey" headerText="Mitarbeiter">
                    <Container>
                        <Row>
                            <Col md={6} xs={6}>
                                <Label>Shopmanager ({this.shopManagers.length})</Label>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} md={12}>
                                {
                                    this.displayShopManagers()
                                }
                            </Col>
                        </Row>

                        <Row>
                            <Col md={12} xs={12}>
                                <Label>Mitarbeiter ({this.allEmployees.length})</Label>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={6} md={6}>
                                {this.oneSection(this.previewEmployees[0] === undefined ? '' : this.previewEmployees[0].firstName + " " + this.previewEmployees[0].surname, this.previewEmployees[0] === undefined ? '' : this.previewEmployees[0].employee_void)}
                            </Col>
                            <Col xs={6} md={6}>
                                {this.oneSection(this.previewEmployees[1] === undefined ? '' : this.previewEmployees[1].firstName + " " + this.previewEmployees[1].surname, this.previewEmployees[1] === undefined ? '' : this.previewEmployees[1].employee_void)}
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={6} md={6}>
                                {this.oneSection(this.previewEmployees[2] === undefined ? '' : this.previewEmployees[2].firstName + " " + this.previewEmployees[2].surname, this.previewEmployees[2] === undefined ? '' : this.previewEmployees[2].employee_void)}
                            </Col>
                            <Col xs={6} md={6}>
                                {this.oneSection(this.previewEmployees[3] === undefined ? '' : this.previewEmployees[3].firstName + " " + this.previewEmployees[3].surname, this.previewEmployees[3] === undefined ? '' : this.previewEmployees[3].employee_void)}
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={6} md={6}>
                                <Link onClick={(e) => { this._manageEmployeesClicked(e) }} >Mitarbeiter verwalten</Link>
                            </Col>
                        </Row>
                    </Container>
                </PivotItem>

                {selectedElement === undefined ? <></> :
                    <PivotItem itemKey="structure" headerText="Struktur">
                        <Container>
                            <div className="spacer-default" />
                            <Row>
                                <Col xs={6} md={6}>
                                    {this.oneSection("Key Account Manager", selectedElement.key_account_manager)}
                                </Col>
                                <Col xs={6} md={6}>
                                    {this.oneSection("Vertriebsleiter", selectedElement.salesManager)}
                                </Col>
                            </Row>
                            <div className="spacer-default" />
                            <div className="spacer-default" />
                            <div className="spacer-default" />
                            <Row>
                                <Col xs={6} md={6}>
                                    {this.oneSection("Vertriebsbeauftragter", selectedElement.salesRepresentative)}
                                </Col>
                                <Col xs={6} md={6}>
                                    {this.oneSection("Niederlassung", selectedElement.regionIdText.toString())}
                                </Col>
                            </Row>
                            <div className="spacer-default" />
                            <div className="spacer-default" />
                            <div className="spacer-default" />
                            <Row>
                                <Col xs={6} md={6}>
                                    <Text variant={'large'} nowrap block>Händlerstruktur</Text>
                                </Col>
                            </Row>
                            <div className="spacer-default" />
                            <div className="spacer-default" />
                            <div className="spacer-default" />
                            {selectedElement.level_1 === "" || selectedElement.level_1 === "-" ? <></> : <Row>
                                <Col xs={6} md={6}>
                                    <Text variant={'large'} nowrap block>Level 1</Text>
                                </Col>
                                <Col xs={6} md={6}>
                                    {this.oneSection(selectedElement.level_1, selectedElement.level_1_dealername)}
                                </Col>
                            </Row>}
                            <div className="spacer-default" />
                            <div className="spacer-default" />
                            <div className="spacer-default" />
                            {selectedElement.level_2 === "" || selectedElement.level_2 === "-" ? <></> : <Row>
                                <Col xs={6} md={6}>
                                    <Text variant={'large'} nowrap block>Level 2</Text>
                                </Col>
                                <Col xs={6} md={6}>
                                    {this.oneSection(selectedElement.level_2, selectedElement.level_2_dealername)}
                                </Col>
                            </Row>}
                            <div className="spacer-default" />
                            <div className="spacer-default" />
                            <div className="spacer-default" />
                            {selectedElement.level_3 === "" || selectedElement.level_3 === "-" ? <></> : <Row>
                                <Col xs={6} md={6}>
                                    <Text variant={'large'} nowrap block>Level 3</Text>
                                </Col>
                                <Col xs={6} md={6}>
                                    {this.oneSection(selectedElement.level_3, selectedElement.level_3_dealername)}
                                </Col>
                            </Row>}
                        </Container>
                    </PivotItem>
                }

                {
                    selectedElement === undefined ? <></> : <PivotItem itemKey="availBrands" headerText="Brandübersicht">
                        <Container>
                            <div className="spacer-default" />
                            <Row>
                                <Col md={6} xs={6}>
                                    <Text variant={'xLarge'} >Buchungsrechte für:</Text>
                                </Col>
                            </Row>
                            <div className="spacer-default" />
                            <div className="spacer-default" />

                            <div className={classNamesRadio.item} data-is-focusable={true} data-selection-index={0}>
                                <div className={classNamesRadio.check} key={"bookVF"} id={"bookVF"} onClick={(e) => { this.addRemoveBrandForAccount(e, selectedElement) }} data-is-focusable={true} data-selection-toggle={true}>
                                    <Check checked={selectedElement.bookVF} />
                                </div>
                                <span className={classNamesRadio.cell}>{"Vodafone Mobilfunk"}</span>
                            </div>
                            <div className={classNamesRadio.item} id={"bookOTELO"} onClick={(e) => { this.addRemoveBrandForAccount(e, selectedElement) }} data-is-focusable={true} data-selection-index={1}>
                                <div className={classNamesRadio.check} data-is-focusable={true} data-selection-toggle={true}>
                                    <Check checked={selectedElement.bookOTELO} />
                                </div>
                                <span className={classNamesRadio.cell}>{"OTELO Mobilfunk"}</span>
                            </div>
                            <div className={classNamesRadio.item} id={"bookDSL"} onClick={(e) => { this.addRemoveBrandForAccount(e, selectedElement) }} data-is-focusable={true} data-selection-index={2}>
                                <div className={classNamesRadio.check} data-is-focusable={true} data-selection-toggle={true}>
                                    <Check checked={selectedElement.bookDSL} />
                                </div>
                                <span className={classNamesRadio.cell}>{"Vodafone DSL"}</span>
                            </div>
                            <div className={classNamesRadio.item} id={"bookKABEL"} onClick={(e) => { this.addRemoveBrandForAccount(e, selectedElement) }} data-is-focusable={true} data-selection-index={3}>
                                <div className={classNamesRadio.check} data-is-focusable={true} data-selection-toggle={true}>
                                    <Check checked={selectedElement.bookKABEL} />
                                </div>
                                <span className={classNamesRadio.cell}>{"Vodafone Kabel KD + TV"}</span>
                            </div>
                            <div className={classNamesRadio.item} id={"bookUNITY"} onClick={(e) => { this.addRemoveBrandForAccount(e, selectedElement) }} data-is-focusable={true} data-selection-index={4}>
                                <div className={classNamesRadio.check} data-is-focusable={true} data-selection-toggle={true}>
                                    <Check checked={selectedElement.bookUNITY} />
                                </div>
                                <span className={classNamesRadio.cell}>{"Vodafone Kabel UM"}</span>
                            </div>
                            <div className={classNamesRadio.item} id={"bookCallYa"} onClick={(e) => { this.addRemoveBrandForAccount(e, selectedElement) }} data-is-focusable={true} data-selection-index={4}>
                                <div className={classNamesRadio.check} data-is-focusable={true} data-selection-toggle={true}>
                                    <Check checked={selectedElement.bookCallYa} />
                                </div>
                                <span className={classNamesRadio.cell}>{"Vodafone Callya"}</span>
                            </div>
                            <div className={classNamesRadio.item} id={"bookVVLHardware"} onClick={(e) => { this.addRemoveBrandForAccount(e, selectedElement) }} data-is-focusable={true} data-selection-index={5}>
                                <div className={classNamesRadio.check} data-is-focusable={true} data-selection-toggle={true}>
                                    <Check checked={selectedElement.bookVVLHardware} />
                                </div>
                                <span className={classNamesRadio.cell}>{"VVL mit Vodafone Hardware"}</span>
                            </div>
                            {/* 
                            <Row>
                                <Col md={12} xs={12}>
                                    <Text variant={'mediumPlus'} >Mit Erteilung der jeweiligen Brandrechte, erhält der Händler diese auch. So wird verwaltet, welcher Händler, Zugriff auf die jeweiligen Brands hat.</Text>
                                </Col>
                            </Row> */}
                            <div className="spacer-default" />
                            <div className="spacer-default" />
                            {/* <Row>
                                <Col md={1} xs={1}>
                                    <Button variant="primary" onClick={() => { this.setState({ mode: EScreenMode.edit, isOKVisible: true }) }}>Speichern</Button>
                                </Col>
                            </Row> */}
                        </Container>
                    </PivotItem>
                }
            </Pivot>

        )

        return ctrl;
    }

    private handlePivotItems(e?: any) {
        if (e) {
            this.setState({ lastSelectedPivot: e.props.itemKey })
        }

    }

    private addRemoveBrandForAccount(ev: any, selectedElement: HandlerModel) {
        //Global.setValueByPath(selectedElement, ev.target.name, ev.target.checked);

        //disabled if user are not allowed to change brands
        if (Global.inRole(Roles.VBBA.toString())) {
            switch (ev.currentTarget.id) {
                case "bookVF": {
                    if (selectedElement.bookVF) {
                        selectedElement.bookVF = false;
                    } else {
                        selectedElement.bookVF = true;
                    }
                    break;
                }
                case "bookOTELO": {
                    if (selectedElement.bookOTELO) {
                        selectedElement.bookOTELO = false;
                    } else {
                        selectedElement.bookOTELO = true;
                    }
                    break;
                }
                case "bookDSL": {
                    if (selectedElement.bookDSL) {
                        selectedElement.bookDSL = false;
                    } else {
                        selectedElement.bookDSL = true;
                    }
                    break;
                }
                case "bookKABEL": {
                    if (selectedElement.bookKABEL) {
                        selectedElement.bookKABEL = false;
                    } else {
                        selectedElement.bookKABEL = true;
                    }
                    break;
                }
                case "bookUNITY": {
                    if (selectedElement.bookUNITY) {
                        selectedElement.bookUNITY = false;
                    } else {
                        selectedElement.bookUNITY = true;
                    }
                    break;
                }
                case "bookCallYa": {
                    if (selectedElement.bookCallYa) {
                        selectedElement.bookCallYa = false;
                    } else {
                        selectedElement.bookCallYa = true;
                    }
                    break;
                }
                case "bookVVLHardware": {
                    if (selectedElement.bookVVLHardware) {
                        selectedElement.bookVVLHardware = false;
                    } else {
                        selectedElement.bookVVLHardware = true;
                    }
                    break;
                }
            }

            // if (ev.currentTarget.id == 'bookCallYa' || ev.currentTarget.id == 'bookVVLHardware')
            Api.fetch(this.url + 'addRemoveBrandAvailForAccount', selectedElement, "POST",
                (api: IApiResponse) => {
                    this.setState({ changed: true });
                });
        }
    }

    private displayShopManagers() {
        let elements: JSX.Element[] = [];
        this.shopManagers.forEach(manager => {
            elements.push(this.oneSection(manager.firstName + " " + manager.surname, manager.employee_void));
        })

        let allManagers: JSX.Element = <>
            <Row>
                <Col md={6} xs={6}>
                    {elements[0] === undefined ? <></> : elements[0]}
                </Col>
                <Col md={6} xs={6}>
                    {elements[1] === undefined ? <></> : elements[1]}
                </Col>
            </Row >
            <Row>
                <Col md={6} xs={6}>
                    {elements[2] === undefined ? <></> : elements[2]}
                </Col>
                <Col md={6} xs={6}>
                    {elements[3] === undefined ? <></> : elements[3]}
                </Col>
            </Row >
        </>

        return (<>{allManagers}</>)

    }

    private oneSection(title: string, value: string) {
        let ctrl = <></>;

        ctrl = (
            <>
                <Row>
                    <Col md={12} xs={12}>
                        <Label>{title}</Label>
                    </Col>
                </Row>
                <Row>
                    <Col md={12} xs={12}>
                        <Text variant={'small'} nowrap block>{value}</Text>
                    </Col>
                </Row>
            </>
        );
        return ctrl;
    }


    private _manageEmployeesClicked(e: any) {

        this.setState({ mode: EScreenMode.manageEmployees });
    }

    //  private _onChangeModalSelection = (ev: React.MouseEvent<HTMLElement>, checked: any) => {
    //    this.setState({ isModalSelection: checked });
    //  };



    private _onItemInvoked(item: any): void {

    }

    private _getSelectionDetails(): string {

        const selectionCount = this._selection.getSelectedCount();
        var dealerCount = 0;
        if (this.state && this.state.handlerItems) {
            dealerCount = this.totalItems;
        }


        switch (selectionCount) {
            case 0:
                return `${dealerCount} Händler insgesamt`;
            case 1:
                return '1 Händler ausgewählt: ' + (this._selection.getSelection()[0] as HandlerModel).void;
            default:
                return `${selectionCount} items selected`;
        }
    }

    private _onColumnClick = (ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
        const { columns, handlerItems } = this.state;
        const newColumns: IColumn[] = columns.slice();
        const currColumn: IColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
        newColumns.forEach((newCol: IColumn) => {
            if (newCol === currColumn) {
                currColumn.isSortedDescending = !currColumn.isSortedDescending;
                currColumn.isSorted = true;
                this.setState({
                    announcedMessage: `${currColumn.name} is sorted ${currColumn.isSortedDescending ? 'descending' : 'ascending'}`
                });
            } else {
                newCol.isSorted = false;
                newCol.isSortedDescending = true;
            }
        });
        const newItems = _copyAndSort(handlerItems, currColumn.fieldName!, currColumn.isSortedDescending);

        //  if (this._selection.getSelectedCount() === 1 && this.state.mode===EScreenMode.edit) {
        //     this.setState({ selectionDetails: this._getSelectionDetails(),columns:newColumns,handlerItems:newItems })
        // }else{
        this._selection.setAllSelected(false);

        this.setState({
            columns: newColumns,
            handlerItems: newItems,
            mode: EScreenMode.view,
            lastSelectedPivot: 'initial',
            selectionDetails: this._getSelectionDetails()
        });

    };

    private _hidePanel() {

        this.setState({ mode: EScreenMode.view, lastSelectedPivot: "initial" });

    };


    private onDataArrival(api: IApiResponse) {

        if (api.data !== undefined) {

            this._allHandlerItems = api.data.items;
            this.totalItems = Number(api.data.countModel);

            this.howManypagToRender = Math.ceil(api.data.countModel / 500);

            this.setState({
                mode: EScreenMode.view,
                handlerItems: api.data.items,
                representationExists: api.data.hasOwnProperty('representationInfo') && api.data.representationInfo.length > 0 ?
                    api.data.representationInfo : undefined,
                spinner: false,
                selectionDetails: `${this.totalItems} Händler insgesamt`,
                pagingModel: { page: this.currentPage, count: this.totalItems }
            });

        }

    }

    private onDataArrivalForEmployees(api: IApiResponse) {
        if (api.data !== undefined) {
            this.allEmployees = api.data.allEmployees;
            this.shopManagers = [];
            this.previewEmployees = [];


            this.allEmployees.forEach(empl => {
                if (empl.shopmanager === 1) {
                    this.shopManagers.push(empl);
                } else {
                    if (this.previewEmployees.length < 4) {
                        this.previewEmployees.push(empl);
                    }
                }
            });
            this.allPositions = []
            // this.allUserStatuses = [];
            for (let position of api.data.allPositions) {

                this.allPositions.push({ key: position.Text, text: position.Text });
            }

            // for (let status of api.data.userStatuses) {
            //     if (status.Name === "Active" || status.Name === "Inactive")
            //         this.allUserStatuses.push({ key: status.Name, text: status.Name });

            // }



            this.setState({
                mode: EScreenMode.edit,
                allEmployees: api.data,
                selectionDetails: this._getSelectionDetails(),
                spinner: false

            });

        }
    }
}

function _copyAndSort<T>(items: T[], columnKey: string, isSortedDescending?: boolean): T[] {
    const key = columnKey as keyof T;
    return items.slice(0).sort((a: T, b: T) => ((isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1));
}




export default Handler;