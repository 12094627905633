import { IBookingOrder } from "../../model/data/IBookingOrder";
import ApiAsync from "../../utils/ApiAsync";
import { BrandKeys, HttpMethods, Roles, WebSites } from "../../utils/Defaults";
import Global from "../../utils/Global";
import StorageHelper from "../../utils/StorageHelper";

export class BookingManager {
    private base: string;
    private baseDs: string;

    constructor() {
        this.base = StorageHelper.getWebsites().filter(x => x.key == WebSites.gate)[0].text;
        this.baseDs = StorageHelper.getWebsites().filter(x => x.key == WebSites.ds)[0].text;

        if (this.base.endsWith('/')) {
            this.base = this.base.substring(0, this.base.length - 1)
        }

        if (!ApiAsync.token) {
            ApiAsync.token = StorageHelper.getUser()!.easyToken;
        };
    }

    async getOrder(id: string): Promise<IBookingOrder | undefined> {
        const api = `${this.base}/booking/order/${id}`;

        try {
            const result = await ApiAsync.run<IBookingOrder>(api, HttpMethods.get);

            return result;
        } catch (error: any) {
            if (error) {
                return error.message
            }
        };
    }

    async archiveConfirmation(id: string, brand: string): Promise<any | undefined> {
        let backup;
        if (!Global.inAnyRole([Roles.PortalAdministrator])) {
            return
        }
        if (brand === BrandKeys.otelo ||
            brand === BrandKeys.unity ||
            brand === BrandKeys.cable ||
            brand === BrandKeys.kabel ||
            brand === BrandKeys.tivano) {
            backup = 5
        }
        else if (brand === BrandKeys.dsl ||
            brand === BrandKeys.vodafone ||
            brand === BrandKeys.vodafoneBusiness) {
            backup = 7
        }
        const api = `${this.baseDs}signer/confirm/${id}/${backup}`;

        try {
            const result = await ApiAsync.run<any>(api, HttpMethods.put);

            return result;
        } catch (error: any) {
            if (error) {
                return error.message
            }
        };
    }

    
    async downloadZip(id: string): Promise<Blob | Response | undefined> {
        const api = `${this.baseDs}order/zip/${id}`;
        let usr = StorageHelper.getUser();
        try {
            const response = await fetch(api, {
                method: HttpMethods.get,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `${usr?.easyToken}`
                }
            });

            if(!response.ok){
                return response
            }
            const blob = await response.blob();
            return blob;
        } catch (error: any) {
            console.error('Error downloading zip:', error);
            throw error;
        }
    }

    async getLogWebRequest(id: string): Promise<IBookingOrder | undefined> {
        const api = `${this.base}/elastic/get/kibana/${id}`;

        try {
            const result = await ApiAsync.run<IBookingOrder>(api, HttpMethods.get);

            return result;
        } catch (error: any) {
            if (error) {
                return error.message
            }
        };
    }
};

export default BookingManager;