import linq from 'linq';
import { Dropdown, Icon, IDropdownStyles, Panel, PanelType, TextField } from 'office-ui-fabric-react';
import {
    IDocumentCardActivityPerson, IDocumentCardStyles
} from 'office-ui-fabric-react/lib/DocumentCard';
import { ProgressIndicator } from 'office-ui-fabric-react/lib/ProgressIndicator';
import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import '../../src/App.css';
import DSLImage from '../assets/img/blister-dsl.jpg';
import KabelImage from '../assets/img/blister-kabel.jpg';
import OteloImage from '../assets/img/blister-otelo.jpg';
import VodafoneImage from '../assets/img/blister-vf.jpg';
import { EScreenMode } from '../model/app/Enums';
import { IApiResponse } from '../model/app/IApiResponse';
import { Brand } from '../model/data/Brand';
import Api from '../utils/Api';
import AppRouter from '../utils/AppRouter';
import { StorageKeys } from '../utils/Defaults';
import Global from '../utils/Global';
import StorageHelper from '../utils/StorageHelper';





export interface IBrandScreenState {
    brands?: Brand[],
    selectedBrandKey?: any,
    mode: EScreenMode,
    selectedBrandId?: any,
    selectedBrandIdForSUB?: any,
    changed: boolean,
    dropDownId: string
};


export class BrandScreen extends React.Component<{}, IBrandScreenState> {
    private url: string = "activationdata/";
    private currentBrand: Brand;
    private businessCase: string = '';
   ;


    private people: IDocumentCardActivityPerson[] = [
        { name: 'Jens Seidel', profileImageSrc: "C:\Users\VladislavG\Desktop\easy3.UIfabric.github\easy3.boff\src\assets\img\jens.jpg" },
    ];

    constructor(props: any) {
        super(props);

        this.onDataArrival = this.onDataArrival.bind(this);
        this._openEditor = this._openEditor.bind(this);
        this._saveBrand = this._saveBrand.bind(this);
        this._refreshModel = this._refreshModel.bind(this);
        this._hidePanel = this._hidePanel.bind(this);
        this.onBrandClick = this.onBrandClick.bind(this);

        this.currentBrand = new Brand();

        this.state = {
            brands: undefined,
            mode: EScreenMode.loading,
            changed: false,
            dropDownId: ''

        };


        //1. name controller, 2. method's name, 3. (id-might be undefinied)  backEnd->boAreaRegistration.cs for more info
        Api.fetch(this.url + 'brands', undefined, "GET", this.onDataArrival);
    };

    public render() {
        return (
            <div>
                {this.state.mode === EScreenMode.loading ? <ProgressIndicator label="Daten werden geladen" description="Bitte warten Sie" /> : this.renderData()}
            </div>);
    };

    private renderData(): JSX.Element {
        let ctrl = (<></>);
        let cfg = Global.getConfiguration();

        if (this.state.brands) {
            ctrl = (
                <>
                    {/* <PrimaryButton text="Export Json" onClick={() => { window.open(cfg.apiUrl + 'activationdata/exportBrands', "_blank") }} /> */}
                    <Row>
                        <Col xs={3} md={3}>
                            <Button variant="danger" onClick={() => { window.open(cfg.apiUrl + 'activationdata/exportBrands', "_blank") }}>Export Brands</Button>
                        </Col>
                        <Col xs={3} md={3}>
                            <Button variant="danger" onClick={() => { window.open(cfg.apiUrl + 'activationdata/exportErrors', "_blank") }}>Export Errors</Button>
                        </Col>
                        <Col xs={3} md={3}>
                            <Button variant="danger" onClick={() => { window.open(cfg.apiUrl + 'activationdata/exportGigacombi', "_blank") }}>Export GigaKombi</Button>
                        </Col>
                    </Row>


                    <div className="spacer-default" />
                    <Container fluid>
                        <Row>
                            {this.state.brands.map((i, idx) => (
                                <Col md={'auto'} xs={'auto'} key={"col-" + idx} >
                                    {this.renderBrand(i)}
                                </Col>
                            ))}
                        </Row>
                    </Container>

                    {this.renderEditor()}
                </>
            );
        }


        return ctrl;
    };

    private renderEditor(): JSX.Element {
        let ctrl = <></>

        ctrl =
            <Panel isBlocking={false} isOpen={this.state.mode === EScreenMode.edit} onDismiss={this._hidePanel} type={PanelType.medium} headerText={this.currentBrand.name}>
                <div>
                    <TextField label="name" name="name" required value={this.currentBrand.name} onChange={this._refreshModel} />
                    <TextField label="Key" name="Key" required value={this.currentBrand.Key} onChange={this._refreshModel} />
                    {/* <TextField label="Sort" name="Sort" required value={this.currentBrand.Sort.toString()} onChange={this._refreshModel} /> */}
                </div>
                <br />
                {/* <PrimaryButton text="Speichern" onClick={this._saveBrand} />
                <DefaultButton text="Abbrechen" onClick={this._hidePanel} /> */}
                <Button variant="primary" className="sub-screen-edit" onClick={this._saveBrand} >Speichern</Button>
                <Button variant="secondary" onClick={this._hidePanel}>Abbrechen</Button>
            </Panel>

        return ctrl;
    };

    private _hidePanel() {
        this.setState({ mode: EScreenMode.view });
    };

    private _refreshModel(ctrl: any) {
        Global.setValueByPath(this.currentBrand, ctrl.target.name, ctrl.target.value);
        this.setState({ changed: true });
    };

    private _saveBrand() {
        Api.fetch(this.url + 'saveBrand', this.currentBrand, "POST",
            (api: IApiResponse) => {
                this.setState({
                    mode: EScreenMode.view,
                    brands: api.data
                });
            });
    };

    private _openEditor(dc: any) {
        let id = dc.currentTarget.id.replace("dc-", "");
        if (this.state.brands) {
            this.currentBrand = linq.from<Brand>(this.state.brands)
                .where(x => x.id === Number(id))
                .firstOrDefault();

            this.setState({ mode: EScreenMode.edit });
        }
    };



    private renderBrand(brand: Brand): JSX.Element {
        let id: string = 'dc-' + brand.id;
        var listImages = [VodafoneImage, OteloImage, KabelImage, DSLImage];

        // let ctrl = 
        // (
        // <DocumentCard id={id} key={brand.id} styles={this.cardStyles} onClick={this._openEditor}>
        //      <DocumentCardImage height={150} imageFit={ImageFit.contain} imageSrc={''}   />  
        //     <DocumentCardDetails>
        //     <DocumentCardTitle title={brand.name} shouldTruncate />
        //     </DocumentCardDetails>
        //     <DocumentCardActivity activity="Modified March 13, 2018" people={this.people}>         
        //     </DocumentCardActivity>
        //         <Icon className="dcicon" iconName="JoinOnlineMeeting" title="Produkte" onClick={(e) => this.onBrandClick(e, brand.Key, 'products')}/>
        //         <Icon className="dcicon" iconName="EngineeringGroup" title="Dienste" onClick={(e) => this.onBrandClick(e, brand.Key, 'services')}/>
        //         <Icon className="dcicon" iconName="Puzzle" title="Substufen" onClick={(e) => this.onBrandClick(e, brand.Key, 'subs')}/>       
        // </DocumentCard>
        // );   
        // return ctrl;

       


        let ctr = <></>;

        ctr = (
            <div className="outter-lining hover" id={id} key={brand.id} onClick={this._openEditor}>

                <img src={listImages[brand.id - 1]} alt="vodafone image" />

                <div className="right-info">
                    <h2 className="product-name">{brand.name}</h2>
                    <ul className="product-info">
                        {/* <li>Products: {brand.brandProps.productsCount}</li>
                        <li>Subs:   {brand.brandProps.subsCount}</li>
                        <li>Services:   {brand.brandProps.servicesCount}</li>
                        <li>PS:   {brand.brandProps.productSubsCount}</li>
                        <li>PSS:   {brand.brandProps.productSubsServicesCount}</li>
                        <li>Excludes:   {brand.brandProps.serviceExcludesCount}</li> */}
                    </ul>
                </div>
                <div className="bottom-text">
                    <div className="bottom-text-inner-left">
                        <p className="changed-by-text"></p>

                    </div>
                    <div className="bottom-text-inner-right">
                        <ul className="changed-by-info">
                            <li>           </li>
                            <li>               </li>
                        </ul>
                    </div>
                </div>

                <div className="ms-Grid">
                    <div className="ms-Grid-row" style={{ float: 'left', marginRight: '30px' }}>

                        {/* <div id="pr1" style={{ paddingRight: '25px' }} >
                            <Icon className="dcicon" id="1,3" iconName="Org" title="Product Act&ActPort" onClick={(e) => this.onBrandClick(e, brand.Key, brand.id, 'products')} />
                        </div>

                        <div id="pr2" style={{ paddingRight: '25px' }}>
                            <Icon className="dcicon" id="2,3" iconName="Org" title="Product Port&ActPort" onClick={(e) => this.onBrandClick(e, brand.Key, brand.id, 'products')} />
                        </div>

                        <div id="pr3" style={{ paddingRight: '25px' }}>
                            <Icon className="dcicon" id="3" iconName="Org" title="Produkte ActPort" onClick={(e) => this.onBrandClick(e, brand.Key, brand.id, 'products')} />
                        </div>

                        <div id="pr4" style={{ paddingRight: '25px' }}>
                            <Icon className="dcicon" iconName="Settings" title="Dienste" onClick={(e) => this.onBrandClick(e, brand.Key, brand.id, 'services')} />
                        </div>

                        <div id="pr5" style={{ paddingRight: '25px' }}>
                            <Icon className="dcicon" iconName="Shop" title="Substufen" onClick={(e) => this.onBrandClick(e, brand.Key, brand.id, 'subs')} />
                        </div> */}
                        <div style={{display:'inline-block'}}   id={id} onClick={(e) => { e.stopPropagation(); }} >
                            <Dropdown
                                placeholder="Business case"
                                id={id}
                                
                                options={[
                                    { key: '1', text: 'BNT' },
                                    { key: '2', text: 'VVL' },
                                    { key: '4', text: 'TW' },
                                    { key: '8', text: 'VVLTW' },
                                    { key: '64', text: 'BookService' },
                                    { key: '128', text: 'Credit-Debit-Swap' }
                                ]}
                                selectedKey={id === this.state.dropDownId ? this.currentBrand.businessCaseId : null}
                                required={true}

                                onChanged={(option) => {
                                    this.currentBrand.businessCaseId = option.key.toString();
                                    this.businessCase = option.text;

                                    this.setState({ changed: true, dropDownId: id });
                                }}
                            />
                        </div>

                    </div>
                </div>

            </div>

        )

        return (
            ctr
        )

    };



    private onBrandClick(ev: React.MouseEvent<HTMLElement, MouseEvent>, key: string, id: number, comp: string) {
        ev.stopPropagation();
        if (this.businessCase === '') {
            this.businessCase = "BNT"
        }

        if (comp === 'products') {
            AppRouter.routeTo("Produkte", "/brands/" + key + '/' + this.businessCase + '/' + ev.currentTarget.id + '/' + id + '/' + comp);
        } else {
            AppRouter.routeTo("Produkte", "/brands/" + key + '/' + comp);
        }

        ev.preventDefault();

        return false;
    };

    private onBrandClickForServices(key: string) {
        this.setState({ selectedBrandId: key });
    }

    private onBrandClickForSUB(key: string) {
        this.setState({ selectedBrandIdForSUB: key });
    }



    private onDataArrival(api: IApiResponse) {
        StorageHelper.set(StorageKeys.keyBrands, api.data);
        this.setState({
            mode: EScreenMode.view,
            brands: api.data
        });
    };
};

export default BrandScreen;