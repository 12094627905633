import { locationsAreEqual } from "history";
import IIdName, { IStructures } from "../model/app/IIdName";
import IKeyText from "../model/app/IKeyText";
import IUser from "../model/app/IUser";
import { Brand } from "../model/data/Brand";
import { ProductInfo } from "../model/data/Product";
import { RepresenterUser } from "../model/data/Representative";
import { StorageKeys } from "./Defaults";
import * as linq from "linq";
import { IAmUser } from "../screens/am/Models";

export class StorageHelper {
	public static clear() {
		sessionStorage.clear();
	}

	public static reset() {
		sessionStorage.removeItem(StorageKeys.keyUser);
		sessionStorage.removeItem(StorageKeys.keySupport);
		sessionStorage.removeItem(StorageKeys.keyStructures);

		window.location.href = "/";
	}

	public static set(key: string, model: any) {
		sessionStorage.setItem(key, JSON.stringify(model));
	}

	public static getBrnads(): Brand[] {
		let data = sessionStorage.getItem(StorageKeys.keyBrands);
		let items: Brand[] = [];
		if (data != null) {
			items = JSON.parse(data);
		}

		return items;
	}

	public static getRepresentativesOptions(): RepresenterUser[] {
		let data = sessionStorage.getItem(StorageKeys.representationOptions);
		let items: RepresenterUser[] = [];
		if (data != null) {
			items = JSON.parse(data);
		}

		return items;
	}

	public static getUser(): IUser | undefined {
		let data = sessionStorage.getItem(StorageKeys.keyUser);
		let items: IUser | undefined;
		if (data != null) {
			try {
				items = JSON.parse(data);
			} catch {}
		}

		return items;
	}

	public static getStructures(): IStructures {
		var data = sessionStorage.getItem(StorageKeys.keyStructures);
		return JSON.parse(data!);
	}

	public static getStructureUsers(
		position: number,
		user: string | undefined = undefined
	) {
		var pos = this.getStructure("positions");
		var s = StorageHelper.getStructures();

		var a = linq.from<IAmUser>(s["users"]).join(
			pos,
			(u) => u.positionId,
			(p) => p.key,
			(u, p) => {
				return { u, p };
			}
		);

		if (position > 0) {
			a = a.where((x) => x.u.positionId == position);
		}

		if (user) {
			a = a.where((x) => x.u.username == user);
		}

		return a
			.select((x) => {
				return {
					key: x.u.id,
					text: `${x.u.firstname} ${x.u.surname} (${x.u.username} / ${x.p.text})`,
				};
			})
			.toArray();
	}

	public static getBrandNames(
		all: boolean = false,
		allKey: any = ""
	): IKeyText[] {
		let data = sessionStorage.getItem(StorageKeys.keyBrands);
		let items: Brand[] = [];

		if (data != null) {
			items = JSON.parse(data);
		}

		if (all) {
			const allOption: Brand = {
				id: allKey,
				name: "Alle",
			};

			items = [allOption, ...items];
		}
		return items.map((item) => ({ key: item.id, text: item.name }));
	}

	public static getBrandNamesReturnKeyBrand(
		all: boolean = false,
		allKey: any = ""
	): IKeyText[] {
		let data = sessionStorage.getItem(StorageKeys.keyBrands);
		let items: Brand[] = [];

		if (data != null) {
			items = JSON.parse(data);
		}

		if (all) {
			const allOption: Brand = {
				id: allKey,
				name: "Alle",
				keyBrand: "",
			};

			items = [allOption, ...items];
		}
		return items.map((item) => ({
			key: item.keyBrand ? item.keyBrand : undefined,
			text: item.name,
		}));
	}

	public static getActivationTypes(
		all: boolean = false,
		allKey: any = ""
	): IKeyText[] {
		let data = sessionStorage.getItem(StorageKeys.keyStructures);
		let items: any = [];

		if (data != null) {
			items = JSON.parse(data);
		}

		if (all) {
			const allOption = {
				id: allKey,
				name: "Alle",
			};

			items = [allOption, ...items.activationType];
		}
		return items.map((item) => ({
			key: item.id ? item.id : undefined,
			text: item.name,
		}));
	}

	public static getBusinessCases(
		all: boolean = false,
		allKey: any = ""
	): IKeyText[] {
		let data = sessionStorage.getItem(StorageKeys.keyStructures);
		let items: any = [];

		if (data != null) {
			items = JSON.parse(data);
		}

		if (all) {
			const allOption = {
				id: allKey,
				name: "Alle",
			};

			items = [allOption, ...items.businessCase];
		}
		return items.map((item) => ({
			key: item.id ? item.id : undefined,
			text: item.name,
		}));
	}

	public static getBrandKeys(
		all: boolean = false,
		allKey: any = ""
	): { key: string | undefined; text: string }[] {
		let data = sessionStorage.getItem(StorageKeys.keyBrands);
		let items: Brand[] = [];

		if (data != null) {
			items = JSON.parse(data);
		}

		if (all) {
			const allOption: Brand = {
				id: allKey,
				name: "Alle",
			};

			items = [allOption, ...items];
		}

		return items.map((item) => ({ key: item.keyBrand, text: item.name }));
	}

	public static getContainers(
		all: boolean = false,
		allKey: any = "Alle"
	): IKeyText[] {
		let data: any = sessionStorage.getItem(StorageKeys.monitoringContainers);
		let items: string[] = [];

		if (data != null) {
			items = JSON.parse(data as string);
		}

		if (all) {
			items.unshift(allKey); // Add allKey as the first element
		}

		return items.map((item) => ({
			key: item.toString(),
			text: item.toString(),
		}));
	}

	public static getStructure(
		type: string,
		all: boolean = false,
		allKey: any = undefined
	): IKeyText[] {
		var s = StorageHelper.getStructures();

		var a: IKeyText[] = [];

		// special case - hardcoded "never" changes
		if (type == "statusesAccount") {
			a = [
				{ key: "AK", text: "Aktiv" },
				{ key: "MZ", text: "Mit Zahlung" },
				{ key: "OZ", text: "Ohne Zahlung" },
			];
		} else if (type == "statusesOrder") {
			a = [
				{ key: "0", text: "Wird bearbeitet" },
				{ key: "1", text: "Storniert" },
				{ key: "2", text: "Verschickt" },
				{ key: "3", text: "Geändert" },
				{ key: "4", text: "k.A." },
			];
		} else {
			if (type == "users") {
				var pos = this.getStructure("positions");

				a = linq
					.from<IAmUser>(s[type])
					.join(
						pos,
						(u) => u.positionId,
						(p) => p.key,
						(u, p) => {
							return { u, p };
						}
					)
					.select((x) => {
						return {
							key: x.u.id,
							text: `${x.u.firstname} ${x.u.surname} (${x.u.username} / ${x.p.text})`,
						};
					})
					.toArray();
			} else {
				a = linq
					.from<IIdName>(s[type])
					.select((x) => {
						return { key: x.id, text: x.name };
					})
					.toArray();
			}
		}

		if (all) {
			//v.g. 20230428 longer explanation why in the sp_orderFilter proc
			a.splice(0, 0, {
				key: allKey || (type == "statusesOrder" ? "-1" : "0"),
				text: "Alle",
			});
		}

		return a;
	}

	public static getWebsites(): IKeyText[] {
		let data = sessionStorage.getItem(StorageKeys.keySupport);
		let items: IKeyText[] = [];
		if (data != null) {
			try {
				var all = JSON.parse(data);

				all.url.map((x: { key: any; value: any }) =>
					items.push({ key: x.key, text: x.value })
				);
			} catch {}
		}

		return items;
	}

	public static getProduct(): ProductInfo {
		let data = sessionStorage.getItem(StorageKeys.keyProduct);
		let item: ProductInfo = new ProductInfo();
		if (data != null) {
			item = JSON.parse(data);
		}
		return item;
	}
}

export default StorageHelper;
