import { ActionButton, Checkbox, DefaultButton, Dialog, DialogFooter, DialogType, Dropdown, IContextualMenuItem, Panel, PanelType, Pivot, PivotItem, PrimaryButton, TextField } from "office-ui-fabric-react";
import React from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import TableFilter from "../../../components/table/TableFilter";
import { EScreenMode } from "../../../model/app/Enums";
import LoaderVf, { ELoaderVfType } from "../../../Support/LoaderVf";
import AccountManager from "../../../utils/AccountManager";
import { AmHelper, EUserType, IAmUser } from "../Models";
import IKeyText from "../../../model/app/IKeyText";
import StorageHelper from "../../../utils/StorageHelper";
import { ITableScreen } from "../../../interfaces/ITableScreen";
import { MenuItems, Roles, WebSites } from "../../../utils/Defaults";
import TableWrapper, { ITableWrapperProps, TableConfig } from "../../../components/table/TableWrapper";
import { ETableSource } from "../../../components/controls/Table";
import { EControlType } from "../../../components/table/enums/control-type.enum";
import { from } from 'linq';

export interface IGroupCreatePanelProps {
    onDismiss: any;
};

export interface IGroupCreateState extends ITableScreen {
    model: {
        name: string;
        applicationId: string;
        typeId: number;
    };
    wrongDataDialogHidden: boolean;
};

export class AmGroupCreatePanel extends React.Component<IGroupCreatePanelProps, IGroupCreateState> {
    private am: AccountManager = new AccountManager();

    constructor(props: any) {
        super(props);

        this.state = {
            mode: EScreenMode.view,
            model:{
                name: "",
                applicationId: "",
                typeId: 0
            },
            wrongDataDialogHidden: true
        };
    };

    private onAddGroup = async (): Promise<void> => {
        const response = await this.am.addGroup(this.state.model);

        if(response.error){
            this.setState({ wrongDataDialogHidden: false });
        }else{
            this.props.onDismiss();
        }
    }

    render = () => {
        let ctrl = <></>;

        ctrl = <>
            <Panel isOpen={true} onDismiss={this.onDismiss} onOuterClick={ () => {} } type={PanelType.medium} headerText="Neue Gruppe" isFooterAtBottom={true}>
                <Container>
                    <Row>
                        <TextField label="Name" name="Name" value={this.state.model.name} onChange={(e, v) => this.setState({ model: { ...this.state.model, name: v ? v : "" } })} />
                    </Row>
                    <Row>
                    <TextField label="ApplicationId" name="ApplicationId" value={this.state.model.applicationId} onChange={(e, v) => this.setState({ model: { ...this.state.model, applicationId: v ? v : "" } })} />
                    </Row>
                    <Row>
                    <Dropdown
                            placeholder="Select Type"
                            label="Type"
                            options={[
                                { key: 0, text: "Undefined" },
                                { key: 1, text: "All" },
                                { key: 2, text: "List" }
                            ]}
                            onChange={(e, v, k) => { this.setState({ model: { ...this.state.model, typeId: k ? k : 0 } }) }}
                        />
                    </Row>
                    <Row style={{ marginTop: '10px' }}>
                        <PrimaryButton style={{ marginLeft: '3px' }}  text="Add" onClick={async () => await this.onAddGroup()} />
                    </Row>
                </Container>
            </Panel>

            <Dialog
                hidden={this.state.wrongDataDialogHidden}
                onDismiss={() => this.setState({ wrongDataDialogHidden: true })}
                dialogContentProps={{
                    type: DialogType.largeHeader,
                    title: `Falsche Daten`,
                }}
                modalProps={{
                    isBlocking: true,
                    containerClassName: "ms-dialogMainOverride",
                }}
            >
                <DialogFooter>
                    <Button
                        variant="secondary"
                        onClick={() => this.setState({ wrongDataDialogHidden: true })}
                    >
                        Abbrechen
                    </Button>
                </DialogFooter>
            </Dialog>
        </>;

        return ctrl;
    };

    onDismiss = () => {
        this.props.onDismiss();
    };

};

export default AmGroupCreatePanel;