import React, { useEffect, useState } from "react";
import { IAmPortalAccess } from "../../../model/data/IAmPortalAccess";
import { MenuItems, WebSites } from "../../../utils/Defaults";
import { EScreenMode } from "../../../model/app/Enums";
import TableWrapper, { ITableWrapperProps, TableConfig } from "../../../components/table/TableWrapper";
import { ETableSource } from "../../../components/controls/Table";
import Global from "../../../utils/Global";
import { EControlType } from "../../../components/table/enums/control-type.enum";
import StorageHelper from "../../../utils/StorageHelper";
import { PortalAccessPanel } from "./panels/PortalEditPanel";
import { ContextualMenuItemType, IContextualMenuItem } from "office-ui-fabric-react";
import IKeyText from "../../../model/app/IKeyText";
import { from } from "linq";

export interface PortalAccessScreenState extends IAmPortalAccess {
    mode?: EScreenMode;
}

export const Portal: React.FC<IAmPortalAccess> = () => {

    const [portalState, setPortalState] = useState<PortalAccessScreenState | undefined>({
        id: 0,
        type: 0,
        mode: EScreenMode.view,
        hourFrom: "",
        hourTo: "",
        dateFrom: "",
        dateTo: "",
        voidName: ""
    });

    // const logManager = new LogManager();
    const [current, setCurrent] = useState<IAmPortalAccess | undefined>(undefined);

    const processContextMenu = (item: IAmPortalAccess, e) => {
        if (e.key == MenuItems.edit) {
            const dayOfWeekFrom = new Date(item.dateFrom).toLocaleDateString("en-US", { weekday: "long" });
            const dayOfWeekTo = new Date(item.dateTo).toLocaleDateString("en-US", { weekday: "long" });
            const hourFromExact = new Date(item.hourFrom).getHours();
            const hourToExact = new Date(item.hourTo).getHours();

            setPortalState({
                id: item.id,
                dateFrom: dayOfWeekFrom,
                dateTo: dayOfWeekTo,
                hourFrom: `${hourFromExact}:00`,
                hourTo: `${hourToExact}:00`,
                type: item.type,
                voidName: item.voidName,
                mode: EScreenMode.details
            });
        }
    };

    let cm: IContextualMenuItem[] = [
        {
            key: MenuItems.edit,
            iconProps: { iconName: "View" },
            text: "Sicht",
        },
        { key: "divider_1", itemType: ContextualMenuItemType.Divider },
    ];

    const tc = TableConfig.createInstance({
        url:
            from<IKeyText>(StorageHelper.getWebsites())
                .toArray()
                .filter(x => x.key == WebSites.am)[0].text + 'portal/filter',
        sourceType: ETableSource.dynamic,
        arrayName: "data",
        sort: [],
        columns: [
            { name: "id", label: "Id", sortable: true },
            {
                name: "dateFrom",
                label: "Date(from)",
                sortable: true,
                renderer: (r) => {
                    const dayOfWeek = new Date(r.dateFrom).toLocaleDateString("en-US", { weekday: "long" });
                    return dayOfWeek;
                },
            },
            {
                name: "dateTo",
                label: "Date(to)",
                sortable: true,
                renderer: (r) => {
                    const dayOfWeek = new Date(r.dateTo).toLocaleDateString("en-US", { weekday: "long" });
                    return dayOfWeek;
                },
            },
            {
                name: "hourFrom",
                label: "Hour(from)",
                sortable: true,
                renderer: (r) => {
                    const hour = new Date(r.hourFrom).getHours();
                    return `${hour}:00`;
                },
            },
            {
                name: "hourTo",
                label: "Hour(to)",
                sortable: true,
                renderer: (r) => {
                    const hour = new Date(r.hourTo).getHours();
                    return `${hour}:00`;
                },
            },
        ],
    });

    const handleDismiss = () => {
        setPortalState((prevState) => ({
            ...prevState!,
            mode: EScreenMode.view,
        }));
    };

    let cfg: ITableWrapperProps = {
        id: "rety",
        action: processContextMenu,
        onItemChanged: (u) => {
            setCurrent(u)
        },
        model: { needle: "", paging: TableConfig.initPaging() },
        contextMenu: cm,
        filter: [
            { type: EControlType.text, key: "needle", label: "Suche" },
        ],
        config: tc,
    };

    return (
        <>
            <TableWrapper {...cfg} />
            {portalState?.mode === EScreenMode.details ? (
                <PortalAccessPanel {...portalState} onDismiss={handleDismiss} />
            ) : null}
        </>
    );
};
