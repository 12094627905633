import { Icon, IPersonaSharedProps, IStyleFunctionOrObject, ITeachingBubbleStyleProps, ITeachingBubbleStyles, Link, loadTheme, Persona, PersonaPresence, PersonaSize, TeachingBubble } from 'office-ui-fabric-react';
import * as React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Api from '../../utils/Api';
import AppRouter from '../../utils/AppRouter';
import Global from '../../utils/Global';
import StorageHelper from '../../utils/StorageHelper';
import { EUserType } from '../../screens/am/Models';

interface IUserThumbState {
    showDialog: boolean
}

loadTheme({
    palette: {
        themePrimary: '#0078d4',
        themeLighterAlt: '#eff6fc',
        themeLighter: '#deecf9',
        themeLight: '#c7e0f4',
        themeTertiary: '#71afe5',
        themeSecondary: '#2b88d8',
        themeDarkAlt: '#106ebe',
        themeDark: '#005a9e',
        themeDarker: '#004578',
        neutralLighterAlt: '#f8f8f8',
        neutralLighter: '#f4f4f4',
        neutralLight: '#eaeaea',
        neutralQuaternaryAlt: '#dadada',
        neutralQuaternary: '#d0d0d0',
        neutralTertiaryAlt: '#c8c8c8',
        neutralTertiary: '#c2c2c2',
        neutralSecondary: '#858585',
        neutralPrimaryAlt: '#4b4b4b',
        neutralPrimary: '#333333',
        neutralDark: '#272727',
        black: '#1d1d1d',
        white: '#ffffff'
    }
});



export class UserThumb extends React.Component<any, any | IUserThumbState> {

    constructor(props: any) {
        super(props);
        this.state = {
            showDialog: false
        }
    };

    public render() {

        let u = StorageHelper.getUser();
        
        if (!u) { return <></> }

        const teachingBubbleStyles: IStyleFunctionOrObject<ITeachingBubbleStyleProps, ITeachingBubbleStyles> = {       
            bodyContent:{
                backgroundColor:'#ffeeed',            
            },
            body: {
                backgroundColor: '#ffeeed',             
            }

        }

        let cfg = Global.getConfiguration();
        
        let initialone = u && u.firstname && u.firstname[0] !== undefined ? u.firstname[0] : 'N/';
        let initialtwo = u && u.surname && u.surname[0] !== undefined ? u.surname[0] : 'A';

        let pri = cfg.envUrl + u.pictureUrl;

        let st: any = {
            backgroundImage: 'url(' + pri + ')'
        };

        const examplePersona: IPersonaSharedProps = {
            imageUrl: '',
            imageInitials: initialone + initialtwo + (u.username),
            color: ' #ffffff',
            initialsColor: '#cc0000'
        };

        if (u.userTypeId !== EUserType.dealer) {
            examplePersona.imageInitials = u.username;
        }

        let ctrl = (
            <>
                <div className="user" >
                    <Row>
                        <Col md={3} xs={3}>
                            <Persona
                                {...examplePersona}
                                id="targetButton"
                                size={PersonaSize.size40}
                                presence={PersonaPresence.online}
                                onClick={() => { this.setState({ showDialog: true }) }}
                                hidePersonaDetails={false}
                                imageAlt="Log out"
                            />
                        </Col>
                    </Row>
                </div>

                {this.state.showDialog ? <TeachingBubble
                
                    target="#targetButton"
                    styles={teachingBubbleStyles}
                    onDismiss={() => { this.setState({ showDialog: false }) }}
                
                >
                    <Container>
                        <Row>
                            <Col xs={12} md={12}>
                                {u.username}
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} md={12}>
                            <Icon style={{fontSize:'20px'}} className="dcicon" iconName="EditContact" />
                                <Link onClick={()=>{this.setState({showDialog:false});AppRouter.routeTo(window.location.href,'/editUser')}}>Profil bearbeiten</Link>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} md={12}>
                            <Icon  style={{fontSize:'20px'}} className="dcicon" iconName="ProtectionCenterLogo32" />
                                <Link onClick={()=>{this.setState({showDialog:false});AppRouter.routeTo(window.location.href,'/editPassword')}}>Passwort ändern</Link>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} md={12}>
                            <Icon  style={{fontSize:'20px'}} className="dcicon" iconName="SignOut" />
                                <Link onClick={()=> Api.logout()}>Abmelden</Link>
                            </Col>
                        </Row>
                    </Container>
                </TeachingBubble> : <></>}
            </>
        );

        return ctrl;
    };



};

export default UserThumb;