import * as React from "react";
import { Route, Router, Switch } from "react-router-dom";
import { hist } from "../../App";
import {
	BrandsScreen,
	DashboardScreen,
	DefconScreen,
	DetailsListCustomRows,
	ErrorScreen,
	Handler,
	ProductFamily,
	ProductScreen,
	ProductTreeScreen,
	Representatives,
	ServiceFamilyScreen,
	ServiceScreenGroupedColumns,
	SUBScreen,
	MTanValidation,
} from "../../screens";
import AmAccountScreen from "../../screens/am/Account";
import AmUserScreen from "../../screens/am/User";
import { AmUserSubstitutionScreen } from "../../screens/am/UserSubstitution";
import { EditPassword } from "../../screens/editPassword";
import { EditUser } from "../../screens/editUser";
import HotlineShopScreen from "../../screens/hotline/Shop";
import { ProductSubandServiceScreen } from "../../screens/productSubandServiceScreen";
import { Roles } from "../../utils/Defaults";
import Global from "../../utils/Global";
import StorageHelper from "../../utils/StorageHelper";
import TestScreen from "../../screens/dev/test";
import AdminTanAuthScreen from "../../screens/admin/TanAuthScreen";
import { MonitoringScreen } from "../../screens/admin/MonitoringScreen";
import BookingStatus from "../../screens/query/BookingStatus";
import EpmProductScreen from "../../screens/epm/product/EpmProductScreen";
import { EScreenMode } from "../../model/app/Enums";
import { LogJournalScreen } from "../../screens/log/journal/LogJournalScreen";
import { Portal } from "../../screens/am/portal/Portal";
import { LogMonitoringScreen } from "../../screens/log/monitoring/LogMonitoringScreen";
import { BookingProcessScreen } from "../../screens/booking/BookingProcessScreen";
import OrderHistory from "../../screens/order-history/OrderHistory";
import WhitelistBestandskunden from "../../screens/log/whitelist-bestandskunden/WhitelistBestandskunden";
import EpmBlacklistScreen from "../../screens/epm/blacklist/EpmBlacklistScreen";
import BookingDsArchive from "../../screens/booking/BookingDsArchive";
import AmDealerGroupScreen from "../../screens/am/DealerGroup";
import MessagesScreen from "../../screens/messages/MessagesScreen";
import NoMatch404Page from "../../screens/noMatch404Page";

export interface IAppContainerState {
	collapsed: boolean;
}

export class AppContainer extends React.Component<{}, IAppContainerState> {
	private comp: JSX.Element | null = null;

	constructor(props: any) {
		super(props);

		this.state = {
			collapsed: false,
		};
	}

	public render() {
		const u = StorageHelper.getUser();

		let css = this.state.collapsed
			? { margin: "60px 0px 0px 10px" }
			: { margin: "60px 0px 0px 220px" };

		let comp = <></>;
		if (
			u !== undefined &&
			!Global.inAnyRole([
				Roles.PortalAdministrator,
				Roles.VB,
				Roles.VL,
				Roles.HL,
				Roles.NL,
				Roles.NS,
				Roles.BO,
				Roles.E3_EPM,
				Roles.E3_dealer_administration,
				Roles.TemoDealer,
				Roles.TemoBackOffice,
			])
		) {
			// ||(u !== undefined && u.user_type_id==='1')) {
			comp = (
				<Route>
					<ErrorScreen data={{ code: 403 }} />
				</Route>
			);
		} else {
			comp = (
				<div>
					<Switch>
						<Route exact path="/">
							<DashboardScreen />
						</Route>
						<Route exact path="/brands">
							<BrandsScreen />
						</Route>
						<Route exact path="/handler">
							<Handler />
						</Route>
						<Route exact path="/vb/representatives">
							<Representatives />
						</Route>
						<Route exact path="/representatives">
							<Representatives />
						</Route>
						<Route exact path="/structFamily">
							<ProductFamily />
						</Route>
						<Route exact path="/am/user">
							<AmUserScreen />
						</Route>
						<Route exact path="/am/portal">
							<Portal
								id={0}
								type={0}
								hourFrom=""
								hourTo=""
								dateFrom=""
								dateTo=""
								voidName=""
							/>
						</Route>
						<Route exact path="/order/orderHistory">
							<OrderHistory />
						</Route>
						<Route exact path="/log/journal">
							<LogJournalScreen
								mode={EScreenMode.undefined}
								journal={undefined}
								operationId=""
								status={0}
								type={0}
							/>
						</Route>
						<Route exact path="/epm/product">
							<EpmProductScreen
								mode={EScreenMode.undefined}
								pivot={""}
								product={undefined}
							/>
						</Route>
						<Route exact path="/epm/blacklist">
							<EpmBlacklistScreen
								mode={EScreenMode.undefined}
								item={undefined}
							/>
						</Route>
						<Route exact path="/dev/test">
							<TestScreen />
						</Route>
						<Route exact path="/booking/process">
							<BookingProcessScreen />
						</Route>
						<Route exact path="/booking/archive">
							<BookingDsArchive mode={EScreenMode.undefined} />
						</Route>
						<Route exact path="/am/account">
							<AmAccountScreen />
						</Route>
            <Route exact path="/am/groups">
              <AmDealerGroupScreen />
            </Route>
						<Route exact path="/vb/substitution">
							<AmUserSubstitutionScreen />
						</Route>
						<Route exact path="/hotline/shop">
							<HotlineShopScreen />
						</Route>
						<Route exact path="/serviceFamily">
							<ServiceFamilyScreen />
						</Route>
						<Route exact path="/defcon">
							<DefconScreen />
						</Route>
						<Route exact path="/admin/defcon">
							<DefconScreen />
						</Route>
						<Route exact path="/admin/tanauth">
							<AdminTanAuthScreen />
						</Route>
						<Route exact path="/admin/monitoring">
							<LogMonitoringScreen mode={EScreenMode.undefined} />
						</Route>
						<Route exact path="/admin/whitelist-bestandskunden">
							<WhitelistBestandskunden />
						</Route>
						<Route exact path="/query/bookingStatus">
							<BookingStatus />
						</Route>
						<Route exact path="/getTreeProperties">
							<ProductTreeScreen />
						</Route>
						<Route exact path="/editUser">
							<EditUser />
						</Route>
						<Route exact path="/editPassword">
							<EditPassword />
						</Route>

						<Route
							path="/brands/:brandKey/:businessCaseId/:activationTypeId/products/:productKey/productSubs"
							render={(props) => (
								<ProductSubandServiceScreen
									brandKey={props.match.params.brandKey}
									businessCaseId={props.match.params.businessCaseId}
									activationTypeId={props.match.params.activationTypeId}
									productKey={props.match.params.productKey}
								/>
							)}
						/>

						<Route
							path="/brands/:brandKey/:businessCaseId/details/:productKey"
							render={(props) => (
								<DetailsListCustomRows
									brandKey={props.match.params.brandKey}
									productKey={props.match.params.productKey}
								/>
							)}
						/>
						<Route
							path="/brands/:brandKey/:brandId/products"
							render={(props) => (
								<ProductScreen
									brandId={props.match.params.brandId}
									brandKey={props.match.params.brandKey}
								/>
							)}
						/>
						<Route
							path="/brands/:brandKey/subs"
							render={(props) => (
								<SUBScreen brandKey={props.match.params.brandKey} />
							)}
						/>

						<Route
							path="/brands/:brandKey/services"
							render={(props) => (
								<ServiceScreenGroupedColumns
									brandKey={props.match.params.brandKey}
								/>
							)}
						/>

						<Route exact path="/frd/mTanValidation">
							<MTanValidation />
						</Route>

						<Route exact path="/mTanValidation">
							<MTanValidation />
						</Route>

						<Route exact path="/messages">
							<MessagesScreen />
						</Route>

						<Route path="*">
							<NoMatch404Page />
						</Route>
					</Switch>
				</div>
			);
		}

		const ctrl = (
			<div id="container" style={css}>
				<Router history={hist}>{comp}</Router>
			</div>
		);

		return ctrl;
	}

	public collapse(val: boolean) {
		this.setState({ collapsed: val });
	}

	public apply(val: boolean) {
		this.setState({ collapsed: val });
	}
}

export default AppContainer;
