import React, { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { IEpmProduct } from "../../../model/data/IEpmProduct";
import IEpmProperties from "../../../model/data/Properties";
import IEpmProperty from "../../../model/data/Property";
import Global from "../../../utils/Global";
import EpmManager from "../../../utils/EpmManager";
import { TableConfig } from "../../../components/table/TableWrapper";
import { IBlacklistItem } from "../../../model/data/IBlacklistItem";
import StorageHelper from "../../../utils/StorageHelper";
import { MerchandiseTypes } from "../blacklist/merchandise-type.enum";

const NewBlacklistItemForm = ({ show, onHide, setBlacklistState }) => {
    const epmManager = new EpmManager
    const [blacklistItemData, setBlacklistItemData] = useState<IBlacklistItem>({});
    const brands = StorageHelper.getBrandKeys(true)
    const types = MerchandiseTypes;

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        setBlacklistItemData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleAddProduct = async () => {
        try {
            await epmManager.addBlacklistItem(blacklistItemData);

            setBlacklistItemData({
                brandId: undefined,
                typeId: undefined,
                key: "",
            });

            onHide();

        } catch (error) {
            console.error("Error adding blacklist item:", error);
        }
    };

    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>Add New Blacklist Item</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group controlId="brandId">
                        <Form.Label>Brand</Form.Label>
                        <select
                            className="form-control"
                            name="brandId"
                            value={blacklistItemData.brandId}
                            onChange={handleInputChange}
                        >
                            <option value="">Select a Brand</option>
                            {brands.map((brand) => (
                                <option key={brand.key} value={brand.key}>
                                    {brand.text}
                                </option>
                            ))}
                        </select>
                    </Form.Group>
                    <Form.Group controlId="typeId">
                        <Form.Label>Type</Form.Label>
                        <select
                            className="form-control"
                            name="typeId"
                            value={blacklistItemData.typeId}
                            onChange={handleInputChange}
                        >
                            <option value="">Select a Type</option>
                            {types.map((type) => (
                                <option key={type.key} value={type.key}>
                                    {type.text}
                                </option>
                            ))}
                        </select>
                    </Form.Group>
                    <Form.Group controlId="key">
                        <Form.Label>Key</Form.Label>
                        <Form.Control
                            type="text"
                            name="key"
                            value={blacklistItemData.key}
                            onChange={handleInputChange}
                        />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onHide}>
                    Cancel
                </Button>
                <Button variant="primary" onClick={handleAddProduct}>
                    Add
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default NewBlacklistItemForm;
