export default class Service {
  public Id: number = 0;
  public Name: string = "";
  public Key: string = "";
  public BrandId: number = 0;

  public TypeId: number = 0;
  public Sort: number = 0;
  public FamilyId: number = 0;
  public FamilyName: string = "";
  public TypeXmlId: number = 0;
  public Mandatory: number = 0;
  public Hidden: boolean = false;
  public ActivationFee: number = 0;
  public MonthlyFee: number = 0;
  public Model: string = "";
  public Excludes: number[] = [];
  public AgeValidation: string = "";

  public ui_name: string = "";
  public ui_tooltip: string = "";
  public ui_extra: string = "";
  public minDuration: string = "";

  public ProductSubId: number = 0;
  public BusinessCaseId: number = 0;
  public ActivationTypeId: number = 0;
  public ProductSubServiceId: number = 0;
  public Selected: boolean = false;
}
