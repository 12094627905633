import * as React from 'react';
import { Announced } from 'office-ui-fabric-react/lib/Announced';
import { TextField } from 'office-ui-fabric-react/lib/TextField';
import { DetailsList, DetailsListLayoutMode, Selection, IColumn, SelectionMode } from 'office-ui-fabric-react/lib/DetailsList';
import { MarqueeSelection } from 'office-ui-fabric-react/lib/MarqueeSelection';
import { Fabric } from 'office-ui-fabric-react/lib/Fabric';
import { mergeStyles } from 'office-ui-fabric-react/lib/Styling';
import { Link } from 'office-ui-fabric-react';
import { ShopEmployeeModel } from '../model/data/ShopEmployeeModel';

const exampleChildClass = mergeStyles({
  display: 'block',
  marginBottom: '10px'
});

export interface IDetailsListBasicExampleItem {
  key: number;
  name: string;
  value: number;
}

export interface IEmployeeListProps {
  allEmployees: ShopEmployeeModel[],
  selectedEmployee: any

}

export interface IDetailsListBasicExampleState {
  items: ShopEmployeeModel[];
  selectionDetails: string;
  columns: IColumn[];
}

export class EmployeeList extends React.Component<IEmployeeListProps, IDetailsListBasicExampleState> {
  private _selection: Selection;
  private _allItems: ShopEmployeeModel[];
  private _columns: IColumn[];

  constructor(props: IEmployeeListProps) {
    super(props);

    this._selection = new Selection({
      onSelectionChanged: () => this.setState({ selectionDetails: this._getSelectionDetails() })
    });

    // Populate with items for demos.
    this._allItems = this.props.allEmployees ? this.props.allEmployees : [];
    // for (let i = 0; i < 5; i++) {
    //   this._allItems.push({
    //     key: i,
    //     name: 'Item ' + i,
    //     value: i
    //   });
    // }

    this._columns = [
      {
        key: 'employee_void', name: 'Mitarbeiter-VOID', fieldName: 'employee_void', minWidth: 100, maxWidth: 200, isResizable: true, isSorted: true,
        isSortedDescending: false,
        sortAscendingAriaLabel: 'Sorted A to Z',
        sortDescendingAriaLabel: 'Sorted Z to A',
        data: 'string', onColumnClick: this._onColumnClick
      },
      {
        key: 'firstName', name: 'Vorname', fieldName: 'firstName', minWidth: 80, maxWidth: 80, isResizable: true, isSorted: true,
        isSortedDescending: false,
        sortAscendingAriaLabel: 'Sorted A to Z',
        sortDescendingAriaLabel: 'Sorted Z to A',
        data: 'string', onColumnClick: this._onColumnClick
      },
      {
        key: 'surname', name: 'Nachname', fieldName: 'surname', minWidth: 80, maxWidth: 80, isResizable: false, isSortedDescending: false,
        sortAscendingAriaLabel: 'Sorted A to Z',
        sortDescendingAriaLabel: 'Sorted Z to A', data: 'string', onColumnClick: this._onColumnClick
      },
      {
        key: 'userStatusId', name: 'Status', fieldName: 'userStatusId', minWidth: 50, maxWidth: 50, isResizable: true, isSorted: true,
        isSortedDescending: false,
        sortAscendingAriaLabel: 'Sorted A to Z',
        sortDescendingAriaLabel: 'Sorted Z to A',
        data: 'string', onColumnClick: this._onColumnClick,
        onRender: (item) => (
          <>{item.userStatusId == 7 ? "Blocked" : ''}</>
        )
      },
      {
        key: 'edit', name: '', fieldName: 'edit', minWidth: 63, maxWidth: 63, isResizable: false,
        onRender: (item) => (
          item.userStatusId == 7 ? <></> : <Link style={{ color: item.userStatusId == 7 ? 'white' : '' }} onClick={() => {
            this.props.selectedEmployee(this._selection.getSelection()[0] as ShopEmployeeModel, "edit");

          }}>Bearbeiten</Link>
        ),
      },

      //  { key: 'delete', name: '', fieldName: 'delete', minWidth: 50, maxWidth: 100, isResizable: false,
      //  onRender: (item) => (
      //    <Link  onClick={(event) => { 
      //      this.props.selectedEmployee(this._selection.getSelection()[0] as ShopEmployeeModel,"delete");

      //     }}>Löschen</Link>
      //  )}

    ];

    this.state = {
      items: this._allItems,
      selectionDetails: this._getSelectionDetails(),
      columns: this._columns,
    };
  }

  public render(): JSX.Element {
    const { items, selectionDetails, columns } = this.state;

    return (
      <Fabric>

        {<TextField
          className={exampleChildClass}
          label="Mitarbeiter suchen:"
          onChange={this._onFilter}
          styles={{ root: { maxWidth: '300px' } }}
        />}


        <DetailsList
          items={items}
          columns={this._columns}
          setKey="set"
          selectionMode={SelectionMode.single}
          layoutMode={DetailsListLayoutMode.justified}
          selection={this._selection}
          selectionPreservedOnEmptyClick={true}
          ariaLabelForSelectionColumn="Toggle selection"
          ariaLabelForSelectAllCheckbox="Toggle selection for all items"
          checkButtonAriaLabel="Row checkbox"
          onItemInvoked={this._onItemInvoked}
          onRenderRow={(props, defaultRender) => (
            <div>
              {props && defaultRender !== undefined ? defaultRender({ ...props, className: props!.item.userStatusId == 7 ? 'employeeBlocked' : (props!.itemIndex % 2 == 0 ? '' : 'employeeRow') }) : null}
            </div>
          )}
        />

      </Fabric>
    );
  }



  private _getSelectionDetails(): string {
    const selectionCount = this._selection.getSelectedCount();

    switch (selectionCount) {
      case 0:
        return 'No items selected';
      case 1:
        return '1 item selected: ' + (this._selection.getSelection()[0] as ShopEmployeeModel).employee_void;
      default:
        return `${selectionCount} items selected`;
    }
  }

  private _onFilter = (ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, text: any): any => {
    this.setState({
      items: text ? this._allItems.filter(i => i.employee_void.toLowerCase().indexOf(text.toLowerCase()) > -1 || i.firstName.toLowerCase().indexOf(text.toLowerCase()) > -1 || i.surname.toLowerCase().indexOf(text.toLowerCase()) > -1) : this._allItems
    });
  };

  private _onItemInvoked = (item: ShopEmployeeModel): any => {

  };

  private _onColumnClick = (ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
    const { columns, items } = this.state;
    const newColumns: IColumn[] = columns.slice();
    const currColumn: IColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
    newColumns.forEach((newCol: IColumn) => {
      if (newCol === currColumn) {
        currColumn.isSortedDescending = !currColumn.isSortedDescending;
        currColumn.isSorted = true;
        this.setState({

        });
      } else {
        newCol.isSorted = false;
        newCol.isSortedDescending = true;
      }
    });
    const newItems = _copyAndSort(items, currColumn.fieldName!, currColumn.isSortedDescending);
    this._selection.setAllSelected(false);
    this.setState({
      columns: newColumns,
      items: newItems,
      selectionDetails: this._getSelectionDetails()
    });
  };



}
function _copyAndSort<T>(items: T[], columnKey: string, isSortedDescending?: boolean): T[] {
  const key = columnKey as keyof T;
  return items.slice(0).sort((a: T, b: T) => ((isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1));
}

export default EmployeeList;
