import * as React from 'react';
import linq from 'linq';
import { ErrorMessages } from '../utils/messages';
import { Container, Row, Col } from 'react-bootstrap';

export interface IErrorScreenData {
    title?: string;
    message?: string;
    code?: number;
};

export interface IErrorScreenProps {
    data: IErrorScreenData;
};

export interface IErrorScreenState {
};

export class ErrorScreen extends React.Component<IErrorScreenProps, IErrorScreenState> {
    constructor(props: IErrorScreenProps) {
        super(props);

        this.state = {

        };
    };

    public render() {
        let d = this.prepare();

        let ctrl = (

            <Container>
                <Row>
                    <Col xs={12} md={12} className="np text">
                        {
                            d.message ? d.message.split('\n').map((i, idx) => (<p key={"err" + idx}>{i}</p>)) : null
                        }
                    </Col>
                </Row>
            </Container>

        );

        return ctrl;
    };

    private prepare(): IErrorScreenData {
        let data: IErrorScreenData = this.props.data;

        if (data.code) {
            if (data.code === 404) {
                data.message = ErrorMessages.message404;
                data.title = ErrorMessages.title404;
            } else if (data.code == 403) {
                data.message = ErrorMessages.message403;
                data.title = ErrorMessages.title403;
            };
        };

        return data;
    };
};

export default ErrorScreen;