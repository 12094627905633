import React, { useState, useEffect } from "react";
import { Card, Col, Container, Form, Row, Button } from "react-bootstrap";
import IEpmProperty from "../../../model/data/Property";
import { IEpmProduct } from "../../../model/data/IEpmProduct";
import EpmManager from "../../../utils/EpmManager";
import { EScreenMode } from "../../../model/app/Enums";
import { ITableScreen } from "../../../interfaces/ITableScreen";
import IEpmProperties from "../../../model/data/Properties";

interface PropertiesEditorProps extends IEpmProduct, ITableScreen {
  changedValue: string;
  setChangedValue: (value: string) => void;
}

interface TreeItem {
  id: number;
  name: string;
  childs?: TreeItem[];
}

export const PropertiesEditor: React.FC<PropertiesEditorProps> = ({
  id,
  mode,
  changedValue,
  setChangedValue,
}) => {
  const productId: number = Number(id);
  const epmManager: EpmManager = new EpmManager();
  const canEdit: boolean = mode === EScreenMode.edit || mode === EScreenMode.new;

  const [propertyValues, setPropertyValues] = useState<{ [id: number]: boolean }>({});
  const [selectedPropertyId, setSelectedPropertyId] = useState<number | null>(null);
  const [allElements, setAllElements] = useState<IEpmProperty[]>([]);
  const [loading, setLoading] = useState(true);
  const [selectedProperty, setSelectedProperty] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [propertyValueAdded, setPropertyValueAdded] = useState<boolean>(false); 

  useEffect(() => {
    const fetchProperties = async () => {
      try {
        const properties = await epmManager.getAllProperties();
        setAllElements(properties);
        setLoading(false);
      } catch (error) {
        console.error(error);
      }
    };

    fetchProperties();

  }, []);


  const buildTree = (element: IEpmProperty): TreeItem => {
    const tree: TreeItem = { id: 0, name: '', childs: [] };
    tree.id = element.id
    tree.name = element.name;

    const childs = allElements.filter((x) => x.parentId == element.id);

    if (childs && childs.length > 0) {
      childs.forEach((child) => {
        tree.childs!.push(buildTree(child));
      });
    }

    return tree;
  };

  const rootElement = allElements.find((x) => x.name === 'Root');

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!rootElement) {
    return <div>No root element found.</div>;
  }

  const tree = buildTree(rootElement as IEpmProperty);

  const handleAddPropertyValue = async () => {
    try {
      await epmManager.createPropValueForProduct(
        productId,
        selectedPropertyId as number,
        changedValue
      );
      setPropertyValueAdded(true);
      setPropertyValues({});
      setChangedValue("");
      setError("");
    } catch (error) {
      setError("Error adding property value");
      console.error(error);
    }
  };


  const handleCheckboxChange = async (propertyId: number, propertyName: string) => {
    if (Object.values(propertyValues).some((value) => value)) {
      if (propertyValues[propertyId]) {
        setPropertyValues((prevValues) => ({
          ...prevValues,
          [propertyId]: false,
        }));
        setSelectedProperty("");
        setChangedValue("");
      }
      return;
    }

    setPropertyValues((prevValues) => ({
      ...prevValues,
      [propertyId]: !prevValues[propertyId],
    }));

    if (propertyValues[propertyId]) {
      setSelectedProperty("");
      setChangedValue("");
    } else {
      setSelectedProperty(propertyName);
      try {
        const response = await epmManager.getProductPropertyValue(
          productId,
          propertyId
        );
        setChangedValue(response.value);
        setError("");
      } catch (error) {
        setError("Error fetching property value");
        console.error(error);
      }
    }
  };

  const renderTree = (element: TreeItem): JSX.Element => {
    const childs = element.childs;

    if (!childs || !childs.length) {
      return (
        <li key={element.id}>
          <label>
            {element.name}
            <input
              style={{ marginLeft: "5px" }}
              type="checkbox"
              checked={Boolean(propertyValues[element.id])}
              onChange={() => handleCheckboxChange(element.id, element.name)}
            />
          </label>
        </li>
      );
    } else {
      return (
        <ul>
          <li key={element.id}>
            <label>
              {element.name}
              <input
                style={{ marginLeft: "5px" }}
                type="checkbox"
                checked={Boolean(propertyValues[element.id])}
                onChange={() => handleCheckboxChange(element.id, element.name)}
              />
            </label>
          </li>
          {childs.map((child) => (
            <div key={child.id}>{renderTree(child)}</div>
          ))}
        </ul>
      );
    }
  };

  return (
    <>
      {!propertyValueAdded && (
        <>
          <ul>{renderTree(tree)}</ul>

          {selectedProperty && (
            <>
              <label style={{ color: "black", fontSize: "20px" }}>
                {selectedProperty}:
              </label>
              <div style={{ marginTop: "5px" }}>
                <input
                  type="text"
                  value={changedValue}
                  onChange={(e) => setChangedValue(e.target.value)}
                  placeholder="Property Value"
                  style={{ width: "100%", height: "50px", fontSize: "16px" }}
                />
                <Button
                  style={{ marginTop: "5px" }}
                  variant="primary"
                  onClick={handleAddPropertyValue}
                >
                  Add Property Value
                </Button>
                {error && <div style={{ color: "red" }}>{error}</div>}
              </div>
            </>
          )}

        </>
      )}

      {propertyValueAdded && (
        <div>
          <ul>{renderTree(tree)}</ul>
        </div>
      )}
    </>
  );
};
