import { INavLink } from 'office-ui-fabric-react';
import { hist } from '../App';
import Global from './Global';


export class AppRouter {
    public static routeTo(t: string, u: string) {
        let link: INavLink = {
            name: t,
            title: t,
            url: u
        };

        AppRouter.route(link);
    }

    public static reload() {
        AppRouter.routeTo(window.location.href,'/');
    };

    public static route(item?: INavLink): JSX.Element | null {
        if (item) {
           
            if (item.url.indexOf(Global.getAppUrl()) >= 0) {
                item.url = item.url.replace(Global.getAppUrl(), '');
            }
           
            hist.push(item.url);
        }

        return null;
    }
}

export default AppRouter;