import * as React from 'react';
import linq from 'linq';
import { DetailsList, DetailsRow, IDetailsRowProps, IDetailsRowStyles, SelectionMode } from 'office-ui-fabric-react/lib/DetailsList';
import { createListItems, IExampleItem } from 'office-ui-fabric-react/lib/utilities/exampleData';
import { getTheme } from 'office-ui-fabric-react/lib/Styling';
import { IApiResponse } from '../model/app/IApiResponse';
import { EScreenMode } from '../model/app/Enums';
import Api from '../utils/Api';
import { ColorPicker, TeachingBubbleBase, Checkbox } from 'office-ui-fabric-react';
import {Dialog,DialogFooter,DialogType,IContextualMenuItem, ContextualMenu, DirectionalHint,Dropdown, Pivot, PivotItem, Panel, PanelType, PrimaryButton, DefaultButton, TextField, } from 'office-ui-fabric-react';
import Global from '../utils/Global';
import { ProductDetail } from '../model/data/ProductDetail';
import Loader from '../Support/Loader';
import { DatePicker, IDatePickerStrings } from 'office-ui-fabric-react/lib/DatePicker';
import { Brand } from '../model/data/Brand';
import StorageHelper from '../utils/StorageHelper';
import { ListValueOption } from '../model/data/ListValueOption';
import { SUB } from '../model/data/SUB';
import IKeyText from '../model/app/IKeyText';
import { Item } from 'react-simple-tree-menu/dist/TreeMenu/walk';
import { Product } from '../model/data/Product';
import BusinessCases from '../screens/panels/businessCasesForProduct';
import EditPanel from './panels/EditPanel';

const DayPickerStrings: IDatePickerStrings = {
  months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],

  shortMonths: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],

  days: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],

  shortDays: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],

  goToToday: 'Go to today',
  prevMonthAriaLabel: 'Go to previous month',
  nextMonthAriaLabel: 'Go to next month',
  prevYearAriaLabel: 'Go to previous year',
  nextYearAriaLabel: 'Go to next year',
  closeButtonAriaLabel: 'Close date picker'
};

const theme = getTheme();

export interface IDetailsListCustomRowsProps {
  brandKey: string;
  productKey: string;
};

export interface IServiceScreenState {

  mode: EScreenMode,
  changed: boolean;
  contextMenu?: {
    target: HTMLElement;
    items: IContextualMenuItem[];
  },
  currentItem?: Item;
};

//need to receive product_key from the previous screen l_key  
export default class DetailsListCustomRows extends React.Component<any, any> {
  private url: string = "activationdata/";
  private _items: IExampleItem[];
  private productDetailNewItem: ProductDetail;
  private currentProduct: Product;
  
  // IDetailsListCustomRowsProps


  constructor(props: any) {
    super(props);
    this._items = createListItems(50);


    this.productDetailNewItem = new ProductDetail();
    this._openEditor = this._openEditor.bind(this);
    this._refreshModel = this._refreshModel.bind(this);
    this._hidePanel = this._hidePanel.bind(this);
    this._openEditorForNewDetail = this._openEditorForNewDetail.bind(this);
    this._onSelectDate = this._onSelectDate.bind(this);
    this._onSelectDateForValidTo = this._onSelectDateForValidTo.bind(this);
    this.onItemContextMenu = this.onItemContextMenu.bind(this);
    this._onContextMenuDismiss = this._onContextMenuDismiss.bind(this);
    this.onDataArrival = this.onDataArrival.bind(this);
    this._saveDetail = this._saveDetail.bind(this)
    this._onContextMenuItemClicked = this._onContextMenuItemClicked.bind(this);
    this._onNo = this._onNo.bind(this);
    this._onYes = this._onYes.bind(this);
    this.onDataArrival2 = this.onDataArrival2.bind(this);
    this._onSelectDateForAktionsflagFrom = this._onSelectDateForAktionsflagFrom.bind(this);
    this._onSelectDateForAktionsflagTo = this._onSelectDateForAktionsflagTo.bind(this);
    this.onChangeCheckbox = this.onChangeCheckbox.bind(this);

    this.currentProduct =  new Product();

    this.state = {
      mode: EScreenMode.loading,
      contextMenu: undefined,
      currentItem: undefined,
      changed: false
    };

    Api.fetch(this.url + 'activationProductDetailGet?product_key=' + props.productKey, undefined, "GET", this.onDataArrival);
    Api.fetch(this.url + 'getProductForDetailGeschäftsfall?product_key=' + props.productKey, undefined, "GET", this.onDataArrival2);
  }

  public render() {
    const { contextMenu } = this.state;

    let t1 = this.props.title == null ? 'Bitte bestätigen' : this.props.title;
    let t2 = this.props.text == null ? 'Sind Sie sicher?' : this.props.text;

    let ctr = <></>
    ctr = (
      <div>
      
        {this.renderEditor()}
        {
                    this.state.hidden ? <></> :
                    <>
                        <Dialog
                            hidden={this.state.hidden}
                            onDismiss={this._onNo}
                            dialogContentProps={{
                            type: DialogType.normal,
                            title: t1,
                            subText: t2
                        }}
                            modalProps={{
                            titleAriaId: t1,
                            subtitleAriaId: t2,
                            isBlocking: false,
                            containerClassName: 'ms-dialogMainOverride'
                        }}
                        >
                        <DialogFooter>
                            <PrimaryButton onClick={this._onYes} text="Ja" />
                            <DefaultButton onClick={this._onNo} text="Abbrechen" />
                        </DialogFooter>
                        </Dialog> 
                    </>
                }
        {contextMenu ? (
                        <ContextualMenu
                            onItemClick={this._onContextMenuItemClicked}
                            items={contextMenu.items}
                            isBeakVisible={true}
                            target={contextMenu.target}
                            directionalHint={DirectionalHint.bottomLeftEdge}
                            onDismiss={this._onContextMenuDismiss} />
                    ) : null
                    }
        <DetailsList selectionMode={SelectionMode.single} 
          onItemContextMenu={this.onItemContextMenu} items={this._items} setKey="set" />
      </div>
     
    )
   
    return ctr;

  }

  private _onNo() {
      this.setState({ hidden: true, mode: EScreenMode.view });
  };

private _onYes() {
    if (this.state.currentItem === undefined) return;

    Api.fetch(this.url + 'deleteDetail/' , this.productDetailNewItem, "POST",
        (api: IApiResponse) => {
          this._items = api.data;
            this.setState({
                mode: EScreenMode.view,
                hidden: true,  
            });
        });
};

  private onItemContextMenu(item?: any, index?: number, ev?: Event) {
    if(item !== undefined) {
      console.log(item);

      let actionItems: any[] = [];

      let target = ev ? ev.target : undefined;

        if (target !== undefined) {
          actionItems.push({ key: 'new', iconProps: { iconName: 'Add' }, text: 'Neu' });
          actionItems.push({ key: 'edit', iconProps: { iconName: 'Edit' }, text: 'Bearbeiten' });
          actionItems.push({ key: 'delete', iconProps: { iconName: 'Delete' }, text: 'Löschen' });

          let itm = item;
          
          itm.Name = item.label;
          itm.Key = item.custom_key;
          itm.ParentId = item.l_parent;
          itm.key = item.l_key;

          this.setState({
              contextMenu: {
                  target: target as HTMLElement,
                  items: actionItems
              },
              currentItem: itm
          });
          this.productDetailNewItem = itm;

          if (ev) {
            ev.preventDefault();
          }
        }
      }
  }

private _onContextMenuDismiss(): void {
  //this.currentItem = undefined;
  this.setState({
      contextMenu: undefined
  });
}

private _onContextMenuItemClicked(ev?: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>, item?: IContextualMenuItem) {
    if (item && item.key == 'new') {
       this.productDetailNewItem = new ProductDetail();
        let itm = this.state.currentItem;
        if (itm !== undefined) {
            itm.ParentId = itm.Key;
        }

        this._openEditorForNewDetail(this.productDetailNewItem) ;

    } else if (item && item.key == 'edit') {
      console.log(this.productDetailNewItem);
        this.setState({
            mode: EScreenMode.edit
        });
     
        console.log(this.state.mode);
    } else {
      console.log(this.productDetailNewItem);
        this.setState({
            mode: EScreenMode.delete,
            hidden: false 
        });
        
        
    }
};

  private _openEditorForNewDetail(dc: any) {

    this.setState({ mode: EScreenMode.edit });
    this._openEditor(undefined);
  };

  private _openEditor(dc: any) {


    this.productDetailNewItem = new ProductDetail();
    this.productDetailNewItem.ProductKey = this.props.productKey;


    console.log(this.productDetailNewItem);

    this.setState({ mode: EScreenMode.edit });

  };

  private renderEditor(): JSX.Element {
    let ctrl = <></>

    ctrl =
      <Panel isOpen={this.state.mode === EScreenMode.edit} onDismiss={this._hidePanel} type={PanelType.large} headerText="Neuer Tarif">
        {
          this.state.mode === EScreenMode.loading ? <Loader /> :
            (

              this.renderControl()
            )
        }


      </Panel>

    return ctrl;
  };

  public renderControl() {
    // let subs : SUB[] = linq.from<Brand>(StorageHelper.getBrnads())
    //   .where(x=>x.Key == this.props.brandKey)
    //   .first().subs;

    //   let isChecked: boolean = false;
    //   if(this.productDetailNewItem.Aktionsflag==1){
    //         isChecked = true;
    //   }else{
    //     isChecked =false;
    //   }

      // this.currentProduct = StorageHelper.getProduct();


    
    // let subsOptions = linq.from<SUB>(subs)
    //   .select(x=> ListValueOption.createInstance(x.Name, x.Key, false))
    //   .select<IKeyText>(x => ({ key: x.value, text: x.text }))
    //   .toArray();

    //   if(this.productDetailNewItem.ValidTo==null){
    //          this.productDetailNewItem.ValidTo=new Date();
    //   }
      

    let ctrl = <div>
      <Pivot>
        <PivotItem headerText="Main">
          <div className="ms-Grid">
            <div className="ms-Grid-row">
              <div className="ms-Grid-col ms-md6">
                <TextField label="product_key" name="ProductKey" required disabled value={this.productDetailNewItem.ProductKey} onChange={this._refreshModel} />
              </div>
              <div className="ms-Grid-col ms-md6">
              {/* <Dropdown
                  placeholder="Select an option"
                  label="SUB"
                  options={}
                  required={true}
                  selectedKey={this.productDetailNewItem.SubKey}
                  onChanged={(option) => {
                    this.productDetailNewItem.SubKey = String(option.key);
                    this.setState({changed: true});
                  }} /> */}
                {/* <TextField label="sub_key" name="SubKey" required value={this.productDetailNewItem.SubKey} onChange={this._refreshModel} /> */}
              </div>
              {/* <TextField label="member_type_id" name="MemberTypeId" required value={this.productDetailNewItem.MemberTypeId.toString()} onChange={this._refreshModel} /> */}
              <div className="ms-Grid-col ms-md6">
                <Dropdown
                  placeholder="Select an option"
                  label="Member type"
                  selectedKey={this.productDetailNewItem.MemberTypeId.toString()}
                  options={[
                    { key: '1', text: 'Owner', title: 'Owner' },
                    { key: '2', text: 'Member' },
                    { key: '4', text: 'NotValid' }

                  ]}
                  required={true}
                  onChanged={(option) => {
                    this.productDetailNewItem.MemberTypeId = Number(option.key);
                  }} />
              </div>
              {/* <TextField label="group_must" name="GroupMust" required value={this.productDetailNewItem.GroupMust.toString()} onChange={this._refreshModel} /> */}
              <div className="ms-Grid-col ms-md6">
                <Dropdown
                  placeholder="Select an option"
                  label="Group must"
                  selectedKey={this.productDetailNewItem.GroupMust.toString()}
                  options={[
                    { key: '1', text: 'No', title: 'Owner' },
                    { key: '2', text: 'Yes' },
                    { key: '4', text: 'NotValid' }

                  ]}
                  required={true}
                  onChanged={(option) => {
                    this.productDetailNewItem.GroupMust = Number(option.key);
                  }} />
              </div>
              <div className="ms-Grid-col ms-md6">
                <DatePicker label="Gültig ab" strings={DayPickerStrings} value={new Date(this.productDetailNewItem.ValidFrom.toString().substring(0, 10))} placeholder="Select a date..." ariaLabel="Select a date" onSelectDate={this._onSelectDate} />
              </div>
              <div className="ms-Grid-col ms-md6">
                <DatePicker label="Gültig bis" strings={DayPickerStrings} value={new Date(this.productDetailNewItem.ValidTo.toString().substring(0, 10))} placeholder="Select a date..." ariaLabel="Select a date" onSelectDate={this._onSelectDateForValidTo} />
              </div>
              <div className="ms-Grid-col ms-md6">
                <TextField label="monthly_fee" name="MonthlyFee" required value={this.productDetailNewItem.MonthlyFee.toString()} onChange={this._refreshModel} />
              </div>
              <div className="ms-Grid-col ms-md6">
                <TextField label="activation_fee" name="ActivationFee" required value={this.productDetailNewItem.ActivationFee.toString()} onChange={this._refreshModel} />
              </div>

            </div>

            <div className="spacer-default" />
            <div className="ms-Grid-row">
              <div className="ms-Grid-col ms-md12"></div>
              <PrimaryButton text="Speichern" onClick={this._saveDetail} />
              <DefaultButton className="default-button-edit-screen" text="Abbrechen" onClick={this._hidePanel} />

            </div>
          </div>

        </PivotItem>

        <PivotItem headerText="Dienste & Promotions">
                    <div>
                        <EditPanel productId={this.currentProduct.Id} />
                    </div>
                </PivotItem>

        <PivotItem headerText="Geschäftsfall">
                    <BusinessCases tariff={this.productDetailNewItem} />
        </PivotItem>
{/* 
        <PivotItem headerText="Aktionsflag">
        <Checkbox   checked={isChecked} label='Aktion Verfügbar' onChange={this.onChangeCheckbox}/>
        <DatePicker label="Gültig von" strings={DayPickerStrings} disabled={!isChecked} value={new Date(this.productDetailNewItem.ValidFromAktionsflag.toString().substring(0, 10))} placeholder="Select a date..." ariaLabel="Select a date" onSelectDate={this._onSelectDateForAktionsflagFrom} />
        <DatePicker label="Gültig bis" strings={DayPickerStrings} disabled={!isChecked} value={new Date(this.productDetailNewItem.ValidtoAktionsflag.toString().substring(0, 10))} placeholder="Select a date..." ariaLabel="Select a date" onSelectDate={this._onSelectDateForAktionsflagTo} />
        <TextField label="UI-Tooltip" name="UItooltip" disabled={!isChecked} required value={this.productDetailNewItem.UItooltip} onChange={this._refreshModel} />
        </PivotItem> */}

      </Pivot>
    </div >
    return ctrl;
  }

  private onChangeCheckbox(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean){
    if(checked==true){
 this.productDetailNewItem.Aktionsflag=1;
    }else{
      this.productDetailNewItem.Aktionsflag=0;
    }
 
    this.setState({
     changed: true,
     mode: EScreenMode.edit  
 });
 }
 

  private _saveDetail() {

    console.log(this.productDetailNewItem.ValidFrom);
    console.log(this.productDetailNewItem.ValidTo);



    Api.fetch(this.url + 'addNewDetail', this.productDetailNewItem, "POST",
      (api: IApiResponse) => {
        this._items = api.data;
        this.setState({
          mode: EScreenMode.view,

        });
      });
  };

  private _hidePanel() {
    this.setState({ mode: EScreenMode.view });
  };

  private _onSelectDate(date: Date | null | undefined) {
    if (date == undefined) return;
    this.productDetailNewItem.ValidFrom = date;
  };

  private _onSelectDateForAktionsflagFrom(date: Date | null | undefined) {
    if (date == undefined) return;
    this.productDetailNewItem.ValidFromAktionsflag = date;
  };

  private _onSelectDateForAktionsflagTo(date: Date | null | undefined) {
    if (date == undefined) return;
    this.productDetailNewItem.ValidtoAktionsflag = date;
  };


  private _onSelectDateForValidTo(date: Date | null | undefined) {
    if (date == undefined) return;
    this.productDetailNewItem.ValidTo = date;
  };


  private _refreshModel(ctrl: any) {
    Global.setValueByPath(this.productDetailNewItem, ctrl.target.name, ctrl.target.value);
    this.setState({ changed: true });
  };

  // private _onRenderRow = (props: any): JSX.Element => {
  //   const customStyles: Partial<IDetailsRowStyles> = {};
  //   if (props.itemIndex % 2 === 0) {
  //     // Every other row renders with a different background color
  //     customStyles.root = { backgroundColor: theme.palette.redDark };
  //   }

  //   return <DetailsRow {...props} styles={customStyles} />;
  // };

  private onDataArrival(api: IApiResponse) {
    console.log(api);
    this._items = api.data;
    this.setState({
      mode: EScreenMode.view,
    });
  };

  private onDataArrival2(api2: IApiResponse) {
    console.log(api2);
  this.currentProduct = api2.data;
    this.setState({
      mode: EScreenMode.view,
    });
  };
}
