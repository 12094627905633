import React, { useState, useEffect } from "react";
import TableWrapper, {
  ITableWrapperProps,
  TableConfig,
} from "../../components/table/TableWrapper";
import IKeyText from "../../model/app/IKeyText";
import { from } from "linq";
import StorageHelper from "../../utils/StorageHelper";
import { HttpMethods, MenuItems, WebSites } from "../../utils/Defaults";
import { ETableSource } from "../../components/controls/Table";
import { EScreenMode } from "../../model/app/Enums";
import { EControlType } from "../../components/table/enums/control-type.enum";
import OrderHistoryPanel from "./OrderHistoryPanel";
import { ContextualMenuItemType, IContextualMenuItem } from "office-ui-fabric-react";
import GatewayManager from "../../utils/GatewayManager";

const OrderHistory = () => {
  const [current, setCurrent] = useState({
    mode: EScreenMode.undefined,
    kibanaId: undefined,
  });

  const processContextMenu = (item, e) => {
    const idMatch = item.renderedMessage.match(/Kibana id (.+)$/);
    if (idMatch && e.key === MenuItems.edit) {
      setCurrent({
        mode: EScreenMode.details,
        kibanaId: idMatch[1],
      });
    }
  };

  let cm: IContextualMenuItem[] = [
    {
      key: MenuItems.edit,
      iconProps: { iconName: "View" },
      text: "Sicht",
    },
    { key: "divider_1", itemType: ContextualMenuItemType.Divider },
  ];

  const handleDismiss = () => {
    setCurrent((prevState) => ({
      ...prevState,
      mode: EScreenMode.view,
    }));
  };

  const tc = TableConfig.createInstance({
    url: from<IKeyText>(StorageHelper.getWebsites())
      .toArray()
      .filter(x => x.key == WebSites.gate)[0].text + "elastic/get/all/kibana",
    sourceType: ETableSource.dynamic,
    arrayName: "data",
    sort: [],
    columns: [
      {
        name: "renderedMessage",
        label: "Kibana Id",
        sortable: false,
        renderer: (data) => {
          const idMatch = data.renderedMessage.match(/Kibana id (.+)$/);
          const kibanaId = idMatch ? idMatch[1] : "N/A"; // Set to "N/A" if no match

          return kibanaId;
        },
      },
      {
        name: "level",
        label: "Level",
        sortable: false,
      },
      {
        name: "username",
        label: "Username",
        sortable: false,
      },
    ],
  });

  const cfg: ITableWrapperProps = {
    id: "rety",
    action: processContextMenu,
    onItemChanged: (u) => {
      setCurrent(u);
    },
    model: { needle: "", paging: TableConfig.initPaging() },
    contextMenu: cm,
    filter: [{ type: EControlType.text, key: "needle", label: "Suche" }],
    config: tc,
  };

  return (
    <>
      <TableWrapper {...cfg} />
      {current?.mode === EScreenMode.details ? (
        <OrderHistoryPanel kibanaId={current.kibanaId} onDismiss={handleDismiss} />
      ) : null}
    </>
  );
};

export default OrderHistory;
