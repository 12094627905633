export class ProductDetail {
  public Id: number = 0;
  public Changed: Date = new Date();
  public ChangedBy: number = 0;
  public ProductKey: string = "";
  public SubKey: string = "";
  public MemberTypeId: number = 0;
  public GroupMust: number = 0;
  public ValidFrom: Date = new Date();
  public ValidTo: Date = new Date();
  public MonthlyFee: number = 0;
  public ActivationFee: number = 0;
  public BusinessCasesId: number = 0;
  public BusinesCase: number = 0;
  public Aktionsflag: number = 0;
  public ValidFromAktionsflag: Date = new Date();
  public ValidtoAktionsflag: Date = new Date();
  public UItooltip: string = "";
}
