import { ActionButton, Checkbox, DefaultButton, Dialog, DialogFooter, DialogType, Dropdown, IContextualMenuItem, Panel, PanelType, Pivot, PivotItem, PrimaryButton, TextField } from "office-ui-fabric-react";
import React from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import TableFilter from "../../../components/table/TableFilter";
import { EScreenMode } from "../../../model/app/Enums";
import LoaderVf, { ELoaderVfType } from "../../../Support/LoaderVf";
import AccountManager from "../../../utils/AccountManager";
import { AmHelper, EUserType, IAmUser } from "../Models";
import IKeyText from "../../../model/app/IKeyText";
import StorageHelper from "../../../utils/StorageHelper";
import { ITableScreen } from "../../../interfaces/ITableScreen";
import { MenuItems, Roles, WebSites } from "../../../utils/Defaults";
import TableWrapper, { ITableWrapperProps, TableConfig } from "../../../components/table/TableWrapper";
import { ETableSource } from "../../../components/controls/Table";
import { EControlType } from "../../../components/table/enums/control-type.enum";
import { from } from 'linq';

export interface IGroupEditProps {
    groupId?: number;
    onDismiss: any;
};

export interface IGroupEditState extends ITableScreen {
    model?: any;
    voidToAdd: string;
    updateWrapper: boolean;
    isWrongVoidDialogHidden: boolean;
};

export class AmGroupEditPanel extends React.Component<IGroupEditProps, IGroupEditState> {
    private am: AccountManager = new AccountManager();

    constructor(props: any) {
        super(props);

        this.state = {
            mode: EScreenMode.loading,
            voidToAdd: "",
            updateWrapper: false,
            isWrongVoidDialogHidden: true
        };
    };

    componentDidMount(): void {
        this.loadData();
    };

    render = () => {
        const processContextMenu = async (item, e): Promise<void> => {
            if (e.key == MenuItems.delete) {
                await this.am.removeUserFromGroup(item.id);
                this.setState({ updateWrapper: true });
            }

            this.setState({ updateWrapper: false })
        };

        let cm: IContextualMenuItem[] = [
            { key: MenuItems.delete, iconProps: { iconName: 'Delete' }, text: 'Löschen' }
        ];

        const tc = TableConfig.createInstance({
            url: from<IKeyText>(StorageHelper.getWebsites()).toArray().filter(x => x.key == WebSites.am)[0].text + 'groupmembership/filter',
            sourceType: ETableSource.dynamic,
            arrayName: "groupMemberships",
            sort: [],
            columns: [
                { name: "void", label: "Void", sortable: true },
            ]
        });

        let cfg: ITableWrapperProps = {
            id: "dealers",
            action: async (item, e) => processContextMenu(item, e),
            onItemChanged: (u) => {
                //this.current = u;
            },
            model: { needle: "", paging: TableConfig.initPaging(), groupId: this.props.groupId },
            contextMenu: cm,
            filter: [
                { type: EControlType.text, key: "needle", label: "Suche" },
            ],
            config: tc,
            updateWrapper: this.state.updateWrapper
        };

        const onVoidChange = (x: any) => this.setState({ voidToAdd: x.target.value });

        const onAddVoid = async (): Promise<void> => {
            const response = await this.am.addUserToGroup({ void: parseInt(this.state.voidToAdd), groupId: this.props.groupId });

            if(response.error){
                this.setState({ isWrongVoidDialogHidden: false });
            }else{
                this.setState({ updateWrapper: true });
            }

            this.setState({ updateWrapper: false });
        }

        let ctrl = <></>;

        ctrl = <>
            {
                this.state.mode == EScreenMode.loading || (this.state.model && this.state.model.error) ?
                    <LoaderVf type={ELoaderVfType.full} onClose={(x) => {
                        var u = this.state.model as IAmUser;

                        if (u.error) {
                            u.error = undefined;
                            this.setState({ model: u });
                        };
                    }} message={LoaderVf.parseApiError(this.state.model ? this.state.model.error : undefined)} />
                    : null
            }

            <Panel isOpen={true} onDismiss={this.onDismiss} onOuterClick={ () => {} } type={PanelType.medium} headerText="Gruppen" isFooterAtBottom={true}>
                <Container>
                    <Row style={{ display: 'flex', alignItems: 'flex-end' }}>
                        <TextField label="Void" name="Void" value={this.state.voidToAdd} onChange={onVoidChange} />
                        <PrimaryButton style={{ marginLeft: '3px' }}  text="Add" onClick={async () => onAddVoid()} />
                    </Row>
                    <Row>
                        <Col>
                            <TableWrapper {...cfg} />
                        </Col>
                    </Row>
                </Container>
            </Panel>

            <Dialog
                hidden={this.state.isWrongVoidDialogHidden}
                onDismiss={() => this.setState({ isWrongVoidDialogHidden: true })}
                dialogContentProps={{
                    type: DialogType.largeHeader,
                    title: `Benutzer mit VOID ${this.state.voidToAdd} nicht gefunden`,
                }}
                modalProps={{
                    isBlocking: true,
                    containerClassName: "ms-dialogMainOverride",
                }}
            >
                <DialogFooter>
                    <Button
                        variant="secondary"
                        onClick={() => this.setState({ isWrongVoidDialogHidden: true })}
                    >
                        Abbrechen
                    </Button>
                </DialogFooter>
            </Dialog>
        </>;

        return ctrl;
    };

    onDismiss = () => {
        this.props.onDismiss();
    };

    async loadData() {
        this.setState({ mode: EScreenMode.view });
    };

};

export default AmGroupEditPanel;