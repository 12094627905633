import React from "react";
import { IContextualMenuItem, PrimaryButton } from "office-ui-fabric-react";
import TableWrapper, {
  ITableWrapperProps,
  TableConfig,
} from "../../components/table/TableWrapper";
import { ITableScreen } from "../../interfaces/ITableScreen";
import { EScreenMode } from "../../model/app/Enums";
import { WebSites, Defaults, Roles, MenuItems } from "../../utils/Defaults";
import { ETableSource } from "../../components/table/enums/table-source.enum";
import { IAmUserSub } from "./Models";
import { AmUserSubstitutionEditPanel } from "./panels/AmUserSubstitutionEditPanel";
import { EControlType } from "../../components/table/enums/control-type.enum";
import linq from "linq";
import IKeyText from "../../model/app/IKeyText";
import Global from "../../utils/Global";
import StorageHelper from "../../utils/StorageHelper";
import ErrorScreen from "../error";
import AccountManager from "../../utils/AccountManager";

export interface IAmUserSubstitutionScreenState extends ITableScreen {
  mode: EScreenMode;
  updateWrapper: boolean;

  changed?: boolean;
}

export class AmUserSubstitutionScreen extends React.Component<
  {},
  IAmUserSubstitutionScreenState
> {
  private current!: IAmUserSub;

  private am: AccountManager = new AccountManager();

  constructor(props: any) {
    super(props);

    this.state = {
      id: Global.getEmptyGuid(),
      mode: EScreenMode.view,
      updateWrapper: false,
    };
  }

  processContextMenu = (item, e) => {
    if (e.key == MenuItems.edit || e.key == MenuItems.new) {
      this.current = item;
      this.setState({ mode: EScreenMode.edit, id: item.id });
    } else if (e.key == MenuItems.delete) {
      this.patch(item.id);
    }
  };

  private reload = () => {
    let ctrl = Global.GetComponent("et-subs") as TableWrapper;

    if (ctrl) {
      ctrl.reload();
    }
  };

  render(): React.ReactNode {
    if (!Global.inAnyRole([Roles.PortalAdministrator, Roles.VB, Roles.VL])) {
      return <ErrorScreen data={{ code: 403 }} />;
    }

    let cm: IContextualMenuItem[] = [
      {
        key: MenuItems.edit,
        iconProps: { iconName: "Edit" },
        text: "Bearbeiten",
      },
      {
        key: MenuItems.delete,
        iconProps: { iconName: "Delete" },
        text: "Vertretung entfernen",
      },
    ];

    let tc = TableConfig.createInstance({
      url:
        linq
          .from<IKeyText>(StorageHelper.getWebsites())
          .toArray()
          .filter((x) => x.key == WebSites.am)[0].text +
        "user/substitution/filter",
      method: "get",
      sourceType: ETableSource.dynamic,
      arrayName: "userSubstitutions",
      sort: [],
      columns: [
        {
          name: "from",
          label: "Von",
          sortable: true,
          renderer: (r, c) => {
            return Global.toGermanDate(r["from"]);
          },
        },
        {
          name: "to",
          label: "Bis",
          sortable: true,
          renderer: (r, c) => {
            return Global.toGermanDate(r["to"]);
          },
        },
        {
          name: "source",
          label: "Source",
          renderer: (r, c) => {
            return r["source"] ? r["source"]["username"] : Defaults.na;
          },
        },
        {
          name: "target",
          label: "Target",
          renderer: (r, c) => {
            return r["target"] ? r["target"]["username"] : Defaults.na;
          },
        },
      ],
      propertyMappers: {
        from: (x: string) => new Date(x),
        to: (x: string) => new Date(x),
      },
    });

    let cfg: ITableWrapperProps = {
      id: "subs",
      action: this.processContextMenu,
      onItemChanged: (u) => {
        this.current = u;
      },
      model: { needle: "", paging: TableConfig.initPaging() },
      contextMenu: cm,
      filterAction: (a) => {
        return (
          <>
            <PrimaryButton
              text="Hinzufügen"
              onClick={(e) => {
                var i = { id: Global.getEmptyGuid() };
                var ee = { key: MenuItems.new };

                this.processContextMenu(i, ee);
              }}
            />
          </>
        );
      },
      filter: [{ type: EControlType.text, key: "needle", label: "Suche" }],
      config: tc,
      updateWrapper: this.state.updateWrapper,
    };

    return (
      <>
        <h1>Vertretung</h1>
        <div className="data">
          <TableWrapper {...cfg} />

          {this.state.mode === EScreenMode.edit ? (
            <AmUserSubstitutionEditPanel
              id={this.state.id!}
              onDismiss={() => {
                this.setState({ mode: EScreenMode.view });
              }}
              model={this.current}
              onModelChanged={() => {
                this.setState({ updateWrapper: true }, () => {
                  this.setState({ updateWrapper: false });
                });
              }}
            />
          ) : null}
        </div>
      </>
    );
  }

  async patch(id: string) {
    var x: any = "";

    x = await this.am.removeUserSubstitution(id);

    this.setState({ changed: true, mode: EScreenMode.view }, () => {
      this.reload();
    });
  }
}
