import React from "react";
import {
    DefaultButton,
    Dialog,
    DialogFooter,
    DialogType,
    Dropdown,
    Icon,
    IDropdownOption,
    MessageBar,
    MessageBarType,
    Panel,
    PanelType,
    Pivot,
    PivotItem,
    PrimaryButton,
    TextField
} from "office-ui-fabric-react";
import {
    Breadcrumb,
    IBreadcrumbItem
} from "office-ui-fabric-react/lib/Breadcrumb";
import {
    DatePicker,
    IDatePickerStrings
} from "office-ui-fabric-react/lib/DatePicker";
import {
    IDocumentCardActivityPerson,
    IDocumentCardStyles
} from "office-ui-fabric-react/lib/DocumentCard";
import { ProgressIndicator } from "office-ui-fabric-react/lib/ProgressIndicator";
import {
    ButtonToolbar,
    Card,
    Col,
  Container,
  OverlayTrigger,
  Row
} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Popover from "react-bootstrap/Popover";
import PopoverContent from "react-bootstrap/PopoverContent";
import PopoverTitle from "react-bootstrap/PopoverTitle";
import "../../src/App.css";
import DSLImageSmall from "../assets/img/thumb-dsl.jpg";
import KabelImageSmall from "../assets/img/thumb-kabel.jpg";
import OteloImageSmall from "../assets/img/thumb-otelo.jpg";
import VodafoneImageSmall from "../assets/img/thumb-vf (1).jpg";
import { EScreenMode } from "../model/app/Enums";
import { IApiResponse } from "../model/app/IApiResponse";
import { Brand } from "../model/data/Brand";
import { ListValueOption } from "../model/data/ListValueOption";
import {
    IProductEditModel,
    IProductModel,
    Product,
    ProductInfo
} from "../model/data/Product";
import { StorageKeys } from "../utils/Defaults";
import linq from "linq";
import Loader from "../Support/Loader";
import Api from "../utils/Api";
import AppRouter from "../utils/AppRouter";
import IKeyText from "../model/app/IKeyText";
import Global from "../utils/Global";
import StorageHelper from "../utils/StorageHelper";
import EpmManager from "../utils/EpmManager";

const DayPickerStrings: IDatePickerStrings = {
  months: [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
  ],

  
  shortMonths: [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec"
  ],

  days: [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday"
  ],

  shortDays: ["S", "M", "T", "W", "T", "F", "S"],

  goToToday: "Go to today",
  prevMonthAriaLabel: "Go to previous month",
  nextMonthAriaLabel: "Go to next month",
  prevYearAriaLabel: "Go to previous year",
  nextYearAriaLabel: "Go to next year",
  closeButtonAriaLabel: "Close date picker"
};

export interface IProductScreenState {
  products?: Product[];
  mode: EScreenMode;
  changed: boolean;
  hidden: boolean;
  details: boolean;
  allInfo?: IProductModel;
  disableButton: boolean;
  disableButtonKeyField: boolean;
  disableButtonNameField: boolean;
  disableButtonAgeValField: boolean;
}

export class ProductScreen extends React.Component<any, IProductScreenState> {
  private currentProduct: IProductEditModel;
  private editButtonClicked: boolean = false;
  private allInformation: IProductModel = new IProductModel();
  private uiTextChanged: boolean = false;
  private current?: IProductModel;
  private epmManager = new EpmManager();
  

  private cardStyles: IDocumentCardStyles = {
    root: {
      display: "inline-block",
      marginRight: 20,
      marginBottom: 20,
      width: 320
    }
  };
  private people: IDocumentCardActivityPerson[] = [
    { name: "Jens Seidel", profileImageSrc: "/assets/img/jens.jpg" }
  ];

  constructor(props: any) {
    super(props);

    this.onDataArrival = this.onDataArrival.bind(this);

    this._openEditor = this._openEditor.bind(this);
    this.productDetailsClick = this.productDetailsClick.bind(this);
    this._saveProduct = this._saveProduct.bind(this);
    this._getErrorMessage = this._getErrorMessage.bind(this);
    this._getErrorMessageKeyField = this._getErrorMessageKeyField.bind(this);
    this._refreshModel = this._refreshModel.bind(this);
    this._hidePanel = this._hidePanel.bind(this);
    this._onSelectDate = this._onSelectDate.bind(this);
    this.prepareForDeletion = this.prepareForDeletion.bind(this);
    // this._handleChangeDD = this._handleChangeDD.bind(this);
    this._refreshProperty = this._refreshProperty.bind(this);
    this._onNo = this._onNo.bind(this);
    this._onYes = this._onYes.bind(this);
    this.prepareForEdit = this.prepareForEdit.bind(this);
    this.onDataArrival2 = this.onDataArrival2.bind(this);
    this._onBreadcrumbItemClicked = this._onBreadcrumbItemClicked.bind(this);
    this._getErrorMessageNameField = this._getErrorMessageNameField.bind(this);
    this._getErrorMessageAgeValidation =
      this._getErrorMessageAgeValidation.bind(this);

    this._onSelectDateForValidTo = this._onSelectDateForValidTo.bind(this);

    this.currentProduct = {
      product: new Product(),
      optionsPayment: [],
      services: [],
      subs: [],
      families: [],
      customerTypes: [],
      creditDebitTypes: [],
      activationTypes: [],
      categoryBrands: []
    };

    this.state = {
      products: undefined,
      changed: false,
      mode: EScreenMode.loading,
      hidden: true,
      details: false,
      allInfo: undefined,
      disableButton: false,
      disableButtonKeyField: false,
      disableButtonNameField: false,
      disableButtonAgeValField: false
    };
    
    const filterModel = {
      brandId: this.props.brandId,
      paging: {
        start: 1,
        pageSize: 2
      }
    };

    (async () => {
      const productIds = await this.epmManager.filterProducts(filterModel);

      productIds.data.forEach((data: { id: number }) => {
        (async () => {
          const products = await this.epmManager.getProduct(this.props.brandKey);
          console.log(products);
            
          this.onDataArrival2(products);
        })();
      });
    })();
  }


  public render() {
    console.log("render prod" + this.state.mode);
    if (this.state.mode === EScreenMode.loading) {
      return (
        <ProgressIndicator
          label="Daten werden geladen"
          description="Bitte warten Sie"
        />
      );
      //} else if (this.state.mode === EScreenMode.delete) {
      //    this.renderDeletion();
      // }else if(this.state.details==true){
      //    this.renderDetails();
    } else {
      return this.renderData();
    }
  }

  // private renderDetails(){
  //     let ctr = <></>

  //     ctr =  <DetailsListCustomRows product_key={this.currentProduct.product.Key}/>;
  //     return ctr;
  // }

  private renderData(): JSX.Element {
    let ctrl = <></>;

    let t1 = this.props.title == null ? "Bitte bestätigen" : this.props.title;
    let t2 = this.props.text == null ? "Sind Sie sicher?" : this.props.text;

    let thirdParam: string = "";
    let firstParam: string = "";

    if (this.props.activationTypeId === "1,3") {
      thirdParam = "ACT&ACTPORT";
    } else if (this.props.activationTypeId === "2,3") {
      thirdParam = "PORT&ACTPORT";
    } else {
      // ===3
      thirdParam = "ACTPORT";
    }

    if (this.props.brandKey === "vf") {
      firstParam = "Vodafone";
    } else if (this.props.brandKey === "otelo") {
      firstParam = "Otelo";
    } else if (this.props.brandKey === "kabel") {
      firstParam = "Kabel";
    } else if (this.props.brandKey === "dsl") {
      firstParam = "DSL";
    }
    
    if (this.state.allInfo) {

      ctrl = (
        <div id="products">
          <Breadcrumb
            items={[
              {
                text: "Start",
                key: "Start",
                onClick: (ev: any, item: any) => {
                  this._onBreadcrumbItemClicked(ev, item);
                }
              },
              {
                text:
                  firstParam +
                  "-" +
                  this.props.businessCaseId +
                  "-" +
                  thirdParam,
                key: this.props.brandKey
              },
              { text: "Products", key: "f5", isCurrentItem: true }
            ]}
            ariaLabel="Breadcrumb with no maxDisplayedItems"
            overflowAriaLabel="More links"
          />
          <br />
          {/* <TableWrapper {...cfg} /> */}

          <Button
            variant="danger"
            onClick={() => {
              this._openEditorForNewProduct(this.currentProduct);
            }}
          >
            + Neu produkt
          </Button>
          {this.state.hidden ? (
            <></>
          ) : (
            <>
              <Dialog
                hidden={this.state.hidden}
                onDismiss={this._onNo}
                dialogContentProps={{
                  type: DialogType.normal,
                  title: t1,
                  subText: t2
                }}
                modalProps={{
                  titleAriaId: t1,
                  subtitleAriaId: t2,
                  isBlocking: false,
                  containerClassName: "ms-dialogMainOverride"
                }}
              >
                <DialogFooter>
                  <Button variant="danger" onClick={this._onYes}>
                    Ja
                  </Button>
                  <Button variant="secondary" onClick={this._onNo}>
                    Abbrechen
                  </Button>
                </DialogFooter>
              </Dialog>
            </>
          )}

          <div className="spacer-default" />
          {this.renderGroups()}

          {this.renderEditor()}
        </div>
      );
    }

    return ctrl;
  }

  private _onBreadcrumbItemClicked(
    ev: React.MouseEvent<HTMLElement>,
    item: IBreadcrumbItem
  ): void {
    AppRouter.routeTo("Produkte", "/brands");
  }

  private renderGroups(): JSX.Element {
    if (this.state.allInfo !== undefined) {

      let groups: string[] = [];
      for (
          let index = 0;
          
          index < this.allInformation.productsSubsServices.length;
          index++
          ) {
            
              var element = this.allInformation.productsSubsServices[index];
            
        if (!groups.includes(element.product.FamilyName)) {
          groups.push(element.product.FamilyName);
        }
      }

      let ctrl = <>{groups.map((i, idx) => this.renderGroup(i, idx))}</>;

      return ctrl;
    }

    return <></>;
  }

  private renderGroup(name: string, idx: number) {
      if (this.state.allInfo !== undefined) {
          var productsTemp = linq
          .from<ProductInfo>(this.allInformation.productsSubsServices)
          .where((x) => x.product.FamilyName == name)
          .toArray();


      let ctrl = <></>;

      ctrl = (
        <>
          <div>{name}</div>
          <Container fluid>
            <Row>
              {productsTemp.map((i, idx) => (
                <Col xs={"auto"} md={"auto"}>
                  {this.renderProduct(i)}
                </Col>
              ))}
            </Row>
          </Container>
        </>
      );
      return ctrl;
    }
  }

  public renderNewServiceEditor() {
    let ctrl = <></>;

    ctrl = (
      <Panel
        isOpen={this.state.mode === EScreenMode.edit}
        onDismiss={this._hidePanel}
        type={PanelType.medium}
        headerText="Neues produkt hinzufügen"
      >
        {
          <Pivot>
            <PivotItem headerText="Generell">
              <div className="ms-Grid">
                <div className="ms-Grid-row">
                  <TextField
                    label="Name"
                    name="Name"
                    required
                    value={this.currentProduct.product.Name}
                    onChange={this._refreshModel}
                  />
                  <TextField
                    label="Key"
                    name="Key"
                    required
                    value={this.currentProduct.product.Key}
                    onChange={this._refreshModel}
                  />
                </div>
                <br />
                <PrimaryButton text="Speichern" onClick={this._saveProduct} />
                <DefaultButton
                  className="default-button-edit-screen"
                  text="Abbrechen"
                  onClick={this._hidePanel}
                />
              </div>
            </PivotItem>
          </Pivot>
        }
      </Panel>
    );

    return ctrl;
  }

  private _openEditorForNewProduct(dc: any) {
    // this.setState({ mode: EScreenMode.edit });
    this._openEditor(undefined);
  }

  private _openEditor(dc: any) {
    let id = dc == undefined ? "0" : dc.currentTarget.id.replace("dc-", "");

    Api.fetch(
      "getproduct/" + id + "?brandKey=" + this.props.brandKey,
      undefined,
      "GET",
      (api: IApiResponse) => {
        this.currentProduct = api.data;
        if (this.currentProduct.product.Id == 0) {
          this.currentProduct.product = new Product();
        }

        this.setState({ mode: EScreenMode.details });
      }
    );
  }

  private prepareForDeletion(dc: any) {
    // let id = dc.currentTarget.id.replace("dc-", "");
    // if (this.state.products) {
    //     this.currentProduct.product = linq.from<Product>(this.state.products)
    //         .where(x => x.Id === this.currentProduct.product.Id)
    //         .firstOrDefault();
    //     if (!this.currentProduct) {
    //         this.currentProduct = { product: new Product(), optionsPayment: [], services: [], subs: [], families: [], customerTypes: [], creditDebitTypes: [], activationTypes: [] };
    //         this.currentProduct.product.BrandId = this.props.brandId;
    //     }

    //     this.setState({ hidden: false });
    // }
    this.setState({ hidden: false });
  }

  private renderEditor(): JSX.Element {
    let ctrl = <></>;
    if (this.currentProduct.product.Model !== "") {
      var arr_from_json = JSON.parse(this.currentProduct.product.Model);
    }
    if (arr_from_json === undefined) {
      arr_from_json = {
        product_ui: {}
      };
    }
    console.log("Mode: " + this.state.mode);
    ctrl = (
      <>
        <Panel
          isBlocking={false}
          isOpen={this.state.mode === EScreenMode.details}
          onDismiss={this._hidePanel}
          type={PanelType.medium}
          headerText={this.currentProduct.product.Name}
        >
          {<div>Eigenschaften</div>}
          {this.state.mode === EScreenMode.loading ? (
            <Loader />
          ) : (
            <>
              <ul>
                <li>L_key: {this.currentProduct.product.Key}</li>
                <li>name: {this.currentProduct.product.Name}</li>
                <li>family: {this.currentProduct.product.FamilyName}</li>
                <li>category: {this.currentProduct.product.CategoryId}</li>
                <li>
                  ui_name:{" "}
                  {arr_from_json.product_ui.ui_name === undefined
                    ? ""
                    : arr_from_json.product_ui.ui_name}
                </li>
                <li>
                  ui_tooltip:{" "}
                  {arr_from_json.product_ui.ui_tooltip === undefined
                    ? ""
                    : arr_from_json.product_ui.ui_tooltip}
                </li>
                <li>
                  ui_blister:{" "}
                  {arr_from_json.product_ui.ui_blister === undefined
                    ? ""
                    : arr_from_json.product_ui.ui_blister}
                </li>
                <li>
                  ui_extra:{" "}
                  {arr_from_json.product_ui.ui_extra === undefined
                    ? ""
                    : arr_from_json.product_ui.ui_extra}
                </li>
              </ul>
              <Icon
                className="dcicon"
                iconName="Edit"
                title="Produkt bearbeiten"
                onClick={this.prepareForEdit}
              />
              <Icon
                className="dcicon"
                iconName="Delete"
                title="Produkt löschen"
                onClick={this.prepareForDeletion}
              />
              {/* <Icon className="dcicon"  iconName="Info" title="Produkt einzelheiten" onClick={() => { this.productDetailsClick(this.currentProduct.product) }} /> */}
            </>
          )}
        </Panel>

        <Panel
          isBlocking={false}
          isOpen={this.state.mode === EScreenMode.edit}
          onDismiss={this._hidePanel}
          type={PanelType.large}
          headerText={this.currentProduct.product.Name}
        >
          {<div>Bearbeiten</div>}
          {this.state.mode === EScreenMode.loading ? (
            <Loader />
          ) : (
            this.renderControl()
          )}
        </Panel>
      </>
    );

    return ctrl;
  }

  public prepareForEdit() {
    this.setState({ mode: EScreenMode.edit });

    this.editButtonClicked = true;
  }

  public renderControl() {
    if (this.state.mode != EScreenMode.edit) {
      return;
    }

    let optionsType = linq
      .from<ListValueOption>(this.currentProduct.optionsPayment)
      .select<IKeyText>((x) => ({ key: x.value, text: x.text }))
      .toArray();

    let optionsTypeForFamily = linq
      .from<ListValueOption>(this.currentProduct.families)
      .select<IKeyText>((x) => ({ key: x.value, text: x.text }))
      .toArray();

    let optionsCustomerType = linq
      .from<ListValueOption>(this.currentProduct.customerTypes)
      .select<IKeyText>((x) => ({ key: x.value, text: x.text }))
      .toArray();

    let optionsCreditDebitType = linq
      .from<ListValueOption>(this.currentProduct.creditDebitTypes)
      .select<IDropdownOption>((x) => ({ key: x.value, text: x.text }))
      .toArray();

    let brandCategories = linq
      .from<ListValueOption>(this.currentProduct.categoryBrands)
      .select<IDropdownOption>((x) => ({ key: x.value, text: x.text }))
      .toArray();

    let optionsActivationType = linq
      .from<ListValueOption>(this.currentProduct.activationTypes)
      .select<IKeyText>((x) => ({ key: x.value, text: x.text }))
      .toArray();

    // let optionsMnpType = linq.from<ListValueOption>(this.currentProduct.mnpTypes)
    //     .select<IKeyText>(x => ({ key: x.value, text: x.text }))
    //     .toArray();

    if (this.currentProduct.product.ActionFlagValidFrom == null) {
      this.currentProduct.product.ActionFlagValidFrom = new Date();
    }
    if (this.currentProduct.product.ActionFlagValidTo == null) {
      this.currentProduct.product.ActionFlagValidTo = new Date();
    }

    let ctrl = (
      <div>
        <Pivot>
          <PivotItem headerText="Generell">
            {this.state.disableButton === false ? (
              <></>
            ) : (
              <>
                <br />
                <MessageBar messageBarType={MessageBarType.severeWarning}>
                  Bitte beheben Sie alle Fehler
                </MessageBar>
              </>
            )}

            <div className="ms-Grid">
              <div className="ms-Grid-row">
                <div className="ms-Grid-col ms-md6">
                  <TextField
                    label="Name"
                    name="Name"
                    required
                    onGetErrorMessage={this._getErrorMessageNameField}
                    validateOnLoad={false}
                    value={this.currentProduct.product.Name}
                    onChange={this._refreshModel}
                  />
                </div>

                <div className="ms-Grid-col ms-md6">
                  <TextField
                    label="Key"
                    name="Key"
                    required
                    onGetErrorMessage={this._getErrorMessageKeyField}
                    validateOnLoad={false}
                    value={this.currentProduct.product.Key}
                    onChange={this._refreshModel}
                  />
                </div>

                <div className="ms-Grid-col ms-md6">
                  <TextField
                    label="Validity"
                    name="Validity"
                    onGetErrorMessage={this._getErrorMessage}
                    validateOnLoad={false}
                    required
                    value={this.currentProduct.product.Validity}
                    onChange={this._refreshModel}
                  />
                </div>

                <div className="ms-Grid-col ms-md6">
                  <TextField
                    label="Sort"
                    name="Sort"
                    required
                    value={this.currentProduct.product.Sort.toString()}
                    onChange={this._refreshModel}
                  />
                </div>

                <div className="ms-Grid-col ms-md6">
                  <Dropdown
                    placeholder="Typ auswählen"
                    required={true}
                    id="CreditDebitId"
                    key="CreditDebitId"
                    label="kredit oder lastschrift"
                    selectedKey={this.currentProduct.product.CreditDebitId.toString()}
                    onChange={(e, option) => {
                      this.currentProduct.product.CreditDebitId = Number(
                        option!.key
                      );
                      this.setState({ changed: true });
                    }}
                    options={optionsCreditDebitType}
                  />
                </div>

                <div className="ms-Grid-col ms-md6">
                  <Dropdown
                    placeholder="select category"
                    required={true}
                    id="CategoryId"
                    key="CategoryId"
                    label="category selection"
                    selectedKey={this.currentProduct.product.CategoryId.toString()}
                    onChange={(e, option) => {
                      this.currentProduct.product.CategoryId = Number(
                        option!.key
                      );
                      this.setState({ changed: true });
                    }}
                    options={brandCategories}
                  />
                </div>

                <div className="ms-Grid-col ms-md6">
                  <Dropdown
                    placeholder="Typ auswählen"
                    required={true}
                    id="ActivationTypeId"
                    label="ActivationType"
                    selectedKey={this.currentProduct.product.ActivationTypeId.toString()}
                    onChange={(e, option) => {
                      this.currentProduct.product.ActivationTypeId = Number(
                        option!.key
                      );
                      this.setState({ changed: true });
                    }}
                    options={optionsActivationType}
                  />
                </div>

                <div className="ms-Grid-col ms-md6">
                  <DatePicker
                    label="Von"
                    strings={DayPickerStrings}
                    value={
                      new Date(
                        this.currentProduct.product.ActionFlagValidFrom.toString().substring(
                          0,
                          10
                        )
                      )
                    }
                    placeholder="Select a date for validFrom"
                    ariaLabel="ActionflagValidFrom"
                    onSelectDate={this._onSelectDate}
                  />
                </div>

                <div className="ms-Grid-col ms-md6">
                  <DatePicker
                    label="Bis"
                    strings={DayPickerStrings}
                    value={
                      new Date(
                        this.currentProduct.product.ActionFlagValidTo.toString().substring(
                          0,
                          10
                        )
                      )
                    }
                    placeholder="Select a date  for validTo"
                    ariaLabel="ActionflagValidto"
                    onSelectDate={this._onSelectDateForValidTo}
                  />
                </div>

                <div className="ms-Grid-col ms-md6">
                  <TextField
                    label="Activation fee"
                    name="ActivationFee"
                    required
                    value={this.currentProduct.product.ActivationFee.toString()}
                    onChange={this._refreshModel}
                  />
                </div>

                <div className="ms-Grid-col ms-md6">
                  <TextField
                    label="Altersvalidierung"
                    name="AgeValidation"
                    required
                    onGetErrorMessage={this._getErrorMessageAgeValidation}
                    validateOnLoad={false}
                    value={this.currentProduct.product.AgeValidation}
                    onChange={this._refreshModel}
                  />
                </div>

                <div className="ms-Grid-col ms-md6">
                  <TextField
                    label="Monthly fee"
                    name="MonthlyFee"
                    required
                    value={this.currentProduct.product.MonthlyFee.toString()}
                    onChange={this._refreshModel}
                  />
                </div>

                <div className="ms-Grid-col ms-md6">
                  <Dropdown
                    placeholder="Zielgruppe"
                    required={true}
                    id="CustomerTypeId"
                    label="Kundschaft"
                    selectedKey={this.currentProduct.product.CustomerTypeId.toString()}
                    onChange={(e, option) => {
                      this.currentProduct.product.CustomerTypeId = Number(
                        option!.key
                      );
                      this.setState({ changed: true });
                    }}
                    options={optionsType}
                  />
                </div>

                {/* <div className="ms-Grid-col ms-md6">
                                <Dropdown placeholder="Bitte auswählen" required={true}
                                    id="MnpId"
                                    label="Portierung"
                                    selectedKey={this.currentProduct.product.MnpId.toString()}
                                    onChange={this._handleChangeDD}
                                    options={optionsMnpType} />
                            </div> */}

                <div className="ms-Grid-col ms-md6">
                  <Dropdown
                    placeholder="Family name"
                    required={true}
                    id="FamilyId"
                    label="Family"
                    selectedKey={this.currentProduct.product.FamilyId.toString()}
                    options={optionsTypeForFamily}
                    onChanged={(option) => {
                      this.currentProduct.product.FamilyId = Number(option.key);
                      this.currentProduct.product.FamilyName = option.text;
                      this.setState({ changed: true });
                    }}
                  />
                </div>
              </div>

              <div className="ms-Grid-col ms-md6">
                <TextField
                  label="SortFocus"
                  name="SortFocus"
                  required
                  value={this.currentProduct.product.SortFocus ? "0" : ""}
                  onChange={this._refreshModel}
                />
              </div>

              <div className="ms-Grid-row">
                <div className="ms-Grid-col ms-md12">
                  <br />
                  <Button
                    style={{ marginRight: "5px" }}
                    variant="primary"
                    disabled={
                      this.state.disableButton ||
                      this.state.disableButtonKeyField ||
                      this.state.disableButtonNameField ||
                      this.state.disableButtonAgeValField
                    }
                    onClick={this._saveProduct}
                  >
                    Speichern
                  </Button>
                  <Button variant="secondary" onClick={this._hidePanel}>
                    Abbrechen
                  </Button>
                </div>
              </div>
            </div>
          </PivotItem>

          {/* <PivotItem headerText="Dienste & Promotions">
                    <div>
                        <EditPanel productId={this.currentProduct.product.Id} />
                    </div>
                </PivotItem> */}

          {/* <PivotItem headerText="Substufen">
                    <SUBSforProduct productId={this.currentProduct.product.Id} />
                </PivotItem> */}

          {/* <PivotItem headerText="Geschäftsfall">
                    <BusinessCases product={this.currentProduct.product} />
                </PivotItem> */}

          <PivotItem headerText="UI Text">
            {this.renderProps("ui_", undefined)}
          </PivotItem>

          <PivotItem headerText="Properties">
            {this.renderProps(undefined, "ui_")}
          </PivotItem>
        </Pivot>
      </div>
    );

    return ctrl;
  }

  private _getErrorMessage = (value: string): string => {
    const regex = /[0-9]+([ydm])(?!\S)/gm;
    const str = value;
    let m;
    let err: string = "";

    console.log(this.state.disableButton);

    if ((m = regex.exec(str)) !== null) {
      err = "";
      this.setState({ disableButton: false });
    } else {
      err = `Der Eingabewert beginnt mit einer Nummer gefolgt von entweder "m" , "d" oder "y".`;
      this.setState({ disableButton: true });
    }

    console.log(this.state.disableButton);

    return err;
  };

  private _getErrorMessageAgeValidation = (value: string): string => {
    const regex = /^[0-9-]{1,40}$/gm;
    const str = value;
    let m;
    let err: string = "";

    if ((m = regex.exec(str)) !== null) {
      err = "";
      this.setState({ disableButtonAgeValField: false });
    } else {
      err = `Das Feld sollte nur Zahlen und einen Bindestrich enthalten und nicht länger als 40 Zeichen sein`;
      this.setState({ disableButtonAgeValField: true });
    }

    return err;
  };

  private _getErrorMessageNameField = (value: string): string => {
    const regex = /^[A-Za-z0-9_-\s&]{1,80}$/gm;

    const str = value;
    let m;
    let err: string = "";

    if ((m = regex.exec(str)) !== null) {
      err = "";
      this.setState({ disableButtonNameField: false });
    } else {
      err = `Das Feld sollte nur aus Buchstaben, Zahlen, &, Unterstrichen und Bindestrichen bestehen und nicht mehr als 80 Symbole.`;
      this.setState({ disableButtonNameField: true });
    }

    return err;
  };

  private _getErrorMessageKeyField = (value: string): string => {
    const regex = /^[A-Za-z0-9_-]{1,31}$/gm;

    const str = value;
    let m;
    let err: string = "";

    if ((m = regex.exec(str)) !== null) {
      err = "";
      this.setState({ disableButtonKeyField: false });
    } else {
      err = `Das Feld sollte nur aus Buchstaben, Zahlen, Unterstrichen und Bindestrichen bestehen und nicht mehr als 31 Symbole.`;
      this.setState({ disableButtonKeyField: true });
    }

    return err;
  };

  private renderProps(
    key: string | undefined,
    exclude: string | undefined
  ): JSX.Element {
    let ctrl = <></>;
    let markup: JSX.Element[] = [];

    console.log("propss-----", this.currentProduct.product.Properties);

    if (this.currentProduct.product.Id === 0 && this.uiTextChanged === false) {
      this.currentProduct.product.Properties = JSON.parse(
        '{"ui_tooltip":"","ui_name":"","ui_blister":"","ui_extra":""}'
      );
      this.uiTextChanged = true;
    }

    for (var item in this.currentProduct.product.Properties) {
      let torender: boolean = false;

      if (key && item.indexOf(key) >= 0) {
        torender = true;
      }

      if (!key && exclude) {
        torender = true;
      }

      if (exclude && item.indexOf(exclude) >= 0) {
        torender = false;
      }

      if (torender) {
        let c = (
          <div key={item} className="ms-Grid-col ms-md12">
            <TextField
              label={item}
              name={item}
              value={this.currentProduct.product.Properties[item]}
              onChange={this._refreshProperty}
            />
          </div>
        );

        markup.push(c);
      }
    }

    ctrl = (
      <div className="ms-Grid">
        <div className="ms-Grid-row">{markup}</div>
      </div>
    );

    return ctrl;
  }

  private _onSelectDate(date: Date | null | undefined) {
    if (date == undefined) return;
    this.currentProduct.product.ActionFlagValidFrom = date;
  }
  private _onSelectDateForValidTo(date: Date | null | undefined) {
    if (date == undefined) return;
    this.currentProduct.product.ActionFlagValidTo = date;
  }

  private _hidePanel() {
    if (this.editButtonClicked === true) {
      this.setState({ mode: EScreenMode.view });
    }
  }

  private _refreshProperty(ctrl: any) {
    if (ctrl) {
      this.currentProduct.product.Properties[ctrl.target.name] =
        ctrl.target.value;
      console.log(this.currentProduct.product.Properties);
      this.setState({ changed: true });
    }
  }

  private _refreshModel(ctrl: any) {
    Global.setValueByPath(
      this.currentProduct.product,
      ctrl.target.name,
      ctrl.target.value
    );
    this.setState({ changed: true });
  }

  private _saveProduct() {
    // if (this.state.disableButton == false) {
    //   if (!this.currentProduct.product.BrandId) {
    //     var br = StorageHelper.getBrnads();
    //     this.currentProduct.product.BrandId = linq
    //       .from<Brand>(br)
    //       .where((x) => x.Key == this.props.brandKey)
    //       .first().Id;
    //   }
    //   this.currentProduct.product.SortFocus = Number(
    //     this.currentProduct.product.SortFocus
    //   );

    //   Api.fetch(
    //     "saveProduct/",
    //     this.currentProduct.product,
    //     "POST",
    //     (api: IApiResponse) => {
    //       this.allInformation.productsSubsServices.push(api.data);

    //       this.setState({
    //         mode: EScreenMode.view
    //       });
    //     }
    //   );
    // }
  }

  private renderProduct(product: ProductInfo): JSX.Element {
    let id: string = "dc-" + product.product.Id;
    let idButton: string = "dc-" + product.product.Id;

    product.product.Model = product.product.Model.replace(
      /(?:\r\n|\r|\n)/g,
      " "
    );

    if (product.product.Model !== "") {
      var arr_from_json = JSON.parse(product.product.Model);
    }

    if (arr_from_json === undefined) {
      arr_from_json = {
        product_ui: {}
      };
    }
    let str: string = product.product.MonthlyFee.toString();
    var begining = str.substring(0, 2);
    var end = str.substring(2, 4);
    var result = begining + "." + end;

    let t1 = this.props.title == null ? "Bitte bestätigen" : this.props.title;
    let t2 = this.props.text == null ? "Sind Sie sicher?" : this.props.text;

    var listImages = [
      VodafoneImageSmall,
      OteloImageSmall,
      KabelImageSmall,
      DSLImageSmall
    ];

    let ctrl = (
      <>
        <ButtonToolbar>
          <OverlayTrigger
            trigger="hover"
            key="bottom"
            placement="auto"
            overlay={
              <Popover
                style={{
                  boxShadow:
                    "0 4px 8px 0 rgba(0, 0, 0, 0.4), 0 6px 30px 0 rgba(0, 0, 0, 0.19)"
                }}
                id={`popover-positioned-$bottom`}
              >
                <PopoverTitle as="h4">
                  <img
                    style={{ marginRight: "10px" }}
                    src={listImages[product.product.BrandId - 1]}
                    alt="vodafone image"
                  />
                  {product.product.Name}
                </PopoverTitle>
                <PopoverContent>
                  {arr_from_json.product_ui.ui_tooltip === undefined
                    ? ""
                    : arr_from_json.product_ui.ui_tooltip}
                  <h2 style={{ float: "right" }}>{result + "€"}</h2>
                  <p style={{ float: "right" }}>MONATLICHE GESAMTKOSTEN</p>
                </PopoverContent>
              </Popover>
            }
          >
            <Card
              id={id}
              key={product.product.Id}
              style={{
                width: "7rem",
                height: "9rem",
                borderRadius: "15px",
                backgroundColor: "#F0F0F0",
                cursor: "pointer",
                boxShadow:
                  "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.19)"
              }}
              onClick={this._openEditor}
            >
              <Card.Body>
                <Card.Title
                  style={{
                    textAlign: "center",
                    fontSize: "12px",
                    fontWeight: "bold"
                  }}
                >
                  {product.product.Name}
                </Card.Title>
                <Card.Subtitle
                  className="mb-2 text-muted"
                  style={{ textAlign: "center", fontSize: "12px" }}
                >
                  {product.product.Key}
                </Card.Subtitle>
                <Card.Subtitle
                  style={{ textAlign: "center", color: "#4169E1" }}
                  onClick={(event: React.MouseEvent<HTMLElement, MouseEvent>) =>
                    this.onProductClick(
                      event,
                      this.props.brandKey,
                      this.props.activationTypeId,
                      product.product.Key,
                      product
                    )
                  }
                >
                  Subs:{" "}
                  {
                    product.allSubsServ.filter((x) => x.sub.Selected === true)
                      .length
                  }
                </Card.Subtitle>
              </Card.Body>
            </Card>
          </OverlayTrigger>
        </ButtonToolbar>
      </>
    );

    return ctrl;
  }

  private onProductClick(
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    key: string,
    activationTypeId: string,
    productKey: string,
    product: ProductInfo
  ) {
    StorageHelper.set(StorageKeys.keyProduct, product);

    event.stopPropagation();

    AppRouter.routeTo(
      "Produkte",
      "/brands/" +
        key +
        "/" +
        this.props.businessCaseId +
        "/" +
        activationTypeId +
        "/products/" +
        product.product.Key +
        "/productSubs"
    );

    event.preventDefault();

    // const div = document.getElementById('products');

    // let ctrl = <ProductSubandServiceScreen {...product}/>;

    // ReactDOM.render(ctrl, div);

    return false;
  }

  private productDetailsClick(product: Product) {
    //set the storage helper data here the product info here

    AppRouter.routeTo("", window.location.href + "/details/" + product.Key);
    //    console.log(product);
    //    this.currentProduct.product = product;
    //     this.setState({
    //          details: true});
  }

  private _onNo() {
    this.setState({ hidden: true, mode: EScreenMode.view });
  }

  private _onYes() {
    var nenen = this.currentProduct;
    Api.fetch(
      "deleteProduct",
      this.currentProduct.product,
      "POST",
      this.onDataArrival
    );
  }

  private onDataArrival(api: IApiResponse) {
    for (
      let index = 0;
      index < this.allInformation.productsSubsServices.length;
      index++
    ) {
      const element = this.allInformation.productsSubsServices[index];
      if (element.product.Id === this.currentProduct.product.Id) {
        this.allInformation.productsSubsServices.splice(index, 1);
      }
    }

    this.setState({
      mode: EScreenMode.view,
      hidden: true
    });
  }

  public onDataArrival2(api : IProductModel) {
    this.allInformation = api;
    console.log(api);

    this.setState({
      mode: EScreenMode.view,
      hidden: true,
      allInfo: api
    });
  }
}

export default ProductScreen;
