import React from "react";
import { ErrorScreen } from "../screens";
import Global from "./Global";

export interface IAccessManagerProps {
    roles: string[];
};

export class AccessManager extends React.Component<IAccessManagerProps,{}> {
    constructor(props: IAccessManagerProps) {
        super(props);
    };

    render = () => {
        if(!Global.inAnyRole(this.props.roles)) {
            return <ErrorScreen data={{ code: 403 }} />
        };

        return <>{this.props.children}</>
    };
};

export default AccessManager;