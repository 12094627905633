import { IEpmProduct } from "../../../model/data/IEpmProduct";
import React, { useState } from "react";
import EpmManager from "../../../utils/EpmManager";
import { ButtonToolbar, Button } from "react-bootstrap";
import { EScreenMode } from "../../../model/app/Enums";
import { ITableScreen } from "../../../interfaces/ITableScreen";
import Global from "../../../utils/Global";
import TableWrapper, { ITableWrapperProps, TableConfig } from "../../../components/table/TableWrapper";
import { from } from "linq";
import IKeyText from "../../../model/app/IKeyText";
import StorageHelper from "../../../utils/StorageHelper";
import { MenuItems, Roles, WebSites } from "../../../utils/Defaults";
import { ETableSource } from "../../../components/controls/Table";
import { EControlType } from "../../../components/table/enums/control-type.enum";
import { ContextualMenuItemType, IContextualMenuItem } from "office-ui-fabric-react";
import { EpmProductEditPanel, getPivots, pivotMain } from "../panels/EpmProductEditPanel";
import NewProductPanel from "../panels/NewProductPanel";
import Api from "../../../utils/Api";

export interface IProductScreenState extends ITableScreen {
    product?: IEpmProduct | undefined;
    pivot?: string;
    brands?: any;
    showNewProductForm?: boolean;
    products?: IEpmProduct[];
}

export const EpmProductScreen: React.FC<IProductScreenState> = () => {
    const cfg1 = Global.getConfiguration();
    const [productState, setProductState] = useState<IProductScreenState>({
        product: undefined,
        id: Global.getEmptyGuid(),
        mode: EScreenMode.view,
        pivot: pivotMain,
        brands: undefined,
        showNewProductForm: false,
        products: []
    });
    const epmManager = new EpmManager();

    const [current, setCurrent] = useState<IEpmProduct | undefined>(undefined);

    const processContextMenu = (item: IEpmProduct, e) => {
        if (e.key == MenuItems.edit) {
            setProductState({
                mode: EScreenMode.edit,
                product: item,
                id: item.id,
                pivot: pivotMain,
                products: productState.products,
            });
        }
    };

    let cm: IContextualMenuItem[] = [
        {
            key: MenuItems.edit,
            iconProps: { iconName: "Edit" },
            text: "Bearbeiten",
        },
        { key: "divider_1", itemType: ContextualMenuItemType.Divider },
    ];

    const tc = TableConfig.createInstance({
        url:
            from<IKeyText>(StorageHelper.getWebsites())
                .toArray()
                .filter(x => x.key == WebSites.epm)[0].text + 'product/v2/filter',
        sourceType: ETableSource.dynamic,
        arrayName: "data",
        sort: [],
        columns: [
            { name: "id", label: "Key", sortable: true },
            { name: "name", label: "Name", sortable: true },
            { name: "brandName", label: "Brand", sortable: true }
        ]
    });

    let cfg: ITableWrapperProps = {
        id: "rety",
        action: processContextMenu,
        onItemChanged: (u) => {
            setCurrent(u)
        },
        model: { needle: "", paging: TableConfig.initPaging() },
        contextMenu: cm,
        filter: [
            { type: EControlType.text, key: "needle", label: "Suche" },
            {
                type: EControlType.dropdown,
                key: "brandId",
                label: "Brand",
                list: StorageHelper.getBrandNames(true)
            }
        ],
        config: tc,
    };

    const handleNewProduct = () => {
        setProductState({ ...productState, mode: EScreenMode.new, showNewProductForm: true });
    };

    const handleCloseForm = () => {
        setProductState({ ...productState, mode: EScreenMode.view, showNewProductForm: false });
    };

    return (
        <>
            {productState.mode !== EScreenMode.loading && (
                <>
                    <ButtonToolbar style={{
                        marginBottom: '12px',
                    }}>
                        <Button style={{ backgroundColor: "red" }} onClick={handleNewProduct}>New Product</Button>
                       {Global.inRole(Roles.E3_EPM) ? <Button style={{ backgroundColor: "red" }} onClick={             
                            ()=>{window.open(cfg1.apiUrl + 'activationdata/exportBrands', "_blank") }
                        }>EXPORT BRANDS</Button> : <></> }
                    </ButtonToolbar>
                    <TableWrapper {...cfg} />
                    <NewProductPanel
                        show={productState.showNewProductForm}
                        onHide={handleCloseForm}
                        setProductState={setProductState}
                    />
                </>
            )}
            {productState.mode === EScreenMode.edit && getPivots().indexOf(productState.pivot as string) >= 0 ? (
                <EpmProductEditPanel
                    id={productState.id as string}
                    onDismiss={() => {
                        setProductState({ mode: EScreenMode.view });
                    }}
                />
            )
                : null
            }
        </>
    );
};

export default EpmProductScreen;
