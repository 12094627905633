export class StorageKeys {
  public static keyUser: string = "usr";
  public static keyBrands: string = "bra";
  public static keyApp: string = "app";
  public static keyProduct: string = "pro";
  public static keySupport: string = "sup";
  public static keyStructures: string = "str";
  public static representationOptions: string = "repr";
  public static monitoringContainers: string = "mct";
}

export class WebSites {
  public static am: string = "easy4-am";
  public static epm: string = "easy4-epm";
  public static gate: string = "easy4-gateway";
  public static log: string = "easy4-log";
  public static ds: string = "easy4-ds";
}

export class StringTokens {
  public static brand: string = "brand";
  public static product: string = "product";
}

export class HttpMethods {
  public static post: string = "POST";
  public static get: string = "GET";
  public static patch: string = "PATCH";
  public static put: string = "PUT";
  public static delete: string = "DELETE";
}

export class AppIds {
  public static app: string = "app";
  public static container: string = "container";
}

export class MenuItems {
  public static new: string = "new";
  public static download: string = "download";
  public static archive: string = "archive";
  public static edit: string = "edit";
  public static delete: string = "delete";
  public static admin: string = "admin";
  public static resetUser: string = "resetUser";
  public static pwPatch: string = "pwPatch";
  public static statusPatch: string = "statusPatch";
}

export class Defaults {
  public static currencyUnit: string = "€";

  public static pageSize: number = 10;

  public static na: string = "k.A";
}

export class Roles {
  public static Auftragsmanager = "Auftragsmanager";
  public static Backoffice = "Backoffice";
  public static CMSAdministrator = "CMSAdministrator";
  public static CMSBearbeiter = "CMSBearbeiter";
  public static E3_dealer_administration = "E3_dealer_administration";
  public static E3_EPM = "E3_EPM";
  public static E3_User = "E3_User";
  public static IdentityServerAdministrators = "IdentityServerAdministrators";
  public static IdentityServerDevelopers = "IdentityServerDevelopers";
  public static IdentityServerUsers = "IdentityServerUsers";
  public static PortalAdministrator = "PortalAdministrator";
  public static PortalTester = "PortalTester";
  public static PortalUser = "PortalUser";
  public static VodafoneSMS = "VodafoneSMS";
  public static XTND_Druckshop = "XTND_Druckshop";
  public static XTND_Kampagnen = "XTND_Kampagnen";
  public static XTND_Nutzerverwaltung = "XTND_Nutzerverwaltung";
  public static XTND_Produktbestellung = "XTND_Produktbestellung";
  public static XTND_Provisionen = "XTND_Provisionen";
  public static XTND_Provisionssimulation = "XTND_Provisionssimulation";
  public static XTND_Reporting = "XTND_Reporting";
  public static XTND_ShopManager = "XTND_ShopManager";
  public static VBtool = "VBtool";
  public static E3_user_administration = "E3_user_administration";
  public static VBBA = "VBTool_BrandAdministration";
  public static TemoDealer = "TemoDealer";
  public static TemoBackOffice = "TemoBackOffice";

  public static FraudMgmt = "FraudMgmt";
  public static DsArchive = "DsArchive";

  public static VB = "Vertriebsbeauftragter";
  public static VL = "Vertriebsleiter";
  public static NL = "Niederlassungsleiter";
  public static NS = "Niederlassungssupport";
  public static BO = "Backoffice";
  public static HL = "Hotline";
}

export class VfPositions {
  public static Hotline = 1;
  public static Hauptabteilungsleiter = 2;
  public static KeyAccountManager = 3;
  public static Backoffice = 4;
  public static Vertriebsleiter = 5;
  public static Vertriebsbeauftragter = 6;
  public static NA = 7;
  public static Niederlassungsleiter = 8;
  public static Niederlassungssupport = 9;
}


export class BrandKeys {
  public static vodafone: string = 'vf';
  public static vodafoneBusiness: string = 'vfb';
  public static vfFN: string = 'vfFn'
  public static otelo: string = 'otelo';
  public static kabel: string = 'kabel';
  //in easy4 the brand is finally named how it should be so for now we have both
  public static cable: string = 'cable';
  public static dsl: string = 'dsl';
  public static unity: string = 'unity';
  public static tivano: string = 'tivano';
};
