import StorageHelper from "./StorageHelper";
import { HttpMethods, WebSites } from "./Defaults";
import ApiAsync from "./ApiAsync";
import { IProductFilterModel } from "../screens/epm/Models";
import { IEpmProduct } from "../model/data/IEpmProduct";
import IEpmProperty from "../model/data/Property";
import IEpmProperties from "../model/data/Properties";
import { ILogJournal, ILogMonitoring } from "../model/data/ILogJournal";


export class LogManager {
    private base: string;

    constructor() {
        this.base = StorageHelper.getWebsites().filter(x => x.key == WebSites.log)[0].text;

        if (this.base.endsWith('/')) {
            this.base = this.base.substring(0, this.base.length - 1)
        }

        if (!ApiAsync.token) {
            ApiAsync.token = StorageHelper.getUser()!.easyToken;
        };
    }

    async getTypes(): Promise<ILogJournal[] | string> {
        const api = `${this.base}/log/getTypes`;

        try {
            const result = await ApiAsync.run<ILogJournal[]>(api, HttpMethods.get);

            return result;
        } catch (error: any) {
            if (error) {
                return error.message
            }
        };
        return ""
    }

    async getContainers(): Promise<ILogMonitoring[] | string> {
        const api = `${this.base}/monitoring/GetContainers`;

        try {
            const result = await ApiAsync.run<ILogMonitoring[]>(api, HttpMethods.get);

            return result;
        } catch (error: any) {
            if (error) {
                return error.message
            }
        };
        return ""
    }
};

export default LogManager;