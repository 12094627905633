import React, { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { IEpmProduct } from "../../../model/data/IEpmProduct";
import IEpmProperties from "../../../model/data/Properties";
import IEpmProperty from "../../../model/data/Property";
import Global from "../../../utils/Global";
import EpmManager from "../../../utils/EpmManager";
import { TableConfig } from "../../../components/table/TableWrapper";

const NewProductForm = ({ show, onHide, setProductState }) => {
    const epmManager = new EpmManager
    const [productData, setProductData] = useState<IEpmProduct>({});
    const [brands, setBrands] = useState<{ value: string | undefined; label: string | undefined; }[]>([]);
    const [families, setFamilies] = useState<{ value: string | undefined; label: string | undefined; }[]>([]);
    const [categories, setCategories] = useState<{ value: string | undefined; label: string | undefined; }[]>([]);

    useEffect(() => {
        async function fetchBrands() {
            try {
                const response = await epmManager.getAllBrands();
                const brandOptions = response.map((brand) => ({
                    value: brand.id,
                    label: brand.name,
                }));
                setBrands(brandOptions);
            } catch (error) {
                console.error("Error fetching brands:", error);
            }
        }

        fetchBrands();

        async function fetchFamilies() {
            const response = await epmManager.getProductFamilies();

            const familyOptions = response.map((family) => ({
                value: family.id,
                label: family.name
            }))

            setFamilies(familyOptions)
        }

        fetchFamilies();

        async function fetchCategories() {
            const response = await epmManager.getAllCategories();

            const categoryOptions = response.map((category) => ({
                value: category.id,
                label: category.name
            }))

            setCategories(categoryOptions);
        }

        fetchCategories();
        return () => {
            // const currentDate = new Date();
            // const formattedDate = currentDate.toISOString().replace('T', ' ').slice(0, -1);

            setProductData({
                brandId: undefined,
                familyId: undefined,
                // activationFee: undefined,
                creditDebitType: 1,
                name: "",
                activeStatus: 1,
                categoryId: undefined,
                // activationTypeId: undefined,
                // customerTypeId: undefined,
                osfKey: "",
                // changed: formattedDate,
                key: Global.getUniqueId(),
            });
        };
    }, [show]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        console.log(name, value);
        

        setProductData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleAddProduct = async () => {
        try {
            const newProductId = await epmManager.addProduct(productData);
            const newProduct = {
                id: newProductId,
                ...productData,
            };

            setProductData({
                key: "",
                brandId: undefined,
                familyId: undefined,
                creditDebitType: undefined,
                name: "",
                activeStatus: undefined,
                categoryId: undefined,
                osfKey: "",
            });

            onHide();
            // const model = { needle: "", paging: TableConfig.initPaging() }
            // const responseProducts: { data: {}, paging: {} } = await epmManager.filterProducts(model)

            // setProductState((prevState) => ({
            //     ...prevState,
            //     products: responseProducts.data,
            //     paging: responseProducts.paging,
            // }));
            // console.log(responseProducts.data, responseProducts.paging);

        } catch (error) {
            console.error("Error adding product:", error);
        }
    };

    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>Add New Product</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group controlId="name">
                        <Form.Label>Name</Form.Label>
                        <Form.Control
                            type="text"
                            name="name"
                            value={productData.name}
                            onChange={handleInputChange}
                        />
                    </Form.Group>
                    <Form.Group controlId="brandId">
                        <Form.Label>Brand</Form.Label>
                        <select
                            className="form-control"
                            name="brandId"
                            value={productData.brandId}
                            onChange={handleInputChange}
                        >
                            <option value="">Select a Brand</option>
                            {brands.map((brand) => (
                                <option key={brand.value} value={brand.value}>
                                    {brand.label}
                                </option>
                            ))}
                        </select>
                    </Form.Group>
                    <Form.Group controlId="familyId">
                        <Form.Label>Family</Form.Label>
                        <select
                            className="form-control"
                            name="familyId"
                            value={productData.familyId}
                            onChange={handleInputChange}
                        >
                            <option value="">Select a Product Family</option>
                            {families.map((family) => (
                                <option key={family.value} value={family.value}>
                                    {family.label}
                                </option>
                            ))}
                        </select>

                    </Form.Group>
                    {/* <Form.Group controlId="activationFee">
                        <Form.Label>Activation Fee</Form.Label>
                        <Form.Control
                            min={0}
                            type="number"
                            name="activationFee"
                            value={productData.activationFee?.toString()}
                            onChange={handleInputChange}
                        />
                    </Form.Group> */}
                    <Form.Group controlId="creditDebitType">
                        <Form.Label>Credit/Debit Type</Form.Label>
                        <div>
                            <Form.Check
                                type="checkbox"
                                id="creditCheckbox"
                                label="Credit"
                                name="creditDebitType"
                                value="1"
                                checked={productData.creditDebitType?.toString() === "1" || productData.creditDebitType === undefined}
                                onChange={handleInputChange}
                            />
                            <Form.Check
                                type="checkbox"
                                id="debitCheckbox"
                                label="Debit"
                                name="creditDebitType"
                                value="2"
                                checked={productData.creditDebitType?.toString() === "2"}
                                onChange={handleInputChange}
                            />
                        </div>
                    </Form.Group>

                    {/* <Form.Group controlId="monthlyFee">
                        <Form.Label>Monthly Fee</Form.Label>
                        <Form.Control
                            min={0}
                            type="number"
                            name="monthlyFee"
                            value={productData.monthlyFee?.toString()}
                            onChange={handleInputChange}
                        />
                    </Form.Group> */}
                    <Form.Group controlId="activeStatus">
                        <Form.Label>Active/Not Active</Form.Label>
                        <div>
                            <Form.Check
                                type="checkbox"
                                id="activeCheckbox"
                                label="Active"
                                name="activeStatus"
                                value="1"
                                checked={productData.activeStatus?.toString() === "1" || productData.activeStatus === undefined}
                                onChange={handleInputChange}
                            />
                            <Form.Check
                                type="checkbox"
                                id="inactiveCheckbox"
                                label="Inactive"
                                name="activeStatus"
                                value="0"
                                checked={productData.activeStatus?.toString() === "0"}
                                onChange={handleInputChange}
                            />
                        </div>
                    </Form.Group>
                    <Form.Group controlId="categoryId">
                        <Form.Label>Category</Form.Label>
                        <select
                            className="form-control"
                            name="categoryId"
                            value={productData.categoryId}
                            onChange={handleInputChange}
                        >
                            <option value="">Select a Category</option>
                            {
                                categories.map((category) => (
                                    <option key={category.value} value={category.value}>
                                        {category.label}
                                    </option>
                                ))
                            }
                            {/* Add more options as needed */}
                        </select>
                    </Form.Group>
                    {/* <Form.Group controlId="activationTypeId">
                        <Form.Label>Actiovation Type Id</Form.Label>
                        <Form.Control
                            min={1}
                            type="number"
                            name="activationTypeId"
                            value={productData.activationTypeId?.toString()}
                            onChange={handleInputChange}
                        />
                    </Form.Group> */}
                    {/* <Form.Group controlId="customerTypeId">
                        <Form.Label>Customer Type Id</Form.Label>
                        <Form.Control
                            min={0}
                            type="number"
                            name="customerTypeId"
                            value={productData.customerTypeId?.toString()}
                            onChange={handleInputChange}
                        />
                    </Form.Group> */}
                    <Form.Group controlId="osfKey">
                        <Form.Label>Osf Key</Form.Label>
                        <Form.Control
                            type="text"
                            name="osfKey"
                            value={productData.osfKey}
                            onChange={handleInputChange}
                        />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onHide}>
                    Cancel
                </Button>
                <Button variant="primary" onClick={handleAddProduct}>
                    Add
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default NewProductForm;
