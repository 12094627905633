import { Modal } from "office-ui-fabric-react";
import React from "react";
import { IApiError } from "../utils/ApiAsync";
import { ErrorMessages } from "../utils/messages";

export enum ELoaderVfType
{
    full = 0,
    spinner = 1
};

export interface ILoaderVfProps 
{
    type: ELoaderVfType;
    message?: ILoaderMessage;
    onClose?: any;
};

export enum EMessageType {
    undefined = 0,
    content = 1,
    error = 2,
    loader = 4
};

export interface ILoaderMessage {
    title: string;
    body: any;
    type: EMessageType;
};

export interface ILoaderVfState {
    hidden: boolean;
    message?: ILoaderMessage;
};

export class LoaderVf extends React.Component<ILoaderVfProps, ILoaderVfState> {
    private closable: boolean = false;

    constructor(props: any) {
        super(props);

        this.state = {
            hidden: false,
            message: this.props.message
        };
    };

    static getDerivedStateFromProps(props, state) {
        if (props.message && props.message.body) {
            if(!state.message || props.message.body !== state.message.body)
            return {
                message: props.message
            };
        };

        // Return null to indicate no change to state.
        return null;
    };

    componentDidUpdate(prevProps: Readonly<ILoaderVfProps>, prevState: Readonly<ILoaderVfState>, snapshot?: any): void {
        //console.log('cdu', prevProps);
    };

    render = () => {
        if(this.props.type == ELoaderVfType.spinner) {
            return this.renderSpinner();
        }

        if(this.state.hidden) {
            return <></>
        };

        return <div className="loader-vf">
                <div className="wrapper" onClick={(x)=>{this.onDismiss();}}>
                    <div className="inner">
                        {
                            this.state.message && ((this.state.message.type & EMessageType.loader) != EMessageType.loader) ? this.renderContent() : 
                            <>
                                {this.renderSpinner()}

                                <br/>
                                {
                                    this.state.message ? <p>{this.state.message.body}</p> : <p>  Bitte warten ...</p>
                                }
                            </>
                        }
                    </div>
                </div>
        </div>
    };

    onDismiss = () => {
        if(this.closable) {
            this.setState({hidden: true});

            if(this.props.onClose) {
                this.props.onClose();
            };
        };
    };

    renderSpinner = () => {
        return <div className="inner">
                <span className="1LoadingSpinnerstyle__LoadingSpinner-sc-8vhfp9-0 jrWTEh">
                <svg width="34" height="44" viewBox="0 0 34 44" fill="none" xmlns="http://www.w3.org/2000/svg" data-di-res-id="bab585af-5bad5c4f" data-di-rand="1657292271008"><path d="M25.0866 15.9962C22.7495 14.7514 21.4788 14.5559 18.482 14.057C18.482 8.56832 21.9782 3.07968 21.9782 3.07968C21.9782 3.07968 16.551 4.00486 12.5013 6.57245C9.73842 8.32411 7.14499 10.9895 5.50887 13.558C2.40439 18.0002 1.84278 22.5264 2.03315 26.2641C2.24667 35.0364 9.56734 41.5797 17.5622 41.5797C25.557 41.5797 32 35.3206 32 27.2828C32 21.6166 29.359 18.3599 25.3801 16.1557" stroke="#E60000"></path></svg>
            </span>        
        </div>
    };

    renderContent() {

        this.closable = true;

        return <div>
            <h3>{this.state.message?.title}</h3>
            <div dangerouslySetInnerHTML={{__html:  this.state.message?.body}}></div>
        </div>
    };

    static parseApiError(err?: IApiError) : ILoaderMessage | undefined {
        if(!err) { return undefined; };

        var res:ILoaderMessage = {title: ErrorMessages.error, body: ErrorMessages.apiError, type: EMessageType.error };

        if(err.detail) {
            res.body = `${ErrorMessages.apiError} <br/> ${err.message} (${err.detail.code})`;
        };

        return res;
    };
};

export default LoaderVf;