import React from 'react';
import { DatePicker } from 'office-ui-fabric-react';
import { Button } from 'react-bootstrap';

export interface IDateTimePickerProps {
    label: string;
    date?: Date;
    onChange?: any
    enableClearButton: boolean;
};

export interface IDateTimePickerState {
    label: string;
    date?: Date;
    timeStr?: string;
};

export class DateTimePicker extends React.Component<IDateTimePickerProps, IDateTimePickerState> {
    constructor(props: any) {
        super(props);

        this.state = {
            label: this.props.label,
            date: this.props.date,
            timeStr: this._formatTimeToStr(props.date)
        };

        this._clearBtnClicked = this._clearBtnClicked.bind(this);
    }

    render() {
        let clearBtn = <></>;

        if (this.props.enableClearButton) {
            clearBtn = <Button onClick={this._clearBtnClicked}>Clear</Button>;
        }

        return <div>
            <DatePicker id="date"
                placeholder="dd.MM.yyyy"
                label={this.props.label}
                value={this.state.date}
                onSelectDate={this._onSelectDate}
                formatDate={this._onFormatDate}
                parseDateFromString={this._onParseDateFromString} />
            <input placeholder="HH:mm" id="time" name="time" onChange={this._onTimeChange} value={this.state.timeStr} />
            {clearBtn}

        </div>;
    }

    private _onFormatDate = (date: Date | undefined): string => {
        if (date) {
            return this._leftpad(date.getDate(), 2) + '.' + this._leftpad(date.getMonth() + 1) + '.' + this._leftpad(date.getFullYear(), 4);
        }

        return "";
    }

    private _onParseDateFromString = (value: string): Date => {
        const date = this.state.date || new Date();
        const values = (value || '').trim().split('.');
        const day = values.length > 0 ? Math.max(1, Math.min(31, parseInt(values[0], 10))) : date.getDate();
        const month = values.length > 1 ? Math.max(1, Math.min(12, parseInt(values[1], 10))) - 1 : date.getMonth();
        let year = values.length > 2 ? parseInt(values[2], 10) : date.getFullYear();
        if (year < 100) {
            year += date.getFullYear() - (date.getFullYear() % 100);
        }
        return new Date(year, month, day);
    }

    private _clearBtnClicked = () => {
        this.setState({ date: undefined, timeStr: '' });
        if(this.props.onChange) {
            this.props.onChange();
        }
    }

    private _onSelectDate = (date: Date | null | undefined): void => {
        if (date === null) {
            date = undefined;
        }

        let d = this.state.date;
        if (!d && date) {
            d = new Date();
        }

        if (d && date) {
            d.setFullYear(date.getFullYear());
            d.setMonth(date.getMonth());
            d.setDate(date.getDate());
        } else {
            d = undefined;
        }

        this.setState({ date: d });
        console.log("date selected: " + d);
        if (this.props.onChange) {
            this.props.onChange(d);
        }
    }

    _onTimeChange = (ev: any) => {
        let time = ev.currentTarget.value as string;
        if (time.length < 5) {
            this.setState({ timeStr: time });
        } else if (time.length === 5) {
            const values = (time).trim().split(':');
            if (values.length === 2) {
                const hours = parseInt(values[0]);
                const mins = parseInt(values[1]);

                let date = this.state.date;
                if (date) {
                    date.setHours(hours);
                    date.setMinutes(mins);
                    date.setSeconds(0);

                    this.setState({ date: date, timeStr: time });
                    console.log('time changed: ' + date);
                    if (this.props.onChange) {
                        this.props.onChange(date);
                    }
                }
            }
        }
    }

    _formatTimeToStr = (date?: Date) => {

        console.log(date);
        if (date) {
            return this._leftpad(date.getHours(), 2)
                + ':' + this._leftpad(date.getMinutes(), 2);
        }

        return undefined;
    }

    _leftpad = (val: number, resultLength = 2, leftpadChar = '0') => {
        return (String(leftpadChar).repeat(resultLength)
            + String(val)).slice(String(val).length);
    }
}

export default DateTimePicker;