import { EScreenMode } from "../../../../src/model/app/Enums";
import { IEpmProduct } from "../../../../src/model/data/IEpmProduct";
import React, { useEffect, useState } from "react";
import EpmManager from "../../../utils/EpmManager";
import { IModelPanel } from "../../../interfaces/IModel";
import { ITableScreen } from "../../../interfaces/ITableScreen";
import Global from "../../../utils/Global";
import LoaderVf, { ELoaderVfType } from "../../../Support/LoaderVf";
import { ActionButton, Dropdown, Panel, PanelType, Pivot, PivotItem, PrimaryButton, TextField } from "office-ui-fabric-react";
import { Col, Container, Row } from "react-bootstrap";
import { MainTexts } from "../../../utils/messages";
import { PropertiesEditor } from "./PropertiesEditor";

import linq from "linq";
import StorageHelper from "../../../utils/StorageHelper";
import IKeyText from "../../../model/app/IKeyText";
import TableFilter from "../../../components/table/TableFilter";
import IEpmProperties from "../../../model/data/Properties";


export const pivotMain = "main";
export const pivotProps = "props"

export const getPivots = (): string[] => {
    return [pivotMain];
}

export const EpmProductEditPanel = ({
    id,
    onDismiss,
}) => {
    const prefix: string = "ef";
    const epmManager: EpmManager = new EpmManager;

    const [mode, setMode] = useState(EScreenMode.loading);
    const [pivot, setPivot] = useState(pivotMain);
    const [product, setProduct] = useState<IEpmProduct | undefined>(undefined)
    const [nameValue, setNameValue] = useState("");
    const [changedValue, setChangedValue] = useState("");

    const isNew = id == Global.getEmptyGuid();

    useEffect(() => {
        loadData();
    }, []);

    const loadData = async () => {
        let data = await epmManager.getProduct(id);
        setProduct(data)
        setNameValue(data?.name ?? "");
        setMode(EScreenMode.view)
    }

    const switchView = (key: string) => {
        if (pivot !== key) {
            setMode(isNew ? mode : EScreenMode.view);
            setPivot(key);
        }
    };

    const onSave = async () => {
        if (pivot === pivotProps) {
            await patch();
        }
    };

    const patch = async () => {
        setMode(EScreenMode.loading);
        const u = (product?.properties || [])[0] as IEpmProperties;
        let x: any = "";
        if (pivot === pivotProps) {
            const body = {
                property: u.property,
                id: u.id,
                productId: u.productId,
                parentId: u.parentId,
                propertyId: u.propertyId,
                value: changedValue !== "" ? changedValue : u.value
            }
            x = await epmManager.editPropertyValue(body);
        }

        if (x.error) {
            u.error = x.error;
        } else {
            const updatedProperties = product?.properties?.map((prop) =>
                prop.id === u.id ? { ...prop, value: changedValue } : prop
            );

            setProduct((prevProduct: IEpmProduct | undefined) => ({
                ...(prevProduct as IEpmProduct),
                properties: updatedProperties
            }));
            setMode(EScreenMode.view)
        }
    }

    const onRenderFooterContent = (): JSX.Element => {
        let ctrl = <></>;

        if (pivot === pivotProps) {
            if (mode === EScreenMode.edit) {
                ctrl = (
                    <>
                        <ActionButton
                            iconProps={{ iconName: "Cancel" }}
                            text={MainTexts.cancel}
                            onClick={() => {
                                setProduct(product);
                                setMode(EScreenMode.view);
                            }}
                        />
                        <PrimaryButton text={MainTexts.save} onClick={onSave} />
                    </>
                );
            } else {
                ctrl = (
                    <PrimaryButton
                        text={MainTexts.edit}
                        onClick={() => setMode(EScreenMode.edit)}
                    />
                );
            }
        }

        return <div className="ea">{ctrl}</div>;
    };


    const onChange = (e, v, k) => {
        if (product) {
            const data = { ...product } as IEpmProduct;
            let key = k;

            if (key) {
                data[key] = v.key;
            } else {
                key = e.target.id.replace(prefix + "-", "");
                data[key] = e.target.value;
                setNameValue(e.target.value);
            }

            setProduct(data);
        }
    };


    const renderMain = () => {
        if (product) {
            const m = product as IEpmProduct;

            return (
                <Container className="ee">
                    <Row>
                        <Col md={2} xs={2}>
                            {MainTexts.id}
                        </Col>
                        <Col md={10} xs={10}>
                            <TextField
                                id={prefix + "-id"}
                                disabled={true}
                                value={m.id?.toString()}
                                readOnly
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={2} xs={2}>
                            Name
                        </Col>
                        <Col md={10} xs={10}>
                            <TextField
                                multiline
                                rows={3}
                                id={prefix + "-name"}
                                disabled={true}
                                value={nameValue}
                                onChange={(e, v) => onChange(e, v, undefined)}
                            />
                        </Col>
                    </Row>
                </Container>
            );
        }
    };

    let ctrl = <></>;
    if (
        mode === EScreenMode.loading ||
        (product && product.error)
    ) {
        ctrl = (
            <LoaderVf
                type={ELoaderVfType.full}
                message={LoaderVf.parseApiError(
                    // product ? product.error : undefined
                )}
            />
        );
    } else {
        ctrl = (
            <Panel
                isOpen={true}
                onDismiss={onDismiss}
                type={PanelType.medium}
                headerText="Product"
                isFooterAtBottom={true}
                onRenderFooterContent={onRenderFooterContent}
            >
                <Pivot
                    onLinkClick={(e) => {
                        switchView(e!.props.itemKey as string);
                    }}
                    defaultSelectedKey={pivot}
                >
                    <PivotItem
                        itemKey={pivotMain}
                        headerText="Data"
                        onClick={() => {
                            switchView(pivotMain);
                        }}
                    >
                        <Container>
                            <Row>
                                <Col>{renderMain()}</Col>
                            </Row>
                        </Container>
                    </PivotItem>
                        
                    <PivotItem itemKey={pivotProps} headerText="Properties">
                        {product && product.properties && product.properties.length > 0 ? (
                            <PropertiesEditor id={product.id} mode={mode} changedValue={changedValue} setChangedValue={setChangedValue} />

                        ) : (
                            <div>No properties available.</div>
                        )}
                    </PivotItem>
                </Pivot>
            </Panel>
        );
    }

    return ctrl;
}




