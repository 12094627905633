export class SUB {
  public Id: number = 0;
  public Name: string = "";
  public Key: string = "";
  public BrandId: number = 0;
  public ActivationFee: number = 0;
  public Properties: any = {};
  public MonthlyFee: number = 0;
  public Sort: number = 0;
  public Model: string = "";

  public ui_name: string = "";
  public ui_tooltip: string = "";
  public ui_extra: string = "";

  public Selected: boolean = false;
  public ProductId: number = 0;
  public Ultracard: number = 0;
  public ProductSubId: number = 0;
  public BusinessCaseId: number = 0;
  public ActivationTypeId: number = 0;
}
