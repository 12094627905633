import * as React from 'react';

class Loader extends React.Component {
    public render() {
        let com = (
            <div className="loader">
                    <img alt={"Loading..."} src="/assets/loader.gif" width="150" height="150"/>
                </div>
        );

        return com;
    }
  };

  export default Loader;