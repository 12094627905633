import { ListValueOption } from "./ListValueOption";
import { SUB } from "./SUB";

import Service from "../data/Service";

export class Product {
  public Id: number = 0;
  public Name: string = "";
  public Key: string = "";
  // public SerialKey: string = '';
  public CreditDebitId: number = 0;
  public ActivationTypeId: number = 0;
  public Validity: string = "";
  public Sort: number = 0;
  public BrandId: number = 0;
  public Family: any = {};
  public FamilyName: string = "";
  // public ValidFrom: Date = new Date();
  // public ProductPaymentId: number = 0;
  public CustomerTypeId: number = 0;
  // public MnpId: number = 0;
  public BusinesCaseId: number = 0;
  public CategoryId: number = 0;
  public ProductSubCount: number = 0;
  public BusinesCase: number = 0;
  public ActivationFee: number = 0;
  public MonthlyFee: number = 0;
  public Model: string = "";
  public ActionFlagValidFrom: Date = new Date();
  public ActionFlagValidTo: Date = new Date();
  // public ProductType: string = '';
  // public ValidTo: Date =  new Date();
  public SortFocus: number = 0;
  public FamilyId: number = 0;
  public Properties: any = {};
  public AgeValidation: string = "";
}

export interface IProductEditModel {
  services: ListValueOption[];
  product: Product;
  optionsPayment: ListValueOption[];
  subs: ListValueOption[];
  families: ListValueOption[];
  customerTypes: ListValueOption[];
  creditDebitTypes: ListValueOption[];
  categoryBrands: ListValueOption[];
  activationTypes: ListValueOption[];
  // mnpTypes: ListValueOption[];
}

export class IProductModel {
  public productsSubsServices: ProductInfo[] = [];
}

export class ProductInfo {
  public product: Product = new Product();
  public allSubsServ: SubServices[] = [];
}

export class SubServices {
  public sub: SUB = new SUB();
  public allServices: Service[] = [];
}
