export interface HandlerModel {
  companyName: string;
  companyNameAdditional: string;
  moreInfo: any;
  address: string;
  land: string;
  federalState: string;
  zip: string;
  city: string;
  longitude: number;
  latitude: number;
  phone: string;
  fax: string;
  email: string;
  filter_account: number;
  void: string;
  status: string;
  region_id: number;
  regionIdText: string;
  fnvoid: string;
  sales_id?: number;
  vpkn: string;
  level: string;
  level_1: string;
  level_1_dealername: string;
  level_2: string;
  level_2_dealername: string;
  level_3: string;
  level_3_dealername: string;
  salesRepresentative: string;
  salesManager: string;
  key_account_manager: string;
  accountId: number;
  bookDSL: boolean;
  bookKABEL: boolean;
  bookOTELO: boolean;
  bookUNITY: boolean;
  bookVF: boolean;
  bookCallYa: boolean;
  bookVVLHardware: boolean;
  dealerType: string;
}

export class MoreInfoShop {
  public tel: Tel = new Tel();
  public address: Address = new Address();
}

export class Tel {
  public cc?: string = "";
  public ndc?: string = "";
  public sn?: string = "";
}

export class Address {
  public name?: any = {};
  public street?: string = "";
  public nr?: string = "";
  public city?: string = "";
  public zip?: string = "";
}

export class mTanHandlerModel {
  accountID: string = "";
  companyName: string = "";
  address: string = "";
  city: string = "";
  zip: string = "";
  salesRepresentative: string = "";
  salesManager: string = "";
  level_1: string = "";
  level_2: string = "";
  level_3: string = "";
  level: string = "";
  registrationDate: string = "";
  tanAuthDateSet: string = "";
}
