import { IApiResponse, ERAPIAction } from "../model/app/IApiResponse";
import { HttpMethods, StorageKeys } from "../utils/Defaults";
import Global from "../utils/Global";
import AccountManager from "./AccountManager";
import ApiAsync from "./ApiAsync";
import AppRouter from "./AppRouter";
import EpmManager from "./EpmManager";
import LogManager from "./LogManager";
import StorageHelper from "./StorageHelper";

export class Api {
	public static fetch(
		url: string,
		model: any | undefined,
		method: string,
		callback: any
	) {
		let cfg = Global.getConfiguration();

		if (url.indexOf("http") != 0) {
			url = cfg.apiUrl + url;
		}

		console.log("Api:" + url);
		let what: any = {
			method: method == null ? "GET" : method,
			credentials: "include",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
			},
			mode: "cors",
		};

		if (method != HttpMethods.get) {
			what["body"] = JSON.stringify(model);
		}

		fetch(url, what)
			.then((res) => res.json())
			.then((res) => {
				console.log(res);
				return res;
			})
			.then(
				(res) => {
					Api.parseResponse(res, () => {
						callback(res as IApiResponse);
					});
				},
				(res) => {
					callback(res as IApiResponse);
				}
			);
	}

	// public static getUser(callback: any) {
	//     let app = Global.getConfiguration();

	//     if(window.location.pathname != '/') {
	//         callback();

	//         return;
	//     };
	//     Api.fetch(app.envUrl + 'environment/support',
	//         null,
	//         HttpMethods.get,
	//         (a: IApiResponse) => {
	//             Api.parseResponse(a, (api: IApiResponse) => {
	//                 StorageHelper.set(StorageKeys.keyUser, api.data.user);
	//                 StorageHelper.set(StorageKeys.keySupport, api.data.support);

	//                 Api.getStructures(undefined);

	//                 if (callback) {
	//                     AppRouter.reload();

	//                     AppRouter.routeTo(window.location.href,'/')

	//                     callback();
	//                 }
	//             });
	//         });
	// };

	public static getUserOnly(callback: any) {
		let app = Global.getConfiguration();

		Api.fetch(
			app.envUrl + "environment/support",
			null,
			HttpMethods.get,
			(a: IApiResponse) => {
				Api.parseResponse(a, (api: IApiResponse) => {
					console.log("support arrived", api.data);

					if (api.data.user.easyToken.indexOf("Bearer ") < 0) {
						api.data.user.easyToken = "Bearer " + api.data.user.easyToken;
					}

					StorageHelper.set(StorageKeys.keyUser, api.data.user);
					StorageHelper.set(StorageKeys.keySupport, api.data.support);

					if (callback) {
						callback();
					}
				});
			}
		);
	}

	public static async getStructures(callback: any) {
		try {
			var am = new AccountManager();

			var s = await am.getStructures();

			StorageHelper.set(StorageKeys.keyStructures, s);
		} catch (e) {
			console.error("getStructures", e);
		}

		if (callback) {
			callback();
		}
	}

	public static async getContainers(callback: any) {
		try {
			const logManager = new LogManager();

			const response = await logManager.getContainers();

			StorageHelper.set(StorageKeys.monitoringContainers, response);
		} catch (e) {
			console.error("getContainers", e);
		}

		if (callback) {
			callback();
		}
	}

	public static async getBrands(callback: any) {
		try {
			let epmManager = new EpmManager();

			const response = await epmManager.getAllBrands();

			StorageHelper.set(StorageKeys.keyBrands, response);
		} catch (e) {
			console.error("getBrands", e);
		}

		if (callback) {
			callback();
		}
	}

	public static logout() {
		let user = StorageHelper.getUser();
		if (user) {
			StorageHelper.clear();
			window.location.href = user.logoutUrl + "?app=" + Api.getAppUrl();
		}
	}

	public static getAppUrl() {
		let path = window.location.protocol + "//" + window.location.host;

		return path;
	}

	public static parseResponse(api: IApiResponse, callback: any) {
		if (api) {
			if (api.error && api.error.action) {
				if (
					api.error.action.type == ERAPIAction.login ||
					api.error.action.type == ERAPIAction.follow
				) {
					StorageHelper.clear();
					window.location.href =
						api.error.action.url + "?app=" + Api.getAppUrl();
					return;
				}
			}

			if (callback) {
				callback(api);
			}
		}
	}
}

export default Api;
