import React from 'react';
import { Brand } from '../model/data/Brand';
import { EScreenMode } from '../model/app/Enums';
import Api from '../utils/Api';
import linq from 'linq';
import { IApiResponse } from '../model/app/IApiResponse';
import {
  DocumentCard,
  DocumentCardActivity,
  DocumentCardTitle,
  DocumentCardDetails,
  DocumentCardImage,
  IDocumentCardStyles,
  IDocumentCardActivityPerson
} from 'office-ui-fabric-react/lib/DocumentCard';
import { ImageFit } from 'office-ui-fabric-react/lib/Image';
import Global from '../utils/Global';
import ProductScreen from './productScreen';

import { SUB } from '../model/data/SUB';
import { Label, Dialog, DialogFooter, DialogType, Icon, Pivot, PivotItem, Panel, PanelType, PrimaryButton, DefaultButton, TextField, BaseButton, Checkbox } from 'office-ui-fabric-react';
import StorageHelper from '../utils/StorageHelper';
import Button from 'react-bootstrap/Button';
import { ProgressIndicator } from 'office-ui-fabric-react/lib/ProgressIndicator';



export interface ISUBScreenState {
  subs?: SUB[],
  mode: EScreenMode,
  changed: boolean,
  hidden: boolean,
  disableButtonKeyField: boolean,
  disableButtonNameField: boolean
};


export class SUBscreen extends React.Component<any, ISUBScreenState> {
  private url: string = "activationdata/";
  public currentSUB: SUB;

  private cardStyles: IDocumentCardStyles = {
    root: {
      display: 'inline-block', marginRight: 20, marginBottom: 20, width: 320, backgroundColor: '#F5F5F5', borderRadius: '15px',
      boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.19)'
    }
  };
  private people: IDocumentCardActivityPerson[] = [
    { name: 'Jens Seidel', profileImageSrc: "/assets/img/jens.jpg" },
  ];

  constructor(props: any) {
    super(props);



    this.onDataArrival = this.onDataArrival.bind(this);
    this._openEditor = this._openEditor.bind(this);
    this._saveSUB = this._saveSUB.bind(this);
    this._refreshModel = this._refreshModel.bind(this);
    this._hidePanel = this._hidePanel.bind(this);
    this.prepareForDeletion = this.prepareForDeletion.bind(this);
    this._refreshProperty = this._refreshProperty.bind(this);
    this._onNo = this._onNo.bind(this);
    this._onYes = this._onYes.bind(this);
    this.onChangeSUB = this.onChangeSUB.bind(this);
    this._getErrorMessageKeyField = this._getErrorMessageKeyField.bind(this);
    this._getErrorMessageNameField = this._getErrorMessageNameField.bind(this);



    this.currentSUB = new SUB();


    this.state = {
      subs: undefined,
      mode: EScreenMode.loading,
      changed: false,
      hidden: true,
      disableButtonKeyField: false,
      disableButtonNameField: false

    };

    //1. name controller, 2. method's name, 3. (id)  backEnd->boAreaRegistration.cs for more info
    Api.fetch(this.url + 'subs/' + props.brandKey, undefined, "GET", this.onDataArrival);

  }

  public render() {
    if (this.state.mode === EScreenMode.loading) {
      return <ProgressIndicator label="Daten werden geladen" description="Bitte warten Sie" />;
    }
    else {
      return this.renderData();
    }
  };



  private renderData(): JSX.Element {
    let ctrl = (<></>);

    let t1 = this.props.title == null ? 'Bitte bestätigen' : this.props.title;
    let t2 = this.props.text == null ? 'Sind Sie sicher?' : this.props.text;

    if (this.state.subs) {
      ctrl = (
        <>

          <Button variant="danger" onClick={() => { this._openEditorForNewProduct(this.currentSUB) }}>+ Neu SUB</Button>
          <br />
          {
            this.state.hidden ? <></> :
              <>
                <Dialog
                  hidden={this.state.hidden}
                  onDismiss={this._onNo}
                  dialogContentProps={{
                    type: DialogType.normal,
                    title: t1,
                    subText: t2
                  }}
                  modalProps={{
                    titleAriaId: t1,
                    subtitleAriaId: t2,
                    isBlocking: false,
                    containerClassName: 'ms-dialogMainOverride'
                  }}
                >
                  <DialogFooter>
                    <Button variant="danger" onClick={this._onYes}>Ja</Button>
                    <Button variant="secondary" onClick={this._onNo}>Abbrechen</Button>
                  </DialogFooter>
                </Dialog>
              </>
          }
          <div className="spacer-default" />
          {this.state.subs.map((i, idx) => (
            this.renderSUB(i)
          ))}

          {this.renderEditor()}
        </>
      );
    }

    return ctrl;
  };

  private _openEditorForNewProduct(dc: any) {

    this.setState({ mode: EScreenMode.edit });
    this._openEditor(undefined);
  };

  private renderSUB(sub: SUB): JSX.Element {
    let id: string = 'dc-' + sub.Id;
    let idButton: string = 'dc-' + sub.Id;


    let ctrl =
      (

        <DocumentCard id={id} key={sub.Id} styles={this.cardStyles} >

          <DocumentCardDetails>
            <DocumentCardTitle title={sub.Name} shouldTruncate />
          </DocumentCardDetails>


          <Icon className="dcicon" id={idButton} iconName="Edit" title="Dienste bearbeiten" onClick={this._openEditor} />
          <Icon className="dcicon" id={idButton} iconName="Delete" title="Dienste löschen" onClick={this.prepareForDeletion} />

        </DocumentCard>

      );

    return ctrl;
  };

  private _onNo() {
    this.setState({ hidden: true, mode: EScreenMode.view });
  };

  private _onYes() {
    Api.fetch(this.url + 'deleteSUB', this.currentSUB, "POST",
      (api: IApiResponse) => {
        this.setState({
          mode: EScreenMode.view,
          hidden: true,
          subs: api.data
        });
      });
    return this.renderSUB;
  };

  private prepareForDeletion(dc: any) {
    let id = dc.currentTarget.id.replace("dc-", "");
    if (this.state.subs) {
      this.currentSUB = linq.from<SUB>(this.state.subs)
        .where(x => x.Id === Number(id))
        .firstOrDefault();
      if (!this.currentSUB) {
        this.currentSUB = new SUB();
        this.currentSUB.BrandId = this.props.brandId;

      }
      this.setState({ hidden: false });
    }
  };

  private _openEditor(dc: any) {


    let id = dc == undefined ? "0" : dc.currentTarget.id.replace("dc-", "");
    if (this.state.subs) {
      this.currentSUB = linq.from<SUB>(this.state.subs)
        .where(x => x.Id === Number(id))
        .firstOrDefault();
      if (!this.currentSUB) {
        this.currentSUB = new SUB();
        this.currentSUB.BrandId = this.props.brandId;
      }

      var arr_from_json: any = undefined;
      if (this.currentSUB.Model === '') {

      } else {
        arr_from_json = JSON.parse(this.currentSUB.Model);
      }

      if (arr_from_json === undefined) {
        arr_from_json = JSON.parse('{"product":{},"xml":{},"product_ui":{"ui_tooltip":"","ui_name":"","ui_extra":""}}');
      }

      this.currentSUB.ui_name = arr_from_json.product_ui.ui_name;
      this.currentSUB.ui_tooltip = arr_from_json.product_ui.ui_tooltip;
      this.currentSUB.ui_extra = arr_from_json.product_ui.ui_extra;

      this.setState({ mode: EScreenMode.edit });
    }
  };

  private renderEditor(): JSX.Element {
    let ctrl = <></>

    ctrl =

      <Panel isBlocking={false} isOpen={this.state.mode === EScreenMode.edit} onDismiss={this._hidePanel} type={PanelType.medium} headerText={this.currentSUB.Name}>
        {

          <div>Bearbeiten</div>
        }
        {
          this.state.mode === EScreenMode.loading ? <>loading</> :
            (
              // this.state.response != null && this.state.response.error != null?
              // <div className="ed-error">{this.state.response.error.Message}</div>
              // :
              this.renderControl()
            )
        }


      </Panel>

    return ctrl;
  };

  public renderControl() {
    let isChecked: boolean = false;
    // if(this.currentSUB.Rentable==1){
    //       isChecked = true;
    // }else{
    //   isChecked =false;
    // }



    let ctrl = <div> <br />

      <Pivot>
        <PivotItem headerText="Generell">
          <div className="ms-Grid">
            <div className="ms-Grid-row">
              <TextField label="Name" name="Name" required value={this.currentSUB.Name} onChange={this._refreshModel} />
              <TextField label="Key" name="Key" required onGetErrorMessage={this._getErrorMessageKeyField}
                                    validateOnLoad={false} value={this.currentSUB.Key} onChange={this._refreshModel} />
              <TextField label="ActivationFee" name="ActivationFee" required value={this.currentSUB.ActivationFee.toString()} onChange={this._refreshModel} />
              <TextField label="MonthlyFee" name="MonthlyFee" required value={this.currentSUB.MonthlyFee.toString()} onChange={this._refreshModel} />
              <TextField label="Sort" name="Sort" required value={this.currentSUB.Sort.toString()} onChange={this._refreshModel} />
              <br />
              {/* <Checkbox checked={isChecked} label='Rentable' onChange={this.onChangeSUB} /> */}
            </div>
            <br />

            {/* <PrimaryButton text="Speichern" onClick={this._saveSUB} />
            <DefaultButton className="default-button-edit-screen" text="Abbrechen" onClick={this._hidePanel} /> */}
            <Button variant="primary" className="sub-screen-edit" disabled={this.state.disableButtonKeyField||this.state.disableButtonNameField} onClick={this._saveSUB} >Speichern</Button>
            <Button variant="secondary" onClick={this._hidePanel}>Abbrechen</Button>
          </div>
        </PivotItem>

        <PivotItem headerText="UI Text">
          <TextField label="ui_name" name="ui_name" required value={this.currentSUB.ui_name} onChange={this._refreshModel} />
          <TextField label="ui_tooltip" name="ui_tooltip" required value={this.currentSUB.ui_tooltip} onChange={this._refreshModel} />
          <TextField label="ui_extra" name="ui_extra" required value={this.currentSUB.ui_extra} onChange={this._refreshModel} />
        </PivotItem>

        <PivotItem headerText="XML Properties">
          {this.renderProps(undefined, 'ui_')}
        </PivotItem>
      </Pivot>
    </div>

    return ctrl;
  }

  private _getErrorMessageKeyField = (value: string): string => {

    const regex = /^[A-Za-z0-9_-]{1,31}$/gm;
    const str = value;
    let m;
    let err: string = '';


    if ((m = regex.exec(str)) !== null) {
        err = '';
        this.setState({ disableButtonKeyField: false });
    } else {
        err = `Das Feld sollte nur aus Buchstaben, Zahlen, Unterstrichen und Bindestrichen bestehen und nicht mehr als 31 Symbole.`;
        this.setState({ disableButtonKeyField: true });
    }


    return err;

}

private _getErrorMessageNameField = (value: string): string=>{
  const regex = /^[A-Za-z0-9_-\s&]{1,63}$/gm;

  const str = value;
  let m;
  let err: string = '';


  if ((m = regex.exec(str)) !== null) {
      err = '';
      this.setState({ disableButtonNameField: false });
  } else {
      err = `Das Feld sollte nur aus Buchstaben, Zahlen, &, Unterstrichen und Bindestrichen bestehen und nicht mehr als 63 Symbole.`;
      this.setState({ disableButtonNameField: true });
  }


  return err;
}

  private onChangeSUB(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) {
    // if(checked==true){
    // this.currentSUB.Rentable=1;
    //    }else{
    //      this.currentSUB.Rentable=0;
    //    }

    this.setState({
      changed: true,
      mode: EScreenMode.edit
    });
  }


  private renderProps(key: string | undefined, exclude: string | undefined): JSX.Element {
    let ctrl = <></>;
    let markup: JSX.Element[] = [];

    for (var item in this.currentSUB.Properties) {


      let torender: boolean = false;

      if (key && item.indexOf(key) >= 0) {
        torender = true;
      }

      if (!key && exclude) {
        torender = true;
      }

      if (exclude && item.indexOf(exclude) >= 0) {
        torender = false;
      }

      if (torender) {
        let c = <div key={item} className="ms-Grid-col ms-md12">
          <TextField label={item} name={item} value={this.currentSUB.Properties[item]} onChange={this._refreshProperty} />
        </div>;

        markup.push(c);
      }
    }



    ctrl = <div className="ms-Grid">
      <div className="ms-Grid-row">
        {markup}
      </div>
    </div>;

    return ctrl;
  };

  private _refreshProperty(ctrl: any) {
    if (ctrl) {
      this.currentSUB.Properties[ctrl.target.name] = ctrl.target.value;
      console.log(this.currentSUB.Properties);
      this.setState({ changed: true });
    }
  };


  private _hidePanel() {
    this.setState({ mode: EScreenMode.view });
  };

  private _refreshModel(ctrl: any) {
    Global.setValueByPath(this.currentSUB, ctrl.target.name, ctrl.target.value);
    this.setState({ changed: true });
  }

  private _saveSUB() {
    // if (!this.currentSUB.BrandId) {
    //   let br = StorageHelper.getBrnads();
    //   this.currentSUB.BrandId = linq.from<Brand>(br)
    //     .where(x => x.Key == this.props.brandKey)
    //     .first().Id;
    // };

    if (this.currentSUB.Properties.length == 0) {

    }

    var arr_from_json: any = undefined;

    if (this.currentSUB.Model === '') {


    } else {
      arr_from_json = JSON.parse(this.currentSUB.Model);
    }

    if (arr_from_json === undefined) {
      arr_from_json = JSON.parse('{"product":{},"xml":{},"product_ui":{"ui_tooltip":"","ui_name":"","ui_extra":""}}');
    }

    arr_from_json.product_ui.ui_name = this.currentSUB.ui_name;
    arr_from_json.product_ui.ui_tooltip = this.currentSUB.ui_tooltip;
    arr_from_json.product_ui.ui_extra = this.currentSUB.ui_extra;

    this.currentSUB.Model = JSON.stringify(arr_from_json);


    Api.fetch(this.url + 'saveSUB/', this.currentSUB, "POST",
      (api: IApiResponse) => {
        this.setState({
          mode: EScreenMode.view,
          subs: api.data
        });
      });
  };


  private onDataArrival(api: IApiResponse) {
    this.setState({
      mode: EScreenMode.view,
      subs: api.data
    });
  };
};

export default SUBscreen;