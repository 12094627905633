import { SUB } from "./SUB";

export class Brand {
  public id: number = 0;
  public name: string = "";
  public Key?: string = "";
  public Sort?: number = 0;
  public keyBrand?: string = "";
  public subs?: SUB[] = [];
  public brandProps?: BrandProps = new BrandProps();
  public businessCaseId?: string = "";
}

class BrandProps {
  public productsCount: number = 0;
  public subsCount: number = 0;
  public servicesCount: number = 0;
  public productSubsCount: number = 0;
  public productSubsServicesCount: number = 0;
  public serviceExcludesCount: number = 0;
}
