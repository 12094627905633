import React from 'react';
import {ListValueOption} from '../../model/data/ListValueOption';
import { EScreenMode } from '../../model/app/Enums';
import Api from '../../utils/Api';
import {IApiResponse} from '../../model/app/IApiResponse';
import { Product, IProductEditModel } from '../../model/data/Product';
import { JSXElement } from '@babel/types';
import { Checkbox } from 'office-ui-fabric-react';
import linq from 'linq';


//will return model class from the database we predifined it in Product-> first make a new entity for it ListValueOption
export interface IServiceScreenState {
 
    mode: EScreenMode;
};

//this component will receive productId from productScreen in second pivot item
//the second param is the interface above 
export class EditPanel extends React.Component<any, IServiceScreenState> {
    private url: string = "activationdata/";

    private model?: IProductEditModel; 

    constructor(props: any){
        super(props);

        this.onDataArrival = this.onDataArrival.bind(this);
        this.onChangeService = this.onChangeService.bind(this);

        this.state = {
            mode: EScreenMode.loading
        };

        Api.fetch( this.url + 'getProduct/'+props.productId + '?brandKey=' + props.brandKey , undefined, "GET", this.onDataArrival);
    }

    public render() {
        if(this.state.mode === EScreenMode.loading) {
            return <div>loading...</div>
        } else {
            return this.renderServices();
        }
    };

    private onChangeService(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, isChecked?: boolean): void {
        if(ev==undefined) return ;
        let s:string = (ev.target as HTMLElement).id;
        s = s.replace('svc-', '');
       
        if(this.model==undefined || this.model.product==undefined) return;
       
        var svc = linq.from<ListValueOption>(this.model.services)
            .where(x=>x.value == s)
            .firstOrDefault();
            console.log(svc);
            console.log(isChecked);

        
             this.saveService({productId: this.model.product.Id,serviceId: Number(svc.value),action: isChecked?1:2});
           
    };

    private saveService(model: any){
        Api.fetch( this.url + 'addRemoveService', model, "POST", 
        (api: IApiResponse)=>{
            this.model=api.data;
            this.setState({
                mode: EScreenMode.view,               
            });
        });
    }

    private renderServices() : JSX.Element{
       if(this.model==null) {return <></>;}

       let ctrl = (
        <>
            {
                this.model.services.map((i, idx) => (
                    <Checkbox id={"svc-" + i.value} key={idx} checked={i.selected} label={i.text} onChange={this.onChangeService}/>
                ))
            }
        </>
       )

        return ctrl;
    }

    private onDataArrival (api: IApiResponse) {
        //if in setSate exception is thrown we forgot to bind the method onDataArrival
        this.model = api.data;
        this.setState({
            mode: EScreenMode.view,
        });
    };
}


export default EditPanel;

