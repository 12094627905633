import { ActionButton, Checkbox, DefaultButton, Dropdown, Panel, PanelType, Pivot, PivotItem, PrimaryButton, TextField } from "office-ui-fabric-react";
import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import TableFilter from "../../../components/table/TableFilter";
import { EScreenMode } from "../../../model/app/Enums";
import LoaderVf, { ELoaderVfType } from "../../../Support/LoaderVf";
import AccountManager from "../../../utils/AccountManager";
import { EUserType, IAmPasswordChange, IAmUser } from "../Models";
import * as linq from 'linq';
import IKeyText from "../../../model/app/IKeyText";
import IIdName, { IStructures } from "../../../model/app/IIdName";
import StorageHelper from "../../../utils/StorageHelper";
import { DEFAULT_ROW_HEIGHTS } from "office-ui-fabric-react/lib/components/DetailsList/DetailsRow.styles";
import DialogLoader from "../../../Support/DialogLoader";
import { MenuItems } from "../../../utils/Defaults";

export interface IUserAdminProps
{
    id: string;
    onDismiss: any;
    pivot: string;
};

export interface IUserAdminState
{
    mode: EScreenMode;
    pivot: string;
    model?: IAmUser;
    modelPw: IAmPasswordChange;
};

export class AmUserAdminPanel extends React.Component<IUserAdminProps,IUserAdminState> 
{
    private static prefix:string = 'ef';
    private am:AccountManager = new AccountManager();
    private struct:IStructures = StorageHelper.getStructures();

    public static pivotPassword = MenuItems.pwPatch;
    public static pivotReset = MenuItems.resetUser;

    private canedit: boolean = false;
    private original?: IAmUser;

    constructor(props: any) {
        super(props);

        this.state = {
            mode: EScreenMode.loading,
            pivot: this.props.pivot,
            modelPw: {password: '', repeat: ''}
        };
    };   

    componentDidMount(): void {
        this.loadData();
    };

    static getPivots = () : string[] => {
        return [AmUserAdminPanel.pivotPassword, AmUserAdminPanel.pivotReset];
    };

    render = () => {
        let ctrl = <></>;

        var m = this.state.model as IAmUser;

        this.canedit = this.state.mode == EScreenMode.edit || this.state.mode == EScreenMode.new;
        
        ctrl = <>
            {
                this.state.mode == EScreenMode.loading || (this.state.model && this.state.model.error) ? <LoaderVf type={ELoaderVfType.full} message={LoaderVf.parseApiError(this.state.model ? this.state.model.error : undefined)}/> : null
            }     
            <Panel isOpen={true} onDismiss={this.onDismiss} type={PanelType.medium} headerText="Benutzer" isFooterAtBottom={true} onRenderFooterContent={this.onRenderFooterContent}>        
                <Pivot onLinkClick={(e) => { this.switchView(e!.props.itemKey) }}  defaultSelectedKey={this.state.pivot}>
                    <PivotItem itemKey={AmUserAdminPanel.pivotPassword} headerText="Password" onClick={()=>{this.switchView(AmUserAdminPanel.pivotPassword)}}>
                        <Container>
                            <Row>
                                <Col>
                                    {this.renderPassword(this.state.modelPw)}
                                </Col>
                            </Row>
                        </Container>
                    </PivotItem>

                    <PivotItem itemKey={AmUserAdminPanel.pivotReset} headerText="Zurücksetzen" onClick={()=>{this.switchView(AmUserAdminPanel.pivotReset)}}>
                        <Container>
                                <Row>
                                    <Col>
                                        {this.renderReset(m)}
                                    </Col>
                                </Row>
                        </Container>            
                    </PivotItem>
                </Pivot>
            </Panel>
            </>

        return ctrl;
    };

    private switchView = (key) => {
        if(this.state.pivot != key) {
            this.setState({mode:EScreenMode.view, pivot: key});
        };
    };

    private renderPassword = (m: IAmPasswordChange) => {
        if(this.state.model)
        {
            let ctrl = <Container className="ee">
                <Row>
                    <Col md={2} xs={2}>Benutzername</Col>
                    <Col md={4} xs={4}>{this.state.model.username}</Col>
                </Row>
                <Row>
                    <Col md={3} xs={3}>Neues Kennwort</Col>
                    <Col md={9} xs={9}>
                        <TextField id={AmUserAdminPanel.prefix + '-password'} disabled={false} value={m.password} onChange={(e,v) => { m.password = v || ''; this.setState({modelPw: m});}} />                     
                    </Col>
                </Row>                
                <Row>
                    <Col md={3} xs={3}>Wiederholen</Col>
                    <Col md={9} xs={9}>
                        <TextField id={AmUserAdminPanel.prefix + '-repeat'} disabled={false} value={m.repeat} onChange={(e,v) => { m.repeat = v || ''; this.setState({modelPw: m});}} />                   
                    </Col>                  
                </Row>                                  
            </Container>

            return ctrl;
        }
    };

    private renderReset = (m:IAmUser) => {
        var canedit: boolean = this.state.mode == EScreenMode.edit || this.state.mode == EScreenMode.new;

        if(this.state.model)
        {
            let ctrl = <Container className="ee">
                <Row>
                    <Col md={2} xs={2}>Benutzername</Col>
                    <Col md={4} xs={4}>{this.state.model.username}</Col>
                </Row>
                <Row>
                    <Col md={3} xs={3}>Neue E-mail</Col>
                    <Col md={9} xs={9}>
                        <TextField id={AmUserAdminPanel.prefix + '-password'} disabled={false} value={m.email} onChange={(e,v) => { m.email = v || ''; this.setState({model: m});}} />                     
                    </Col>
                </Row>                
                <Row>
                    <Col md={3} xs={3}>Mobilfunknummer</Col>
                    <Col md={9} xs={9}>
                        <TextField id={AmUserAdminPanel.prefix + '-repeat'} disabled={false} value={m.mobile} onChange={(e,v) => { m.mobile = v || ''; this.setState({model: m});}} />                   
                    </Col>                  
                </Row>   
                <Row>
                    <Col md={2} xs={2}></Col>
                    <Col md={10} xs={10}>
                        Benutzer kriegt eine Wilkommensemail
                    </Col>
                </Row>                 
            </Container>

            return ctrl;
        }
    };

    private onRenderFooterContent = () : JSX.Element => {
        if(this.state.mode == EScreenMode.loading) 
        {
            return <></>
        }

        let ctrl = <div className="ea">
            {
                this.state.pivot == AmUserAdminPanel.pivotPassword ? 
                    <>
                        <ActionButton iconProps={{ iconName: 'Cancel' }} text="Abbrechen" onClick={()=>this.onDismiss()} />
                        <PrimaryButton text="Übernehmen"  onClick={()=>{this.onSave()}}/>
                    </>      
                    :  null
            }
            {
                this.state.pivot == AmUserAdminPanel.pivotReset ? 
                    <>
                        <ActionButton iconProps={{ iconName: 'Cancel' }} text="Abbrechen" onClick={()=>this.onDismiss()} />
                        <PrimaryButton text="Zurücksetzen"  onClick={()=>{this.onSave()}}/>
                    </>      
                    :  null
            }            
        </div>

        return ctrl;
    };

    onChangeRole = (c, r) => {
        if(this.state.model) {
            var u = this.state.model as IAmUser;

            var i = u.roles.indexOf(r);

            if(i >= 0) {
                u.roles = u.roles.splice(i, 1);
            } else {
                u.roles.push(r);
            };

            this.setState({model: u});
        };
    };

    onChange = (e, v, k) => {
        var key = k;
        
        if(this.state.model)
        {
            let u = this.state.model as IAmUser;

            if(key) {
                u[key] = v.key;
            } else { //text
                key = e.target.id.replace(AmUserAdminPanel.prefix + '-', '');
                u[key] = e.target.value;
            };

            this.setState({model: u});
        };
    };

    onDismiss = () => {
        this.props.onDismiss();
    };

    onSave = () => {
        if(this.state.pivot == AmUserAdminPanel.pivotPassword || this.state.pivot == AmUserAdminPanel.pivotReset) {
            this.patch();
        };
    };

    async patch() {
        this.setState({mode: EScreenMode.loading});
        var u = this.state.model as IAmUser;
        var x : any = '';

        if(this.state.pivot == AmUserAdminPanel.pivotPassword) {
            x = await this.am.patchPassword(u.username, this.state.modelPw.password);
        } else if (this.state.pivot == AmUserAdminPanel.pivotReset) {
            x = await this.am.resetUser(u.username, this.state.model as IAmUser);
        };

        if(x.error) {
            u.error = x.error;
        };

        this.setState({model: u, mode: EScreenMode.view});
    };

    async loadData () {
        var u = await this.am.getUser(this.props.id);
        this.original = JSON.parse(JSON.stringify(u as IAmUser));
        this.setState({model: u, mode: EScreenMode.view});
    };
};

export default AmUserAdminPanel;