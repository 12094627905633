export class ErrorMessages {
    public static title404 = 'DIE VON IHNEN GEWÄHLTE SEITE EXISTIERT LEIDER NICHT (MEHR).';
    public static message404 = 'Sehr geehrte Kundin, \nSehr geehrter Kunde, \n\ndie von Ihnen gewählte Seite oder Resource konnte nicht gefunden werden. Bitte versuchen Sie es erneut. \nSollten weiterhin Probleme auftreten, freuen wir uns, wenn Sie mit uns Kontakt aufnehmen und das Problem beschreiben. \nSie helfen uns dadurch, die Seite weiter zu optimieren. \nIhr otelo-EASY Team';

    public static title403 = 'ZUGRIFF VERWEIGERT';
    public static message403 = 'Sie verfügen nicht über ausreichnde Rechte auf die gewünschte Resource zuzugreiffen.';

    public static apiError: 'Bei der Verarbeitung Ihrer Anforderung ist ein Fehler aufgetreten.';
    public static error = 'Fehler';
};

export class ValidationMessages {
    public static required = 'Pflichtfeld';
    public static numeric = 'Numerisch';
    public static errorPasswordOutsideCustomerData = "Bitte achten Sie auf die Kennwortrichtlinie.";
    public static nameNotValidError = 'Bitte prüfen Sie Ihre Eingaben und versuchen es erneut.';
    public static errorEmailnotValid = 'E-Mail hat nicht das richtige Format';
    public static errorMobile = 'Handynummernformat ist ungültig';
    public static exactLength = 'Das Feld muss genau {0} Stellen lang sein.';
    public static minLength = 'Das Feld muss mind. {0} Stellen lang sein.';
};

export class MainTexts {
    public static appName = 'Backoffice';
    public static save = 'Speichern';
    public static apply = 'Anwenden';
    public static edit = 'Bearbeiten';
    public static cancel = 'Abbrechen';

    public static id = 'ID';
    public static freetext = 'Freitext';
    public static status = 'Status';
    public static dealer = 'Händler';
    public static user = 'Benutzer';
    public static address = 'Adresse';
    public static date = 'Datum';
};