import React, { useEffect, useState } from "react";
import AccountManager from "../../../utils/AccountManager";
import { IAmRole } from "../../../model/data/IAmRole";
import { Col, Row } from "react-bootstrap";

export const RoleTable = ({ currentRuleId }) => {
    const [availableRoles, setAvailableRoles] = useState<IAmRole[]>([]);
    const [selectedRoles, setSelectedRoles] = useState<IAmRole[]>([]);
    const [savedRoles, setSavedRoles] = useState<IAmRole[]>([]);
    const amManager = new AccountManager();

    useEffect(() => {
        fetchRolesAndPortalRelations();
        fetchSavedRoles();
    }, []);

    const fetchRolesAndPortalRelations = async () => {
        try {
            const roles = await amManager.getRoles();
            const portalRoles = await amManager.getPortalRelationTable();

            const addedRoleIds = portalRoles.map((role) => role.roleId);
            const filteredRoles = roles.filter((role) => !addedRoleIds.includes(role.id));
            const filteredSavedRoles = roles.filter((savedRole) => addedRoleIds.includes(savedRole.id))
            
            setAvailableRoles(filteredRoles);
            setSavedRoles(filteredSavedRoles)
        } catch (error) {
            console.error("Error fetching roles and portal relations:", error);
        }
    };

    const fetchSavedRoles = async () => {
        try {
            const portalRoles = await amManager.getPortalRelationTable();

            // Get an array of roleIds from portalRoles
            const savedRoleIds = portalRoles.map((savedRole) => savedRole.roleId);

            // Filter the roles from availableRoles using the savedRoleIds
            const matchingRoles = availableRoles.filter((role) => savedRoleIds.includes(role.id));
            
            setSavedRoles(matchingRoles);
        } catch (error) {
            console.error("Error fetching saved roles:", error);
        }
    };


    const handleSelectRole = (role: IAmRole) => {
        setSelectedRoles([...selectedRoles, role]);
        setAvailableRoles(prevAvailableRoles => prevAvailableRoles.filter((r) => r.id !== role.id));
    };

    const handleDeselectRole = (role: IAmRole) => {
        setSelectedRoles(prevSelectedRoles => prevSelectedRoles.filter((r) => r.id !== role.id));
        setAvailableRoles(prevAvailableRoles => [...prevAvailableRoles, role]);
    };

    const handleSaveRoles = async () => {
        try {
            const rolesToSave = [...selectedRoles]; 
            for (const role of selectedRoles) {
                const model = {
                    portalAccessRuleId: currentRuleId,
                    roleId: role.id,
                };
    
                const response = await amManager.postRuleIdAndRoleId(model);
                console.log(`Role '${role.name}' saved:`, response);
            }
    
            setSelectedRoles([]);
            setSavedRoles(prevSavedRoles => [...prevSavedRoles, ...rolesToSave]); 
        } catch (error) {
            console.error("Error saving roles:", error);
        }
    };

    return (
        <div style={{ display: "flex" }}>
            <div style={{ flex: "1", padding: "10px", border: "1px solid #ccc", overflowY: "hidden", minWidth: "200px" }}>
                <h3>Available Roles</h3>
                <ul style={{ overflowY: "auto", maxHeight: "240px" }}>
                    {availableRoles.map((role) => (
                        <li key={role.id}>
                            {role.name}
                            <button style={{ marginLeft: "5px" }} onClick={() => handleSelectRole(role)}>
                                &gt;
                            </button>
                        </li>
                    ))}
                </ul>
            </div>
            <div style={{ flex: "1", padding: "10px", border: "1px solid #ccc", overflowY: "hidden", minWidth: "200px" }}>
                <h3>Selected Roles</h3>
                <ul style={{ overflowY: "auto", maxHeight: "240px" }}>
                    {selectedRoles.map((role) => (
                        <li key={role.id}>
                            {role.name}
                            <button style={{ marginLeft: "5px" }} onClick={() => handleDeselectRole(role)}>
                                &lt;
                            </button>
                        </li>
                    ))}
                </ul>
                <button onClick={handleSaveRoles}>Save Roles</button>
            </div>
            <div style={{ flex: "1", padding: "10px", border: "1px solid #ccc", overflowY: "hidden", minWidth: "200px" }}>
                <h3>Saved Roles</h3>
                <ul>
                    {savedRoles.map((savedRole) => {
                        return <li key={savedRole.roleId}>{savedRole.name}</li>;
                    })}
                </ul>
            </div>
        </div>
    );
};
