import { IBookingOrder } from "../../model/data/IBookingOrder";
import React, { useEffect, useState } from "react";
import BookingManager from "./BookingManager";
import { EScreenMode } from "../../model/app/Enums";
import {
  Panel,
  PanelType,
  PivotItem,
  TextField,
  Text,
} from "office-ui-fabric-react";
import { Col, Container, Row } from "react-bootstrap";
import { MainTexts } from "../../utils/messages";

export const OrderScreen = ({
  id,
  brandId,
  status,
  activationType,
  businessCase,
  onDismiss,
}) => {
  const bookingManager: BookingManager = new BookingManager();

  const [mode, setMode] = useState(EScreenMode.loading);
  const [order, setOrder] = useState<IBookingOrder | undefined>(undefined);
  const [logWebReq, setLogWebReq] = useState<IBookingOrder | undefined>(
    undefined
  );

  useEffect(() => {
    const fetchData = async () => {
      const data = await bookingManager.getOrder(id);
      setOrder(data);
      setMode(EScreenMode.view);
    };

    fetchData();
  }, [id]);

  return (
    <Panel
      isOpen={true}
      onDismiss={onDismiss}
      onAbort={onDismiss}
      type={PanelType.medium}
      headerText="Order"
      isFooterAtBottom={true}
    >
      <PivotItem itemKey={id} headerText="Data">
        <Container>
          <Row>
            <Col>
              <Container className="ee">
                <Row>
                  <Col md={2} xs={2}>
                    {MainTexts.id}
                  </Col>
                  <Col md={10} xs={10}>
                    <TextField
                      id={id + "-id"}
                      readOnly={true}
                      value={id.toString()}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={2} xs={2}>
                    Brand
                  </Col>
                  <Col md={10} xs={10}>
                    <TextField
                      id={brandId + "-brand"}
                      readOnly={true}
                      value={brandId}
                    />
                  </Col>
                </Row>
                <Row>
                  {order && order.documents && order.documents.length > 0 && (
                    <>
                      <Col md={2} xs={2}>
                        Documents
                      </Col>
                      <Col md={10} xs={10}>
                        {order?.documents.map((document) => (
                          <Text key={document.id} block>
                            <strong>Type:</strong> {document.type},{" "}
                            <strong>Content Type:</strong>{" "}
                            {document.contentType},<strong> ID:</strong>{" "}
                            {document.id}, <strong>Name:</strong>{" "}
                            {document.name}
                          </Text>
                        ))}
                      </Col>
                    </>
                  )}
                </Row>
              </Container>
            </Col>
          </Row>
        </Container>
      </PivotItem>
    </Panel>
  );
};
