import * as React from 'react';
import linq from 'linq';
import { ErrorMessages, ValidationMessages } from '../utils/messages';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { TextField, Link, Dialog, DialogFooter, DialogType, IDialogContentStyles } from 'office-ui-fabric-react';
import AppRouter from '../utils/AppRouter';
import Global from '../utils/Global';
import StorageHelper from '../utils/StorageHelper';
import IUser from '../model/app/IUser';
import Api from '../utils/Api';
import { findLastIndex } from 'lodash';
import { EValidationOption, IValidationOption, Validation } from '../utils/Validation';
import AccountManager from '../utils/AccountManager';
import { IAmUserChangePasswordRequest } from './am/Models';
import { EScreenMode } from '../model/app/Enums';
import LoaderVf, { ELoaderVfType } from '../Support/LoaderVf';
import { IApiError } from '../utils/ApiAsync';
import IKeyText from '../model/app/IKeyText';
import { WebSites } from '../utils/Defaults';
import { IApiResponse } from '../model/app/IApiResponse';



export interface IEditPasswordScreenProps {

};

export interface IEditPasswordScreenState {
    hidden: boolean;
    changed: boolean;
    disableSave: boolean;
    mode: EScreenMode;
    model: PasswordModel;
};

export enum EApiResultStatus {
    undefined = 0,
    ok = 1,
    failed = 2,
    invalid = 3
}

export interface PasswordModel {
    clientId: number;
    username: string;
    currentPassword: string;
    newPassword: string;
    newPasswordRepeat: string;
    error?: IApiError;
}



export class EditPassword extends React.Component<IEditPasswordScreenProps, IEditPasswordScreenState> {
    private url: string = "activationdata/";
    private model: PasswordModel;
    private errorText: string;
    private val: Validation = new Validation();
    private am: AccountManager = new AccountManager();
    private websites: IKeyText[] = StorageHelper.getWebsites();

    constructor(props: IEditPasswordScreenProps) {

        super(props);
        this._refreshModel = this._refreshModel.bind(this);


        let u: IUser | undefined = StorageHelper.getUser();
        this.errorText = '';
        this._onNo = this._onNo.bind(this);

        this.model = { currentPassword: '', newPassword: '', newPasswordRepeat: '', clientId: u !== undefined ? u.ClientId : 0, username: u !== undefined ? u.username : '', error: undefined }


        console.log(u?.ClientId, u?.username)

        this.state = {
            hidden: true,
            changed: false,
            disableSave: false,
            mode: EScreenMode.view,
            model: this.model
        };
    };

    public render() {
        let ctrl = (
            <>
                {
                    this.state.mode == EScreenMode.loading || (this.state.model && this.state.model.error)
                        ?
                        <LoaderVf type={ELoaderVfType.full} message={LoaderVf.parseApiError(this.state.model.error ? this.state.model.error : undefined)} />
                        :
                        null
                }
                <Dialog
                    hidden={this.state.hidden}
                    onDismiss={this._onNo}
                    minWidth={600}
                    dialogContentProps={{
                        type: DialogType.normal,
                        title: this.errorText,
                    }}
                    modalProps={{
                        titleAriaId: this.errorText,
                        // subtitleAriaId: t2,
                        isBlocking: false,
                        containerClassName: 'ms-dialogMainOverride'
                    }}
                >
                    <DialogFooter>
                    </DialogFooter>
                </Dialog>

                <Container style={{ backgroundColor: '#F8F8F8', width: '1000px', borderColor: '#C0C0C0', borderRadius: '15px', boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.19)' }}>
                    <Row className="justify-content-md-center">
                        <Col xs={6} md={6}>
                            <TextField label="Aktuelles Passwort" name="currentPassword" required errorMessage={Validation.getMessage(this.model, 'currentPassword')} value={this.model.currentPassword} onChange={this._refreshModel} />
                        </Col>
                    </Row>
                    <Row className="justify-content-md-center">
                        <Col xs={6} md={6}>
                            <TextField label="Neues Passwort"
                                //  onGetErrorMessage={this._getErrorMessageNameField}
                                errorMessage={Validation.getMessage(this.model, 'newPassword')}
                                validateOnLoad={false} name="newPassword" required value={this.model.newPassword} onChange={this._refreshModel} />
                        </Col>
                    </Row>
                    <br />
                    <Row className="justify-content-md-center">
                        <Col xs={6} md={6}>
                            <p style={{ color: '#cd9575', fontSize: '12px' }}>* Das Kennwort muss mind. 8 und max. 30 Zeichen lang sein. Das Kennwort muss alphanumerisch sein und muss mindestens einen Großbuchstaben enthalten. Das Kennwort darf keine Umlaute und Sonderzeichen enthalten.</p>
                        </Col>
                    </Row>
                    <Row className="justify-content-md-center">
                        <Col xs={6} md={6}>
                            <TextField label="Neues Passwort bestätigen" name="newPasswordRepeat" errorMessage={Validation.getMessage(this.model, 'newPasswordRepeat')} required value={this.model.newPasswordRepeat} onChange={this._refreshModel} />
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col xs={8} md={8}>
                        </Col>
                        <Col xs={2} md={2}>
                            <Link onClick={() => { AppRouter.routeTo(window.location.href, '/') }}>Abbrechen</Link>
                        </Col>
                        <Col xs={2} md={2}>
                            <Button size="sm" disabled={this.state.disableSave} variant="primary" onClick={() => { this.onSave() }}>Speichern</Button>
                        </Col>
                    </Row>
                    <br />
                </Container>
            </>
        );

        return ctrl;
    };

    private _onNo() {
        this.setState({ hidden: true });
    };

    private async onSave() {
        if (!this.val.validateModel(this.model, this.getValidationOptions())) {
            this.setState({ changed: true });
            return;
        } else {
            let mode = this.websites.find(x => x.key == WebSites.am);
            if (mode) {
                let model: IAmUserChangePasswordRequest = {
                    passwordNew: this.model.newPassword,
                    passwordRepeat: this.model.newPasswordRepeat,
                    username: this.model.username,
                    password: this.model.currentPassword
                };
                this.setState({ mode: EScreenMode.loading })
                let res = await this.am.changePassword(model);

                if (res.error) {
                    this.model.error = res.error!;
                    if (res.error.detail) {
                        if (res.error.detail.code === '7x0900002-400') {
                            this.model.error.detail.text = 'Ihr aktuelles Passwort passt nicht zu unseren Systemen.';
                            this.model.error.message = 'Ihr aktuelles Passwort passt nicht zu unseren Systemen.';
                        } else if (res.error.detail.code === '7x0900018-400') {
                            this.model.error.message = 'Die neu angegebenen Passwörter stimmen nicht überein.';
                            this.model.error.detail.text = 'Die neu angegebenen Passwörter stimmen nicht überein.';
                        } else if (res.error.detail.code === '7x0900017-400') {
                            this.model.error.message = 'Dein neues Passwort ist gleich mit deinem bisherigen. Bitte verwende ein neues Passwort. ';
                            this.model.error.detail.text = 'Dein neues Passwort ist gleich mit deinem bisherigen. Bitte verwende ein neues Passwort. ';
                        }
                    }
                    this.setState({ mode: EScreenMode.view, model: this.model })
                } else { //everything is fine use the error object to show a message it was saved             
                    this.errorText = 'Das Passwort wurde erfolgreich gespeichert.';
                    this.setState({
                        hidden: false,
                        mode: EScreenMode.view
                    });
                }
            } else {
                Api.fetch(this.url + 'changePassword', this.model, "POST",
                    (api: IApiResponse) => {
                        if (api.data.status !== EApiResultStatus.ok) {
                            this.errorText = api.data.notes[0].exception.Message;

                            this.setState({
                                hidden: false
                            });
                        } else {
                            this.errorText = 'Das Passwort wurde erfolgreich gespeichert.';

                            this.setState({
                                hidden: false
                            });
                        }
                    });
            }
        };
    }

    private _refreshModel(ctrl: any) {
        Global.setValueByPath(this.model, ctrl.target.name, ctrl.target.value);
        this.setState({ changed: true });
    };

    private getValidationOptions = (): IValidationOption[] => {
        var all: IValidationOption[] = [];
        all.push({ key: 'newPassword', type: EValidationOption.required, message: ValidationMessages.required });
        all.push({ key: 'currentPassword', type: EValidationOption.required, message: ValidationMessages.required });
        all.push({ key: 'newPasswordRepeat', type: EValidationOption.required, message: ValidationMessages.required });
        all.push({ key: 'newPassword', type: EValidationOption.passwordOutsideCustomerData, message: ValidationMessages.errorPasswordOutsideCustomerData });
        all.push({ key: 'newPasswordRepeat', type: EValidationOption.passwordOutsideCustomerData, message: ValidationMessages.errorPasswordOutsideCustomerData });

        return all;
    };
};

export default EditPassword;