import React, { useEffect, useState } from "react"
import Global from "../../../utils/Global";
import { ILogJournal } from "../../../model/data/ILogJournal";
import { MenuItems, StorageKeys, WebSites } from "../../../utils/Defaults";
import { EScreenMode } from "../../../model/app/Enums";
import { ContextualMenuItemType, IContextualMenuItem } from "office-ui-fabric-react";
import TableWrapper, { ITableWrapperProps, TableConfig } from "../../../components/table/TableWrapper";
import IKeyText from "../../../model/app/IKeyText";
import StorageHelper from "../../../utils/StorageHelper";
import { from } from "linq";
import { ETableSource } from "../../../components/controls/Table";
import { EControlType } from "../../../components/table/enums/control-type.enum";
import LogManager from "../../../utils/LogManager";

export interface ILogJournalState extends ILogJournal {
    mode: EScreenMode;
    journal?: ILogJournal | undefined;
    types?: string | ILogJournal;
}

export const LogJournalScreen: React.FC<ILogJournalState> = () => {

    const [journalState, setJournalState] = useState<ILogJournalState | undefined>({
        operationId: "",
        status: 0,
        type: 0,
        mode: EScreenMode.view,
        journal: undefined,
    });
    const [current, setCurrent] = useState<ILogJournal | undefined>(undefined);

    const tc = TableConfig.createInstance({
        url:
            from<IKeyText>(StorageHelper.getWebsites())
                .toArray()
                .filter(x => x.key == WebSites.log)[0].text + 'log/journal/filter',
        sourceType: ETableSource.dynamic,
        arrayName: "data",
        sort: [],
        columns: [
          { name: "operationId", label: "Id", sortable: true },
          { name: "container", label: "Container", sortable: true },
          { name: "username", label: "Username", sortable: true },
          {
            name: "text",
            label: "Text",
            sortable: true,
            renderer: (r) => {
              return <p title={r.text}>{r.text}</p>;
            },
          },
          { name: "status", label: "Status", sortable: true },
          { name: "type", label: "Type", sortable: true },
          { name: "code", label: "Code", sortable: true },
          {
            name: "created",
            label: "Datum",
            sortable: true,
            renderer: (r) => {
              return Global.toGermanDate(r.created);
            },
          },
        ],
      });
      
    let cfg: ITableWrapperProps = {
        id: "rety",
        onItemChanged: (u) => {
            setCurrent(u)
        },
        model: { needle: "", paging: TableConfig.initPaging() },
        filter: [
            { type: EControlType.text, key: "needle", label: "Suche" },
            {
                type: EControlType.dropdown,
                key: "type",
                label: "Type",
                list: StorageHelper.getStructure("journalType", true)
            }
        ],
        config: tc,
    };

    return (
        <>
            <TableWrapper {...cfg} />
        </>
    );
}